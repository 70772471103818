import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SpinnerSmall} from "@wpa/components/lib/Spinner";
import {Button, formHandleInputChange, Input} from '@wpa/components/lib/Form';

import Notice from "@wpa/components/lib/Notice";
import AuthorList from "../author/List";
import {
	Row,
	Col
} from 'reactstrap';
import feathersAPI from "@wpa/feathers-client";
import withStateMachine from "../../functional/withStateMachine";
import {getBookStoreIdsByType} from '../../reducers/db/bookPublishers';
import {getAllAuthors} from '../../reducers/db/authors';

class ImportBookListItem extends React.Component {
	static displayName = 'ImportBookListItem';

	constructor(props){
		super(props);

		this.getName = this.getName.bind(this);

		this.state = {
			[this.getName()]: this.props.selected || false
		};

		this.handleLabelClick = this.handleLabelClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	getName(){
		return ImportBookListItem.displayName + '' + this.props.index;
	}

	handleLabelClick(e){
		//	If we don't stop here the modal closes :/
		e.stopPropagation();
	}

	handleChange(e){
		const self = this;
		formHandleInputChange.call(self, e)
			.then(() => {
				const state = self.state[self.getName()];

				//	Call parent list with index and item state
				if(self.props.onToggle){
					self.props.onToggle(self.props.index, state);
				}
			}
		);
	}

	render(){
		const book = this.props.book;

		const classes = ["author"];
		if(this.state.selected){
			classes.push("author-active");
		}

		const name = this.getName();

		return (
			<li className="col-12 col-sm-3" key={this.props.index}>
				<label htmlFor={name} onClick={this.handleLabelClick}>
					{book.image && <img alt="" src={book.image.url[0]} width="100%"/>}
					<Input type="checkbox" name={name}
						   onChange={this.handleChange}
						   defaultValue={false}
						   value={this.state[name]}
						   id={name}
					/>
					{book.title}
				</label>
			</li>
		);
	}
}

class ImportBookListBase extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			selectedBooks: []
		};

		this.toggleBookImport = this.toggleBookImport.bind(this);
		this.saveBooks = this.saveBooks.bind(this);
		this.handleSaveBooks = this.handleSaveBooks.bind(this);
	}

	componentDidMount(){
		// const self = this;

		const existingBooksASINs = this.props.asins || [];

		feathersAPI.service('import-books').timeout = 35000;
		feathersAPI.service('import-books').find({
			query: {
				authorName: this.props.author.name
			}
		})
			.then((json) =>{
				this.props.goToState('display', {
					books: json.data.filter(book =>{
						return !existingBooksASINs.includes(book.asin);
					})
				});
			})
			.catch(err =>{
				console.log('ERR!!!', err);
				//	@todo: bugsnug
				this.props.goToState('error', {message: 'Unable to fetch books.'});
			});
	}

	/**
	 * Update the selected books list
	 * @param index Integer Index of the selected book in the books list
	 * @param state Boolean Add or remove the book from the selected list
	 */
	toggleBookImport(index, state){
		const list = this.state.selectedBooks;
		if(state){
			list.push(index);
		} else {
			const listIndex = list.indexOf(index);
			if(listIndex !== -1){
				list.splice(listIndex, 1);
			}
		}
		this.setState({
			selectedBooks: list
		});
	}

	saveBooks(books){
		if(books && books.length > 0){
			return feathersAPI.service('books').create(
				books,
				{
					query: {
						authorId: this.props.author.id
					}
				}
			);
		}
	}

	handleSaveBooks(){
		const self = this;

		this.props.goToState('import', {}, () =>{
			const {books} = this.props.stateMachine;
			const booksToSave = [];

			//	Only handle selected books
			self.state.selectedBooks.forEach(index =>{
				if(books[index]){
					booksToSave.push(books[index]);
				}
			});

			if(booksToSave.length < 1){
				return;
			}

			//	Save books through api
			self.saveBooks(booksToSave)
				.then((result) =>{
					if(result && result.length > 0){
						return this.props.goToState('saved');
					}

					return this.props.goToState('error', {
						message: "Unable to save selected books.",
					});

				})
				.catch(err =>{
					//	@todo: bugsnug
					this.props.goToState('error', {message: "Unable to save selected books."});
				});
		});
	}

	layout(content){
		const {error, errorMessage, books} = this.props.stateMachine;
		return (
			<div>
				<hr/>
				<h3>{this.props.author.name} Books</h3>
				{content}
				{(error && !books) && <Notice color="danger">{errorMessage}</Notice>}
			</div>
		);
	}

	render(){
		const {booksLoaded, booksSaved, books, error, errorMessage} = this.props.stateMachine;

		if(!booksLoaded){
			return this.layout(
				<p><SpinnerSmall inline={true}/> Loading books...</p>
			);
		}

		if(booksSaved){
			return this.layout(
				<p>Books saved.</p>
			);
		}

		if(!books || books.length < 1){
			return this.layout(
				<p>No books found.</p>
			);
		}

		return this.layout(
			<div>
				<ul className="bookList row">{books.map((book, i) =>
					<ImportBookListItem index={i} key={i} book={book} onToggle={this.toggleBookImport}/>
				)}</ul>
				{(error && books && books.length > 0) && <Notice color="danger">{errorMessage}</Notice>}
				<p>
					<Button loading={this.state.importStarted} onClick={this.handleSaveBooks} type="primary">Save Books</Button>
				</p>
			</div>
		);


	}
}

const ImportBookList = withStateMachine(
	connect(state => ({
		store: state,
		asins: getBookStoreIdsByType(state, "asin"),
	}))(ImportBookListBase),
	{
		defaultName: 'loading',
		generate: (stateName, stateParams, previousState) =>{
			switch(stateName) {
				case 'saved':
					return {
						booksLoaded: true,
						booksSaved: true,
						books: [],
						importStarted: false,
						error: false,
						errorMessage: ''
					};
				case 'import':
					return {
						booksLoaded: true,
						booksSaved: false,
						books: previousState.books || [],
						importStarted: true,
						error: false,
						errorMessage: ''
					};
				case 'display':
					return {
						booksLoaded: true,
						booksSaved: false,
						books: stateParams.books || previousState.books || [],
						importStarted: false,
						error: false,
						errorMessage: ''
					};
				case 'error':
					return {
						booksLoaded: true,
						booksSaved: previousState.booksSaved || false,
						books: previousState.books || [],
						importStarted: false,
						error: true,
						errorMessage: stateParams.message || 'Error'
					};
				case 'loading':
				default:
					return {
						booksLoaded: false,
						booksSaved: false,
						books: [],
						importStarted: false,
						error: false,
						errorMessage: ''
					};
			}
		}
	});


export class BookImport extends React.Component {
	static displayName = 'BookImport';
	static propTypes = {
		authors: PropTypes.array
	};

	constructor(props){
		super(props);

		this.state = {
			authorsLoaded: false,
			booksLoaded: false,
			authors: {},
		};

		this.authorToggle = this.authorToggle.bind(this);
	}

	authorToggle(authorId){
		const authors = this.state.authors;

		authors[authorId] = ! authors[authorId];

		this.setState({
			authors: authors,
		});
	}

	render(){
		const {
			authors,
			// ...props
		} = this.props;

		const books = authors
			.filter(author => this.state.authors[author.id] === true)
			.map(author => {
				return <ImportBookList key={author.id} author={author}/>;
			})
		;

		return (
			<div className="container-fluid import">
				<Row className="filter">
					<Col>
						<p>Click on the author to see books available for import.</p>
						<AuthorList
							authors={authors}
							onToggle={this.authorToggle}
							selected={this.state.authors}
						/>
					</Col>
				</Row>
				{books}
			</div>
		);
	}
}

export default connect(state => ({
	authors: getAllAuthors(state)
}))(BookImport);