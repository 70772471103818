import React from 'react';
import {isEqual} from 'lodash';

import { Machine } from 'xstate';
import Logger from '@wpa/logger';

export const withStateMachineOld = (WrappedComponent, config = {}) =>{
	const name = config.defaultName || 'loading';
	const defaultParams = config.defaultParams || {};
	// const monitorProps = config.monitorProps;

	return class extends React.Component {
		static displayName = 'FSM';
		static component = WrappedComponent;

		constructor(props){
			super(props);
			Logger.warn('***** HOC State Machine deprecated *****', WrappedComponent.displayName);
			this._isUnMounted = false;

			this.goToState = this.goToState.bind(this);
			this.generate = config.generate.bind(this);

			this.state = {
				name: name,
				machine: {...this.generate(name, defaultParams, {}), stateName: name}
			};
		}

		componentDidMount() {
			Logger.debug('M+', WrappedComponent.displayName);
			this._isMounted = true;
			this._isUnMounted = false;
		}

		componentWillUnmount(){
			Logger.debug('M-', WrappedComponent.displayName);
			this._isMounted = false;
			this._isUnMounted = true;
		}

		componentDidUpdate(prevProps, prevState){
			let stateName = this.state.name;

			//	When a stateName is set and it's different from the currently set one
			if(this.props.stateName && this.props.stateName !== stateName){
				stateName = this.props.stateName;
			}

			//	If props change reset the current state
			if(prevProps && ! isEqual(this.props,prevProps)){
				this.setState({
					name: stateName,
					machine: {...this.generate(stateName, defaultParams, {}), stateName: stateName}
				});
			}
		}


		goToState(stateName, stateParams, callback = null){
			if(!stateName){
				return Logger.error(WrappedComponent.displayName, ': No state provided');
			}

			//	@todo: this is just a patch for now
			//	We check if it has been UNmounted rather than whether it is mounted, as
			//	this is to prevent changes after an unmount
			if(this._isUnMounted){
				Logger.debug('NOT MOUNTED', WrappedComponent.displayName);
				return;
			}

			const previousState = this.state ? {...this.state.machine} : {};
			const newState = {...this.generate(stateName, stateParams, previousState), stateName: stateName};
			Logger.debug('GOTO: ', WrappedComponent.displayName, ':', stateName, stateParams, newState);

			this.setState({
				name: stateName,
				machine: newState
			}, callback);
			const listener = 'on' + stateName.replace(/^\w/, c => c.toUpperCase()) + 'State';

			//	Kick off an event for state change
			if(this.props[listener]){
				this.props[listener](newState, previousState);
			}
		}

		render(){
			// ... and renders the wrapped component with the fresh data!
			// Notice that we pass through any additional props
			return <WrappedComponent stateMachine={this.state.machine} goToState={this.goToState} {...this.props}></WrappedComponent>;
		}
	};
};


export default withStateMachineOld;


export function addFSM(self, xstate, reducers = []){
	//	Add extra vars to the object within the xstate namespace
	self.xstate = {
		machine: Machine(xstate),
		TRANSITION_QUEUE: [],
		//	We have to first create a separate instance, otherwise the reducers will be linked
		reducers: reducers.slice(),
		command: command.bind(self)
	};

	//	Shorthand reducer for calling internal functions
	self.xstate.reducers.push(
		function internalReducer(action, event){
			if(typeof self[action] === 'function'){
				return self[action](action, event);
			}

			return {};
		}
	);

	//	Extra state param
	self.state.currentState = self.xstate.machine.initialStateValue;

	//	Keep this one top level
	self.transition = transition.bind(self);
}

export function transition(type, payload = {}, callback) {
	const self = this;

	if(payload && payload.type){
		Logger.warn('['+ self.constructor.displayName +'] Attempting to overwrite event type.');
	}

	const event = {
		...payload,
		type: type,
	};

	const logHeader = '['+ self.constructor.displayName +'::'+ this.state.currentState +'->'+ type +']';

	Logger.debug(logHeader, 'EVENT: valid:', !!self.xstate.machine.config.states[this.state.currentState].on[type], event);

	self.xstate.TRANSITION_QUEUE.push(event);
	Logger.debug(logHeader, `TRANSITION_QUEUE ADD: ${JSON.stringify(self.xstate.TRANSITION_QUEUE)}`);

	if (self.xstate.TRANSITION_QUEUE.length === 1) {
		const execFunction = ((evt) => new Promise((() => {
			const { currentState } = this.state;

			//	Not sure why we ended up calling this transition
			//	@todo: possibly might generate false positives for nested/more complex machines
			if(! self.xstate.machine.config.states[this.state.currentState].on[evt.type]){
				Logger.warn(logHeader, 'Calling a non-existing transition:', this.state.currentState, '->', evt.type);
			}

			const nextState = self.xstate.machine.transition(currentState, evt.type);
			const { value, actions } = nextState;
			Logger.debug(logHeader, `StateEvent: ${JSON.stringify(evt)}`);
			Logger.debug(logHeader, `NextState: ${JSON.stringify(nextState)}`);

			const listener = 'on' + self.constructor.displayName + evt.type.replace(/^\w/, c => c.toUpperCase());
			Logger.debug(logHeader, 'LISTENER: ', listener);

			if(self.xstate.machine.config.states[this.state.currentState].on[evt.type]){
				if(self.props[listener]){
					Logger.debug(logHeader, 'TRANSITION EXTERNAL LISTENER', listener);
					self.props[listener](event);
				}
				if(self[listener]){
					Logger.debug(logHeader, 'TRANSITION INTERNAL LISTENER', listener);
					self[listener](event);
				}
			}

			if (actions) {
				const actionState = actions
					.reduce((state, action) => ({
						...state,
						...this.xstate.command(action, evt, self),
					}), {});

				this.setState({
					currentState: value,
					...actionState,
				}, () => {
					self.xstate.TRANSITION_QUEUE.shift();

					Logger.debug(logHeader, `TRANSITION_QUEUE FINISH: ${JSON.stringify(self.xstate.TRANSITION_QUEUE)}`);

					if (self.xstate.TRANSITION_QUEUE[0]) {
						execFunction(self.xstate.TRANSITION_QUEUE[0]).catch(err => {
							Logger.error(err);
						});
					}

					//	?
					if(callback){
						Logger.debug(logHeader, 'CALLBACK:', callback);
						callback();
					}
				});
			}
		//	@todo: eslint complains about the bind - but this is going to be replaced so ignoring for now
		//	eslint-disable-next-line
		}).bind(this)));
		execFunction(self.xstate.TRANSITION_QUEUE[0]).catch(err => {
			Logger.error(err);
		});
	}
}

export function command(action, event) {
	// const self = this;
	return this.xstate.reducers
		.reduce((state, reducer) =>({
			...state,
			...reducer(action, event),
		}), {});
}
/*

When the component is created the default FSM state is set

When the component props change FSM regenerates the state

When


 */