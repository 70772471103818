import { getItemIdByParam } from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'SITES';
export const ADD_SITES = 'ADD_'+ serviceName;
export const UPDATE_SITES = 'UPDATE_'+ serviceName;
export const REMOVE_SITES = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */

export function getSiteByDomain(state, domain) {
	const siteId = getSiteIdByDomain(state, domain);

	return state.db.sites.byId[siteId];
}

export function getSiteIdByDomain(state, domain) {
	const stateSlice = state.db.sites;
	return getItemIdByParam(stateSlice, 'domain', domain);
}

export function getSiteById(state, siteId) {
	return state.db.sites.byId[siteId];
}

export function getAllSites(state) {
	const stateSlice = state.db.sites;

	return stateSlice.allIds.map(id => stateSlice.byId[id]);
}

export const addSites = items => ({
	type: ADD_SITES,
	payload: items
});

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);