import React from 'react';
import { imagePath } from '../utils/imagePath';
import PropTypes from 'prop-types';

//	@todo: check if the plain button is enough?
//	Since we don't need the styling at all we're not using our WPA button here

const handleSelect = onSelect => (e) => {
	e.preventDefault();
	return onSelect(parseInt(e.currentTarget.dataset.imageId, 10), parseInt(e.currentTarget.dataset.imageIndex, 10));
};

export const ImageList = ({ images = [], onSelect, selectedImageId }) => {

	return (
		<div className="image-select-list">
			{images.map((image, index) => {
				return (
					<div key={image.id} className={image.imageId === selectedImageId ? 'selected' :  ''}>
						<button
							onClick={handleSelect(onSelect)}
							data-image-id={image.imageId}
							data-image-index={index}
						>
							<img
								src={imagePath(encodeURI(image.Image.path))}
								width="100%"
								alt=""
							/>
								{image.Image.title}
							</button>
					</div>
				);
			})}
		</div>
	);
};

ImageList.propTypes = {
	/** List of images */
	images: PropTypes.array.isRequired,
	/** onSelect(imageId, imageIndex) - pass on details of the clicked image */
	onSelect: PropTypes.func.isRequired,
};

export default ImageList;