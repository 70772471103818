//	eslint-disable-next-line
import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Tree, Form } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllSiteMenuPagesByMenuId } from '../../reducers/db/siteMenuPages';
import { Button , FormAlert} from '@wpa/components/lib/Form';
import {isEqual} from 'lodash';
import {
	formStateMachine,
	xstateOnChange,
	FORM_ACTION,
	FORM_STATE
} from '@wpa/state-machine';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { useMachine } from '@xstate/react';
const { TreeNode } = Tree;

const stateMachine = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.SITE_MENU_PAGES].remove(context.menuPageId, {
						query: {
							
							includes: {
								page: true
							}
						}
					})
				)
				.then(res => {
					return resolve();
				})
				.catch(err => reject('Unable to remove page.'));
		})
});

const ListItemButton = ({
	pageTitle,
	menuId,
	pageId,
	menuPageId,
	onError,
	className
}) => {
	const dispatch = useDispatch();

	//	eslint-disable-next-line
	const [state, send] = useMachine(
		stateMachine.withFormContext(
			{
				dispatch,
				onError,
				menuId,
				pageId,
				menuPageId
			},
			{}
		)
	);

	const isSaving = state.matches(FORM_STATE.SUBMITTING);
	const isDisabled = isSaving || stateMachine.hasChanged(state);

	const handleSave = useCallback(
		() => {
			send(FORM_ACTION.SUBMIT, {});
		},
		//	eslint-disable-next-line
		[send, menuId, pageId, menuPageId]
	);

	return (
		<Form
			className={className}
			onFinish={handleSave}
			onChange={xstateOnChange(send)}
			layout="horizontal"
		>
			<Button
				type="primary"
				size="small"
				htmlType="submit"
				title={'Remove ' + pageTitle + ' page from the menu'}
				disabled={isDisabled}
				loading={isSaving}
			>
				DELETE
			</Button>
		</Form>
	);
};

function nestedMenu(pages, error, setError) {
	const menuData = [];
	//	Keep reference list so we can add children as needed
	const list = {};

	for (const item of pages) {
		const newItem = {
			id: item.id,
			children: [],
			title: (
				<span>
					{item.title}
					<ListItemButton
						className="float-right"
						pageTitle={item.title}
						menuId={item.menuId}
						pageId={item.pageId}
						menuPageId={item.id}
						onError={(em, e) => {
							if(e){
								error[item.id] = (
									<FormAlert
										description={em}
										style={{margin: '1rem 0 0 0'}}
									/>
								);
							} else {
								delete error[item.id];
							}
							setError([...error]);
						}}
					/>
					{error[item.id]}
				</span>
			),
			//	Key needs to be a string for the D&D to work
			key: item.id + ''
		};
		newItem.children = [];
		list[newItem.id] = newItem;

		//	@todo: fix this so that we look-up parent of the parent
		if (item.parentId && list[item.parentId]) {
			list[item.parentId].children.push(newItem);
		} else {
			menuData.push(newItem);
		}
	}

	return menuData;
}

const SiteMenuPageList = ({
	match: {
		params: { menuId }
	}
}) => {
	const pages = useSelector(state =>
		getAllSiteMenuPagesByMenuId(state, parseInt(menuId, 10))
	);
	const [gData, setGData] = useState([]);
	const [lastPages, setLastPages] = useState(pages);
	const [error, setError] = useState([]);

	if (! isEqual(pages, lastPages)) {
		setLastPages(pages);
		setGData(nestedMenu(pages, error, setError));
	}
	// let isModified = false;

	//	Reset this when we switch menus
	useEffect(() => {
		setGData(nestedMenu(pages, error, setError));
	//	eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuId, JSON.stringify(error), JSON.stringify(pages)]);

	const onDragEnter = info => {
		// expandedKeys 需要受控时设置
		// this.setState({
		//   expandedKeys: info.expandedKeys,
		// });
	};

	const onDrop = info => {
		const dropKey = info.node.props.eventKey;
		const dragKey = info.dragNode.props.eventKey;
		const dropPos = info.node.props.pos.split('-');
		const dropPosition =
			info.dropPosition - Number(dropPos[dropPos.length - 1]);

		const loop = (data, key, callback) => {
			data.forEach((item, index, arr) => {
				if (item.key === key) {
					return callback(item, index, arr);
				}
				if (item.children) {
					return loop(item.children, key, callback);
				}
			});
		};
		const data = [...gData];

		// Find dragObject
		let dragObj;
		loop(data, dragKey, (item, index, arr) => {
			arr.splice(index, 1);
			dragObj = item;
		});

		if (!info.dropToGap) {
			// Drop on the content
			loop(data, dropKey, item => {
				item.children = item.children || [];
				// where to insert 示例添加到尾部，可以是随意位置
				item.children.push(dragObj);
			});
		} else if (
			(info.node.props.children || []).length > 0 && // Has children
			info.node.props.expanded && // Is expanded
			dropPosition === 1 // On the bottom gap
		) {
			loop(data, dropKey, item => {
				item.children = item.children || [];
				// where to insert 示例添加到尾部，可以是随意位置
				item.children.unshift(dragObj);
			});
		} else {
			let ar;
			let i;
			loop(data, dropKey, (item, index, arr) => {
				ar = arr;
				i = index;
			});
			if (dropPosition === -1) {
				ar.splice(i, 0, dragObj);
			} else {
				ar.splice(i + 1, 0, dragObj);
			}
		}

		setGData(data);

		const loop2 = (data, key, parentId, callback) => {
			data.forEach((item, index, arr) => {
				if (item.key === key) {
					return callback(item, index, arr, parentId);
				}
				if (item.children) {
					return loop2(item.children, key, item.id, callback);
				}
			});
		};

		//	Get newParentId from the tree structure to avoid dealing with the variety of drop positions
		let newParentId;
		loop2(data, dragKey, null, (item, index, arr, pId) => {
			newParentId = pId;
		});

		let dp;
		//	Find the next page entry after the position we dropped into
		pages.forEach((p, i) => {
			if(p.id === parseInt(dropKey, 10)){
				if(dropPosition === -1){
					dp = pages[i-1];
				} else {
					dp = pages[i];
				}
			}
		});

		feathersServices[SERVICE.SITE_MENU_PAGES].patch('reorder', {
			id: dragObj.id,
			prevNodeId: dp ? dp.id : null,
			parentId: newParentId,
		});
	};

	//	Do not render the tree if we have no nodes
	//	Tree.defaultExpandAll only works on first render so we want to
	//	hold off from rendering until data is loaded
	if (gData.length === 0) {
		return null;
	}

	//	eslint-disable-next-line
	const loop = data =>
		data.map(item => {
			const title = (
				<div>
					{item.title}
					{error[item.id]}
				</div>
			);
			if (item.children && item.children.length) {
				return (
					<TreeNode key={item.key} title={title}>
						{loop(item.children)}
					</TreeNode>
				);
			}
			return <TreeNode key={item.key} title={title} />;
		});

	return (
		<div>
			<Tree
				showLine
				className="draggable-tree"
				defaultExpandAll
				draggable
				blockNode
				onDragEnter={onDragEnter}
				onDrop={onDrop}
				selectable={false}
				treeData={gData}
			/>
		</div>
	);
};

export default withRouter(SiteMenuPageList);
