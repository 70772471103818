import React from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { getBookById } from '../../reducers/db/books';
import BookPageLayout from './Layout';
import { getAuthorsByBookId } from '../../reducers/db/authorBooks';
import BookRoyaltiesForm from '../../components/books/RoyaltiesForm';
import { getBookPublishersByBookId } from '../../reducers/db/bookPublishers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TagList from '../../components/TagList';
import {
	getAllPublishersAsTags,
	getAllPublishersMap
} from '../../reducers/db/publishers';
import { getBookRoyaltiesByBookId } from '../../reducers/db/bookRoyalties';

export class BookRoyaltiesPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			publisherList: {}
		};

		//	Initial setup - set all as open
		props.publisherTags.forEach(
			//	@todo: this shouldn't mutate the state directly
			//	eslint-disable-next-line
			publisher => (this.state.publisherList[publisher.id] = true)
		);
	}

	render() {
		const props = this.props;
		const {
			authors,
			book,
			bookPublishersMap,
			publishersMap,
			publisherTags,
			bookRoyalties
		} = props;

		//	Check the state for toggle value
		const publisherList = this.state.publisherList;
		publisherTags.forEach(
			publisher =>
				(publisherList[publisher.id] = !!this.state.publisherList[publisher.id])
		);

		const royaltiesMap = {};

		bookRoyalties.forEach(item => {
			if (!royaltiesMap[item.publisherId + '_' + item.storeBookId]) {
				royaltiesMap[item.publisherId + '_' + item.storeBookId] = [];
			}
			royaltiesMap[item.publisherId + '_' + item.storeBookId].push(item);
		});

		const authorsMap = {};
		authors.forEach(item => (authorsMap[item.id] = item));

		return (
			<BookPageLayout pageName={'bookSales'}>
				<h2>Royalties</h2>

				<Row>
					<Col>
						<Card>
							<CardBody>
								<small>Show store details for:</small>
								<TagList
									// title="Publishers"
									tags={publisherTags}
									onToggle={id =>
										this.setState({
											publisherList: {
												...publisherList,
												[id]: !publisherList[id]
											}
										})
									}
									className="tagListText"
									listState={publisherList}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>

				<Row>
					{Object.keys(publisherList)
						.filter(id => publisherList[id])
						.map(publisherId => {
							const publisher = bookPublishersMap[publisherId] || {
								Publisher: publishersMap[publisherId]
							};

							return (
								<Col xl={6} key={'publisherSales_' + publisherId}>
									<Card>
										<CardBody>
											<h3>Amazon Royalties</h3>
											<BookRoyaltiesForm
												bookPublisherId={publisher.id}
												bookId={book.id}
												publisherId={parseInt(publisherId)}
												storeBookId={publisher.asin}
												storeBookIdField="asin"
												publisherName={publisher.Publisher.name}
												canEdit={publisher.Publisher.User.canEditRoyalties}
											/>
										</CardBody>
									</Card>
								</Col>
							);
						})}
				</Row>

				<h2>Royalty History</h2>

				<Row>
					<Col>
						<Card>
							<CardBody>
								<Table>
									<thead>
										<tr>
											<th>&nbsp;</th>
											<th>Royalty</th>
											<th>Name</th>
											<th>Book ID</th>
											<th>Active From</th>
											<th>Created At</th>
										</tr>
									</thead>
									<tbody>
										{bookRoyalties
											.sort(
												(a, b) => a.activeFrom < b.activeFrom || a.id > b.id
											)
											.map(br => {
												return (
													<tr key={br.id}>
														<td>
															{!br.authorId && (
																<FontAwesomeIcon
																	icon={['fas', 'briefcase']}
																	fixedWidth
																/>
															)}
															{br.authorId && (
																<FontAwesomeIcon
																	icon={['fas', 'user']}
																	fixedWidth
																/>
															)}
														</td>
														<td>{br.royaltyPercentage}%</td>
														<td>
															{!br.authorId &&
																publishersMap[br.publisherId].name}
															{br.authorId && authorsMap[br.authorId].name}
														</td>
														<td>{br.storeBookId}</td>
														<td>
															{new Date(br.activeFrom).toLocaleDateString()}
														</td>
														<td>
															{new Date(br.createdAt).toLocaleDateString()}
														</td>
													</tr>
												);
											})}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</BookPageLayout>
		);
	}
}

export default connect((state, props) => {
	const id = parseInt(props.match.params.id, 10);
	return {
		book: getBookById(state, id),
		authors: getAuthorsByBookId(state, id),
		bookPublishersMap: getBookPublishersByBookId(state, id),
		publisherTags: getAllPublishersAsTags(state),
		publishersMap: getAllPublishersMap(state),
		bookRoyalties: getBookRoyaltiesByBookId(state, id)
	};
})(BookRoyaltiesPage);
