//	eslint-disable-next-line
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	formItemLayout, 
	FormAlert
} from '@wpa/components/lib/Form';
import { SERVICE, feathersServices } from '@wpa/feathers-client';
import {FORM_ACTION, FORM_STATE, xstateOnChange, formStateMachine, xstateFormHandleInputChange} from '@wpa/state-machine';
import Logger from '@wpa/logger';
import {useMachine} from '@xstate/react';
import {Form, Checkbox} from 'antd';
import {useDispatch} from 'react-redux';

const m = formStateMachine({
	formLoading: (context, event) => 
		new Promise((resolve, reject) => {
			context.dispatch(feathersServices[SERVICE.SITE_SOCIAL_MEDIAS]
				.find({
					query: {
						siteId: context.siteId,
						socialMediaId: context.socialMediaId,
					}
				}, {})
			)
				.then(res => {
					context.value = res && res.value && res.value.total > 0;
					resolve();
				})
				.catch(err =>
					Logger.error(err) || reject('Unable to load social media details for siteId #'+ context.siteId +' and socialMediaId #'+ context.socialMediaId+'.')
				);
		}),
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context.value = event.value;

			// Save site social media link
			if(event.value){
				context.dispatch(feathersServices[SERVICE.SITE_SOCIAL_MEDIAS]
					.create({
						siteId: event.siteId,
						socialMediaId: event.socialMediaId,
					}, {})
				)
					.then(json => resolve())
					.catch(err => Logger.error(err) || reject('Unable to update site social media.'));

			//	Delete site social media link
			} else {
				context.dispatch(feathersServices[SERVICE.SITE_SOCIAL_MEDIAS]
					.remove(null, {
						query: {
							siteId: event.siteId,
							socialMediaId: event.socialMediaId,
						}
					})
				)
					.then(json => resolve())
					.catch(err => Logger.error(err) || reject('Unable to update site social media.'));
			}
		})
});

export const SiteSocialMedia = ({siteId, socialMediaId, typeId, label, text, icon, noForm}) => {
	const dispatch = useDispatch();

	const [state, send] = useMachine(
		m.withContext({
			socialMediaId,
			siteId,
			value: false,
			dispatch
		})
	);

	const {
		value,
		errorMessage
	} = state.context;

	const handleSubmit = useCallback(
		e => {
			// Need to override name as component uses a generic "value" variable but multiple can appear on the page 
			e.target.name = 'value';
			
			send(FORM_ACTION.SUBMIT, {
				siteId, 
				socialMediaId,
				...xstateFormHandleInputChange(e)
			});
		},
		[send, siteId, socialMediaId]
	);

	const isLoading = state.matches(FORM_STATE.LOADING);
	const isSaving = state.matches(FORM_STATE.SUBMITTING);
	const isError = state.matches(FORM_STATE.ERROR);
	const isDisabled = isSaving || isLoading;

	const content = (<Form.Item 
		{...formItemLayout} 
		label={(<span>
			{label}{' '}
			{icon && <FontAwesomeIcon icon={icon} fixedWidth size="lg" />}
		</span>)}
		htmlFor={'value_'+ socialMediaId}
	>
		<Checkbox 
			name={'value_'+ socialMediaId} 
			checked={value}
			onChange={handleSubmit}
			disabled={isDisabled} 
		>
			{text}
		</Checkbox>
		{isSaving && <span> [saving...]</span>}
		{isLoading && <span> [checking status...]</span>}
	</Form.Item>);

	const error = isError ? <FormAlert send={send} description={errorMessage} /> : null;

	if(noForm){
		return (
			<div onSubmit={handleSubmit}>
				{content}		
				{error}
			</div>
		);
	}

	return (
		<Form onChange={xstateOnChange(send)} onSubmit={handleSubmit}>
			{content}		
			{error}
		</Form>
	);
};

SiteSocialMedia.propTypes = {
	socialMediaId: PropTypes.number,
	typeId: PropTypes.number,
	label: PropTypes.string,
	text: PropTypes.string,
	icon: PropTypes.array,
	/** Use a div to avoid nested forms */
	noForm: PropTypes.bool,
};
SiteSocialMedia.defaultProps = {
	noForm: false
};

export default SiteSocialMedia;