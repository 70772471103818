import { getItemIdsByParam } from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'SITE_PAGES';
export const ADD_SITE_PAGES = 'ADD_'+ serviceName;
export const UPDATE_SITE_PAGES = 'UPDATE_'+ serviceName;
export const REMOVE_SITE_PAGES = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */

// export function getSiteByDomain(domain, store){
// 	const siteId = getSiteIdByDomain(domain, store);
//
// 	return store.db.sites.byId[siteId];
// }
//
// export function getSiteIdByDomain(domain, store){
// 	const siteId = (store.db.sites.allIds || [])
// 		.filter(siteId => store.db.sites.byId[siteId].domain === domain)
// 		.pop();
//
// 	return siteId;
// }

export const addSitePages = items => ({
	type: ADD_SITE_PAGES,
	payload: items
});

export const removeSitePages = items => ({
	type: REMOVE_SITE_PAGES,
	payload: items
});

export const getPageIdByPath = (state, path) => {
	const stateSlice = state.db.sitePages;
	return stateSlice.allIds
		.filter(itemId => stateSlice.byId[itemId] && stateSlice.byId[itemId].path === '/' + path)
		.shift();
};
export const getPageByPath = (state, path) => {
	const stateSlice = state.db.sitePages;
	const pageId = stateSlice.allIds
		.filter(itemId => stateSlice.byId[itemId] && stateSlice.byId[itemId].path === '/' + path)
		.shift();

	if(!pageId){
		return null;
	}

	return stateSlice.byId[pageId];
};
export const getAllSitePageIds = (state, siteId) => {
	const stateSlice = state.db.sitePages;
	return getItemIdsByParam(stateSlice, 'siteId', siteId);
};
export const getAllSitePagesBySite = (state, siteId) => {
	const stateSlice = state.db.sitePages;
	return getAllSitePageIds(state, siteId)
		.map(itemId => stateSlice.byId[itemId])
		//	Sort by main menu order, non-menu items to the bottom
		.sort((a, b) => {
			if(a.mainMenu === b.mainMenu) return 0;
			if(! a.mainMenu) return 1;
			if(! b.mainMenu) return -1;
			return a.mainMenu > b.mainMenu ? 1 : -1;
		})
		//	Sort non-menu items alphabetically
		.sort((a,b) => {
			if(a.mainMenu !== b.mainMenu) return 0;
			
			if(a.mainMenu === b.mainMenu && ! a.mainMenu){
				return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
			}

			return 0;
		})
	;
};
export const getSitePageById = (state, pageId) => {
	return state.db.sitePages.byId[pageId];
};

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);