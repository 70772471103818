import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {
	Row, Col,
	Card, CardBody, CardHeader, CardTitle,
} from 'reactstrap';

import PublisherLayout from "./Layout";
import PublisherDetailsForm from '../../components/publishers/DetailsForm';

export const PublisherDetailsPage = class extends React.Component {
	static displayName = 'PublisherDetailsPage';
	static propTypes = {
		publisherId: PropTypes.number.isRequired
	};

	render(){
		const props = this.props;

		const {
			publisherId
		} = props;

		return (
			<PublisherLayout page={"publisherDetailsPage"}>

				<Row>
					<Col>
						<Card>
							<CardHeader>
								<CardTitle>Publisher Contact Details</CardTitle>
							</CardHeader>
							<CardBody>
								<PublisherDetailsForm
									publisherId={publisherId}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>

			</PublisherLayout>
		);
	}
};

export default connect((state, props) => {
	const id = parseInt(props.match.params.id, 10);
	return {
		publisherId: id,
	};
})(PublisherDetailsPage);