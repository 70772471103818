import {getItemIdsByParam} from '../helpers';
import {createReducer} from '../helpers';

/*
 *	Constants
 */

const serviceName = 'BOOK_PUBLISHERS';
export const ADD_BOOK_PUBLISHERS = 'ADD_'+ serviceName;
export const UPDATE_BOOK_PUBLISHERS = 'UPDATE_'+ serviceName;
export const REMOVE_BOOK_PUBLISHERS = 'REMOVE_'+ serviceName;

/*
 *	Helper functions
 */

export function getBookPublishersByBookId(state, bookId){
	const stateSlice = state.db.bookPublishers;
	const itemIds = getItemIdsByParam(stateSlice, 'bookId', bookId);

	const bpMap = {};
	itemIds.forEach(itemId => {
		const bp = stateSlice.byId[itemId];
		//	We map to publisher ID not the bookPublisher ID
		bpMap[bp.publisherId] = {
			...bp,
			Publisher: state.db.publishers.byId[bp.publisherId] || { name: 'Publisher N/A' }
		};
	});

	return bpMap;
}

export function getBookPublishersOnlyByBookId(state, bookId){
	const stateSlice = state.db.bookPublishers;
	const itemIds = getItemIdsByParam(stateSlice, 'bookId', bookId);

	return itemIds.map(itemId => {
		const bp = stateSlice.byId[itemId];
		return bp;
	});
}

export function getBookStoreIdsByType(state, type){
	const stateSlice = state.db.bookPublishers;

	const ids = [];
	stateSlice.allIds.forEach(itemId => {
		const item = stateSlice.byId[itemId];
		if(item[type]){
			ids.push(item[type]);
		}
	});

	return ids;
}

export function getBookStoreIdsMap(state){
	const stateSlice = state.db.bookPublishers;
	const bookStateSlice = state.db.books;

	const ids = {};
	stateSlice.allIds.forEach(itemId => {
		const item = stateSlice.byId[itemId];
		ids[item['asin']] = {
			...item,
			Book: bookStateSlice.byId[item.bookId],
		};
	});

	return ids;
}

export const addBookPublishers = (payload) => ({
	type: ADD_BOOK_PUBLISHERS,
	payload: payload
});


// /**
//  * Book Publishers Reducer
//  * @param state
//  * @param action
//  * @returns {*}
//  */
// export const bookPublishersReducer = (state = {
// 	initialState: true,
// 	byId: {},
// 	allIds: [],
// }, action) => {
// 	let newState, payload;

// 	switch (action.type) {
// 		case feathersServices.getFindFulfilledAction(SERVICE.BOOK_PUBLISHERS):
// 			payload = action.payload.data || [];

// 			//	Fallthrough as the action is the same

// 		case ADD_BOOK_PUBLISHERS:
// 			newState = {...state};
// 			newState.initialState = false;

// 			payload = payload || action.payload;

// 			addItems(newState, payload);

// 			return newState;

// 		default:
// 			return state;
// 	}
// };

/*
 *	Reducer
 */
export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);