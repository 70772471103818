import { feathersServices, SERVICE} from "@wpa/feathers-client";

/**
 * Keep the filters in sync
 * @param state
 * @returns {*}
 */
export function resetSecondaryKeys(state, config){
	//	Shorthand
	const groups = config.groups || [];

	//	Reset the key array
	groups.forEach(group => state[group.name] = {});

	state.allIds.forEach(itemId => {
		const item = state.byId[itemId];

		groups.forEach(group => {
			//	Shorthand
			const groupArray = state[group.name][item[group.groupBy]];

			//	Add the id to the group array
			(groupArray || (state[group.name][item[group.groupBy]] = [])).push(itemId);
		});
	});

	return state;
}

/**
 * Add items to a state slice
 * Cover generic byId and allIds keys
 * @param state The state slice to manipulate
 * @param payload Array of items to add
 */
export function addItems(state, payload = []){
	//	If it's not an array, turn it into one
	if(!Array.isArray(payload)){
		payload = [payload];
	}

	payload.forEach(item => {
		//	Only add if doesn't exist
		if(!state.byId[item.id]){
			state.byId[item.id] = item;
			state.allIds.push(item.id);

		//	Replace existing contents otherwise
		} else {
			// state.byId[item.id] = _.merge(state.byId[item.id], item);
			//	@todo: double check if the above was done for performance reasons to limit refresh rates
			//	merge would misbehave when a nested target array had less items than the original
			state.byId[item.id] = {...item};
		}

		state.byId[item.id].lastUpdated = new Date();
	});
}

/**
 * Remove items from a state slice
 * Cover generic byId and allIds keys
 * @param {*} state The state slice to manipulate
 * @param {*} payload Array of items to remove
 */
export function removeItems(state, payload = []){
	//	If it's not an array, turn it into one
	if(!Array.isArray(payload)){
		payload = [payload];
	}

	payload.forEach(item => {
		state.allIds = state.allIds.filter(id => id !== item.id);
		if(state.byId[item.id]){
			delete state.byId[item.id];
		}
	});
}

/**
 * Get an item id based on parameter and value
 * @param stateSlice
 * @param param
 * @param paramValue
 * @returns integer|null
 */
export function getItemIdByParam(stateSlice, param, paramValue){
	const array = getItemIdsByParam(stateSlice, param, paramValue);
	return array.pop();
}

/**
 * Get a list of Ids based on param and paramValue
 * @param stateSlice
 * @param param
 * @param paramValue
 * @returns {*[]}
 */
export function getItemIdsByParam(stateSlice, param, paramValue){
	//	If paramValues is an array
	if(paramValue && Array.isArray(paramValue)){
		return (stateSlice.allIds || [])
			.filter(itemId => stateSlice.byId[itemId] && paramValue.indexOf(stateSlice.byId[itemId][param]) !== -1)
		;
	}

	return (stateSlice.allIds || [])
		.filter(itemId => stateSlice.byId[itemId] && stateSlice.byId[itemId][param] === paramValue)
	;
}

export function updateFilter(type, newState, payload){
	const items = newState.filter[type];
	payload.forEach(item => {
		//	Initialize as true
		items[item.id] = items[item.id] !== false;
	});
}



/**
 * Generate a reducer
 * Helps standardize and reduce boilerplate for the reducers
 * 
 * @param {Object} additionalInitialState A literal to be merged into the standard state shape
 * @param {String} service One of the service names
 * @param {Object} options Additonal options
 * - alterPayload: (item, action) => { return item; }
 * - addActions: []
 * - removeActions: []
 * - actions: { ACTION_TYPE: (newState, action) => (newState | false) }
 */
export function createReducer(additionalInitialState, service, options = {
	addActions: [],
	removeActions: []
}){
	const initialState = {
		initialState: true,
		byId: {},
		allIds: [],
		...additionalInitialState,
	};

	const addList = [
		//	After we call a get or find, run an update of the store values in case something has changed
		//	Or to populate the store in the first place
		feathersServices.getGetFulfilledAction(SERVICE[service]),
		feathersServices.getFindFulfilledAction(SERVICE[service]), 

		//	Standard ADD and UPDATE
		'ADD_'+ service,
		'UPDATE_'+ service,

		//	Additonal actions
		...(options.addActions || [])
	];

	const removeList = [
		//	Standard REMOVE
		'REMOVE_'+ service,

		//	Additional actions
		...(options.removeActions || []),
	];

	return function reducer(state = initialState, action){
		const actionType = action.type + (
			action.type.match('SERVICES_') && 
			action.payload && action.payload.id &&
			//	@todo: this needs to be more robust, since we migth end up with string based IDs... 
			typeof action.payload.id === 'string'
				? '#'+ action.payload.id 
				: ''
		);

		const isAddAction = addList.indexOf(actionType) !== -1;
		const isRemoveAction = removeList.indexOf(actionType) !== -1;
		const isCustomAction = options.actions && options.actions[actionType];
		const isFindAction = feathersServices.getFindFulfilledAction(SERVICE[service]) === actionType;

		//	No action triggered
		if(! isAddAction && ! isRemoveAction && ! isCustomAction){
			return state;
		}

		// console.group('>>> '+ actionType +' ['+ service +']');
		// console.debug(action);

		let payload;
		let newState = { ...state };
		newState.initialState = false;

		//	If we redeclare an action as custom
		if( ! isCustomAction && (isAddAction || isRemoveAction)){
			//	Handle the alternative payload structure for a find call
			if(isFindAction){
				payload = action.payload.data || [];

				//	Don't process partial records
				if (payload && payload[0] && !payload[0].id) {
					//	@todo: bugsnag
					// console.group('Custom Action ['+ service +'] has partial records');
					// console.error('Custom Action ['+ service +'] has partial records');
					// console.error(actionType);
					// console.error(action);
					// console.groupEnd();

					return state;
				}
			}

			//	Normalise to an array
			payload = payload || action.payload || [];

			//	If it's not an array, turn it into one
			if( ! Array.isArray(payload)){
				payload = [payload];
			}

			if(isAddAction){
				//	Additional payload updates before adding to the state
				if(options.alterPayload){
					payload.forEach(options.alterPayload);
				}
				
				addItems(newState, payload);
			}

			if(isRemoveAction){
				removeItems(newState, payload);
			}
			
			if(options.resetSecondaryKeys){
				options.resetSecondaryKeys(newState);
			}

			// console.groupEnd();
			return newState;
		}

		//	eslint-disable-next-line no-console
		console.debug('Custom Action', action, actionType);
		// console.groupEnd();

		//	If false is returned original state will be returned
		//	Allowing custom actions to skip state changes
		return options.actions[actionType](newState, action) || state;
	};
}


