//	eslint-disable-next-line
import React from 'react';
import {withRouter} from 'react-router-dom';
import Menu2 from '../../components/Menu';
import {useSelector} from 'react-redux';
import { getSiteIdByDomain } from '../../reducers/db/sites';
import {getAllSiteMenusBySiteId} from '../../reducers/db/siteMenus';
import {kebabCase} from 'lodash';

export const SiteMenuMenu = ({
	match: {
		url,
		params: { slug, menuSlug, menuId }
	}
}) => {
	const siteId = useSelector(state => getSiteIdByDomain(state, slug));
	const menus = useSelector(state => getAllSiteMenusBySiteId(state, siteId)) || [];

	return (
		<Menu2
			mode="horizontal"
			currentPath={url}
			// @todo: handle the Home page (/) that currently creates urls with "undefined" in them
			baseLink={'/sites/' + slug + '/menus'}
			menu={menus.map(menu => {
				return {
					label: menu.name,
					pathSuffix: '/'+ kebabCase(menu.name) + '/'+ menu.id
				};
			})}
		/>
	);
};

export default withRouter(SiteMenuMenu);