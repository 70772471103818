//	eslint-disable-next-line
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AdminPageLayout } from './Layout';
import { useMachine } from '@xstate/react';
import { loadingStateMachine } from '@wpa/state-machine';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import Logger from '@wpa/logger';
import { Card, Row, Col, Descriptions } from 'antd';
import UserPermissions from './UserPermissions';
import { Button } from '@wpa/components/lib/Form';

const m = loadingStateMachine({
	loadingAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.USERS].get(
						context.userId,
						{
							query: {}
						},
						{}
					)
				)
				.then(res => {
					context.user = res && res.value;
					resolve();
				})
				.catch(
					err =>
						Logger.error(err) ||
						reject('Unable to load user details for userId #' + context.userId)
				);
		})
});

export const AdminUserPage = ({
	match: {
		params: { id }
	}
}) => {
	const dispatch = useDispatch();

	const [
		state, 
		// send
	] = useMachine(
		m.withContext({
			userId: id,
			user: {
				UserStatus: {}
			},
			dispatch
		})
	);

	const { 
		// userId, 
		user, 
		// errorMessage 
	} = state.context;
	const token = user.accessToken;

	return (
		<AdminPageLayout
			page="AdminUserPage"
			subtitle="Users"
			title={user.fullname}
		>
			<Row gutter={16}>
				<Col sm={12}>
					<Card title={<h3>Account Details</h3>}>
						<Descriptions column={1}>
							<Descriptions.Item label="Name">{user.name}</Descriptions.Item>
							<Descriptions.Item label="Full name">{user.fullname}</Descriptions.Item>
							<Descriptions.Item label="Email">{user.email}</Descriptions.Item>
							<Descriptions.Item label="Status">{user.UserStatus.label}</Descriptions.Item>
						</Descriptions>
					</Card>
					<Card title={<h3>Account Access</h3>}>
						<p style={{overflowWrap:'anywhere'}}>
							<b>Token:</b><br />
							{token}
						</p>
						<p><Button type="primary" onClick={() => {
							document.cookie = 'wpa-api='+ token +'; Path=/; domain=.'+ window.location.hostname +';';
							localStorage.setItem('feathers-jwt', token);
							
							//	Go to home as the assumed identity likely won't have access to this page
							window.location.assign('/');
						}}>Assume identity</Button></p>
					</Card>
				</Col>
				<Col sm={12}>
					<Card title={<h3>User Permissions</h3>}>
						<UserPermissions
							permissions={user.Permissions || {}}
							userId={user.id}
						/>
					</Card>
				</Col>
			</Row>
		</AdminPageLayout>
	);
};

export default withRouter(AdminUserPage);

// export const AdminUserPage2 = withRouter(
// 	class extends React.Component {
// 		constructor(props) {
// 			super(props);

// 			const search = queryString.parse(props.location.search);
// 			const limit = 10;

// 			this.state = {
// 				users: {
// 					limit: limit,
// 					skip: limit * (search.p - 1 || 0),
// 					data: null,
// 					total: 0
// 				}
// 			};

// 			this.search = this.search.bind(this);
// 			this.search(search.p || 1);
// 		}

// 		search(page) {
// 			return feathersAPI
// 				.service('users')
// 				.find({
// 					query: {
// 						$limit: this.state.users.limit,
// 						$skip: (page - 1) * this.state.users.limit
// 					}
// 				})
// 				.then(users => {
// 					this.setState({ users: users });
// 				});
// 		}

// 		componentDidMount() {}

// 		layout(children) {
// 			return (
// 				<Row>
// 					<Col>
// 						<Card>
// 							<CardBody>{children}</CardBody>
// 						</Card>
// 					</Col>
// 				</Row>
// 			);
// 		}

// 		render() {
// 			if (!this.state.users.data) {
// 				return this.layout(
// 					<SpinnerSmallWithText label="Loading users" center />
// 				);
// 			}

// 			const totalPages = parseInt(
// 				this.state.users.total / this.state.users.limit,
// 				10
// 			) + 1;
// 			const currentPage = (this.state.users.skip / this.state.users.limit) + 1;

// 			return this.layout(
// 				<div>
// 					<PaginationWPA
// 						search={this.search}
// 						currentPage={currentPage}
// 						totalPages={totalPages}
// 					/>

// 					<Table striped className="table-borderless">
// 						<thead>
// 							<tr>
// 								<th>&nbsp;</th>
// 								<th>Details</th>
// 								<th>Invite</th>
// 								<th className="text-center">Actions</th>
// 							</tr>
// 						</thead>
// 						<tbody>
// 							{this.state.users.data.map(user => {
// 								const inviteDate = user.createdAt ? new Date(user.createdAt) : null;
// 								return (
// 									<tr key={user.id}>
// 										<td>
// 											<span className="fa-layers fa-2x">
// 												<FontAwesomeIcon
// 													icon={['fas', 'user-circle']}
// 													fixedWidth
// 													style={{ color: user.isAdmin ? 'red' : null }}
// 												/>
// 												{user.isVerified && (
// 													<FontAwesomeIcon
// 														icon={['fas', 'badge-check']}
// 														fixedWidth
// 														style={{ color: 'green' }}
// 														transform="down-7 right-7 shrink-8"
// 													/>
// 												)}
// 											</span>
// 										</td>
// 										<td width="100%">
// 											{user.fullname}
// 											<br />
// 											<small>{user.email}</small>
// 										</td>
// 										<td style={{ whiteSpace: 'nowrap' }}>
// 											{user.invitedByName || 'No Name, id: '+ (user.invitedById || '')}
// 											<br />
// 											<small>{inviteDate ? inviteDate.toLocaleString() : 'N/A'}</small>
// 										</td>
// 										<td style={{ whiteSpace: 'nowrap' }}>
// 											<ul className="actions">
// 												{/*	@todo: Pin the user for paralell editing */}
// 												{/* <li>
// 													<a href="/todo">
// 														<FontAwesomeIcon
// 															icon={['far', 'thumbtack']}
// 															size={'lg'}
// 															fixedWidth
// 														/>
// 													</a>
// 												</li> */}
// 												{/* @todo: edit the user details */}
// 												<li>
// 													<a href="/todo">
// 														<FontAwesomeIcon
// 															icon={['far', 'edit']}
// 															size={'lg'}
// 															fixedWidth
// 														/>
// 													</a>
// 												</li>
// 												{/* @todo: delete the user */}
// 												<li>
// 													<a href="/todo">
// 														<FontAwesomeIcon
// 															icon={['far', 'trash-alt']}
// 															size={'lg'}
// 															fixedWidth
// 														/>
// 													</a>
// 												</li>
// 											</ul>
// 										</td>
// 									</tr>
// 								);
// 							})}
// 						</tbody>
// 					</Table>
// 					<PaginationWPA
// 						search={this.search}
// 						currentPage={currentPage}
// 						totalPages={totalPages}
// 					/>
// 				</div>
// 			);
// 		}
// 	}
// );

// const AdminPermissionsPage = connect(state => ({ store: state }))(props => {
// 	// [
// 	//   [
// 	//     { perm... }
// 	//   ]
// 	// ]
// 	// const groups = [];
// 	// let groupName, group;
// 	//
// 	// props.store.db.permissions.allIds.forEach(permissionId => {
// 	// 	const permission = props.store.db.permissions.byId[permissionId];
// 	// 	if(! groupName){
// 	// 		group = [];
// 	// 		groupName = permission.category;
// 	// 	}
// 	// 	//	Group change
// 	// 	if(groupName != permission.category){
// 	// 		groups.push()
// 	// 	}
// 	//
// 	// 	group.push(permission);
// 	// });

// 	return (
// 		<Card>
// 			<CardBody>
// 				<Row>
// 					PERMISSION LIST
// 					{/*{props.store.db.permissions.allIds.map(permissionId => {*/}
// 					{/*const permission = props.store.db.permissions.byId[permissionId];*/}
// 					{/*return (*/}
// 					{/*<Col sm="6" md="4" key={permission.id}>*/}
// 					{/*{permission.label}*/}
// 					{/*</Col>*/}
// 					{/*)})}*/}
// 				</Row>
// 			</CardBody>
// 		</Card>
// 	);
// });

// export const page = props => {
// 	let subpage;
// 	let subtitle = 'Dashboard';

// 	const canAccessUsers = can.accessAdminUsers();
// 	const canAccessPermissions = can.accessAdminPermissions();

// 	switch (props.match.path) {
// 		case '/admin/users':
// 			if (canAccessUsers) {
// 				subtitle = 'Users';
// 				subpage = <AdminUsersPage />;
// 			}
// 			break;
// 		case '/admin/permissions':
// 			if (canAccessPermissions) {
// 				subtitle = 'Permissions';
// 				subpage = <AdminPermissionsPage />;
// 			}
// 			break;
// 	}

// 	const menu = [
// 		{
// 			label: 'Dashboard',
// 			pathSuffix: ''
// 		}
// 	];

// 	if (canAccessUsers) {
// 		menu.push({
// 			label: 'Users',
// 			pathSuffix: '/users'
// 		});
// 	}

// 	if (canAccessPermissions) {
// 		menu.push({
// 			label: 'Permissions',
// 			pathSuffix: '/permissions'
// 		});
// 	}

// 	return (
// 		<Layout>
// 			<Sider className="secondaryMenu" theme="light">
// 				<Menu
// 					menu={menu}
// 					baseLink={'/admin'}
// 					currentPath={props.match.url}
// 				/>
// 			</Sider>
// 			<Layout>
// 				<Content id="sitesPage">
// 					<PageHeader title={subtitle} subtitle={'Writer PA Admin'}>
// 						<InviteUserButton />
// 					</PageHeader>
// 					{subpage}
// 				</Content>
// 			</Layout>
// 		</Layout>
// 	);
// };

// import { connect } from 'react-redux';
// import feathersAPI from '@wpa/feathers-client';
// import can from '../../abilities';
// import Menu from '../../components/Menu';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// export default connect(state => ({ store: state }))(withRouter(page));
