import { Machine, assign } from 'xstate';
import Logger from '@wpa/logger';
import { isEqual } from 'lodash';


/**
 * Common State Actions
 */

export const setErrorData = assign({
	error: true,
	errorMessage: (context, event) => Logger.error(event) || (event.data && event.data.message) || event.data,
});


/**
 *	Form state machine 
 */


export const FORM_STATE = {
	LOADING: 'loading',
	LOADING_ERROR: 'loadingError',
	ERROR: 'error',
	DISPLAY: 'display',
	SUBMITTING: 'submitting',
};

export const FORM_ACTION = {
	LOAD: 'load',
	ERROR: 'error',
	CHANGE: 'change',
	DISPLAY: 'display',
	SUBMIT: 'submit',
};

export function xstateFormHandleInputChange(event) {
	const target = event.target;
	const isArray = target.name.match(/\[\]$/);

	const name = isArray ? target.name.replace('[]', '') : target.name;

	let value = target.value;

	if (target.type === 'checkbox') {
		if (isArray) {
			//	Current state
			value = { ...this.state[name] };

			if (target.checked) {
				value[target.value] = target.value;
			} else {
				delete value[target.value];
			}
		} else {
			value = target.checked;
		}
	}

	if (!name) {
		Logger.error('Form element has no "name"!', event);
		return;
	}

	return {
		[name]: value,
		fields: {
			[name]: value,
		},
		//	Just setting this helps?
		//	@todo: investigate further why the below generates an error when used on the input
		//  onFocus={(e) => {
		// 	    console.log(this.state);
		// 	    e.target.selectionStart = this.state[e.target.name+'Cursor'];
		//  }}
		[name + 'Cursor']: event.target.selectionStart,
	};
}

export const xstateOnChange = (send) => (e) =>
	send(FORM_ACTION.CHANGE, xstateFormHandleInputChange(e));

function changeFields(context, payload) {
	const newContext = {
		...context,
	};
	newContext.fields = {
		...context.fields,
		...payload.fields,
	};
	delete payload.fields;

	return {
		...newContext,
		...payload,
	};
}

export const formStateMachine = ({ formAction, formLoading }) => {
	const m = Machine({
		initial: formLoading ? FORM_STATE.LOADING : FORM_STATE.DISPLAY,
		context: {
			error: false,
			errorMessage: '',
			//	Used when multiple actions are handled
			action: '',
		},
		states: {
			[FORM_STATE.LOADING]: {
				invoke: {
					id: 'loading',
					src: formLoading
						? formLoading
						: (context, event) =>
								new Promise((resolve, reject) =>
									reject('No action specified for loading state')
								),
					onDone: {
						target: FORM_STATE.DISPLAY,
						// actions: [
						// 	assign((context, event) => {

						// 	})
						// ]
					},
					onError: {
						target: FORM_STATE.LOADING_ERROR,
						actions: assign({
							error: (context, event) => event.data,
							//	If we get back an error object, pluck the message, otherwise just use the error value
							errorMessage: (context, event) =>
								Logger.log(event) || event.data.message || event.data,
						}),
					},
				},
			},
			[FORM_STATE.DISPLAY]: {
				onEntry: [
					assign({
						action: '',
					}),
				],
				on: {
					[FORM_ACTION.SUBMIT]: {
						target: FORM_STATE.SUBMITTING,
					},
					[FORM_ACTION.LOAD]: {
						target: FORM_STATE.LOADING,
					},
					[FORM_ACTION.ERROR]: {
						target: FORM_STATE.ERROR,
					},
					[FORM_ACTION.CHANGE]: {
						target: FORM_STATE.DISPLAY,
						actions: [
							//	Hack to make the assign work - why is this needed???
							(context, event) => true,
							assign(changeFields),
						],
					},
				},
			},
			[FORM_STATE.SUBMITTING]: {
				onEntry: [
					assign({
						action: (context, payload) => payload.action,
					}),
				],
				invoke: {
					id: 'submit',
					src: formAction
						? formAction
						: (context, event) =>
								new Promise((resolve, reject) =>
									reject('No event specified for the button')
								),
					onDone: {
						target: FORM_STATE.DISPLAY,
					},
					onError: {
						target: FORM_STATE.ERROR,
						actions: assign({
							error: (context, event) => event.data,
							//	If we get back an error object, pluck the message, otherwise just use the error value
							errorMessage: (context, event) =>
								Logger.log(event) || event.data.message || event.data,
						}),
					},
				},
			},
			[FORM_STATE.ERROR]: {
				onEntry: [
					assign({
						error: (context, event) => event.error || context.error,
						errorMessage: (context, event) =>
							event.errorMessage || context.errorMessage,
					}),
					(context, event) => {
						if (context.onError) {
							return context.onError(context.errorMessage, context.error);
						}
					},
					(context, event) =>
						Logger.error(
							'Default error response:',
							context.errorMessage,
							context.error
						),
				],
				onExit: [
					//	Always clear internal state
					assign({ error: false, errorMessage: null }),
					(context, event) => {
						if (context.onError) {
							return context.onError(null, null);
						}
					},
				].filter(Boolean),
				on: {
					[FORM_ACTION.SUBMIT]: {
						target: FORM_STATE.SUBMITTING,
					},
					// [FORM_ACTION.ERROR]: {
					// 	target: FORM_STATE.ERROR
					// },
					[FORM_ACTION.DISPLAY]: {
						target: FORM_STATE.DISPLAY,
					},
					[FORM_ACTION.CHANGE]: {
						target: FORM_STATE.DISPLAY,
						actions: [
							//	Hack to make the assign work - why is this needed???
							(context, event) => true,
							assign(changeFields),
						],
					},
				},
			},
			[FORM_STATE.LOADING_ERROR]: {
				onEntry: [
					assign({
						error: (context, event) => event.error || context.error,
						errorMessage: (context, event) =>
							event.errorMessage || context.errorMessage,
					}),
					(context, event) => {
						if (context.onError) {
							return context.onError(context.errorMessage, context.error);
						}
					},
					(context, event) =>
						Logger.error(
							'Default loading error response:',
							context.errorMessage,
							context.error
						),
				],
				onExit: [
					//	Always clear internal state
					assign({ error: false, errorMessage: null }),
					(context, event) => {
						if (context.onError) {
							return context.onError(null, null);
						}
					},
				].filter(Boolean),
				on: {
					[FORM_ACTION.SUBMIT]: {
						target: FORM_STATE.SUBMITTING,
					},
					[FORM_ACTION.DISPLAY]: {
						target: FORM_STATE.DISPLAY,
					},
					[FORM_ACTION.CHANGE]: {
						target: FORM_STATE.DISPLAY,
						actions: [
							//	Hack to make the assign work - why is this needed???
							(context, event) => true,
							assign(changeFields),
						],
					},
				},
			},
		},
	});

	m.withFormContext = function (context, defaults) {
		return this.withContext({
			...context,
			fields: defaults,
			defaults: defaults,
		});
	};

	m.hasChanged = function (state) {
		return ! isEqual(state.context.defaults, state.context.fields);
	};

	return m;
};


export const LOADING_STATE = {
	LOADING: 'loading',
	ERROR: 'error',
	LOADING_ERROR: 'loadingError',
	DISPLAY: 'display'
};

export const LOADING_ACTION = {
	LOAD: 'load',
	ERROR: 'error',
	DISPLAY: 'display'
};

export const loadingStateMachine = ({ loadingAction }) =>
	Machine({
		initial: LOADING_STATE.LOADING,
		context: {
			action: '',
			error: false,
			errorMessage: ''
		},
		states: {
			[LOADING_STATE.LOADING]: {
				invoke: {
					id: 'loading',
					src: loadingAction
						? loadingAction
						: (context, event) =>
							new Promise((resolve, reject) =>
								reject('No action specified for loading state')
							),
					onDone: {
						target: LOADING_STATE.DISPLAY
					},
					onError: {
						target: LOADING_STATE.LOADING_ERROR,
						actions: assign({
							error: (context, event) => event.data,
							//	If we get back an error object, pluck the message, otherwise just use the error value
							errorMessage: (context, event) =>
								Logger.log(event) || event.data.message || event.data
						})
					}
				}
			},
			[LOADING_STATE.DISPLAY]: {
				onEntry: [
					assign({
						action: ''
					})
				],
				on: {
					[LOADING_ACTION.LOAD]: {
						target: LOADING_STATE.LOADING
					}
				}
			},
			[LOADING_STATE.ERROR]: {
				onEntry: [
					assign({
						error: (context, event) => event.error || context.error,
						errorMessage: (context, event) => event.errorMessage || context.errorMessage,
					}),
					(context, event) =>
						Logger.error(
							'Default error response:',
							context.errorMessage,
							context.error
						)
				],
				onExit: [
					//	Always clear internal state
					assign({ error: false, errorMessage: null })
				].filter(Boolean),
				on: {
					[LOADING_ACTION.LOAD]: {
						target: LOADING_STATE.LOADING
					},
					[LOADING_ACTION.DISPLAY]: {
						target: LOADING_STATE.DISPLAY
					}
				}
			},
			[LOADING_STATE.LOADING_ERROR]: {
				onEntry: [
					assign({
						error: (context, event) => event.error || context.error,
						errorMessage: (context, event) => event.errorMessage || context.errorMessage,
					}),
					(context, event) =>
						Logger.error(
							'Default error response:',
							context.errorMessage,
							context.error
						)
				],
				onExit: [
					//	Always clear internal state
					assign({ error: false, errorMessage: null })
				].filter(Boolean),
				on: {
					[LOADING_ACTION.LOAD]: {
						target: LOADING_STATE.LOADING
					},
					[LOADING_ACTION.DISPLAY]: {
						target: LOADING_STATE.DISPLAY
					}
				}
			}
		}
	});