import React from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, Table } from 'reactstrap';
import BookPageLayout from './Layout';
import { getSalesTotalByBookId } from '../../reducers/db/bookSales';
import { quickRound } from '../../utils/maths';
import { getAuthorsMap } from '../../reducers/db/authorBooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllPublishersMap } from '../../reducers/db/publishers';
import { ButtonModal } from '@wpa/components/lib/Form';
import ExchangeRateForm from '../../components/books/ExchangeRateForm';
import UnpaidSalesCard from '../../components/shared/sales/UnpaidSalesCard';
import PandLCard from '../../components/shared/sales/PandLCard';
import { Row, Col } from 'antd';

const SalesRow = props => {
	const sales = props.sales;

	const isUnpaid =
		sales.unpaidSales > 0 || sales.unpaidKenp > 0 || sales.unpaidRefunds > 0;

	const rows = [];

	const royalties = props.royalties || [];

	//	Main row
	rows.push(
		<tr className={isUnpaid ? 'text-danger' : ''} key={'salesRowMain'}>
			<td>{sales.salesPeriod}</td>
			<td>
				<b>{isUnpaid ? 'N/A' : '£' + quickRound(sales.royalties, 2)}</b>
			</td>
			<td>{isUnpaid ? 'N/A' : '£' + quickRound(sales.partialRoyalties, 2)}</td>
			<td>
				{isUnpaid
					? 'N/A'
					: '£' + quickRound(sales.royalties - sales.partialRoyalties, 2)}
			</td>
			<td>{sales.sales}</td>
			<td>{sales.kenp}</td>
			<td>{sales.refunds}</td>
			<td>&nbsp;</td>
		</tr>
	);

	//	Royalty split rows
	//	Only show the split if there's money to split

	Object.keys(royalties).forEach(storeBookId => {
		royalties[storeBookId]['publishers'].forEach(publisher => {
			rows.push(
				<tr
					key={'salesRowPublisher-' + publisher.publisherId}
					className={isUnpaid ? 'text-danger' : ''}
				>
					<td>
						<small>{storeBookId}</small>
					</td>
					<td>
						<small>
							£{quickRound(publisher.partialRoyalties)} (
							{quickRound(publisher.royaltyPercentage, 2)}%)
						</small>
					</td>
					<td colSpan={5}>
						<small>
							<FontAwesomeIcon icon={['fas', 'briefcase']} fixedWidth />{' '}
							{publisher.name}
						</small>
					</td>
					<td>
						{isUnpaid && publisher.canUpdateSales && (
							<ButtonModal
								title={sales.salesPeriod + ' Exchange Rates'}
								modal={<ExchangeRateForm salesPeriod={sales.salesPeriod} />}
							>
								<FontAwesomeIcon
									icon={['fas', 'pound-sign']}
									title="Update Exchange Rates"
								/>
							</ButtonModal>
						)}
					</td>
				</tr>
			);
		});

		if (!isUnpaid) {
			royalties[storeBookId]['authors'].forEach(author => {
				rows.push(
					<tr key={'salesRowPublisher-' + author.authorId}>
						<td>&nbsp;</td>
						<td>
							<small>
								£{quickRound(author.partialRoyalties)} (
								{quickRound(author.royaltyPercentage, 2)}%)
							</small>
						</td>
						<td colSpan={6}>
							<small>
								<FontAwesomeIcon icon={['fas', 'user']} fixedWidth />{' '}
								{author.name}
							</small>
						</td>
					</tr>
				);
			});
		}
	});

	return rows;
};

export class BookSalesPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const props = this.props;
		const {
			authorsMap,
			bookId,
			bookSales,
			bookSalesDetails,
			publishersMap
		} = props;

		const royaltiesMap = {};

		//	Author details
		bookSalesDetails.authors.forEach(item => {
			if (!royaltiesMap[item.salesPeriod]) {
				royaltiesMap[item.salesPeriod] = {
					[item.storeBookId]: { authors: [], publishers: [] }
				};
			}
			item.name = authorsMap[item.authorId] && authorsMap[item.authorId].name;
			royaltiesMap[item.salesPeriod][item.storeBookId]['authors'].push(item);
		});

		// Publisher details
		bookSalesDetails.publishers.forEach(item => {
			if (!royaltiesMap[item.salesPeriod]) {
				royaltiesMap[item.salesPeriod] = {
					[item.storeBookId]: { authors: [], publishers: [] }
				};
			}
			item.name = publishersMap[item.publisherId].name;
			item.canUpdateSales =
				publishersMap[item.publisherId].User &&
				publishersMap[item.publisherId].User.canEditRoyalties;
			royaltiesMap[item.salesPeriod][item.storeBookId]['publishers'].push(item);
		});

		return (
			<BookPageLayout pageName={'bookSales'}>
				<h2>Sales</h2>

				<Row gutter={16}>
					<Col xs={12} sm={6} md={3}>
						<PandLCard type="BOOK" itemId={bookId} />
					</Col>

					<Col xs={12} sm={6} md={3}>
						<UnpaidSalesCard type="BOOK" itemId={bookId} />
					</Col>
				</Row>

				<Row>
					<Col>
						<Card>
							<CardBody>
								<Table striped responsive>
									<thead>
										<tr>
											<th>Sales period</th>
											<th>
												<FontAwesomeIcon icon={['fas', 'pound-sign']} /> Royalty
											</th>
											<th>
												<FontAwesomeIcon icon={['fas', 'pound-sign']} />
												&nbsp;
												<FontAwesomeIcon
													icon={['fas', 'briefcase']}
													title="Publisher GBP Royalties"
												/>
											</th>
											<th>
												<FontAwesomeIcon icon={['fas', 'pound-sign']} />
												&nbsp;
												<FontAwesomeIcon
													icon={['fas', 'user']}
													title="Author GBP Royalties"
												/>
											</th>
											<th>Sales</th>
											<th>KENP</th>
											<th>Refunds</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{bookSales.map(sales => (
											<SalesRow
												sales={sales}
												royalties={royaltiesMap[sales.salesPeriod]}
												key={
													sales.salesPeriod +
													'_' +
													sales.publisherId +
													'_' +
													(sales.storeId || '')
												}
												canUpdateSales={
													console.log(
														'+',
														publishersMap[sales.publisherId] &&
															publishersMap[sales.publisherId].User &&
															publishersMap[sales.publisherId].User
																.canEditRoyalties,
														sales.publisherId,
														publishersMap[sales.publisherId]
														// publishersMap[sales.publisherId].User,
														// publishersMap[sales.publisherId].User.canEditRoyalties
													) ||
													(publishersMap[sales.publisherId] &&
														publishersMap[sales.publisherId].User &&
														publishersMap[sales.publisherId].User
															.canEditRoyalties)
												}
											/>
										))}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</BookPageLayout>
		);
	}
}

export default connect((state, props) => {
	const id = parseInt(props.match.params.id, 10);
	return {
		bookId: id,
		authorsMap: getAuthorsMap(state),
		bookSales: getSalesTotalByBookId(state, id, 'MONTHLY_BY_BOOK'),
		bookSalesDetails: {
			authors: getSalesTotalByBookId(
				state,
				id,
				'MONTHLY_BY_STORE_BOOK_ID_AUTHORS'
			),
			publishers: getSalesTotalByBookId(
				state,
				id,
				'MONTHLY_BY_STORE_BOOK_ID_PUBLISHERS'
			)
		},
		publishersMap: getAllPublishersMap(state)
	};
})(BookSalesPage);
