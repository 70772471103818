//	eslint-disable-next-line
import React, { useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import { useDispatch, useSelector} from 'react-redux';
import { imagePath } from '../utils/imagePath';
import AuthorAddButton from '../components/author/AddButton';
import { getAllAuthors } from '../reducers/db/authors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Card, Layout, Modal } from 'antd';
import { Button } from '@wpa/components/lib/Form';
import { feathersServices , SERVICE} from '@wpa/feathers-client';
import Logger from '@wpa/logger';
const { Content } = Layout;

export const AuthorsPage = ({...props}) => {
	const dispatch = useDispatch();

	const authors = useSelector(state => getAllAuthors(state));

	const deleteAuthor = useCallback(
		e => {
			const id = e.currentTarget.dataset.id;

			Modal.confirm({
				title: 'Delete Author',
				content: 'Are you sure you want to delete this author?',
				okText: 'Yes',
				okType: 'danger',
				cancelText: 'No',
				onOk(){
					dispatch(
						feathersServices[SERVICE.USER_AUTHORS].remove(
							null,
							{
								query: {
									authorId: id
								}
							}
						)
					)
						.then(res => {
							// context.defaultContent = context.content;
							// resolve();
						})
						.catch(
							err => Logger.error(err)
							//  || reject('Unable to find reviews.')
						);
				}
			});
		},
		[dispatch]
	);

	return (
		<Content id="authorPage">
			<PageHeader title="Authors">
				<AuthorAddButton showModal={props.match.path === '/authors/add'}>
					Add Author
				</AuthorAddButton>
			</PageHeader>
			<Row gutter={16} type="flex">
				{authors.map(author => {
					const picture = (author.ProfilePic || [])
						.filter(pic => pic.authorImages && pic.authorImages.isPrimary)
						.pop();

					const disabled = !author.User;
					const link = disabled ? null : author.link;

					const actions = [];
					if (link) {
						actions.push(
							<Link to={link}>
								<FontAwesomeIcon icon={['far', 'edit']} size={'lg'} />
							</Link>
						);
						actions.push(
							<Button
								type="link"
								data-id={author.id}
								onClick={deleteAuthor}
							>
								<FontAwesomeIcon
									icon={['far', 'trash-alt']}
									size={'lg'}
								/>
							</Button>
						);
					}

					const publisherName = <span>
						Published by: <b>{author.Publisher.name}</b>
					</span>;

					return (
						<Col sm={24} md={12} key={author.id} align="top">
							<Card
								actions={actions}
								extra={author.isPublished ? <span>Published</span> : <span>&nbsp;</span>}
								title={
									link 
										? <h3><Link to={link}>{author.name}{publisherName}</Link></h3> 
										: <h3>{author.name}{publisherName}</h3>
								}
							>
								<Row gutter={16}>
									{picture && <Col sm={6}>
										<img
											alt={author.name + ' Picture'}
											src={imagePath(picture.path)}
											width="100%"
										/>
									</Col>}
									<Col sm={picture ? 18 : 24}>
										
										{disabled && (
											<i>Author not shared, but assigned to one of your books</i>
										)}
										{author.SocialMedias && author.SocialMedias.length > 0 && <div>
											<h4>Social Media</h4>
											<ul>
												{author.SocialMedias.map(social => {
													const classes = [
														'fab',
														'fa-fw',
														'fa-' + social.SocialMediaTypes.slug
													];
													return (
														<li key={social.id}>
															<a href={social.url}>
																<i
																	className={classes.join(' ')}
																	target="_blank"
																></i>
																{social.SocialMediaTypes.label}
															</a>
														</li>
													);
												})}
											</ul>
										</div>}
									</Col>
								</Row>
							</Card>
						</Col>
					);
				})}
			</Row>
		</Content>
	);
};

export default withRouter(AuthorsPage);
