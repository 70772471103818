import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
// import {Link} from 'react-router-dom';
// import {imagePath} from "../../utils/imagePath";
// import AuthorLink from "../author/Link";

import {
	// CardColumns, 
	Card, 
	// CardImg, 
	CardHeader, 
	// CardTitle, CardBlock, CardText, CardFooter
} from 'reactstrap';
import {Button} from '@wpa/components/lib/Form';
// import {BooksCard} from '../dashboard/Books';
// import BookCard from './Card';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import {RESOURCE_INVITES_STATUS} from '../../reducers/db/resourceInvites';

export class BookOfferedCard extends React.PureComponent {
	static displayName = 'BookOfferedCard';

	static propTypes = {
		book: PropTypes.object.isRequired,
		offerId: PropTypes.number.isRequired,
		showAuthor: PropTypes.bool,
		note: PropTypes.string,
	};

	constructor(props){
		super(props);

		this.handleAccept = this.handleAccept.bind(this);
		this.handleReject = this.handleReject.bind(this);
	}

	handleAccept(e){
		e.preventDefault();
		const self = this;

		self.props.dispatch(
			feathersServices[SERVICE.RESOURCE_INVITES]
				.patch(
					self.props.offerId,
					{
						statusId: RESOURCE_INVITES_STATUS.ACCEPTED
					},
					{}
				)
		)
	}

	handleReject(e){
		e.preventDefault();
		const self = this;

		self.props.dispatch(
			feathersServices[SERVICE.RESOURCE_INVITES]
				.patch(
					self.props.offerId,
					{
						statusId: RESOURCE_INVITES_STATUS.REJECTED
					},
					{}
				)
		)
	}

	render(){
		const props = this.props;

		const {
			book,
			note,
			senderName,
		} = props;

		const hideReject = book.id === 1;

		const classes = ["book", 'offered'];

		classes.push('status-' + book.Status.slug);

		return (
				<Card className={classes.join(' ')}>
					<CardHeader style={{
						paddingBottom: 0
					}}>
						<h3 className={"title title-only"}>
							{book.title}
						</h3>

						<div>
							Sent by: {senderName}
						</div>
					</CardHeader>

					<div className="card-body">
						<div><i>Note:</i> {note}</div>
					</div>

					<p className="text-center">
						<Button type="primary" onClick={this.handleAccept} size="small">Accept</Button>
						{!hideReject && <Button type="danger" onClick={this.handleReject} size="small">Reject</Button>}
					</p>
				</Card>
		);
	}
}

export default connect(state => ({
}))(BookOfferedCard);