//	eslint-disable-next-line
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { feathersServices, SERVICE } from "@wpa/feathers-client";
import { SpinnerSmallWithText } from "@wpa/components/lib/Spinner";
import {
  Button,
  // Input,
  FormAlert,
} from "@wpa/components/lib/Form";
import {
  formStateMachine,
  FORM_STATE,
  FORM_ACTION,
  xstateFormHandleInputChange,
} from "@wpa/state-machine";
import { useMachine } from "@xstate/react";
import Logger from "@wpa/logger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Typography, Modal, Select } from "antd";
import { getSitePageBlockById } from "../../../reducers/db/sitePageBlocks";
import { SitePageBlockContentForm } from "../../../components/sites/page/block/SitePageBlockContentForm";
import SitePageBlockBookForm from "../../../components/sites/page/block/SitePageBlockBookForm";
import { SitePageBlockAuthorBioForm } from "../../../components/sites/page/block/SitePageBlockAuthorBioForm";


const stateMachine = formStateMachine({
  formAction: (context, event) =>
    new Promise((resolve, reject) => {
      context
        .dispatch(
          feathersServices[SERVICE.SITE_PAGE_BLOCKS].patch(event.blockId, {
            name: context.name,
            columns: context.columns,
          })
        )
        .then((res) => {
          return resolve();
        })
        .catch((err) => reject("Unable to save page block."));
    }),
  formLoading: (context, event) =>
    new Promise((resolve, reject) => {
      context
        .dispatch(
          feathersServices[SERVICE.SITE_PAGE_BLOCKS].get(context.blockId)
        )
        .then((payload) => {
          switch (payload.value.blockTypeId) {
            case 1:
              context
                .dispatch(
                  feathersServices[SERVICE.SITE_PAGE_CONTENTS].find({
                    query: {
                      blockId: context.blockId,
                      $sort: {
                        updated_at: "DESC",
                      },
                    },
                  })
                )
                .then((content) => {
                  resolve();
                })
                .catch(
                  (err) =>
                    Logger.error(err) || reject("Unable to get block content.")
                );
              break;
            case 2:
              context
                .dispatch(
                  feathersServices[SERVICE.SITE_PAGE_BOOKS].find({
                    query: {
                      blockId: context.blockId,
                      $sort: {
                        updated_at: "DESC",
                      },
                    },
                  })
                )
                .then((content) => {
                  if (!content.value.total || content.value.total === 0) {
                    // reject('No book content defined?');
                    // 	context.dispatch(feathersServices[SERVICE.SITE_PAGE_BOOKS].create({
                    // 		blockId: context.blockId,
                    // 		pageId: context.pageId
                    // 	}))
                    // 		.then(content => {
                    // 			Logger.error('Book block not preset, creating #'+ content.value.id);
                    // 			resolve();
                    // 		})
                    // 		.catch(err => Logger.error(err) || reject('Unable to create block content.'))
                    // 	;
                    // } else {
                    // 	if(content.value.total > 1){
                    // 		Logger.warn('Duplicate entries for this book block');
                    // 	}
                    // 	resolve();
                  }
                  resolve();
                })
                .catch(
                  (err) =>
                    Logger.error(err) || reject("Unable to get block content.")
                );
              break;
            case 3:
              context
                .dispatch(
                  feathersServices[SERVICE.SITE_PAGE_AUTHOR_BIO].find({
                    query: {
                      blockId: context.blockId,
                      $sort: {
                        updated_at: "DESC",
                      },
                    },
                  })
                )
                .then((content) => {
                  resolve();
                })
                .catch(
                  (err) =>
                    Logger.error(err) ||
                    resolve() /*|| reject('Unable to get block content.')*/
                );
              break;
            default:
              reject("Unknown block type.");
              break;
          }
        })
        .catch((err) => reject("Unable to get block details."));
    }),
});

const { Title } = Typography;

export const SitePageBlock = ({ siteId, blockId, blockTypeId, blockCount }) => {
  const dispatch = useDispatch();

  const block =
    useSelector((state) => getSitePageBlockById(state, blockId)) || {};

  const { name, columns, sort } = block;

  const [state, send] = useMachine(
    stateMachine.withContext({
      blockId: blockId,
      dispatch: dispatch,
    })
  );

  //	State checks
  const isError = state.matches(FORM_STATE.ERROR);
//   const isSaving = state.matches(FORM_STATE.SUBMITTING);
  const isLoading = state.matches(FORM_STATE.LOADING);

  const { errorMessage } = state.context;

  const handleSubmit = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }

      return send(FORM_ACTION.SUBMIT, {
        blockId: blockId,
      });
    },
    [send, blockId]
  );

  const handleDelete = useCallback(
    (e) => {
      Modal.confirm({
        title: "Delete Block",
        content: "Are you sure you want to delete this page block?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          dispatch(feathersServices[SERVICE.SITE_PAGE_BLOCKS].remove(blockId))
            .then((res) => {})
            .catch((err) =>
              send(FORM_ACTION.ERROR, {
                error: err,
                errorMessage: err.message,
              })
            );
        },
      });
    },
    [send, dispatch, blockId]
  );

  const handleSort = useCallback(
    (e) => {
      dispatch(
        feathersServices[SERVICE.SITE_PAGE_BLOCKS].patch(
          blockId,
          {},
          {
            query: {
              sortUp: e.sortUp,
              sortDown: e.sortDown,
            },
          }
        )
      )
        .then((res) => {})
        .catch((err) =>
          send(FORM_ACTION.ERROR, {
            error: err,
            errorMessage: err.message,
          })
        );
    },
    [send, dispatch, blockId]
  );

  const handleTitleChange = useCallback(
    (str) => {
      send(
        FORM_ACTION.CHANGE,
        xstateFormHandleInputChange({
          target: {
            name: "name",
            value: str,
            type: "text",
          },
        })
      );
      handleSubmit();
    },
    [send, handleSubmit]
  );

  const handleColumnChange = useCallback(
    (str) => {
      send(
        FORM_ACTION.CHANGE,
        xstateFormHandleInputChange({
          target: {
            name: "columns",
            value: str,
            type: "text",
          },
        })
      );
      handleSubmit();
    },
    [send, handleSubmit]
  );

  if (isLoading) {
    return <SpinnerSmallWithText label="Loading block..." />;
  }

  const extra = [];

  extra.push(
    <label key="blockWidth">
      Block width:&nbsp;
      {/* Some duplication here on purpose, to save people having to do too much math */}
      <Select
        style={{ width: "70px" }}
        onChange={handleColumnChange}
        defaultValue={columns}
        name="columns"
      >
        <Select.Option value="12">1</Select.Option>
        <Select.Option value="1">1/12</Select.Option>
        <Select.Option value="2">2/12</Select.Option>
        <Select.Option value="3">3/12</Select.Option>
        <Select.Option value="4">4/12</Select.Option>
        <Select.Option value="5">5/12</Select.Option>
        <Select.Option value="6">6/12</Select.Option>
        <Select.Option value="7">7/12</Select.Option>
        <Select.Option value="8">8/12</Select.Option>
        <Select.Option value="9">9/12</Select.Option>
        <Select.Option value="10">10/12</Select.Option>
        <Select.Option value="11">11/12</Select.Option>
        <Select.Option value="2">1/6</Select.Option>
        <Select.Option value="4">2/6</Select.Option>
        <Select.Option value="6">3/6</Select.Option>
        <Select.Option value="8">4/6</Select.Option>
        <Select.Option value="10">5/6</Select.Option>
        <Select.Option value="3">1/4</Select.Option>
        <Select.Option value="6">2/4</Select.Option>
        <Select.Option value="9">3/4</Select.Option>
        <Select.Option value="4">1/3</Select.Option>
        <Select.Option value="8">2/3</Select.Option>
        <Select.Option value="6">1/2</Select.Option>
      </Select>
      &nbsp;
    </label>
  );

  if (sort > 1) {
    extra.push(
      <Button
        key="sortUp"
        onClick={() => {
          handleSort({
            sortUp: true,
          });
        }}
        type="primary"
        title="Move block up"
      >
        <FontAwesomeIcon icon={["fas", "sort-up"]} key="up" />
      </Button>
    );
  }
  if (sort < blockCount) {
    extra.push(
      <Button
        key="sortDown"
        onClick={() => {
          handleSort({
            sortDown: true,
          });
        }}
        type="primary"
        title="Move block down"
      >
        <FontAwesomeIcon icon={["fas", "sort-down"]} key="down" />
      </Button>
    );
  }
  extra.push(
    <Button
      key="delete"
      onClick={handleDelete}
      type="primary"
      title="Delete block"
    >
      <FontAwesomeIcon icon={["fas", "trash-alt"]} key="delete" />
    </Button>
  );

  return (
    <Card
      title={
        <Title editable={{ onChange: handleTitleChange }} level={3}>
          {name}
        </Title>
      }
      extra={extra}
    >
      {isError && <FormAlert send={send} description={errorMessage} />}
      {blockTypeId === 1 && (
        <SitePageBlockContentForm siteId={siteId} blockId={blockId} />
      )}
      {blockTypeId === 2 && (
        <SitePageBlockBookForm siteId={siteId} blockId={blockId} />
      )}
      {blockTypeId === 3 && (
        <SitePageBlockAuthorBioForm siteId={siteId} blockId={blockId} />
      )}
    </Card>
  );
};

export default SitePageBlock;
