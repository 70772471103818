import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import { Menu } from 'antd';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export class Menu2 extends React.Component {
	static displayName = 'Menu';
	static propTypes = {
		currentPath: PropTypes.string,
		baseLink: PropTypes.string,
		/**
		 * [
		 * 	{
		 * 		label: menu item
		 * 		icon: optional icon
		 * 		ficon: optional fontawesome icon
		 * 		isCurrent: check for active element
		 * 		pathSuffix: part after the baseLink to check for menu selection
		 *
		 * 		custom: custom element to render
		 * 	}
		 * ]
		 */
		menu: PropTypes.array,
	};

	render(){
		const {
			currentPath,
			menu,
			baseLink,
			...attributes
		} = this.props;
		
		return (
			<Menu
				selectedKeys={[currentPath + '-']}
				{...attributes}
			>
				{menu.map((item) =>{
					const link = baseLink + (item.pathSuffix || '');
					const key = link + '-';

					if(item.custom){
						return (
							<Menu.Item key={key}>{item.custom}</Menu.Item>
						);
					}

					let icon;
					if(item.icon){
						icon = [
							item.icon,
							' '
						];
					}
					if(item.ficon){
						icon = [
							<FontAwesomeIcon icon={item.ficon} fixedWidth size={'sm'} key={item.label+' Icon'} />,
							' '
						];
					}
					return (
						<Menu.Item key={key}>
							<Link
								to={link}
							>
								{icon}
								{item.label}
							</Link>
						</Menu.Item>
					);
				})}
			</Menu>
		);
	}
}

export default Menu2;