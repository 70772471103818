import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary, bugsnagClient } from '@wpa/bugsnag-react';
import { feathersAPI, SERVICE, feathersServices } from '@wpa/feathers-client';
import Logger from '@wpa/logger';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { updateCurrentUser } from './reducers/currentUser';
import { updateCover } from './reducers/db/books';
import { addResourceInvites } from './reducers/db/resourceInvites';
import {
	dispatchGetBookSalesTotals,
	dispatchGetKenpTotals,
} from './reducers/db/bookSales';
import { dispatchGetExpensesTotals } from './reducers/db/expenses';


window.addEventListener('unhandledrejection', async function handleRejection(event) {
	// Prevent the default behavior, which is logging the unhandled rejection
	// error to the console.
	// --
	// NOTE: This is only meaningful in Chrome that supports this event.
	event.preventDefault();

	if (
		event.reason.type === 'FeathersError' &&
		event.reason.name === 'NotAuthenticated'
	) {
		//	eslint-disable-next-line no-console
		console.log('Not authenticated: ', event.reason.message);

		await feathersAPI.logout();

		//	Delete the parent domain cookie if there is one
		document.cookie =
			'wpa-api=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.' +
			process.env.REACT_APP_PUBLIC_URL.replace(/https?:\/\//, '') +
			';';
		window.location.assign(process.env.REACT_APP_AUTH_URL);

		return;
	}

	//	eslint-disable-next-line no-console
	console.group('UNHANDLED PROMISE REJECTION');
	//	eslint-disable-next-line no-console
	console.log(event.reason);
	//	eslint-disable-next-line no-console
	console.log(event.promise);
	//	eslint-disable-next-line no-console
	console.groupEnd();
});

/**
 * Helper function for handling service events
 *
 * @param {String} action Action to pass on, ACTION+_+SERVICE, e.g. CREATED_AUTHORS
 * @param {Object|Array} item Data to pass to payload
 */
const serviceAction = function (action, item) {
	return {
		type: action,
		payload: Array.isArray(item) || item.data ? item : [item],
	};
};

if (module.hot) {
	module.hot.accept();
}
//	eslint-disable-next-line
import { store } from './store';
async function authenticate() {
	try {
		const { user } = await feathersAPI.reAuthenticate();

		console.log('User authenticated', user);
		// console.log(
		// 	'Authentication information is',
		// 	await feathersAPI.get('authentication')
		// );

		//	Update bugsnag user details
		bugsnagClient.user = {
			id: user.id,
			statusId: user.statusId,
			isVerified: user.isVerified,
			invitedById: user.invitedById,
			permissions: user.Permissions,
			userStatus: user.UserStatus,
		};

		// if (module.hot) {
		// 	// Enable Webpack hot module replacement for reducers
		// 	module.hot.accept('./reducers', () => {
		// 		// const nextRootReducer = require('./reducers');
		// 		// store.replaceReducer(nextRootReducer);
		// 	});
		// }

		//	Run the saga (watchers)
		// sagaMiddleware.run(mySaga);

		//	Set user permissions
		store.dispatch(updateCurrentUser(user));

		/*
		 * These are required to catch the actions that are initiated internally from the API
		 */

		//	Generate event catchers for all declared services
		Object.keys(SERVICE).forEach((service) => {
			// console.log('Register EVENTS for: '+ service);
			feathersAPI
				.service(SERVICE[service])
				.on('created', (item) =>
					store.dispatch(serviceAction('ADD_' + service, item))
				)
				.on('patched', (item) =>
					store.dispatch(serviceAction('UPDATE_' + service, item))
				)
				.on('removed', (item) =>
					store.dispatch(serviceAction('REMOVE_' + service, item))
				);
		});

		//	Additional event based behaviour

		const serviceFindByIds = function (service, item, param = 'id') {
			store
				.dispatch(
					feathersServices[service].find({
						query: {
							ids: (Array.isArray(item) ? item : [item]).map(
								(i) => i[param]
							),
						},
					})
				)
				.catch((err) => {
					console.error(err);
				});
		};

		//	@todo: update all the actions below to use serviceAction()
		feathersAPI
			.service(SERVICE.AUTHORS)
			.on('created', (item) => serviceFindByIds(SERVICE.AUTHORS, item))
			.on('patched', (item) => serviceFindByIds(SERVICE.AUTHORS, item));

		feathersAPI.service(SERVICE.AUTHOR_BOOKS).on('created', (item) => {
			serviceFindByIds(SERVICE.AUTHORS, item, 'authorId');
		});

		feathersAPI
			.service(SERVICE.AUTHOR_IMAGES)
			.on('patched', (item) => {
				serviceFindByIds(SERVICE.AUTHORS, item, 'authorId');
			})
			.on('created', (item) => {
				serviceFindByIds(SERVICE.AUTHORS, item, 'authorId');
			});

		feathersAPI
			.service(SERVICE.BOOKS)
			.on('created', (item) => serviceFindByIds(SERVICE.BOOKS, item))
			.on('patched', (item) => serviceFindByIds(SERVICE.BOOKS, item));

		feathersAPI
			.service(SERVICE.BOOK_IMAGES)
			.on('coverChanged', (item) => store.dispatch(updateCover(item)));

		feathersAPI
			.service(SERVICE.PUBLISHERS)
			.on('created', (item) => serviceFindByIds(SERVICE.PUBLISHERS, item))
			.on('patched', (item) =>
				serviceFindByIds(SERVICE.PUBLISHERS, item)
			);

		feathersAPI
			.service(SERVICE.RESOURCE_INVITES)
			.on('created', (item) => {
				const items = Array.isArray(item) ? item : [item];

				//	Cleanup the user ids
				items.forEach((item) => {
					item.receivingUserId =
						item.receivingUserId === user.id ? 'me' : null;
					item.offeringUserId =
						item.offeringUserId === user.id ? 'me' : null;
				});

				//	Update the store
				store.dispatch(addResourceInvites(items));
			})
			.on('patched', (item) => {
				const items = Array.isArray(item) ? item : [item];

				//	Cleanup the user ids
				items.forEach((item) => {
					item.receivingUserId =
						item.receivingUserId === user.id ? 'me' : null;
					item.offeringUserId =
						item.offeringUserId === user.id ? 'me' : null;
				});

				//	Update the store
				store.dispatch(addResourceInvites(items));
			});

		// feathersAPI
		// 	.service(SERVICE.SITE_IMAGES)
		// 	.on('patched', (item) => serviceFindByIds(SERVICE.SITES, item, 'siteId'))
		// ;


		feathersAPI
			.service(SERVICE.USER_BOOKS)
			//	This means we've been added to a book
			.on('created', (item) => {
				store
					.dispatch(
						feathersServices[SERVICE.BOOKS].get(item.bookId, {})
					)
					.then(() =>
						store.dispatch(
							feathersServices[SERVICE.AUTHOR_BOOKS].find(
								{
									bookId: item.bookId,
								},
								{}
							)
						)
					);
			});

		feathersAPI
			.service(SERVICE.USER_AUTHORS)
			//	This means we've been added to an author
			.on('created', (item) => {
				store
					.dispatch(
						feathersServices[SERVICE.AUTHORS].get(item.authorId, {})
					)
					.then(() =>
						store.dispatch(
							feathersServices[SERVICE.AUTHOR_BOOKS].find(
								{
									authorId: item.authorId,
								},
								{}
							)
						)
					);
			});

		/**
		 * Initial data import
		 * Only import basics, the rest can be pulled in as the user navigates through the site
		 * @returns {Promise<[*]>}
		 */
		const importData = function () {
			const errorHandler = (err) =>
				console.error('Could not import data', err);
			const limit = 10000;

			const promises = [
				SERVICE.AUTHORS,
				SERVICE.BOOKS,
				SERVICE.AUTHOR_BOOKS,
				SERVICE.PUBLISHERS,
				SERVICE.BOOK_STATUSES,
				SERVICE.BOOK_PUBLISHERS,
				SERVICE.BOOK_ROYALTIES,
				SERVICE.PERMISSIONS,
				SERVICE.RESOURCE_INVITES,
				SERVICE.SITES,
				SERVICE.SITE_LAYOUTS,
				SERVICE.SITE_MENUS,
				SERVICE.SOCIAL_MEDIA_TYPES,
				SERVICE.SOCIAL_MEDIAS,
			].map((service) =>
				store
					.dispatch(
						feathersServices[service].find({
							query: { $limit: limit },
						})
					)
					// .then(saveFindData)
					.catch((err) => {
						console.group('Failed to load:', service);
						console.error(err);
						console.groupEnd();
					})
			);

			promises.push(store.dispatch(dispatchGetBookSalesTotals()));
			promises.push(store.dispatch(dispatchGetKenpTotals()));
			promises.push(store.dispatch(dispatchGetExpensesTotals()));

			//	Optional?
			promises.push(
				store
					.dispatch(
						feathersServices[SERVICE.BOOK_EXTERNAL_ASSETS].find({
							query: { $limit: limit },
						})
					)
					.catch(errorHandler)
			);

			promises.push(
				store
					.dispatch(
						feathersServices[SERVICE.SITE_MENU_PAGES].find({
							query: {
								$limit: limit,
								includes: {
									page: true,
								},
							},
						})
					)
					.catch(errorHandler)
			);

			return Promise.all(promises).catch(errorHandler);
		};

		/*
		 *	Fetch initial data
		 */
		importData().then(() => {
			Logger.info('[dashboard] Import Complete');
			
			ReactDOM.render(
				// <React.StrictMode>
					<ErrorBoundary>
						<Provider store={store}>
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</Provider>
					</ErrorBoundary>
				// </React.StrictMode>
				,
				document.getElementById('root')
			);			
		});
	} catch (err) {
		// Authentication failed

		Logger.info('[dashboard] DASHBOARD APP ERROR');
		Logger.error(err);

		if (err.type === 'FeathersError' && err.name === 'NotAuthenticated') {
			await feathersAPI.logout();
			//	Delete the parent domain cookie if there is one
			document.cookie =
				'wpa-api=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.' +
				process.env.REACT_APP_PUBLIC_URL.replace(/https?:\/\//, '') +
				';';
			window.location.assign(process.env.REACT_APP_AUTH_URL);
		}
	}
}

authenticate();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
