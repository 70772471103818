import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { imagePath } from '../../utils/imagePath';
import AuthorLink from '../author/Link';

import {
	Card,
	CardImg,
	CardHeader,
	CardFooter,
} from 'reactstrap';
import { getAuthorsByBookId } from '../../reducers/db/authorBooks';
import {
	getSalesTotalByBookId
} from '../../reducers/db/bookSales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonModal } from '@wpa/components/lib/Form';
import InviteForm, { RESOURCE_TYPE } from '../InviteForm';
import {
	ASSET_DROPBOX,
	ASSET_GDRIVE,
	ASSET_ONEDRIVE,
	getExternalAssetsByBookIdAsMapByTypeId
} from '../../reducers/db/bookExternalAssets';
import { quickRound } from '../../utils/maths';

export class BookCard extends React.PureComponent {
	static displayName = 'BookCard';
	static propTypes = {
		/**
		 *
		 */
		book: PropTypes.object.isRequired,
		showAuthors: PropTypes.bool,
		showActions: PropTypes.bool
	};
	static defaultProps = {
		showActions: true
	};

	render() {
		const props = this.props;

		const { book, authors, showAuthors, showActions, externalAssets } = props;

		const classes = ['book'];

		//	Ignore the props flag if there are no authors to show for this book
		const showAuthor = authors.length > 0 ? showAuthors : false;

		const canShare = showActions ? book.User.canShare : false;

		const pandl = props.bookSalesAllTimeTotal.royalties || 0;
		const sales = props.bookSalesAllTimeTotal || {};
		const isUnpaid =
			sales.unpaidSales > 0 || sales.unpaidKenp > 0 || sales.unpaidRefunds > 0;

		classes.push('status-' + book.Status.slug);

		return (
			<Card className={classes.join(' ')}>
				<CardHeader>
					{showAuthor && (
						<span>
							{authors.map(author => (
								<AuthorLink key={author.id} author={author} link={false} />
							))}
						</span>
					)}
					<h3 className={showAuthor ? 'title' : 'title title-only'}>
						{book.link && <Link to={book.link}>{book.title}</Link>}
						{!book.link && book.title}
					</h3>
				</CardHeader>

				<div className="card-body status">{book.Status.name}</div>

				{book.Status.id === 4 && (
					<div
						className={
							'card-body text-center ' + (pandl >= 0 ? '' : 'text-danger')
						}
					>
						<b>&pound;{quickRound(pandl, 2)}</b>

						{isUnpaid && (
							<div className={'text-danger'}>
								<FontAwesomeIcon icon={['fas', 'books']} />
								&nbsp;{sales.unpaidSales} /{' '}
								<FontAwesomeIcon icon={['fab', 'amazon']} fixedWidth />
								&nbsp;{sales.unpaidKenp} /{' '}
								<FontAwesomeIcon icon={['fas', 'book-dead']} fixedWidth />
								&nbsp;{sales.unpaidRefunds}
							</div>
						)}
					</div>
				)}

				{book.Cover && book.link && (
					<Link to={book.link}>
						<CardImg
							top
							width="100%"
							src={imagePath(encodeURI(book.Cover.Image.path))}
							alt={'Cover for ' + book.title}
						/>
					</Link>
				)}

				{book.Cover && !book.link && (
					<CardImg
						top
						width="100%"
						src={imagePath(encodeURI(book.Cover.Image.path))}
						alt={'Cover for ' + book.title}
					/>
				)}

				{book.logline && <div className="card-body">{book.logline}</div>}

				{(externalAssets[ASSET_GDRIVE] ||
					externalAssets[ASSET_DROPBOX] ||
					externalAssets[ASSET_ONEDRIVE]) && (
					<div className={'text-center card-body'}>
						{externalAssets[ASSET_GDRIVE] && (
							<span className="asset">
								<a
									href={externalAssets[ASSET_GDRIVE].folder}
									title="Google Drive"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'google-drive']} size={'2x'} />
								</a>
								{/*<span>{externalAssets[ASSET_GDRIVE].notes}</span>*/}
							</span>
						)}
						{externalAssets[ASSET_DROPBOX] && (
							<span className="asset">
								<a
									href={externalAssets[ASSET_DROPBOX].folder}
									title="Dropbox"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'dropbox']} size={'2x'} />
								</a>
								{/*<span>{externalAssets[ASSET_DROPBOX].notes}</span>*/}
							</span>
						)}
						{externalAssets[ASSET_ONEDRIVE] && (
							<span className="asset">
								<a
									href={externalAssets[ASSET_ONEDRIVE].folder}
									title="OneDrive"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'microsoft']} size={'2x'} />
								</a>
								{/*<span>{externalAssets[ASSET_ONEDRIVE].notes}</span>*/}
							</span>
						)}
					</div>
				)}

				<CardFooter>
					{showActions && (
						<ul className="actions">
							<li>
								<Link
									to={book.link + '/details'}
									className={'btn btn-sm btn-outline-primary'}
								>
									<FontAwesomeIcon icon={['far', 'edit']} size={'lg'} />
								</Link>
							</li>
							{/*<li><a href="/todo"><i className="far fa-lg fa-trash-alt"></i></a></li>*/}
							{canShare && (
								<li>
									<ButtonModal
										// isOpen={props.match.path === '/invite/book/:slug/:id'}
										size={'small'}
										title="Invite to Book"
										type="link"
										className="btn-outline-primary"
										modal={
											<InviteForm
												type={RESOURCE_TYPE.BOOK}
												resourceId={book.id}
											/>
										}
									>
										<FontAwesomeIcon icon={['far', 'share-alt']} size={'lg'} />
									</ButtonModal>
								</li>
							)}
						</ul>
					)}
				</CardFooter>
			</Card>
		);
	}
}

export default connect((state, props) => ({
	authors: getAuthorsByBookId(state, props.book.id) || [],
	bookSalesAllTimeTotal:
		getSalesTotalByBookId(state, props.book.id, 'ALL_TIME_BY_BOOK')[0] || {},
	externalAssets: getExternalAssetsByBookIdAsMapByTypeId(state, props.book.id)
}))(BookCard);
