import { addItems } from '../helpers';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import {createReducer} from '../helpers';

/*
 *	Constants
 */

const serviceName = 'SERIES'; 
export const ADD_SERIES = 'ADD_'+ serviceName;
export const UPDATE_SERIES = 'UPDATE_'+ serviceName;
export const REMOVE_SERIES = 'REMOVE_'+ serviceName;

/*
 *	Helper functions
 */

export const addSeries = items => ({
	type: ADD_SERIES,
	payload: items
});

export function getSeriesByBookId(state, bookId) {
	// We actually store the series details on the book record
	// so there's no need to query the join table separately
	return state.db.books.byId[bookId].Series || [];
}

export function getSeriesById(state, id){
	return state.db.series.byId[id];
}

export function getSeriesAsTags(state, bookId){
	const stateSlice = state.db.series;
	const ids = bookId ? getSeriesByBookId(state, bookId) : stateSlice.allIds;

	return ids.map(id => ({
		value: id,
		label: stateSlice.byId[id].name,
	}));
}

// /**
//  * Series Reducer
//  * @param state
//  * @param action
//  * @returns {{initialState: boolean, byId: {}, allIds: Array}}
//  */
// export const seriesReducer = (
// 	state = {
// 		initialState: true,
// 		byId: {},
// 		allIds: []
// 	},
// 	action
// ) => {
// 	let newState, payload, books;

// 	switch (action.type) {
// 		case feathersServices.getFindFulfilledAction(SERVICE.BOOKS):
// 			books = action.payload.data || [];
// 			payload = [];

// 			books.forEach(book => {
// 				book.Series.forEach(series => payload.push(series));
// 			});
			
// 		//	eslint-ignore-next-line: no-fallthrough
// 		case feathersServices.getGetFulfilledAction(SERVICE.BOOKS):
// 			payload = payload || [action.payload.Series] || [];
			
// 		//	eslint-ignore-next-line: no-fallthrough
// 		case feathersServices.getFindFulfilledAction(SERVICE.SERIES):
// 			payload = payload || action.payload.data || [];

// 		//	Fallthrough as the action is the same
// 		case ADD_SERIES:
// 			newState = { ...state };
// 			newState.initialState = false;

// 			payload = payload || action.payload;

// 			addItems(newState, payload);

// 			return newState;

// 		default:
// 			return state;
// 	}
// };

/*
 *	Reducer
 */
export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {
			[feathersServices.getFindFulfilledAction(SERVICE.BOOKS)]: (newState, action) => {
				let books, payload = [];
				books = action.payload.data || [];

				books.forEach(book => {
					book.Series.forEach(series => payload.push(series));
				});

				addItems(newState, payload);

				return newState;
			},
			[feathersServices.getGetFulfilledAction(SERVICE.BOOKS)]: (newState, action) => {
				let payload = action.payload.Series || [];

				addItems(newState, payload);

				return newState;			
			}
		}
	}
);