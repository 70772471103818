import React, { useCallback } from 'react';
// import _ from 'lodash';
import { Label } from 'reactstrap';
import { ColorSelect } from '@wpa/components/lib/Form';
import { Form, Input } from '@wpa/components/lib/Form';
import FontSelect from '../FontSelect';
import { formStateMachine, FORM_ACTION } from '@wpa/state-machine';
import { useDispatch } from 'react-redux';
import { useMachine } from '@xstate/react';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import Logger from '@wpa/logger';

const sm = formStateMachine({
	formLoading: (context, event) =>
		new Promise((resolve, reject) =>
			context
				.dispatch(
					feathersServices[SERVICE.SITE_HEADERS].get(context.headerId)
				)
				.then((result) => {
					const data = result.value;
					const values = {
						title: data.title,
						subtitle: data.subtitle,
						socialMediaIcon: !!data.socialMediaIcon,
						socialMediaText: !!data.socialMediaText,
						backgroundColor: data.backgroundColor,
						textColor: data.textColor,
						linkColor: data.linkColor,
						menuFont: data.menuFont,
					};
					context.fields = {
						...context.fieleds,
						...values,
					};
					context.defaults = {
						...context.defaults,
						...values,
					};

					return resolve();
				})
				.catch(
					(err) =>
						Logger.error(err) ||
						reject('Unable to load site header details.')
				)
		),
	formAction: (context, event) =>
		new Promise((resolve, reject) =>
			context
				.dispatch(
					feathersServices[SERVICE.SITE_HEADERS].patch(
						context.headerId,
						{
							...context.fields,
						}
					)
				)
				.then((data) => {
					context.defaults = context.fields;
					resolve();
				})
				.catch(
					(err) =>
						Logger.error(err) ||
						reject('Unable to save site header details.')
				)
		),
});

export const SiteHeaderForm = ({ headerId }) => {
	const dispatch = useDispatch();

	const [state, send] = useMachine(
		sm.withFormContext(
			{
				dispatch,
				headerId,
			},
			{
				title: '',
				subtitle: '',
				socialMediaIcon: '',
				socialMediaText: '',
				backgroundColor: '',
				textColor: '',
				linkColor: '',
				menuFont: '',
			}
		)
	);

	const defaults = state.context.defaults;
	const {
		title,
		subtitle,
		socialMediaIcon,
		socialMediaText,
		backgroundColor,
		textColor,
		linkColor,
		menuFont,
	} = state.context.fields;

	const onBackgroundColorChange = useCallback(
		(color) =>
			send(FORM_ACTION.CHANGE, {
				fields: { backgroundColor: color.hex },
			}),
		[send]
	);

	const onTextColorChange = useCallback(
		(color) =>
			send(FORM_ACTION.CHANGE, {
				fields: { textColor: color.hex },
			}),
		[send]
	);

	const onLinkColorChange = useCallback(
		(color) =>
			send(FORM_ACTION.CHANGE, {
				fields: { linkColor: color.hex },
			}),
		[send]
	);

	return (
		<Form stateMachine={sm} state={state} send={send}>
			<Form.Item label="Title" htmlFor="siteHeaderTitle">
				<Input
					id="siteHeaderTitle"
					name="title"
					value={title}
					defaultValue={defaults.title}
				/>
			</Form.Item>

			<Form.Item label="Subtitle" htmlFor="siteHeaderSubtitle">
				<Input
					id="siteHeaderSubtitle"
					name="subtitle"
					value={subtitle}
					defaultValue={defaults.subtitle}
				/>
			</Form.Item>

			<Form.Item label="BG Colour" htmlFor="siteHeaderBackgroundColor">
				<ColorSelect
					id={'siteHeaderBackgroundColor'}
					name="backgroundColor"
					color={backgroundColor}
					onChange={onBackgroundColorChange}
				/>
			</Form.Item>

			<Form.Item label="Text Colour" htmlFor="siteHeaderTextColor">
				<ColorSelect
					id={'siteHeaderTextColor'}
					name="textColor"
					color={textColor}
					onChange={onTextColorChange}
				/>
			</Form.Item>

			<Form.Item label="Link Colour" htmlFor="siteHeaderLinkColor">
				<ColorSelect
					id={'siteHeaderLinkColor'}
					name="linkColor"
					color={linkColor}
					onChange={onLinkColorChange}
				/>
			</Form.Item>

			<Form.Item label="Header Menu Font" htmlFor="siteHeaderMenuFont">
				<FontSelect
					onChange={(value) => {
						send(FORM_ACTION.CHANGE, {
							fields: { menuFont: value },
						});
					}}
					value={menuFont}
				/>
			</Form.Item>

			<Form.Item label="Social media">
				<Form.Item>
					<Label check>
						<Input
							type="checkbox"
							name="socialMediaIcon"
							checked={!!socialMediaIcon}
							label={'Icons'}
						/>{' '}
						Icons
					</Label>
				</Form.Item>
				<Form.Item>
					<Label check>
						<Input
							type="checkbox"
							name="socialMediaText"
							checked={!!socialMediaText}
						/>{' '}
						Text
					</Label>
				</Form.Item>
			</Form.Item>
		</Form>
	);
};

export default SiteHeaderForm;
