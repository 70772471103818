function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Machine, assign } from 'xstate';
import Logger from '@wpa/logger';
import { isEqual } from 'lodash';
/**
 * Common State Actions
 */

export var setErrorData = assign({
  error: true,
  errorMessage: function errorMessage(context, event) {
    return Logger.error(event) || event.data && event.data.message || event.data;
  }
});
/**
 *	Form state machine 
 */

export var FORM_STATE = {
  LOADING: 'loading',
  LOADING_ERROR: 'loadingError',
  ERROR: 'error',
  DISPLAY: 'display',
  SUBMITTING: 'submitting'
};
export var FORM_ACTION = {
  LOAD: 'load',
  ERROR: 'error',
  CHANGE: 'change',
  DISPLAY: 'display',
  SUBMIT: 'submit'
};
export function xstateFormHandleInputChange(event) {
  var _ref;

  var target = event.target;
  var isArray = target.name.match(/\[\]$/);
  var name = isArray ? target.name.replace('[]', '') : target.name;
  var value = target.value;

  if (target.type === 'checkbox') {
    if (isArray) {
      //	Current state
      value = _objectSpread({}, this.state[name]);

      if (target.checked) {
        value[target.value] = target.value;
      } else {
        delete value[target.value];
      }
    } else {
      value = target.checked;
    }
  }

  if (!name) {
    Logger.error('Form element has no "name"!', event);
    return;
  }

  return _ref = {}, _defineProperty(_ref, name, value), _defineProperty(_ref, "fields", _defineProperty({}, name, value)), _defineProperty(_ref, name + 'Cursor', event.target.selectionStart), _ref;
}
export var xstateOnChange = function xstateOnChange(send) {
  return function (e) {
    return send(FORM_ACTION.CHANGE, xstateFormHandleInputChange(e));
  };
};

function changeFields(context, payload) {
  var newContext = _objectSpread({}, context);

  newContext.fields = _objectSpread(_objectSpread({}, context.fields), payload.fields);
  delete payload.fields;
  return _objectSpread(_objectSpread({}, newContext), payload);
}

export var formStateMachine = function formStateMachine(_ref2) {
  var _on, _on2, _on3, _states;

  var formAction = _ref2.formAction,
      formLoading = _ref2.formLoading;
  var m = Machine({
    initial: formLoading ? FORM_STATE.LOADING : FORM_STATE.DISPLAY,
    context: {
      error: false,
      errorMessage: '',
      //	Used when multiple actions are handled
      action: ''
    },
    states: (_states = {}, _defineProperty(_states, FORM_STATE.LOADING, {
      invoke: {
        id: 'loading',
        src: formLoading ? formLoading : function (context, event) {
          return new Promise(function (resolve, reject) {
            return reject('No action specified for loading state');
          });
        },
        onDone: {
          target: FORM_STATE.DISPLAY // actions: [
          // 	assign((context, event) => {
          // 	})
          // ]

        },
        onError: {
          target: FORM_STATE.LOADING_ERROR,
          actions: assign({
            error: function error(context, event) {
              return event.data;
            },
            //	If we get back an error object, pluck the message, otherwise just use the error value
            errorMessage: function errorMessage(context, event) {
              return Logger.log(event) || event.data.message || event.data;
            }
          })
        }
      }
    }), _defineProperty(_states, FORM_STATE.DISPLAY, {
      onEntry: [assign({
        action: ''
      })],
      on: (_on = {}, _defineProperty(_on, FORM_ACTION.SUBMIT, {
        target: FORM_STATE.SUBMITTING
      }), _defineProperty(_on, FORM_ACTION.LOAD, {
        target: FORM_STATE.LOADING
      }), _defineProperty(_on, FORM_ACTION.ERROR, {
        target: FORM_STATE.ERROR
      }), _defineProperty(_on, FORM_ACTION.CHANGE, {
        target: FORM_STATE.DISPLAY,
        actions: [//	Hack to make the assign work - why is this needed???
        function (context, event) {
          return true;
        }, assign(changeFields)]
      }), _on)
    }), _defineProperty(_states, FORM_STATE.SUBMITTING, {
      onEntry: [assign({
        action: function action(context, payload) {
          return payload.action;
        }
      })],
      invoke: {
        id: 'submit',
        src: formAction ? formAction : function (context, event) {
          return new Promise(function (resolve, reject) {
            return reject('No event specified for the button');
          });
        },
        onDone: {
          target: FORM_STATE.DISPLAY
        },
        onError: {
          target: FORM_STATE.ERROR,
          actions: assign({
            error: function error(context, event) {
              return event.data;
            },
            //	If we get back an error object, pluck the message, otherwise just use the error value
            errorMessage: function errorMessage(context, event) {
              return Logger.log(event) || event.data.message || event.data;
            }
          })
        }
      }
    }), _defineProperty(_states, FORM_STATE.ERROR, {
      onEntry: [assign({
        error: function error(context, event) {
          return event.error || context.error;
        },
        errorMessage: function errorMessage(context, event) {
          return event.errorMessage || context.errorMessage;
        }
      }), function (context, event) {
        if (context.onError) {
          return context.onError(context.errorMessage, context.error);
        }
      }, function (context, event) {
        return Logger.error('Default error response:', context.errorMessage, context.error);
      }],
      onExit: [//	Always clear internal state
      assign({
        error: false,
        errorMessage: null
      }), function (context, event) {
        if (context.onError) {
          return context.onError(null, null);
        }
      }].filter(Boolean),
      on: (_on2 = {}, _defineProperty(_on2, FORM_ACTION.SUBMIT, {
        target: FORM_STATE.SUBMITTING
      }), _defineProperty(_on2, FORM_ACTION.DISPLAY, {
        target: FORM_STATE.DISPLAY
      }), _defineProperty(_on2, FORM_ACTION.CHANGE, {
        target: FORM_STATE.DISPLAY,
        actions: [//	Hack to make the assign work - why is this needed???
        function (context, event) {
          return true;
        }, assign(changeFields)]
      }), _on2)
    }), _defineProperty(_states, FORM_STATE.LOADING_ERROR, {
      onEntry: [assign({
        error: function error(context, event) {
          return event.error || context.error;
        },
        errorMessage: function errorMessage(context, event) {
          return event.errorMessage || context.errorMessage;
        }
      }), function (context, event) {
        if (context.onError) {
          return context.onError(context.errorMessage, context.error);
        }
      }, function (context, event) {
        return Logger.error('Default loading error response:', context.errorMessage, context.error);
      }],
      onExit: [//	Always clear internal state
      assign({
        error: false,
        errorMessage: null
      }), function (context, event) {
        if (context.onError) {
          return context.onError(null, null);
        }
      }].filter(Boolean),
      on: (_on3 = {}, _defineProperty(_on3, FORM_ACTION.SUBMIT, {
        target: FORM_STATE.SUBMITTING
      }), _defineProperty(_on3, FORM_ACTION.DISPLAY, {
        target: FORM_STATE.DISPLAY
      }), _defineProperty(_on3, FORM_ACTION.CHANGE, {
        target: FORM_STATE.DISPLAY,
        actions: [//	Hack to make the assign work - why is this needed???
        function (context, event) {
          return true;
        }, assign(changeFields)]
      }), _on3)
    }), _states)
  });

  m.withFormContext = function (context, defaults) {
    return this.withContext(_objectSpread(_objectSpread({}, context), {}, {
      fields: defaults,
      defaults: defaults
    }));
  };

  m.hasChanged = function (state) {
    return !isEqual(state.context.defaults, state.context.fields);
  };

  return m;
};
export var LOADING_STATE = {
  LOADING: 'loading',
  ERROR: 'error',
  LOADING_ERROR: 'loadingError',
  DISPLAY: 'display'
};
export var LOADING_ACTION = {
  LOAD: 'load',
  ERROR: 'error',
  DISPLAY: 'display'
};
export var loadingStateMachine = function loadingStateMachine(_ref3) {
  var _on5, _on6, _states2;

  var loadingAction = _ref3.loadingAction;
  return Machine({
    initial: LOADING_STATE.LOADING,
    context: {
      action: '',
      error: false,
      errorMessage: ''
    },
    states: (_states2 = {}, _defineProperty(_states2, LOADING_STATE.LOADING, {
      invoke: {
        id: 'loading',
        src: loadingAction ? loadingAction : function (context, event) {
          return new Promise(function (resolve, reject) {
            return reject('No action specified for loading state');
          });
        },
        onDone: {
          target: LOADING_STATE.DISPLAY
        },
        onError: {
          target: LOADING_STATE.LOADING_ERROR,
          actions: assign({
            error: function error(context, event) {
              return event.data;
            },
            //	If we get back an error object, pluck the message, otherwise just use the error value
            errorMessage: function errorMessage(context, event) {
              return Logger.log(event) || event.data.message || event.data;
            }
          })
        }
      }
    }), _defineProperty(_states2, LOADING_STATE.DISPLAY, {
      onEntry: [assign({
        action: ''
      })],
      on: _defineProperty({}, LOADING_ACTION.LOAD, {
        target: LOADING_STATE.LOADING
      })
    }), _defineProperty(_states2, LOADING_STATE.ERROR, {
      onEntry: [assign({
        error: function error(context, event) {
          return event.error || context.error;
        },
        errorMessage: function errorMessage(context, event) {
          return event.errorMessage || context.errorMessage;
        }
      }), function (context, event) {
        return Logger.error('Default error response:', context.errorMessage, context.error);
      }],
      onExit: [//	Always clear internal state
      assign({
        error: false,
        errorMessage: null
      })].filter(Boolean),
      on: (_on5 = {}, _defineProperty(_on5, LOADING_ACTION.LOAD, {
        target: LOADING_STATE.LOADING
      }), _defineProperty(_on5, LOADING_ACTION.DISPLAY, {
        target: LOADING_STATE.DISPLAY
      }), _on5)
    }), _defineProperty(_states2, LOADING_STATE.LOADING_ERROR, {
      onEntry: [assign({
        error: function error(context, event) {
          return event.error || context.error;
        },
        errorMessage: function errorMessage(context, event) {
          return event.errorMessage || context.errorMessage;
        }
      }), function (context, event) {
        return Logger.error('Default error response:', context.errorMessage, context.error);
      }],
      onExit: [//	Always clear internal state
      assign({
        error: false,
        errorMessage: null
      })].filter(Boolean),
      on: (_on6 = {}, _defineProperty(_on6, LOADING_ACTION.LOAD, {
        target: LOADING_STATE.LOADING
      }), _defineProperty(_on6, LOADING_ACTION.DISPLAY, {
        target: LOADING_STATE.DISPLAY
      }), _on6)
    }), _states2)
  });
};