import React from 'react';
import {
	// CardTitle, CardBlock, CardText, CardFooter,
	// Container, 
	Row, Col
} from 'reactstrap';

import {connect} from 'react-redux';

import PageHeader from '../components/PageHeader';
import SiteList from '../components/sites/List';
import SiteAddButton from '../components/sites/AddButton';
import {Layout, Card} from 'antd';
const {Content} = Layout;


export const page = (props) =>{

	return (
		<Content id="sitesPage">
			<PageHeader title="Sites">
				<SiteAddButton showModal={props.match.path === '/sites/add'}>
					Add Site
				</SiteAddButton>
			</PageHeader>
			<Row>
				<Col>
					<Card>
						<SiteList/>

					</Card>
				</Col>
			</Row>
		</Content>
	);
};



export default connect(state => state)(page);