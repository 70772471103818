import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'PERMISSIONS';

export const ADD_PERMISSIONS = 'ADD_'+ serviceName;
export const UPDATE_PERMISSIONS = 'UPDATE_'+ serviceName;
export const REMOVE_PERMISSIONS = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */

export const addPermissions = (payload) => ({
	type: ADD_PERMISSIONS,
	payload: payload
});

export function getAllPermissions(state){
	const stateSlice = state.db.permissions;
	return stateSlice.allIds.map(id => stateSlice.byId[id]);
}


// export const permissionsReducer = (state = {
// 	initialState: true,
// 	byId: {},
// 	allIds: []
// }, action) => {
// 	let newState, payload;

// 	switch (action.type) {
// 		case feathersServices.getFindFulfilledAction(SERVICE.PERMISSIONS):
// 			payload = action.payload.data || [];
			
// 		//	eslint-disable-next-line no-fallthrough
// 		case PERMISSIONS_ADD:
// 			newState = {...state};
// 			newState.initialState = false;

// 			payload = payload || action.payload;

// 			addItems(newState, payload);

// 			return newState;

// 		default:
// 			return state;
// 	}
// };

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);