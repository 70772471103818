import {updateFilter} from '../helpers';
import {INITIAL_BOOK_STATUSES_LOADED} from '../db/bookStatuses';
import {INITIAL_AUTHORS_LOADED} from '../db/authors';
import {feathersServices, SERVICE} from '@wpa/feathers-client';

/*
 * Constants
 */

// export const ADD_BOOKS = "ADD_BOOKS";
// export const UPDATE_COVER = "UPDATE_COVER";
export const UPDATE_BOOK_FILTER_STATUSES = 'UPDATE_BOOK_FILTER_STATUSES';
export const UPDATE_BOOK_FILTER_AUTHORS = 'UPDATE_BOOK_FILTER_AUTHORS';
export const TOGGLE_BOOK_FILTER_STATUS = 'TOGGLE_BOOK_FILTER_STATUS';
export const TOGGLE_BOOK_FILTER_AUTHOR = 'TOGGLE_BOOK_FILTER_AUTHOR';

/*
 * Helper functions
 */

// export function getBookBySlug(state, slug){
// 	const stateSlice = state.db.books;
// 	const bookId = getItemIdByParam(stateSlice, 'slug', slug);
//
// 	return stateSlice.byId[bookId];
// }
//
//
// export const addBooks = (books) => ({
// 	type: ADD_BOOKS,
// 	payload: books
// });
// export const updateCover = (cover) => ({
// 	type: UPDATE_COVER,
// 	payload: cover
// });




/**
 * Books reducer
 * @param state
 * @param action
 * @returns {*} new state
 */
export const booksPageReducer = (state = {
	filter: {
		statuses: {
			//	@todo: this should be dynamic as we might have more than just the defaults
			//	@todo: this should possibly be sticky between user sessions - cookie?
			//	default to showing all
			1: true,
			2: true,
			3: true,
			4: true,
		},
		authors: {},
	},
}, action) => {
	let newState, payload;

	switch (action.type) {

		case INITIAL_BOOK_STATUSES_LOADED:
		case UPDATE_BOOK_FILTER_STATUSES:
			newState = {...state};

			payload = action.payload || [];

			updateFilter('statuses',newState, payload);

			return newState;

		case TOGGLE_BOOK_FILTER_STATUS:
			newState = {...state};
			newState.filter.statuses[action.payload] = ! newState.filter.statuses[action.payload];

			return newState;


		/*
		 * When we add or refresh author details, update the filter
		 */
		case feathersServices.getGetFulfilledAction(SERVICE.AUTHORS):
			payload = payload || [action.payload] || [];

		//	eslint-ignore-next-line: no-fallthrough
		case feathersServices.getFindFulfilledAction(SERVICE.AUTHORS):
			payload = payload || action.payload.data || [];

		//	eslint-ignore-next-line: no-fallthrough
		case INITIAL_AUTHORS_LOADED:
		case UPDATE_BOOK_FILTER_AUTHORS:
			newState = {...state};

			payload = payload || action.payload || [];

			updateFilter('authors',newState, payload);

			return newState;

		case TOGGLE_BOOK_FILTER_AUTHOR:
			newState = {...state};
			newState.filter.authors[action.payload] = ! newState.filter.authors[action.payload];

			return newState;

		default:
			return state;
	}
};