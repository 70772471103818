import { getItemIdByParam } from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'SITE_PAGE_AUTHOR_BIO';
export const ADD_SITE_PAGE_AUTHOR_BIO = 'ADD_'+ serviceName;
export const UPDATE_SITE_PAGE_AUTHOR_BIO = 'UPDATE_'+ serviceName;
export const REMOVE_SITE_PAGE_AUTHOR_BIO = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */

export const addSitePageAuthorBio = items => ({
	type: ADD_SITE_PAGE_AUTHOR_BIO,
	payload: items
});

export const getLatestSitePageAuthorBioForBlockId = (state, blockId) => {
	const stateSlice = state.db.sitePageAuthorBio;
	const id = getItemIdByParam(stateSlice, 'blockId', blockId);
	
	return stateSlice.byId[id];
};

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);