//	eslint-disable-next-line
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import SiteLayout from './Layout';
import { Card, Row, Col } from 'antd';
import SiteMenuMenu from '../../components/sites/SiteMenuMenu';
import SiteMenuPageList from '../../components/sites/SiteMenuPageList';
import SiteMenuAvailablePageList from '../../components/sites/SiteMenuAvailablePageList';

export const MenusPage = ({
	match: {
		url,
		params: { slug, menu }
	}
}) => {
	// const siteMenu = useSelector(state => );

	return (
		<SiteLayout pageName={'siteMenus'} title="Menus">
			<SiteMenuMenu />

			<Row gutter={16}>
				<Col sm={12}>
					
					<Card title={<h3>Available Pages</h3>}>
						<SiteMenuAvailablePageList />
					</Card>
				</Col>
				<Col sm={12}>
					
					<Card title={<h3>Menu</h3>}>
						<p>Hold and drag indivdual page entries below to re-order the menu items.</p>
						<SiteMenuPageList />
					</Card>
				</Col>
			</Row>
		</SiteLayout>
	);
};

export default withRouter(MenusPage);