import React, { useCallback } from 'react';
import CardTextarea from '../shared/CardTextarea';
import { feathersServices, SERVICE } from '@wpa/feathers-client';

export const CustomSiteJSCard = ({ siteId, siteCustomJS }) => {
	const onSubmit = useCallback(
		(content, dispatch) =>
			dispatch(
				feathersServices[SERVICE.SITES].patch(siteId, {
					customJS: content,
				})
			),
		[siteId]
	);

	return (
		<CardTextarea
			title={'Custom JS'}
			content={siteCustomJS}
			id={'customSiteJS'}
			onSubmit={onSubmit}
		></CardTextarea>
	);
};

export default CustomSiteJSCard;
