import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
	Card,
	CardBody,
	// CardFooter,
	CardImg,
	// CardText,
	CardTitle,
	Col,
	// Modal,
	// ModalBody,
	// ModalHeader,
	Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from '../components/PageHeader';
import {connect} from 'react-redux';
import {imagePath} from '../utils/imagePath';
// import AuthorAddButton from '../components/author/AddButton';
import {getAllPublishers} from '../reducers/db/publishers';
import {Layout} from 'antd';
const {Content} = Layout;

export const page = (props) =>{
	const publishers = props.publishers;
	return (
		<Content id="publishersPage">
			<PageHeader title="Publishers">
				{/*<AuthorAddButton showModal={props.match.path === '/authors/add'}>*/}
					{/*Add Publisher*/}
				{/*</AuthorAddButton>*/}
			</PageHeader>
			<Row>
				{publishers.map(publisher => {
					const picture = (publisher.ProfilePic || [])
						.filter(pic => pic.authorImages && pic.authorImages.isPrimary)
						.pop();

					return <Col sm="12" md="6" key={publisher.id}>
						<Card>
							{picture && <CardImg top src={imagePath(picture.path)} alt=""/>}
							<CardBody>
								<Link to={publisher.link} className={"float-right"}><FontAwesomeIcon icon={['far', 'edit']} size={"lg"} /></Link>
								<CardTitle>
									<Link to={publisher.link}>
										{publisher.User.isPrimary && <FontAwesomeIcon icon={['fas', "badge-check"]} fixedWidth size={"lg"} title={"Primary Publisher"} />}
										{publisher.name}
									</Link>
								</CardTitle>

							</CardBody>
						</Card>
					</Col>
				})}
			</Row>
		</Content>
	);
};

export default withRouter(connect(state => ({
	publishers: getAllPublishers(state),
}))(page));
