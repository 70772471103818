import React from 'react';
import {
	Row, Col
} from 'antd';

//	@todo: PureComponent is not much use when children are passed this way
export class PageHeader extends React.PureComponent {
	render(){
		const props = this.props;

		return (
			<Row className="pageHeader" gutter={16}>
				<Col sm={props.children ? 12 : 24}>
					<h1>{props.subtitle && (
						<small style={{
							fontSize: "0.35em"
						}}>{props.subtitle}<br /></small>
					)}{props.title}</h1>
				</Col>
				{props.children && <Col sm={12} className="text-sm-right">
					{props.children}
				</Col>}
			</Row>
		);
	}
};

export default PageHeader;