import React from 'react';
import { Row, Col, Card } from 'antd';
import BookSummaryForm from '../../components/books/SummaryForm';
import { BookBlurbs } from '../../components/books/Blurbs';
import BookPageLayout from './Layout';
import { BookCoverImageCard } from '../../components/ImageCard';

const BookEditPage = ({
	match: {
		params: { id },
	},
}) => {
	const bookId = parseInt(id, 10);

	return (
		<BookPageLayout pageName={'bookDetails'} bookId={bookId}>
			<Row gutter={16}>
				<Col sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
					<BookCoverImageCard bookId={bookId} />
				</Col>
				<Col sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 18 }}>
					<Card title={<h3>Summary</h3>}>
						<BookSummaryForm bookId={bookId} />
					</Card>
				</Col>
			</Row>

			<BookBlurbs bookId={bookId} />
		</BookPageLayout>
	);
};

export default BookEditPage;
