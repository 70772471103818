import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthorPageMenu from './Menu';
import PageHeader from '../../components/PageHeader';
import ErrorBoundary from '@wpa/components/lib/ErrorBoundry';
import { getPublisherById } from '../../reducers/db/publishers';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';

const { Content, Sider } = Layout;

export const PublisherLayout = ({
	pageName,
	match: {
		params: { id },
	},
	children,
	...props
}) => {
	const pageId = pageName + 'Page';
	const publisherId = parseInt(id, 10);
	const publisherName = useSelector(
		(state) => getPublisherById(state, publisherId).name
	);

	return (
		<Layout>
			<Sider className="secondaryMenu" theme="light">
				<AuthorPageMenu />
			</Sider>
			<Layout>
				<Content id={pageId}>
					<PageHeader title={publisherName} subtitle={'Publisher'} />

					<ErrorBoundary>{children}</ErrorBoundary>
				</Content>
			</Layout>
		</Layout>
	);
};

PublisherLayout.propTypes = {
	publisherName: PropTypes.string,
	match: PropTypes.object,
};

export default withRouter(PublisherLayout);
