import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
	Col,
	Form, FormGroup, Label, 
	// FormText, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import {Button, formHandleInputChange, Input} from "@wpa/components/lib/Form";
import Notice from "@wpa/components/lib/Notice";
import {errorStateReducer} from "../../reducers";
import {addFSM} from "../../functional/withStateMachine";
import {getSitePageById} from "../../reducers/db/sitePages";
import {feathersServices, SERVICE} from "@wpa/feathers-client";

export const STATE = {
	//  State constants
	DISPLAY: 'display',
	SAVING: 'saving',
	ERROR: 'error',
};

export const ACTION = {
	SAVE: 'save',
	DISPLAY: 'display',
	ERROR: 'error',
};

export const stateMachine = {
	initial: STATE.DISPLAY,
	states: {
		[STATE.DISPLAY]: {
			on: {
				[ACTION.SAVE]: {
					[STATE.SAVING]: {}
				}
			}
		},
		[STATE.SAVING]: {
			on: {
				[ACTION.DISPLAY]: {
					[STATE.DISPLAY]: {}
				},
				[ACTION.ERROR]: {
					[STATE.ERROR]: {}
				},
			}
		},
		[STATE.ERROR]: {
			on: {
				[ACTION.SAVE]: {
					[STATE.SAVING]: {}
				}
			}
		},
	}
};

export const stateReducers = [
	errorStateReducer.reducer
];

class PageDetailsForm extends React.Component {
	static displayName = 'PageDetailsForm';
	static propTypes = {
		pageId: PropTypes.number.isRequired,
		page: PropTypes.object.isRequired,
	};

	constructor(props){
		super(props);

		//	We do rely on the component actually having page details
		const page = props.page;

		this.state = {
			errorMessage: '',
			title: page.title,
			path: page.path,
			description: page.description,
		};

		this.onChange = formHandleInputChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onPageDetailsFormSave = this.onPageDetailsFormSave.bind(this);

		addFSM(this, stateMachine, stateReducers);
	}

	onPageDetailsFormSave(){
		const self = this;
		self.props
			.dispatch(feathersServices[SERVICE.SITE_PAGES].patch(self.props.pageId, {
				title: self.state.title,
				path: self.state.path,
				description: self.state.description
			}))
			.then(json => {
				self.transition(ACTION.DISPLAY, {});
			})
			.catch(err => self.transition(ACTION.ERROR, {
				message: 'Unable to save page settings',
				error: err
			}))
		;
	}

	onSubmit(e){
		e.preventDefault();

		this.transition(ACTION.SAVE);
	}

	render(){
		const {
			page
		} = this.props;
		const {
			currentState,
			errorMessage
		} = this.state;

		//	Reserved paths - no editing
		const disabledPathEditing = [
			'/',
			'/about',
			'/books',
			'/privacy',
		];

		return (
			<Form onChange={this.onChange} onSubmit={this.onSubmit}>
				<FormGroup row>
					<Label md={5} lg={4}>Title</Label>
					<Col md={7} lg={8}>
						<Input
							name="title"
							defaultValue={page.title || ''}
							value={this.state.title}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label md={5} lg={4}>Path</Label>
					<Col md={7} lg={8}>
						<Input
							name="path"
							defaultValue={page.path || ''}
							value={this.state.path}
							disabled={disabledPathEditing.indexOf(page.path) !== -1}
						/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Label>Description</Label>
					<Col>
						<Input type="textarea"
							name="description"
							defaultValue={page.description || ''}
							value={this.state.description}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Col md={{size: 7, offset: 5}} lg={{size:8, offset:4}}>
						<Button loading={currentState === STATE.SAVING} color="primary">Save</Button>
					</Col>
				</FormGroup>
				{currentState === STATE.ERROR &&
				<Notice color="danger">
					{errorMessage}
				</Notice>}
			</Form>
		);
	}
}

export default connect((state, props)=> ({
	page: getSitePageById(state, props.pageId)
}))(PageDetailsForm);
