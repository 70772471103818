//	eslint-disable-next-line
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { Button } from '@wpa/components/lib/Form';
import SiteCreateForm from './CreateForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SiteAddButton = ({...props}) => {
	// static displayName = 'SiteAddButton';
	// static propTypes = {
	// 	showModal: PropTypes.bool
	// };
	// static defaultProps = {
	// 	showModal: false
	// };
	const [showModal, setShowModal] = useState(props.showModal || false);

	const handleClick = () => {
		setShowModal(! showModal);
	};

	return (
		<div style={{ display: 'inline' }}>
			<Button type="primary" onClick={handleClick}>
				<FontAwesomeIcon icon={['far', 'plus']} />
				{props.children}
			</Button>
			<Modal
				isOpen={showModal}
				toggle={handleClick}
				autoFocus={false}
				fade={false}
			>
				<ModalHeader toggle={handleClick}>Add Site</ModalHeader>
				<ModalBody>
					<SiteCreateForm onSiteCreateFormClose={handleClick} />
				</ModalBody>
			</Modal>
		</div>
	);
};

export default SiteAddButton;
