import React from 'react';
import AuthorLink from "./Link";

class AuthorListItem extends React.PureComponent {
	constructor(props){
		super(props);

		this.state = {
			selected: this.props.selected || false
		};

		this.toggleItem = this.toggleItem.bind(this);
	}

	toggleItem(e){
		const state = !this.state.selected;

		this.setState({
			selected: state
		});

		//	Call parent list with index and item state
		if(this.props.onToggle){
			this.props.onToggle(this.props.author.id, state);
		}
	}

	render(){
		const author = this.props.author;

		return (
			<li key={author.id} onClick={this.toggleItem}>
				<AuthorLink selected={this.state.selected} author={author} link={false} />
			</li>
		);
	}
}

export default AuthorListItem;