import {Card, CardBody, CardTitle} from 'reactstrap';
import React from 'react';
import {connect} from 'react-redux';
import {salesConnect, salesDefaultProps, salesPropTypes} from './sales';
import {quickRound} from '../../../utils/maths';

export const PandLCard = ({royalties}) => {
	//	No expenses available yet, so just assign for now
	const pandl = royalties;
	const expenses = 0;

	return (
		<Card>
			<CardBody className="text-center">
				<div style={{fontSize:"1.5em" }}>
					<b>&pound;{quickRound(pandl, 2)}</b>
				</div>
				<CardTitle style={{fontSize:"0.95em" }}>All Time {pandl >= 0 ? 'Profit' : 'Loss'}</CardTitle>

				<hr />
				<div>
					<b>&pound;{royalties}</b><br/>
					<small>Royalties</small>
				</div>
				<div>
					<b>&pound;{expenses}</b><br/>
					<small>Expenses</small>
				</div>
			</CardBody>
		</Card>
	);
};

PandLCard.propTypes = salesPropTypes;
PandLCard.defaultProps = salesDefaultProps;

export default connect(salesConnect)(PandLCard);