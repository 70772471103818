//  eslint-disable-next-line
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	xstateOnChange,
	FORM_ACTION,
	FORM_STATE,
	formStateMachine
} from '@wpa/state-machine';
import { useMachine } from '@xstate/react';
import Logger from '@wpa/logger';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { Form, Input, Select, Radio, Checkbox } from 'antd';
import { FormAlert, formItemLayout, Button } from '@wpa/components/lib/Form';
import { getLatestSitePageBooksForBlockId } from '../../../../reducers/db/sitePageBooks';
import BookSeriesSelect from '../../../books/BookSeriesSelect';

const m = formStateMachine({
	formLoading: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(feathersServices[SERVICE.SERIES].find())
				.then(res => resolve())
				.catch(
					err =>
						Logger.error(err) || reject('Unable to load book filter details.')
				);
		}),
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			const params = {
				title: context.title,
				filter: context.filter,
				authorFilter: (context.authorId || []).join(','),
				seriesFilter: context.showSeriesStandalone
					? '-1'
					: (context.seriesId || []).join(','),
				showPubDate: context.showPubDate,
				showBlurb: context.showBlurb,
				showCover: context.showCover,
				// showAuthors: context.showAuthors,
				// showSeries: context.showSeries,
				// showWorlds: context.showWorlds,
				bookOrder: context.bookOrder,
				bookLimit: context.bookLimit,
				bookLayoutId: context.bookLayoutId,
				// sectionBookLimit: context.sectionBookLimit,
				showTitle: context.showTitle,
				showSeriesName: context.showSeriesName,
				showAuthorName: context.showAuthorName,
				showPurchaseLinks: context.showPurchaseLinks
			};

			if (!event.bookBlockId) {
				context
					.dispatch(
						feathersServices[SERVICE.SITE_PAGE_BOOKS].create({
							blockId: event.blockId,
							pagekId: event.pageId,
							...params
						})
					)
					.then(res => {
						context.defaultContent = context.content;
						resolve();
					})
					.catch(
						err =>
							Logger.error(err) || reject('Unable to save book block details.')
					);
			} else {
				context
					.dispatch(
						feathersServices[SERVICE.SITE_PAGE_BOOKS].patch(event.bookBlockId, {
							...params
						})
					)
					.then(res => {
						context.defaultContent = context.content;
						resolve();
					})
					.catch(
						err =>
							Logger.error(err) || reject('Unable to save book block details.')
					);
			}
		})
});

export const SitePageBlockBookForm = ({ blockId }) => {
	const dispatch = useDispatch();
	const bookBlock =
		useSelector(state => getLatestSitePageBooksForBlockId(state, blockId)) ||
		{};

	const series = useSelector(state =>
		bookBlock.seriesFilter && bookBlock.seriesFilter !== '-1'
			? bookBlock.seriesFilter
				.split(',')
				// .map(id => id + '')
				// .map(id => getSeriesById(state, id))
				// .filter(Boolean)
				// .map(series => ({
				// 	id: series.id,
				// 	name: series ? series.name : ''
				// }))
			: []
	);

	//	@todo: tickbox does not reset the seriesId field after the initial wipe

	const [state, send] = useMachine(
		m.withContext({
			title: bookBlock.title,
			blockId: blockId,
			dispatch: dispatch,
			seriesId: series,
		})
	);

	const {
		errorMessage,
		action,
		title = bookBlock.title, // ? bookBlock.title + '' : '',
		// showPubDate = !!bookBlock.showPubDate,
		// showAuthors = bookBlock.showAuthors+'',
		showBlurb = bookBlock.showBlurb || bookBlock.showBlurb === 0
			? bookBlock.showBlurb + ''
			: null,
		// showCover = !!bookBlock.showCover,
		// showTitle = !!bookBlock.showTitle,
		// showSeries = bookBlock.showSeries+'',
		// showWorlds = bookBlock.showWorlds+'',
		// showAuthorName = !!bookBlock.showAuthorName,
		// showSeriesName = !!bookBlock.showSeriesName,
		// showPurchaseLinks = !!bookBlock.showPurchaseLinks,
		// sectionBookLimit = bookBlock.sectionBookLimit,
		bookLayoutId = bookBlock.bookLayoutId, // ? bookBlock.bookLayoutId + '' : '1',
		bookLimit = bookBlock.bookLimit, // ? bookBlock.bookLimit + '' : '',
		bookOrder = bookBlock.bookOrder, // ? bookBlock.bookOrder + '' : '1',
		filter = bookBlock.filter, // ? bookBlock.filter + '' : '',
		showSeriesStandalone = bookBlock.seriesFilter === '-1',
		seriesId,
	} = state.context;

	// const {
	// 	title: defaultTitle = defaultTitle ? defaultTitle : '',
	// 	filter: defaultFilter = defaultFilter ? defaultFilter : '',
	// 	bookOrder: defaultBookOrder ? ,
	// 	bookLimit: defaultBookLimit = defaultBookLimit ? defaultBookLimit : '',
	// 	// sectionBookLimit: defaultSectionBookLimit,
	// 	bookLayoutId: defaultBookLayoutId
	// } = bookBlock;

	const isLoading = state.matches(FORM_STATE.LOADING);
	const isError = state.matches(FORM_STATE.ERROR);
	const isSaving = state.matches(FORM_STATE.SUBMITTING) && action === 'save';
	const isPublishing =
		state.matches(FORM_STATE.SUBMITTING) && action === 'publish';
	const isDisabled = isSaving || isPublishing;

	const handleSave = useCallback(
		e => {
			e.preventDefault();

			send(FORM_ACTION.SUBMIT, {
				blockId: blockId,
				bookBlockId: bookBlock.id,
				pageId: bookBlock.pageId
			});
		},
		[send, blockId, bookBlock]
	);

	const handleSeries = useCallback(
		value => 
			send(FORM_ACTION.CHANGE, {
				seriesId: value
			}),
		[send]
	);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	// console.log('BLOCK:', filter, defaultFilter);

	//	@save the seriesId value in the db

	return (
		<Form
			onSubmit={handleSave}
			onChange={xstateOnChange(send)}
			layout="horizontal"
		>
			<Form.Item
				label="Title"
				extra="Optional heading for the block."
				{...formItemLayout}
			>
				<Input name={'title'} value={title} />
			</Form.Item>
			<Form.Item label="Books layout" {...formItemLayout}>
				<Select
					name="bookLayoutId"
					value={bookLayoutId || 1}
					onChange={value => {
						send(FORM_ACTION.CHANGE, { bookLayoutId: value });
					}}
				>
					<Select.Option value={1}>Just cover</Select.Option>
					<Select.Option value={2}>
						Text only: title (series) pubDate
					</Select.Option>
					<Select.Option value={3}>Info panel</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label="Show blurb" {...formItemLayout}>
				<Radio.Group
					name="showBlurb"
					value={showBlurb || '1'}
					onChange={xstateOnChange(send)}
				>
					<Radio.Button value="0">No</Radio.Button>
					<Radio.Button value="1">Short</Radio.Button>
					<Radio.Button value="2">Medium</Radio.Button>
					<Radio.Button value="3">Long</Radio.Button>
				</Radio.Group>
			</Form.Item>
			<Form.Item label="Books limit" extra="0 - no limit" {...formItemLayout}>
				<Input name={'bookLimit'} value={bookLimit} />
			</Form.Item>
			<Form.Item label="Books order" {...formItemLayout}>
				<Select
					name="bookOrder"
					value={bookOrder || 1}
					onChange={value => {
						send(FORM_ACTION.CHANGE, { bookOrder: value });
					}}
				>
					<Select.Option value={1}>
						By publication date (latest first)
					</Select.Option>
					<Select.Option value={2}>
						By publication date (oldest first)
					</Select.Option>
					<Select.Option value={3}>By series order</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item
				label="Filter"
				extra="Filters on title. Leave blank to show all books."
				{...formItemLayout}
			>
				<Input name={'filter'} value={filter} />
			</Form.Item>
			<Form.Item label="Series" {...formItemLayout} onChange={e =>e.stopPropagation()}>
				<Checkbox name="showSeriesStandalone" checked={showSeriesStandalone}>
					Standalone books only
				</Checkbox>
				{!showSeriesStandalone && (
					<BookSeriesSelect
						onChange={handleSeries}
						name="series"
						selected={seriesId}
					/>
				)}
			</Form.Item>
			<Form.Item {...formItemLayout} label="&nbsp;">
				<Button
					onClick={handleSave}
					isLoading={isSaving}
					disabled={isDisabled}
					type="primary"
				>
					Save
				</Button>
			</Form.Item>
			{isError && <FormAlert send={send} description={errorMessage} />}
		</Form>
	);
};

export default SitePageBlockBookForm;
