import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Col, Form, FormGroup, InputGroup, InputGroupAddon, 
	// InputGroupText, 
	Label} from 'reactstrap';
import {
	Button,
	colSizes,
	formHandleInputChange,
	Input,
	labelSizes,
	// offsetSizes, 
	offsetSizes2,
	// offsetSizesReverse
} from '@wpa/components/lib/Form';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import {getPrimaryPublisher} from '../../reducers/db/publishers';
import {errorStateReducer} from '../../reducers';
import {addFSM} from '../../functional/withStateMachine';



export const STATE = {
	DISPLAY: 'display',
	SAVING: 'saving',
	ERROR: 'error',
};

export const ACTION = {
	DISPLAY: 'display',
	CLOSE: 'close',
	SAVE: 'save',
	ERROR: 'error',
};

export const stateMachine = {
	initial: STATE.DISPLAY,
	states: {
		[STATE.DISPLAY]: {
			on: {
				[ACTION.SAVE]: {
					target: STATE.SAVING,
				}
			}
		},
		[STATE.SAVING]: {
			on: {
				[ACTION.CLOSE]: {
					target: STATE.DISPLAY,
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR,
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {}
		},
		[STATE.CLOSE]: {
			on: {}
		},
		[STATE.ERROR_LOADING]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {}
		}
	}
};

const stateReducers = [
	errorStateReducer.reducer,
];

export class ExpenseForm extends React.Component {
	static displayName = 'ExpenseForm';
	static propTypes = {
		authorId: PropTypes.number,
		invoicedAt: PropTypes.instanceOf(Date),
	};
	static defaultProps = {

	};

	constructor(props){
		super(props);

		this.state = {
			value: '',
			title: '',
			notes: '',
			invoicedAt: this.props.invoicedAt,
		};

		this.handleChange = formHandleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		addFSM(this, stateMachine, stateReducers);
	}

	onExpenseFormSave(){
		const self = this;

		this.props.dispatch(feathersServices[SERVICE.EXPENSES].create({
			publisherId: this.props.primaryPublisherId,
			authorId: this.props.authorId,
			currency: 'GBP',
			value: this.state.value,
			targetCurrency: this.props.baseCurrency,
			targetCurrencyValue: this.state.value,
			title: this.state.title,
			notes: this.state.notes,
			isRoyaltyPayment: true,
			invoicedAt: this.state.invoicedAt,
		}))
			.then(res => {
				self.transition(ACTION.CLOSE);
			})
			.catch(err => {
				self.transition(ACTION.ERROR);
			})
		;
	}

	handleSubmit(e){
		e.preventDefault();

		this.transition(ACTION.SAVE);
	}

	render(){
		const disabled = false;

		let saving = this.state.currentState === STATE.SAVING;

		return (
			<div>
				<Form className="grid-form" onChange={this.handleChange} onSubmit={this.handleSubmit}>
					<FormGroup row>
						<Label {...labelSizes} htmlFor="expenseValue" >Value</Label>
						<Col {...colSizes}>
							<InputGroup>
								<InputGroupAddon addonType="prepend">&pound;</InputGroupAddon>
								<Input name="value" id="expenseValue" defaultValue={''} value={this.state.value} />
							</InputGroup>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label {...labelSizes} htmlFor="expenseTitle" >Title</Label>
						<Col {...colSizes}>
							<Input name="title" id="expenseTitle" defaultValue={''} value={this.state.title} />
						</Col>
					</FormGroup>

					<FormGroup row>
						<Label {...labelSizes} htmlFor="expenseNotes" >Notes</Label>
						<Col {...colSizes}>
							<Input name="notes" id="expenseNotes" defaultValue={''} value={this.state.notes} />
						</Col>
					</FormGroup>
					{!disabled && <FormGroup row>
						<Col {...offsetSizes2}>
							<Button type="primary" htmlType="submit" loading={saving} disabled={saving}>Update</Button>
						</Col>
					</FormGroup>}
				</Form>
			</div>
		);
	}
}

export default connect(state => {
	const primaryPublisher = getPrimaryPublisher(state) || {};
	return {
		primaryPublisherId: primaryPublisher.id,
		baseCurrency: primaryPublisher.baseCurrency,
	}
})(ExpenseForm);