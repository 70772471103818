import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthorPageMenu from "./Menu";
import PageHeader from "../../components/PageHeader";
// import SecondaryMenu from "../../components/SecondaryMenu";
import {SpinnerSmallWithText} from "@wpa/components/lib/Spinner";
import ErrorBoundary from "@wpa/components/lib/ErrorBoundry";
// import {feathersServices, SERVICE} from "@wpa/feathers-client";
// import {addFSM} from "../../functional/withStateMachine";
// import {errorStateReducer} from "../../reducers";
import {getAuthorBySlug} from "../../reducers/db/authors";
// import BookMenu from '../book/Menu';
import { Layout } from 'antd';

const { Content, Sider } = Layout;

// export function onSiteReady(event){
// 	this.setState({
// 		siteId: event.siteId
// 	});
// };
//
// export const STATE = {
// 	LOADING: 'loading',
// 	LOADING_ERROR: 'loadingError',
// 	DISPLAY: 'displau'
// };
//
// export const ACTION = {
// 	DISPLAY: 'display',
// 	ERROR: 'error'
// };
//
// export const stateMachine = {
// 	initial: STATE.LOADING,
// 	states: {
// 		[STATE.LOADING]: {
// 			on: {
// 				[ACTION.ERROR]: {
// 					[STATE.LOADING_ERROR]: {}
// 				},
// 				[ACTION.DISPLAY]: {
// 					[STATE.LOADING_ERROR]: {
// 						actions: [
// 							'setAuthorId'
// 						]
// 					}
// 				}
// 			}
// 		},
// 		[STATE.LOADING_ERROR]: {
//
// 		},
// 		[STATE.DISPLAY]: {
//
// 		}
// 	}
// };
//
// const stateReducers = [
// 	errorStateReducer,
// 	(action, event) => {
// 		switch(action.type){
// 			case 'setAuthorId':
// 				return { authorId: event.authorId };
//
// 			//	No Change
// 			default:
// 				return {};
// 		}
// 	}
// ];


export class AuthorLayout extends Component {
	static displayName = 'AuthorLayout';
	static propTypes = {
		page: PropTypes.string.isRequired,
		// onSiteReady: PropTypes.func.isRequired
	};
	static defaultProps = {};

	constructor(props){
		super(props);

		this.state = {
		};

		// addFSM(this, stateMachine, stateReducers);
	}

/*
	componentDidMount(){
		const self = this;
		const props = self.props;

		self.transition(ACTION.DISPLAY, {siteId: props.siteId});
	}
*/

	render(){
		const props = this.props;
		const author = this.props.author;
		const pageId = props.pageName + 'Page';

		//	Site loading
		if(!author){
			return (
				<div id={pageId}>
					<SpinnerSmallWithText label={"Loading author details"} width={'250px'} center/>
				</div>
			);
		}

		//	Site ready
		return (
			<Layout>
				<Sider className="secondaryMenu" theme="light">
					<AuthorPageMenu />
				</Sider>
				<Layout>
					<Content id={pageId}>
						<PageHeader
							title={author.name}
							subtitle={"Author"}
						/>
						<ErrorBoundary>
							{props.children}
						</ErrorBoundary>
					</Content>
				</Layout>
			</Layout>
		);
	}
}

export default withRouter(connect((state, props) => ({
	author: getAuthorBySlug(state, props.match.params.slug),
	store: {
		db: {
			sites: state.db.sites
		}
	},
	// siteId: getSiteIdByDomain(state, props.match.params.slug)
}))(AuthorLayout));