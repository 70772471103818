import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
	xstateOnChange,
	FORM_ACTION,
	FORM_STATE,
	formStateMachine,
} from '@wpa/state-machine';
import { useMachine } from '@xstate/react';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { Form, FormAlert, Button } from '@wpa/components/lib/Form';
import { Card, Row, Col } from 'antd';
import Logger from '@wpa/logger';
import { SpinnerSmallWithText } from '@wpa/components/lib/Spinner';
import { RichTextArea } from '@wpa/components/lib/RichTextArea';

const m = formStateMachine({
	formLoading: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.BOOK_BLURB_CONTENTS].find({
						query: {
							bookBlurbId: context.blurbId,
							$limit: 1,
							$sort: {
								updated_at: 'DESC',
							},
						},
					})
				)
				.then((payload) => {
					const item =
						(payload &&
							payload.value &&
							payload.value.data &&
							payload.value.data[0]) ||
						{};

					context.fields.blurb = item.content || '';
					context.fields.isPublished = !!item.isPublished;

					resolve();
				})
				.catch(
					(err) =>
						Logger.error(err) ||
						reject('Unable to load blurb details.')
				);
		}),
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.BOOK_BLURB_CONTENTS].create(
						{
							bookBlurbId: context.blurbId,
							content: context.fields.blurb,
							isPublished: event.action === 'publish',
						},
						{}
					)
				)
				.then((payload) => {
					context.fields.isPublished = event.action === 'publish';
					context.defaults.blurb = context.fields.blurb;
					resolve();
				})
				.catch(
					(err) =>
						Logger.error(err) ||
						reject(
							'Unable to ' + (event.action || 'save') + ' blurb.'
						)
				);
		}),
});

export const BookBlurb = ({ blurb: bookBlurb, disabled = false, ...props }) => {
	const dispatch = useDispatch();
	const blurbId = bookBlurb.id;

	const [state, send] = useMachine(
		m.withFormContext(
			{
				dispatch: dispatch,
				blurbId: blurbId,
				action: '',
			},
			{
				blurb: bookBlurb.blurb || '',
				isPublished: false,
			}
		)
	);

	const { blurb, isPublished } = state.context.fields;
	const { action, errorMessage } = state.context;

	const isError = state.matches(FORM_STATE.ERROR);
	const isLoading = state.matches(FORM_STATE.LOADING);
	const isSaving = state.matches(FORM_STATE.SUBMITTING) && !action;
	const isPublishing =
		state.matches(FORM_STATE.SUBMITTING) && action === 'publish';
	const hasChanged = m.hasChanged(state);

	const handlePublish = useCallback(
		(e) => {
			e.preventDefault();

			send(FORM_ACTION.SUBMIT, {
				action: 'publish',
			});
		},
		[send]
	);

	// const charCount = blurb.replace(/<\/?[^>]+>/g, '').length;
	const trimmed = blurb.trim();
	const charCount = trimmed ? trimmed.length : 0;
	const wordCount = trimmed
		? (trimmed.replace(/['";:,.?¿\-!¡]+/g, '').match(/\S+/g) || []).length
		: 0;

	return (
		<Card
			title={
				<label htmlFor={'bookBlurb-' + bookBlurb.id}>
					{bookBlurb.label}
				</label>
			}
		>
			{isLoading && (
				<SpinnerSmallWithText label="Loading blurb content..." />
			)}
			{!isLoading && (
				<Form
					stateMachine={m}
					state={state}
					send={send}
					showError={false}
					buttonRow={false}
				>
					<Row gutter={16}>
						<Col flex="auto">
							<small>
								{wordCount} words / {charCount} chars
							</small>
						</Col>
						{!disabled && (
							<Col className="text-right">
								{hasChanged && (
									<Button
										type="primary"
										htmlType="submit"
										loading={isSaving}
										size="small"
									>
										Save
									</Button>
								)}
								{(!isPublished || hasChanged) && (
									<Button
										type="secondary"
										loading={isPublishing}
										size="small"
										onClick={handlePublish}
									>
										Publish
									</Button>
								)}
							</Col>
						)}
					</Row>
					{isError && (
						<FormAlert send={send} description={errorMessage} />
					)}
					<Form.Item wrapperCol={null}>
						<RichTextArea
							id={'bookBlurb_' + bookBlurb.id}
							initialValue={blurb}
							textareaName="blurb"
							onChange={xstateOnChange(send)}
							height="300px"
						/>
					</Form.Item>
				</Form>
			)}
		</Card>
	);
};
