import {Card, CardBody, CardTitle} from 'reactstrap';
import React from 'react';
import {connect} from 'react-redux';
import {salesConnect, salesDefaultProps, salesPropTypes} from './sales';

export const UnpaidSalesCard = ({unpaidSales, unpaidKenp, unpaidReturns, ...props}) => {
	const isUnpaid = unpaidSales > 0 || unpaidKenp > 0 || unpaidReturns > 0;

	return (
		<Card>
			<CardBody className={"text-center "+ (isUnpaid ? 'text-danger' : '')}>
				<div style={{fontSize:"1.5em" }}>
					<b>{unpaidSales}</b>
				</div>
				<CardTitle style={{fontSize:"0.95em" }}>Unpaid Sales</CardTitle>

				<hr />
				<div>
					<b>{unpaidKenp}</b><br/>
					<small>Unpaid KENP</small>
				</div>
				<div>
					<b>{unpaidReturns}</b><br/>
					<small>Unpaid Returns</small>
				</div>
			</CardBody>
		</Card>
	);
};

UnpaidSalesCard.propTypes = salesPropTypes;
UnpaidSalesCard.defaultProps = salesDefaultProps;

export default connect(salesConnect)(UnpaidSalesCard);