import { library } from '@fortawesome/fontawesome-svg-core';

import {
	faAddressBook,
	faAlignLeft,
	faBadgeCheck,
	faBooks,
	faBook,
	faBookDead,
	faBriefcase,
	faChartArea,
	faChartPie,
	faCheck,
	faCog,
	faEnvelope,
	faEnvelopeSquare,
	faEye,
	faExclamationTriangle,
	faFile,
	faHome,
	faInfo,
	faLineColumns,
	faLongArrowAltRight,
	faPoundSign,
	faSort,
	faSortDown,
	faSortUp,
	faTimes,
	faTimesSquare,
	faTrashAlt,
	faUser,
	faUserCircle,
	faWallet,
} from '@fortawesome/pro-solid-svg-icons';

import {
	faAddressBook as farAddressBook,
	faBook as farBook,
	faBriefcase as farBriefcase,
	faCheck as farCheck,
	faCheckSquare as farCheckSquare,
	faCogs as farCogs,
	faCog as farCog,
	faEdit as farEdit,
	faEye as farEye,
	faEnvelope as farEnvelope,
	faDesktop as farDesktop,
	faGlobe as farGlobe,
	faPlus as farPlus,
	faShareAlt as farShareAlt,
	faSort as farSort,
	faSquare as farSquare,
	faTrashAlt as farTrashAlt,
	faThumbtack as farThumbtack,
	faUser as farUser,
} from '@fortawesome/pro-regular-svg-icons';

import {
	faAmazon as fabAmazon,
	faDropbox as fabDropbox,
	faFacebook as fabFacebook,
	faGoodreads as fabGoodreads,
	faGoogleDrive as fabGoogleDrive,
	faInstagram as fabInstagram,
	faMicrosoft as fabMicrosoft,
	faPinterest as fabPinterest,
	faTwitter as fabTwitter,
} from '@fortawesome/free-brands-svg-icons';

library.add(
	faAddressBook,
	faAlignLeft,
	faBadgeCheck,
	faBooks,
	faBook,
	faBookDead,
	faBriefcase,
	faChartArea,
	faChartPie,
	faCheck,
	faCog,
	faEnvelope,
	faEnvelopeSquare,
	faEye,
	faExclamationTriangle,
	faFile,
	faHome,
	faInfo,
	faLineColumns,
	faLongArrowAltRight,
	faPoundSign,
	faSort,
	faSortDown,
	faSortUp,
	faTimes,
	faTimesSquare,
	faTrashAlt,
	faUser,
	faUserCircle,
	faWallet,
	farAddressBook,
	farBook,
	farBriefcase,
	farCheck,
	farCheckSquare,
	farCog,
	farCogs,
	farDesktop,
	farEdit,
	farEye,
	farEnvelope,
	farGlobe,
	farPlus,
	farShareAlt,
	farSort,
	farSquare,
	farThumbtack,
	farTrashAlt,
	farUser,

	//	Brands
	fabAmazon,
	fabDropbox,
	fabFacebook,
	fabGoodreads,
	fabGoogleDrive,
	fabInstagram,
	fabMicrosoft,
	fabPinterest,
	fabTwitter,
);