import React from 'react';
import {withRouter} from 'react-router-dom';
import Menu from '../../components/Menu';
import { CardImg } from 'reactstrap';
import {imagePath} from '../../utils/imagePath';
import { ButtonModal } from '@wpa/components/lib/Form';
import InviteForm, {RESOURCE_TYPE} from '../../components/InviteForm';
// import abilities from '../../abilities';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import { getBookById } from '../../reducers/db/books';
import { PropTypes } from 'prop-types';

const BookMenu = ({
	match: {
		params: { 
			slug, 
			id, 
		},
		path,
		url
	}
}) => {
	const bookId = parseInt(id, 10);

	//	Create basic stubs when no book is selected but we render the menu for some reason (?)
	const book = useSelector(state => getBookById(state, bookId));
	
	//	Without a book no point rendering anything else
	if( ! book){
		return <div>No book found</div>
	}

	// abilities.canShareBook();

	const canShare = book.User.canShare;
	const canViewSales = !! book.User.canViewSales;
	const canViewAssets = !! book.User.canViewAssets;
	const canViewReviews = !! book.User.canViewReviews;
	// const canEditRoyalties = !! book.User.canViewSales;

	return (
		<div>
			{book.Cover && book.Cover.Image &&
			<p><CardImg top width="100%" src={imagePath(encodeURI(book.Cover.Image.path))} alt={'Cover for ' + book.title}/></p>}

			{book.Status && <p
				style={{margin: '1rem 1rem'}}
				className={'text-center status-'+ book.Status.slug}
			>
				<Tag className={'status'} color>{book.Status.name}</Tag>
			</p>}
			{/*<p className={'status-'+ book.Status.slug +' text-center'}>*/}
				{/*<span className="label">*/}
					{/*{book.Status.name}*/}
				{/*</span>*/}
			{/*</p>*/}

			{book.logline &&
			<p style={{margin: '1rem 1rem'}}>
				<small>
					<u>Logline:</u><br />
					{book.logline}
				</small>
			</p>}


			<Menu
				currentPath={url}
				baseLink={'/books/'+ slug +'/'+ id}
				menu={[
					{
						label: 'Dashboard',
						pathSuffix: '',
					},
					{
						label: 'Details',
						pathSuffix: '/details',
					},
					canViewReviews && {
						label: 'Reviews',
						pathSuffix: '/reviews',
					},
					canViewAssets && {
						label: 'Assets',
						pathSuffix: '/assets',
					},
					canViewSales && {
						label: 'Sales',
						pathSuffix: '/sales',
					},
					canViewSales && {
						label: 'Publishers',
						pathSuffix: '/royalties',
					},
				].filter(n => n)}
			/>
			<p className="text-center">
				{canShare &&
				<ButtonModal
					isOpen={path === '/invite/book/:slug/:id'}
					title="Invite to Book"
					modal={<InviteForm
						type={RESOURCE_TYPE.BOOK}
						resourceId={bookId}
					/>}
				>Share</ButtonModal>}
			</p>
		</div>
	);
};

BookMenu.displayName = 'BookMenu';
BookMenu.propTypes = {
	//	A router match object
	match: PropTypes.object.isRequired,
};
BookMenu.defaultProps = {
};

export default withRouter(BookMenu);