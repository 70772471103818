export function imagePath(path) {
	return (
		'//' +
		(process.env.NODE_ENV === 'production'
			? 'api.writerpa.com'
			: 'localhost:3003') +
		'/static/' +
		path
	);
}
