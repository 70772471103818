import React from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import SiteLayout from './Layout';
import { withRouter } from 'react-router-dom';

const SiteDashboardPage = ({
	match: {
		params: { slug },
	},
}) => {
	return (
		<SiteLayout pageName={'siteDashboard'} title="Dashboard">
			<Row>
				<Col sm={8}>
					<Card>
						<CardHeader>
							<CardTitle>ToDo</CardTitle>
						</CardHeader>
						<CardBody>
							<ul>
								<li>
									list books (tabbed added / not added) -
									relevant to resource
								</li>
								<li>
									GA dashboard link (maybe a widget if
									available?)
								</li>
								<li>...</li>
							</ul>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</SiteLayout>
	);
};

export default withRouter(SiteDashboardPage);
