import React from 'react';
import Logger from '@wpa/logger';
import { formStateMachine } from '@wpa/state-machine';
import { useDispatch, useSelector } from 'react-redux';
import { useMachine } from '@xstate/react';
import { Form, Select } from '@wpa/components/lib/Form';
import {getAllSiteLayouts} from '../../reducers/db/siteLayouts'
import {feathersServices, SERVICE} from '@wpa/feathers-client'

export const STATE = {
	//  State constants
	LOADING: 'loading',
	DISPLAY: 'display',
	ERROR: 'error',
	SAVING: 'saving',
};

const m = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.SITES].patch(
						context.siteId,
						{
							layoutId: context.fields.layoutId,
						},
						{}
					)
				)
				.then((res) => {
					if (event.onSubmit) {
						event.onSubmit();
					}
					resolve();
				})
				.catch((err) => Logger.error(err) || reject('Unable to save review.'));
		}),
});

export const SiteThemeForm = ({ siteId, layoutId: initialLayoutId, ...props }) => {
	const dispatch = useDispatch();
	const layouts = useSelector(state => getAllSiteLayouts(state));

	const [state, send] = useMachine(
		m.withFormContext(
			{
				dispatch: dispatch,
				siteId: siteId,
			},
			{
				layoutId: initialLayoutId,
			}
		)
	);

	const { layoutId } = state.context.fields;

	return (
		<Form stateMachine={m} state={state} send={send} buttonRow={true}>
			<Form.Item label="Theme" htmlFor="layoutId">
				<Select
					name="layoutId"
					value={layoutId}
					send={send}
					options={layouts.map(l => ({ label: l.description, value: l.id}))}
				></Select>
			</Form.Item>
		</Form>
	);
};

export default SiteThemeForm;
