import React from 'react';
import PropTypes from 'prop-types';
import {
	// Row, 
	Col, 
	// Card, CardImg, CardTitle, CardBody, CardText, CardFooter,
	// Modal, ModalHeader, ModalBody,
	Form, FormGroup, Label, FormText
} from 'reactstrap';
import {connect} from 'react-redux';
import {Button, Input, colSizes, formHandleInputChange, labelSizes} from "@wpa/components/lib/Form";
import Notice from "@wpa/components/lib/Notice";
import {feathersServices, SERVICE} from "@wpa/feathers-client";
import {addFSM} from "../../functional/withStateMachine";
import {errorStateReducer, formErrorStateReducer} from "../../reducers";


export const STATE = {
	DISPLAY: 'display',
	SAVING: 'saving',
	ERROR: 'error',
};

export const ACTION = {
	SAVE: 'save',
	ERROR: 'error',
	CLOSE: 'close'
};

const stateMachine = {
	initial: STATE.DISPLAY,
	states: {
		[STATE.DISPLAY]: {
			on: {
				[ACTION.SAVE]: {
					target: STATE.SAVING,
				}
			}
		},

		[STATE.SAVING]: {
			on: {
				[ACTION.CLOSE]: {
					target: STATE.DISPLAY,
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR,
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [
				formErrorStateReducer.setMessages,
				errorStateReducer.setMessage,
			],
			onExit: [
				formErrorStateReducer.clearMessages,
				errorStateReducer.clearMessage,
			],
			on: {
				[ACTION.SAVE]: {
					target: STATE.SAVING,
				}
			}
		}
	}
};

export const stateReducers = [
	errorStateReducer.reducer,
	formErrorStateReducer.reducer
];

export class AuthorCreateForm extends React.Component {
	static displayName = 'AuthorCreateForm';
	static propTypes = {
		name: PropTypes.string,
	};
	static defaultProps = {
		name: '',
	};

	constructor(props){
		super(props);

		this.state = {
			name: props.name || '',
			isPublished: false
		};

		this.onChange = formHandleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		addFSM(this, stateMachine, stateReducers);
	}

	onAuthorCreateFormSave(){
		const self = this;
		this.props.dispatch(feathersServices[SERVICE.AUTHORS]
			.create({
				name: this.state.name,
				isPublished: this.state.isPublished
			}, {})
		)
			.then(res => {
				self.transition(ACTION.CLOSE);
			})
		;
	}

	handleSubmit(e){
		e.preventDefault();
		this.transition(ACTION.SAVE);
	}

	render(){
		let saving;
		let error, errorMessage = '';

		return (
			<Form onChange={this.onChange} onSubmit={this.handleSubmit}>
				<FormGroup row>
					<Label{...labelSizes}>Name<br /><small>or penname</small></Label>
					<Col {...colSizes}>
						<Input
							name={"name"}
							value={this.state.name}
							defaultValue={''}
						/>
					</Col>
				</FormGroup>
				<FormGroup
					style={{
						textAlign: 'center'
					}}
				>
					<Label>
						Published any books under this name?
						<Input
							name={"isPublished"}
							value={this.state.isPublished}
							defaultValue={''}
							type={"checkbox"}
							style={{
								marginLeft: '0.5rem'
							}}
						/>
					</Label>
					{this.state.isPublished &&
					<FormText>
						Published on Amazon?<br />
						Make sure you spell the name the same above and we can import your books.
					</FormText>}
				</FormGroup>
				<FormGroup style={{textAlign:'center'}}>
					<Col >
						<Button
							htmlType="submit"
							type="primary"
							loading={saving}
						>Save</Button>
					</Col>
				</FormGroup>
				{error &&
				<Notice color="danger">{errorMessage}</Notice>}
			</Form>
		);
	}
}

export default connect(state => ({
	name: state.db.authors.allIds.length === 0 ? state.currentUser.fullname : ''
}))(AuthorCreateForm);
