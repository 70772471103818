import { addItems, getItemIdsByParam, removeItems } from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'SITE_MENU_PAGES';
export const ADD_SITE_MENU_PAGES = 'ADD_' + serviceName;
export const UPDATE_SITE_MENU_PAGES = 'UPDATE_' + serviceName;
export const REMOVE_SITE_MENU_PAGES = 'REMOVE_' + serviceName;

/*
 * Helper functions
 */

// export function getSiteByDomain(domain, store){
// 	const siteId = getSiteIdByDomain(domain, store);
//
// 	return store.db.sites.byId[siteId];
// }
//
// export function getSiteIdByDomain(domain, store){
// 	const siteId = (store.db.sites.allIds || [])
// 		.filter(siteId => store.db.sites.byId[siteId].domain === domain)
// 		.pop();
//
// 	return siteId;
// }

export const addSiteMenuPages = items => ({
	type: ADD_SITE_MENU_PAGES,
	payload: items
});

export const removeSiteMenuPages = items => ({
	type: REMOVE_SITE_MENU_PAGES,
	payload: items
});

export const getMenuPageIdByPath = (state, path) => {
	const stateSlice = state.db.siteMenuPages;
	return stateSlice.allIds
		.filter(itemId => stateSlice.byId[itemId].path === '/' + path)
		.shift();
};
export const getMenuPageByPath = (state, path) => {
	const stateSlice = state.db.siteMenuPages;
	const pageId = stateSlice.allIds
		.filter(itemId => stateSlice.byId[itemId].path === '/' + path)
		.shift();

	if (!pageId) {
		return null;
	}

	return stateSlice.byId[pageId];
};
export const getAllSiteMenuPageIdsByMenuId = (state, menuId) => {
	const stateSlice = state.db.siteMenuPages;
	return getItemIdsByParam(stateSlice, 'menuId', menuId);
};
// export const getAllSiteMenuPagesBySite = (state, siteId) => {
// 	const stateSlice = state.db.sitePages;
// 	return getAllSiteMenuPageIds(state, siteId)
// 		.map(itemId => stateSlice.byId[itemId])
// 		//	Sort by main menu order, non-menu items to the bottom
// 		.sort((a, b) => {
// 			if(a.mainMenu === b.mainMenu) return 0;
// 			if(! a.mainMenu) return 1;
// 			if(! b.mainMenu) return -1;
// 			return a.mainMenu > b.mainMenu ? 1 : -1;
// 		})
// 		//	Sort non-menu items alphabetically
// 		.sort((a,b) => {
// 			if(a.mainMenu !== b.mainMenu) return 0;

// 			if(a.mainMenu === b.mainMenu && ! a.mainMenu){
// 				return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
// 			}
// 		})
// 	;
// };
export const getAllSiteMenuPagesByMenuId = (state, menuId) => {
	const stateSlice = state.db.siteMenuPages;

	return getAllSiteMenuPageIdsByMenuId(state, menuId)
		.map(itemId => stateSlice.byId[itemId])
		.sort((a, b) => {
			if (a.lft === b.lft) return 0;
			return a.lft > b.lft ? 1 : -1;
		});
	// //	Sort by main menu order, non-menu items to the bottom
	// .sort((a, b) => {
	// 	if(a.mainMenu === b.mainMenu) return 0;
	// 	if(! a.mainMenu) return 1;
	// 	if(! b.mainMenu) return -1;
	// 	return a.mainMenu > b.mainMenu ? 1 : -1;
	// })
	// //	Sort non-menu items alphabetically
	// .sort((a,b) => {
	// 	if(a.mainMenu !== b.mainMenu) return 0;

	// 	if(a.mainMenu === b.mainMenu && ! a.mainMenu){
	// 		return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
	// 	}
	// })
};
export const getSiteMenuPageById = (state, pageId) => {
	return state.db.siteMenuPages.byId[pageId];
};

/*
 *	Reducer
 */

export const reducer = createReducer({}, serviceName, {
	addActions: [],
	removeActions: [],
	actions: {
		// [ADD_SITE_MENU_PAGES+'#reorder']: (newState, action) => {
		// 	let payload = action.payload || [];
		// 	addItems(newState, payload.data);

		// 	return newState;
		// },
		[UPDATE_SITE_MENU_PAGES]: (newState, action) => {
			let payload = action.payload || [];

			//	As part of the 'patch' response we get the re-ordering details - treat those as an add
			if (payload.id && payload.id === 'reorder') {
				addItems(newState, payload.data.data);

				return newState;
			}

			addItems(newState, payload);

			return newState;
		},
		[REMOVE_SITE_MENU_PAGES]: (newState, action) => {
			let payload = action.payload || [];

			//	As part of the 'patch' response we get the re-ordering details - treat those as an add
			if (payload.id && payload.id === 'reorder') {
				removeItems(newState, payload.originalId);

				if (payload.data.data) {
					addItems(newState, payload.data.data);
				}

				return newState;
			}

			addItems(newState, payload);

			return newState;
		}
		// [REMOVE_SITE_MENU_PAGES+'#reorder']: (newState, action) => {
		// 	let payload = action.payload || [];

		// 	addItems(newState, payload.data);

		// 	return newState;
		// },
		// [feathersServices.getFindFulfilledAction(SERVICE.SITE_MENU_PAGES)+'#reorder']: (newState, action) => {
		// 	let payload = action.payload || [];

		// 	addItems(newState, payload.data);

		// 	return newState;
		// },
	}
});
