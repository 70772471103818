import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../components/PageHeader';
import ErrorBoundary from '@wpa/components/lib/ErrorBoundry';
import BookMenu from './Menu';
import { getAuthorsByBookId } from '../../reducers/db/authorBooks';
import { getBookById } from '../../reducers/db/books';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';

const { Content, Sider } = Layout;

const BookPageLayout = ({
	match: {
		params: { id },
	},
	pageName,
	subtitle,
	children,
}) => {
	const bookId = parseInt(id, 10);
	const book = useSelector((state) => getBookById(state, bookId));
	const authors =
		useSelector((state) => getAuthorsByBookId(state, bookId)) || [];

	const pageId = pageName + 'Page';
	const title = (book && book.title) || 'Draft Idea';
	const headerSubtitle =
		subtitle ||
		(authors && authors.map((author) => author.name).join(', '));

	return (
		<Layout>
			<Sider className="secondaryMenu" theme="light">
				<BookMenu />
			</Sider>
			<Layout>
				<Content id={pageId}>
					<PageHeader title={title} subtitle={headerSubtitle} />

					<ErrorBoundary>{children}</ErrorBoundary>
				</Content>
			</Layout>
		</Layout>
	);
};

BookPageLayout.propTypes = {
	// bookId: PropTypes.number.isRequired,
	//	Router match object
	match: PropTypes.object.isRequired,
	pageName: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
};

export default withRouter(BookPageLayout);
