
// import {store} from './dashboard.jsx';

export function userCan(ability, user =  null){
	let currentUser = user;

	if(!currentUser){
		console.error('No user specified when checking '+ ability);
	// 	currentUser = store.getState().currentUser;
	}

	return !!(currentUser && currentUser.permissions && currentUser.permissions[ability]);
}

// export function canWithBook(ability, bookId =  null){
// 	let currentUser;

// 	if(!currentUser){
// 		currentUser = store.getState().currentUser;
// 	}

// 	return !!(currentUser && currentUser.permissions && currentUser.permissions[ability]);
// }


/*
 *	Permission constants
 */

//	WPA Admin
export const USER_ACCESS_ADMIN = 'access-admin';
export const USER_ACCESS_ADMIN_USERS = 'access-admin-users';
export const USER_ACCESS_ADMIN_PERMISSIONS = 'access-admin-permissions';

//	Users
export const USER_INVITE_USERS = 'invite-users';
export const USER_EDIT_USERS = 'edit-users';

//	Books
export const USER_ACCESS_BOOKS = 'access-books';
export const USER_IMPORT_AMAZON_BOOKS = 'import-amazon-books';

export const USER_ACCESS_AUTHORS = 'access-authors';

export const USER_ACCESS_PUBLISHERS = 'access-publishers';

export const USER_ACCESS_SITES = 'access-sites';

export const USER_ACCESS_WORLDS = 'access-worlds';


export const can = {
	accessAdmin(user){
		return userCan(USER_ACCESS_ADMIN, user);
	},
	accessAdminUsers(user){
		return userCan(USER_ACCESS_ADMIN_USERS, user);
	},
	accessAdminPermissions(user){
		return userCan(USER_ACCESS_ADMIN_PERMISSIONS, user);
	},
	accessAuthors(user){
		return userCan(USER_ACCESS_AUTHORS, user);
	},
	accessBooks(user){
		return userCan(USER_ACCESS_BOOKS, user);
	},
	accessPublishers(user){
		return userCan(USER_ACCESS_PUBLISHERS, user);
	},
	accessSites(user){
		return userCan(USER_ACCESS_SITES, user);
	},
	accessWorlds(user){
		return userCan(USER_ACCESS_WORLDS, user);
	},
	inviteUsers(user){
		return userCan(USER_INVITE_USERS, user);
	},
	importAmazonBooks(user){
		return userCan(USER_IMPORT_AMAZON_BOOKS, user);
	},
};

export default can;
