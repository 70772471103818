import React from 'react';
import {
	// Container, 
	Form, FormGroup, Label, 
	// InputGroup, InputGroupAddon, InputGroupText, 
	FormText
} from 'reactstrap';
import {Card, Row, Col} from 'antd';

import PageHeader from '../components/PageHeader';
// import InviteUserButton from '../components/users/Invite';
import {Button, formHandleInputChange, Input} from "@wpa/components/lib/Form";
import {connect} from 'react-redux';
import {addFSM} from "../functional/withStateMachine";
import {feathersServices, SERVICE} from "@wpa/feathers-client";
import {errorStateReducer, formErrorStateReducer} from "../reducers";
import Notice from "@wpa/components/lib/Notice";
import {Layout, Input as Input2} from 'antd';
const {Content} = Layout;

const STATE = {
	DISPLAY: 'display',
	UPDATING: 'updating',
	ERROR: 'error',
	CHANGING: 'changing',
};

const ACTION = {
	UPDATE: 'update',
	DISPLAY: 'display',
	ERROR: 'error',
	CHANGEPASS: 'changepass'
};

const stateMachine = {
	initial: STATE.DISPLAY,
	states: {
		[STATE.DISPLAY]: {
			on: {
				[ACTION.UPDATE]: {
					target: STATE.UPDATING
				},
				[ACTION.CHANGEPASS]: {
					target: STATE.CHANGING
				},
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
				}
			}
		},
		[STATE.UPDATING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR,
				}
			}
		},
		[STATE.CHANGING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR,
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [
				formErrorStateReducer.setMessages,
				errorStateReducer.setMessage,
			],
			onExit: [
				formErrorStateReducer.clearMessages,
				errorStateReducer.clearMessage,
			],
			on: {
				[ACTION.UPDATE]: {
					target: STATE.UPDATING,
				}
			}
		}
	}
};

export const stateReducers = [
	errorStateReducer.reducer,
	formErrorStateReducer.reducer
];


const AccountDetailsForm  = class extends React.Component {
	static displayName = 'AccountDetailsForm';

	constructor(props){
		super(props);

		this.state = {
			name: props.user.name,
			fullname: props.user.fullname,
			email: props.user.email,
			passwordChangeSent: false,
			password: '',
			invalid: {},
			errorMessage: '',
		};

		this.handleInputChange = formHandleInputChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		addFSM(this, stateMachine, stateReducers);
	}

	onAccountDetailsFormChangepass(){
		const location = window.location;
		const self = this;
		self.props.dispatch(feathersServices[SERVICE.AUTHMANAGEMENT]
			.create({
				action: 'sendResetPwd',
				value: {
					email: self.state.email
				},
				notifierOptions: {
					host: location.hostname,
					url: 'http://' + location.host + '/login/password/reset/:token'
				}
			})
		)
			.then(() => {
				self.setState({
					passwordChangeSent: true
				}, self.transition(ACTION.DISPLAY));
				//	@todo: check for errors first
			})
		;
	}

	handlePasswordChange(){
		this.transition(ACTION.CHANGEPASS);
	}

	onAccountDetailsFormUpdate(){
		const self = this;


		let error = false;
		const invalid = {};

		if(self.state.name.length < 2){
			error = true;
			invalid.name = 'Name needs to be at least 2 characters long.';
		}

		if(self.state.fullname.length < 2){
			error = true;
			invalid.fullname = 'Name needs to be at least 2 characters long.';
		}

		if(error){
			return self.transition(ACTION.ERROR, {
				invalid
			});
		}

		if(
			self.state.name !== self.props.user.name ||
			self.state.fullname !== self.props.user.fullname
		){
			return self.props.dispatch(
				feathersServices[SERVICE.USERS]
					.patch(null, {
						name: self.state.name,
						fullname: self.state.fullname
					},{})
			)
				.then(res => {
					self.transition(ACTION.DISPLAY);
					console.log('RES', res);
				})
				.catch(err => {
					self.transition(ACTION.ERROR, {
						message: 'Could not update account details'
					});
					console.log('ERR', err);
				});
		}

		/*
		 * 	@todo: handle email change
		self.props.dispatch(
			feathersServices[SERVICE.AUTHMANAGEMENT]
				.create({
					action: 'identityChange',
					value: {
						user: {email: this.props.user.email},
						password: this.state.password,
						changes: {
							// name: this.state.name,
							// fullName: this.state.fullname,
							email: this.state.email
						}
					},
					notifierOptions: {
						host: location.host,
						url: 'http://' + location.host + '/account/verifyChanges/:token'
					}
				})
		);
		*/

		self.transition(ACTION.DISPLAY);
	}

	onSubmit(e){
		e.preventDefault();

		this.transition(ACTION.UPDATE);
	}

	render(){
		const props = this.props;

		const user = props.user;
		const hasChanges = (
			// this.state.name !== props.user.name ||
			// this.state.fullname !== props.user.fullname ||
			this.state.email !== props.user.email
		);

		return (
			<Form
				id="accountDetailsForm"
				onSubmit={this.onSubmit}
				onChange={this.handleInputChange}
			>
				<FormGroup row>
					<Label md={4} lg={3} for="accountDetailsForm-name">Name</Label>
					<Col md={8} lg={9}>
						<Input
							type="text" name="name" id="accountDetailsForm-name"
							value={this.state.name}
							defaultValue={user.name}
							invalid={this.state.invalid.name}
							required
						/>
						<FormText color="muted">
							How do you like to be referred to?
						</FormText>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label md={4} lg={3}>Full Name</Label>
					<Col md={8} lg={9}>
						<Input
							type="text" name="fullname" id="accountDetailsForm-fullname"
							value={this.state.fullname}
							defaultValue={user.fullname}
							invalid={this.state.invalid.fullname}
							required
						/>
						<FormText color="muted">
							Your full name as it appears in official documents.
						</FormText>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label md={4} lg={3}>Email <i className="fas fa-fw fa-badge-check"></i></Label>
					<Col md={8} lg={9}>
						<Input
							type="text" name="email" id="accountDetailsForm-email"
							value={this.state.email}
							defaultValue={user.email}
							disabled
						/>
					</Col>
				</FormGroup>
				{hasChanges &&
				<FormGroup row>
					<Label md={4} lg={3} for="accountDetailsForm-password">Password</Label>
					<Col md={8} lg={9}>
						<Input
							type="text" name="password" id="accountDetailsForm-password"
							value={this.state.password}
							defaultValue=""
						/>
						<small className="text-muted">Please enter your current password to save any changes</small>
					</Col>
				</FormGroup>}
				<FormGroup row>
					<Label md={4} lg={3}>Status</Label>
					<Col md={8} lg={9} className="form-control-plaintext">
						{user.userStatus.label}
					</Col>
				</FormGroup>
				<FormGroup>
					<Button
						type="primary"
						htmlType="submit"
						loading={this.state.currentState === STATE.UPDATING}
						disabled={this.state.currentState === STATE.UPDATING}
					>
						Update
					</Button>
					&nbsp;
					<Button
						type="secondary"
						onClick={this.handlePasswordChange}
						loading={this.state.currentState === STATE.CHANGING}
						disabled={this.state.currentState === STATE.CHANGING}
					>
						Change Password
					</Button>
				</FormGroup>
				{
					this.state.currentState === STATE.ERROR &&
					this.state.errorMessage !== '' &&
				<Notice color="danger">{this.state.errorMessage}</Notice>}
				{this.state.passwordChangeSent &&
				<Notice color="success">Password change email sent. :)</Notice>
				}
			</Form>
		);
	}
};


export const page = (props) =>{
	let token = document.cookie.replace(/(?:(?:^|.*;\s*)wpa-api\s*=\s*([^;]*).*$)|^.*$/, '$1');

	return (
		<Content id="sitesPage">
			<PageHeader title="Account">

			</PageHeader>
			<Row gutter={16}>
				<Col sm={12}>
					<Card title={<h3>Account Details</h3>}>
						<AccountDetailsForm user={props.currentUser} dispatch={props.dispatch} />
					</Card>

					{/*<Card>*/}
						{/*<CardBody>*/}
							{/*<CardTitle>*/}
								{/*Billing*/}
							{/*</CardTitle>*/}
							{/*<Row>*/}
								{/*<Col>*/}
									{/*BILLING DETAILS (@todo)*/}
								{/*</Col>*/}
							{/*</Row>*/}
						{/*</CardBody>*/}
					{/*</Card>*/}
				</Col>
				{/*<Col sm={6}>*/}
					{/*<Card>*/}
						{/*<CardBody>*/}
							{/*<CardTitle>*/}
								{/*<InviteUserButton style={{float:"right"}}/>*/}
								{/*Invitations*/}
							{/*</CardTitle>*/}
							{/*<Row style={{clear:"right"}}>*/}
								{/*<Col>*/}
									{/*<p>Invited by: <b>Joanna</b></p>*/}
								{/*</Col>*/}
							{/*</Row>*/}
							{/*<Row>*/}
								{/*<Col>*/}
									{/*LIST OF INVITED USERS (@todo)*/}
								{/*</Col>*/}
							{/*</Row>*/}
						{/*</CardBody>*/}
					{/*</Card>*/}
				{/*</Col>*/}
				<Col sm={12}>
					<Card title={<h3>Access</h3>}>
						<p style={{overflowWrap: 'anywhere'}}>
							<b>Current Token:</b><br />
							{token}
						</p>
						<Input2.TextArea width="100%" name="newToken" id="newToken"
							placeholder="Enter your new token here..."
						></Input2.TextArea>
						<Button type="primary" block onClick={() => {
							let token = document.getElementById('newToken').value;
							document.cookie = 'wpa-api='+ token +'; Path=/; domain=.'+ window.location.hostname +';';
							localStorage.setItem('feathers-jwt', token);
							
							window.location.reload(true);
						}}>Switch tokens</Button>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};


export default connect(state => ({
	currentUser: state.currentUser
}))(page);