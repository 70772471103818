import React, { useCallback } from 'react';
import CardTextarea from '../shared/CardTextarea';
import { SERVICE, feathersServices } from '@wpa/feathers-client';

export const CustomSiteCssCard = ({ siteId, siteCustomCSS }) => {
	const onSubmit = useCallback(
		(content, dispatch) =>
			dispatch(
				feathersServices[SERVICE.SITES].patch(siteId, {
					customCSS: content,
				})
			),
		[siteId]
	);

	return (
		<CardTextarea
			title={'Custom CSS'}
			content={siteCustomCSS}
			id={'customSiteCSS'}
			onSubmit={onSubmit}
		>
			<div>
				<small>
					To customise the colors you can use a{' '}
					<a
						href="https://bootstrap.build/app"
						target="_blank"
						rel="noopener noreferrer"
					>
						Bootstrap Build app
					</a>
					. Paste the generated bootstrap.min.css above.
				</small>
			</div>
		</CardTextarea>
	);
};

export default CustomSiteCssCard;
