import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { addItems } from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const reducerName = 'EXPENSES';
const serviceName = 'EXPENSES';

export const ADD_EXPENSES = 'ADD_'+ serviceName;
export const UPDATE_EXPENSES = 'UPDATE_'+ serviceName;
export const REMOVE_EXPENSES = 'REMOVE_'+ serviceName;

export const GET_TOTALS = reducerName + '_GET_TOTALS';

/*
 * Helper functions
 */

export const addExpenses = items => ({
	type: ADD_EXPENSES,
	payload: items
});

export const dispatchGetExpensesTotals = () =>
	feathersServices[SERVICE.EXPENSES].get('totals');

export function getExpensesTotalByType(state, totalType) {
	const stateSlice = state.db.expenses;

	const expenses = (stateSlice.totals || []).filter(
		expense => expense.type === totalType
	);
	return expenses;
}

export function getExpensesTotalByAuthorId(state, id, totalType) {
	const stateSlice = state.db.expenses;

	const expenses = (stateSlice.totals || []).filter(
		expense => expense.type === totalType && expense.authorId === id
	);
	return expenses;
}

/**
 * Expenses reducer
 * @param state
 * @param action
 * @returns {*} new state
 */
export const expensesReducer = (
	state = {
		initialState: true,
		initialTotalsState: true,
		byId: {},
		allIds: [],
		totals: [],
		totalsDate: null
	},
	action
) => {
	let newState, payload;

	switch (action.type) {
		case feathersServices.getGetFulfilledAction(SERVICE.EXPENSES):
			newState = { ...state };
			newState.initialTotalsState = false;

			payload = action.payload || {};

			if (payload.id === 'totals') {
				newState.totals = [...(payload.data || [])];
				// newState.publisherTotals = publisherTotals;

				//	Need this for comparisons to refresh correctly :/
				newState.totalsDate = new Date();
			}

			return newState;

		case feathersServices.getFindFulfilledAction(SERVICE.EXPENSES):
			payload = action.payload.data || [];

			//	Don't process partial records
			if (payload && payload[0] && !payload[0].id) {
				return state;
			}

		//	Fallthrough as the action is the same
		case ADD_EXPENSES:
			newState = { ...state };
			newState.initialState = false;

			payload = payload || action.payload;

			addItems(newState, payload);

			return newState;

		default:
			return state;
	}
};

/*
 *	Reducer
 */
export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {
			[feathersServices.getGetFulfilledAction(SERVICE.EXPENSES)+'#totals']: (newState, action) => {
				let payload = action.payload || {};

				newState.totals = [...(payload.data || [])];

				//	Need this for comparisons to refresh correctly :/
				newState.totalsDate = new Date();

				return newState;
			}
		}
	}
);