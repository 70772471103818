//	eslint-disable-next-line
import React from 'react';
import { useSelector} from 'react-redux';
import {
	Row, Col, Card
} from 'antd';

import AuthorLayout from './Layout';
import AuthorDetailsForm from '../../components/authors/DetailsForm';
import SocialMedia from '../../components/shared/SocialMedia';
import { getAllSocialMediaTypes } from '../../reducers/db/socialMediaTypes';
import { getAuthorSocialMediaMap } from '../../reducers/db/socialMedias';
import AuthorBio from '../../components/author/AuthorBio';
import { AuthorImageCard } from '../../components/ImageCard';
import {withRouter} from 'react-router-dom';

export const AuthorDetailsPage = ({
	match: {
		params: {
			id
		}
	},
}) => {
	const authorId = parseInt(id, 10);
	const socialMediaTypes = useSelector(state => getAllSocialMediaTypes(state));
	const socialMedia = useSelector(state => getAuthorSocialMediaMap(state, authorId));

	return (
		<AuthorLayout page={'authorDetailsPage'}>
			<Row gutter={16}>
				<Col sm={6}>
					<AuthorImageCard 
						authorId={authorId} 
						title="Profile Picture"
					></AuthorImageCard>
				</Col>
				<Col sm={18}>
					<Card title={<h3>Author Contact Details</h3>}>
						<AuthorDetailsForm authorId={authorId} />
					</Card>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col sm={12}>
					<AuthorBio authorId={authorId}></AuthorBio>
				</Col>
				<Col sm={12}>
					<Card title={<h3>Social Media</h3>}>
						{socialMediaTypes.map(smt => {
							let icon = ['fab', smt.slug];
							if(smt.slug === 'contact-email'){
								icon = ['fas', 'envelope'];
							}

							return (<SocialMedia 
								key={smt.id} 
								typeId={smt.id}
								label={smt.label} 
								icon={icon}
								resourceId={authorId}
								value={socialMedia[smt.id] && socialMedia[smt.id].url}
								socialMediaId={socialMedia[smt.id] && socialMedia[smt.id].id}
								//	Author
								resourceTypeId={1}
							/>);
						})}
					</Card>
				</Col>
			</Row>
		</AuthorLayout>
	);

};

export default withRouter(AuthorDetailsPage);
