//	eslint-disable-next-line
import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import {getAllAuthors} from '../../reducers/db/authors';
import Logger from '@wpa/logger';
import {Select} from 'antd';
import {difference} from 'lodash';

// export const STATE = {
// 	LOADING: 'loading',
// 	DISPLAY: 'display',
// 	CONFIRMATION: 'confirmation',
// 	ERROR: 'error',
// 	ERROR_LOADING: 'errorLoading',
// };

// export const ACTION = {
// 	DISPLAY: 'display',
// 	ADD: 'add',
// 	CONFIRM: 'confirm',
// 	REMOVE: 'remove',
// 	ERROR: 'error',
// };

// export const stateMachine = {
// 	initial: STATE.DISPLAY,
// 	states: {
// 		[STATE.DISPLAY]: {
// 			on: {
// 				[ACTION.CONFIRM]: {
// 					target: STATE.CONFIRMATION,
// 					actions: ['setNewAuthorName'],
// 				},
// 				[ACTION.REMOVE]: {
// 					target: STATE.DISPLAY,
// 					actions: ['setAuthors', 'clearNewAuthorName'],
// 				},
// 				[ACTION.ADD]: {
// 					target: STATE.DISPLAY,
// 					actions: ['setAuthors'],
// 				},
// 			}
// 		},
// 		[STATE.CONFIRMATION]: {
// 			onEntry: ['showNewAuthorConfirmOn'],
// 			onExit: ['showNewAuthorConfirmOff'],
// 			on: {
// 				[ACTION.ADD]: {
// 					target: STATE.DISPLAY,
// 					actions: ['setAuthors'],
// 				},
// 				[ACTION.REMOVE]: {
// 					target: STATE.DISPLAY,
// 					actions: ['setAuthors', 'clearNewAuthorName'],
// 				},
// 				[ACTION.ERROR]: {
// 					target: STATE.ERROR,
// 				}
// 			}
// 		},
// 		[STATE.ERROR]: {
// 			onEntry: [errorStateReducer.setMessage],
// 			onExit: [errorStateReducer.clearMessage],
// 			on: {
// 				[ACTION.CONFIRM]: {
// 					target: STATE.CONFIRMATION,
// 					actions: ['setNewAuthorName'],
// 				},
// 			}
// 		},
// 	}
// };

// const stateReducers = [
// 	errorStateReducer.reducer,

// 	//	Custom reducer
// 	(action, event) => {
// 		switch(action.type){
// 			case 'setNewAuthorName':
// 				return { newAuthorName: event.newAuthorName || '' };
// 			case 'clearNewAuthorName':
// 				return { newAuthorName: '' };

// 			case 'showNewAuthorConfirmOn':
// 				return { showNewAuthorConfirm: true };
// 			case 'showNewAuthorConfirmOff':
// 				return { showNewAuthorConfirm: false };
// 			case 'setAuthors':
// 				return { authors: event.authors || [] };

// 			//	No Change
// 			default:
// 				return {};
// 		}
// 	}
// ];

// class AuthorSelect extends Component {
// 	static displayName = 'AuthorSelect';
// 	static propTypes = {
// 		onAdd: PropTypes.func,
// 		onDelete: PropTypes.func,

// 	};
// 	static defaultProps = {};

// 	constructor(props){
// 		super(props);

// 		this.state = {
// 			showNewAuthorConfirm: false,
// 			newAuthorName: '',
// 			authors: props.authors || []
// 		};

// 		this.onNewAuthorCreate = this.onNewAuthorCreate.bind(this);
// 		this.onNewAuthorCancel = this.onNewAuthorCancel.bind(this);
// 		this.onAuthorChange = this.onAuthorChange.bind(this);

// 		addFSM(this, stateMachine, stateReducers);
// 	}

// 	onAuthorChange(items, item){
// 		//	New author added
// 		if(
// 			items.length > this.state.authors.length
// 			&& ! item.id
// 		){
// 			this.transition(ACTION.CONFIRM, {
// 				newAuthorName: item.name
// 			});

// 		//	Existing author added
// 		} else {
// 			this.transition(ACTION.ADD, {
// 				authors: items
// 			});
// 		}
// 	}

// 	onNewAuthorCreate(){
// 		const self = this;
// 		const newAuthor = this.state.newAuthorName;
// 		this.props.dispatch(
// 			feathersServices[SERVICE.AUTHORS]
// 				.create({
// 					name: newAuthor,
// 					//	@todo: check book status for the isPublished value below
// 					isPublished: false
// 				}, {})
// 		)
// 			.then(payload => {
// 				const item = payload.value[0];
// 				const authors = self.state.authors || [];
// 				authors.push({id:item.id, name: newAuthor});

// 				self.transition(ACTION.ADD, {
// 					authors: authors,
// 				});
// 			})
// 			.catch(err => self.transition(ACTION.ERROR, { message: err, error: err }))
// 		;
// 	}

// 	onNewAuthorCancel(){
// 		this.transition(ACTION.REMOVE, {
// 			authors: this.state.authors.filter(item => item.name !== this.state.newAuthorName)
// 		});
// 	}

// 	render(){
// 		const {
// 			authorList,
// 			...attr
// 		} = this.props;

// 		const {
// 			showNewAuthorConfirm,
// 			newAuthorName,
// 			authors,
// 		} = this.state;

// 		const hasAuthorChanged = false;

// 		return (
// 			<div>
// 				<TagInput
// 					className={hasAuthorChanged ? "is-valid" : ""}
// 					tags={authors}
// 					onAdd={this.onAuthorChange}
// 					onDelete={this.onAuthorChange}
// 					suggestions={authorList}
// 					placeholder="Add author"
// 					{...attr}
// 				/>
// 				<ConfirmDialogue
// 					isOpen={showNewAuthorConfirm}
// 					title="Create a new author?"
// 					body={"New author name: "+ newAuthorName}
// 					onYes={this.onNewAuthorCreate}
// 					onNo={this.onNewAuthorCancel}
// 				/>
// 			</div>
// 		);
// 	}
// }

// export default connect(state => ({
// 	authorList: getAllAuthors(state).map(item => ({
// 		id: item.id,
// 		name: item.name,
// 	})),
// }))(AuthorSelect);

//	Need this as the Select does not give an event you can do a stop on...
const stopOnChangeBubbling = (e) => {
	e.stopPropagation();
};

export const AuthorSelect = ({allowNew = false, selected, onChange, single = false}) => {
	const dispatch = useDispatch();
	const authorList = useSelector(state => getAllAuthors(state));

	const children = [];
	authorList.forEach(option => {
		// @todo: can't filter at the moment as then we end up with values (id) displayed in the tag list
		// if(selected.indexOf(option.value+'') === -1){
		children.push(
			<Select.Option value={option.id+''} key={option.id}>{option.name}</Select.Option>
		);
		// }
	});

	const handleChange = useCallback((values) => {
		const diff = difference(values, selected);

		//	Item removed
		if(! diff || ! diff[0]){
			onChange(values);
			return;
		}

		//	We've added an item, check if it's a new tag
		const record = authorList.filter(s => s.id === parseInt(diff[0],10));

		//	Tag exists, just update components
		if(record.length > 0){
			onChange(values);
			return;
		}
		
		const position = values.indexOf(diff[0]);
			
		dispatch(
			feathersServices[SERVICE.AUTHORS]
				.create({
					name: diff[0],
					isPublished: false,
				}, {})
		)
			.then(payload => {
				const id = payload.value.id || payload.value[0].id;
				values.splice(position, 1, id+'');
				onChange(values);
			})
			.catch(err => {
				//	Remove offending item before proceeding
				values.splice(position, 1);
				Logger.error(err);
			});
		
	}, [dispatch, selected, onChange, authorList]);

	const mode = single ? 'default' : allowNew ? 'tags' : 'multiple';
	const placeholder = single ? 'Select author' : 'Select author(s)';

	return (
		<div onChange={stopOnChangeBubbling}>
			<Select
				mode={mode}
				placeholder={placeholder}
				filterOption={true}
				optionFilterProp="children"
				onChange={handleChange}
				value={selected || []}
				style={{width:'100%'}}
			>
				{children}
			</Select>
		</div>
	);
};

export default AuthorSelect;