import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSiteIdByDomain } from '../../reducers/db/sites';
import SiteMenu from './Menu';
import PageHeader from '../../components/PageHeader';
import { SpinnerSmallWithText } from '@wpa/components/lib/Spinner';
import ErrorBoundary from '@wpa/components/lib/ErrorBoundry';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { getPageByPath , getAllSitePageIds} from '../../reducers/db/sitePages';
import { loadingStateMachine, LOADING_STATE } from '@wpa/state-machine';
import { Layout } from 'antd';
import { useMachine } from '@xstate/react';

const { 
	Content, 
	Sider 
} = Layout;

const stateMachine = loadingStateMachine({
	loadingAction: (context, event) => {
		//	Only load pages when none are present
		//	We always should have at least home when fetched, so there shouldn't be a risk of pointless reloading
		//	even for the most barebones site
		if(context.sitePagesCount.length > 0){
			return new Promise(resolve => resolve());
		}

		console.log('FETCH DATA FOR SITE PAGES');

		return context.dispatch(
			feathersServices[SERVICE.SITE_PAGES].find({
				query: {
					siteId: context.siteId
				}
			})
		);
	}
})

export const SiteLayout = ({pageName, match, children, title}) => {
	const dispatch = useDispatch();

	const {
		params: { slug, page }
	} = match;

	const siteId = useSelector(state => getSiteIdByDomain(state, slug));
	const sitePagesCount = useSelector(state => getAllSitePageIds(state, siteId));
	const currentPage = useSelector(state => getPageByPath(state, page === 'home' ? '' : page) || {});

	const [state] = useMachine(stateMachine.withContext({
		dispatch,
		sitePagesCount,
		siteId
	}));

	let pageTitle = currentPage.title || title;
	let subtitle = slug;

	if (page) {
		subtitle += '/'+page.replace(/[_-]/gi, ' ');
	}

	const pageId = pageName + 'Page';

	const isLoading = state.matches(LOADING_STATE.LOADING);

	return (
		<Layout>
			<Sider className="secondaryMenu" theme="light">
				{! isLoading && <SiteMenu />}
			</Sider>
			<Layout>
				<Content id={pageId}>
					<PageHeader title={pageTitle} subtitle={subtitle} />
					{isLoading && <SpinnerSmallWithText
						label={'Loading site details'}
						width={'250px'}
						center
					/>}
					{! isLoading && <ErrorBoundary>{children}</ErrorBoundary>}
				</Content>
			</Layout>
		</Layout>
	);
};

SiteLayout.propTypes = {
	pageName: PropTypes.string.isRequired,
	// page: PropTypes.object.isRequired
};

export default withRouter(SiteLayout);
