import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TagListItem extends React.Component {
	static displayName = 'TagListItem';
	static propTypes = {
		name: PropTypes.string,
		tagClass: PropTypes.string,
		btnClass: PropTypes.string,
		active: PropTypes.bool,
		disabled: PropTypes.bool,
		iconOn: PropTypes.array,
		iconOff: PropTypes.array
	};
	static defaultProps = {
		name: '',
		tagClass: 'tag',
		btnClass: null,
		active: false,
		disabled: false,
		iconOn: ['far', 'check-square'],
		iconOff: ['far', 'square']
	};

	// constructor(props) {
	// 	super(props);
	// }

	render() {
		const {
			name,
			tagClass,
			btnClass,
			active,
			disabled,
			iconOn,
			iconOff
		} = this.props;

		const classes = ['btn', 'btn-sm'];

		//	Do to some toggle-retoggle issues with FontAwesome it seems
		//	rendering both and then showing and hiding is the only workaround for now
		const icon1 = iconOn ? (
			<FontAwesomeIcon icon={iconOn} size={'sm'} pull={'left'} />
		) : null;
		const icon2 = iconOff ? (
			<FontAwesomeIcon icon={iconOff} size={'sm'} pull={'left'} />
		) : null;

		if (!active) {
			classes.push('btn-inactive');
		}

		if (btnClass) {
			classes.push(btnClass);
		}

		return (
			<li className={'status-' + tagClass}>
				<button
					className={classes.join(' ')}
					onClick={this.props.toggleStatus}
					disabled={disabled}
				>
					{active && icon1}
					{!active && icon2}
					&nbsp;<span>{name}</span>
				</button>
			</li>
		);
	}
}

export class TagList extends React.Component {
	static displayName = 'TagList';
	static propTypes = {
		title: PropTypes.string,
		className: PropTypes.string,
		onToggle: PropTypes.func,
		listState: PropTypes.object,
		tags: PropTypes.array
	};
	static defaultProps = {
		title: '',
		className: null,
		onToggle: null,
		listState: {},
		tags: []
	};

	constructor(props) {
		super(props);

		this.toggleStatus = this.toggleStatus.bind(this);
	}

	toggleStatus(id, e) {
		if (this.props.onToggle) {
			this.props.onToggle(id);
		}
	}

	render() {
		const {
			title,
			//	eslint-disable-next-line
			onToggle,
			className,
			listState: active,
			tags,
			...props
		} = this.props;

		const statusItems = tags.map(tag => {
			return (
				<TagListItem
					tagClass={tag.tagClass}
					name={tag.name}
					active={active[tag.id]}
					key={tag.id}
					toggleStatus={this.toggleStatus.bind(null, tag.id)}
					{...props}
				/>
			);
		});

		const listClassName = ['tagList'];
		if (className) {
			listClassName.push(className);
		}

		return (
			<div>
				<h3>{title}</h3>
				<ul className={listClassName.join(' ')}>{statusItems}</ul>
			</div>
		);
	}
}

export default TagList;
