import {getItemIdByParam} from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */
const serviceName = 'BOOKS';
export const ADD_BOOKS = 'ADD_'+ serviceName;
export const UPDATE_BOOKS = 'UPDATE_'+ serviceName;
export const REMOVE_BOOKS = 'REMOVE_'+ serviceName;

export const UPDATE_COVER = 'UPDATE_COVER';

/*
 * Helper functions
 */

export function getBookBySlug(state, slug){
	const stateSlice = state.db.books;
	const bookId = getItemIdByParam(stateSlice, 'slug', slug);

	return stateSlice.byId[bookId];
}

export function getBookById(state, id){
	const stateSlice = state.db.books;
	return stateSlice.byId[id];
}

export function getAllBooks(state){
	const stateSlice = state.db.books;
	return stateSlice.allIds.map(id => stateSlice.byId[id]);
}

export function getAllBooksMap(state){
	const stateSlice = state.db.books;
	return stateSlice.byId;
}

export const addBooks = (books) => ({
	type: ADD_BOOKS,
	payload: books
});
export const updateBooks = (books) => ({
	type: UPDATE_BOOKS,
	payload: books
});
export const updateCover = (cover) => ({
	type: UPDATE_COVER,
	payload: cover
});


/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		alterPayload: (item) => {
			item.link = '/books/'+ item.slug +'/'+ item.id;
		},
		addActions: [],
		removeActions: [],
		actions: {
			[UPDATE_COVER]: (newState, action) => {
				//	@todo: deal with the fact that events come out of order for imported books...
				if( ! newState.byId[action.payload.bookId]){
					return false;
				}

				newState.byId[action.payload.bookId].Cover = action.payload && action.payload.isPrimary ? action.payload : null;

				newState.byId[action.payload.bookId].lastUpdated = new Date();

				//	Full object reset, otherwise useSelector() does not pick up on the change
				newState.byId[action.payload.bookId] = {...newState.byId[action.payload.bookId]};

				return newState;
			}
		}
	}
);