import React from 'react';
import {connect} from 'react-redux';

// import {
// 	Row, Col,
// 	Card, CardBody, CardImg, CardHeader, CardTitle, CardText, CardFooter,
// } from 'reactstrap';

import AuthorLayout from "./Layout";

export const AuthorDashboardPage = class extends React.Component {
	render(){

		return (
			<AuthorLayout page={"authorDashboardPage"}>
AUTHOR DASHBOARD
			</AuthorLayout>
		);
	}
};

export default connect(state => ({store: state}))(AuthorDashboardPage);