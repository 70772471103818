import React from 'react';
import {connect} from 'react-redux';
// import withStateMachine from "../../functional/withStateMachine";
import PropTypes from 'prop-types';
import {
	Input, 
	// TagInput, ConfirmDialogue, 
	Button, formHandleInputChange, 
	// offsetSizes
} from '@wpa/components/lib/Form';
import {feathersServices, SERVICE} from "@wpa/feathers-client";
import {
	Col,
	Form, FormGroup, Label, 
	// FormText, 
	Row, 
	// InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import {errorStateReducer} from "../../reducers";
// import AuthorSelect from '../author/Select';
// import BookSeriesSelect from './BookSeriesSelect';
// import {getAuthorsByBookId} from '../../reducers/db/authorBooks';
// import {getSeriesByBookId} from '../../reducers/db/series';
import {getExternalAssetByBookIdAndTypeId} from '../../reducers/db/bookExternalAssets';



export const STATE = {
	LOADING: 'loading',
	DISPLAY: 'display',
	ERROR: 'error',
	ERROR_LOADING: 'errorLoading',
};

export const ACTION = {
	DISPLAY: 'display',
	SAVE: 'save',
	ERROR: 'error',
};

export const stateMachine = {
	initial: STATE.LOADING,
	states: {
		[STATE.LOADING]: {
			on: {
				[ACTION.ERROR]: {
					[STATE.ERROR_LOADING]: {}
				},
				[ACTION.DISPLAY]: {
					[STATE.DISPLAY]: {
						actions: ['setFields']
					}
				}
			}
		},
		[STATE.DISPLAY]: {
			on: {
				[ACTION.SAVE]: {
					[STATE.SAVING]: {}
				}
			}
		},
		[STATE.SAVING]: {
			on: {
				[ACTION.DISPLAY]: {
					[STATE.DISPLAY]: {}
				},
				[ACTION.ERROR]: {
					[STATE.ERROR]: {}
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {

			}
		},
		[STATE.ERROR_LOADING]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {

			}
		}
	}
};

// const stateReducers = [
// 	errorStateReducer.reducer,
// 	// (action, event) => {
// 	// 	switch(action){
// 	// 		case 'setFields':
// 	// 			return {
// 	// 				showCover: event.showCover,
// 	// 				showTitle: event.showTitle,
// 	// 				showSeriesName: event.showSeriesName,
// 	// 				showAuthorName: event.showAuthorName,
// 	// 				showBlurb: event.showBlurb,
// 	// 				showExtendedBlurb: event.showExtendedBlurb,
// 	// 				showPubDate: event.showPubDate,
// 	// 				showPurchaseLinks: event.showPurchaseLinks,
// 	// 				bookLimit: event.bookLimit,
// 	// 				bookOrder: event.bookOrder,
// 	// 				coverStyle: event.coverStyle,
// 	// 			};
// 	//
// 	// 		//	No Change
// 	// 		default:
// 	// 			return {};
// 	// 	}
// 	// }
// ];


export class BookExternalAssetForm extends React.Component {
	static displayName = 'BookExternalAssetForm';
	static propTypes = {
		bookId: PropTypes.number.isRequired,
		typeId: PropTypes.number.isRequired,
	};
	static defaultProps = {

	};

	constructor(props){
		super(props);

		this.handleInputChange = formHandleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		const asset = props.asset || {};

		// const book = this.props.book || {};
		this.state = {
			folder: asset.folder,
			notes: asset.notes,
		};
	}

	componentDidMount(){
		//	@todo: replace with a generic call to initialize a store
		// this.props
		// 	.dispatch(feathersServices[SERVICE.SERIES].find({}))
		// 	.then(json => {
		// 		this.setState({
		// 			seriesList: json.value.data.map(item => {
		// 				return {
		// 					id: item.id,
		// 					name: item.name
		// 				};
		// 			})
		// 		});
		// 	});
	}

	handleSubmit(e){
		e.preventDefault();

		const self = this;

		if( ! self.props.asset){
			this.props.dispatch(
				feathersServices[SERVICE.BOOK_EXTERNAL_ASSETS].create({
					typeId: self.props.typeId,
					bookId: self.props.bookId,
					folder: self.state.folder,
					notes: self.state.notes,
				}, {})
			)
				.then(res => {
					// this.props.toggle();
				})
				.catch(err => {
					console.error(err);
				})
			;
		} else {
			this.props.dispatch(
				feathersServices[SERVICE.BOOK_EXTERNAL_ASSETS].patch(
					self.props.asset.id,
					{
						typeId: self.props.typeId,
						bookId: self.props.bookId,
						folder: self.state.folder,
						notes: self.state.notes,
					},
					{}
				)
			)
				.then(res => {
					// this.props.toggle();
				})
				.catch(err => {
					console.error(err);
				})
			;
		}

		/*
		//	@todo: Make all these optional if not changed value?
		self.props.dispatch(
			feathersServices[SERVICE.BOOKS].patch(this.props.book.id, {
				title: this.state.title,
				logline: this.state.logline,
				authors: this.state.authors.map(author => author.id),
				series: this.state.series.map(series => series.id)
			}, {})
		)
			.then(payload => {
				//	Reset the series state
				self.setState({
					originalSeries: this.state.series.map(series => ({
						id: series.id,
						name: series.name
					}))
				});
			})
			.catch(err => self.setState({ errorMessage: err.message}))
			// .catch(err => self.transition(ACTION.ERROR, { message: err.message, error: err }))
		;
		*/
	}

	render(){

		const {
			// book,
			typeId,
			asset = {},
			// ...props
		} = this.props;

		// const book = {};
		const disabled = false; //!book.User.canEditDetails;

		return (
			<Form onChange={this.handleInputChange} id={'BookExternalAssetsForm-'+ typeId} onSubmit={this.handleSubmit} className="grid-form">
				<Row>
					<Col>
						<FormGroup >
							<Label for={'BookExternalAssetsForm-'+ typeId +'-folder'}>Folder location</Label>
							<div>
								<Input
									type="text" name="folder" id={'BookExternalAssetsForm-'+ typeId +'-folder'}
									defaultValue={asset.folder}
									value={this.state.folder}
									disabled={disabled}
									placeholder={"Link to access the folder"}
								/>
							</div>
						</FormGroup>
						<FormGroup >
							<Label for={'BookExternalAssetsForm-'+ typeId +'-notes'}>Notes</Label>
							<div>
								<Input
									type="textarea" name="notes" id={'BookExternalAssetsForm-'+ typeId +'-notes'}
									defaultValue={asset.notes}
									value={this.state.notes}
									disabled={disabled}
									placeholder={"What is stored at this location?"}
								/>
							</div>
						</FormGroup>
					</Col>
				</Row>

				{!disabled && <FormGroup row>
					<Col>
						<Button type="primary" htmlType="submit">Update</Button>
					</Col>
				</FormGroup>}
			</Form>
		);
	}
}

export default connect((state, props) => ({
	asset: getExternalAssetByBookIdAndTypeId(state, props.bookId, props.typeId)
}))(BookExternalAssetForm);
