import React from 'react';
import {imagePath} from '../../utils/imagePath';

const AuthorLink = (props) => {
	const author = props.author;
	const classes = props.classes || [];

	if(!author){
		return (<span>AUTHOR</span>);
	}

	classes.push("author");

	if(!! props.selected){
		classes.push("author-active");
	}

	return (
		<a className={classes.join(" ")} href="#todo-author-link">
			{author.ProfilePic && author.ProfilePic.length > 0 &&
			<img src={imagePath(author.ProfilePic[0].path)} alt={author.name} className=""/>}
			<span>{author.name}</span>
		</a>
	)
};

export default AuthorLink;
