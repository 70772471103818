import React from 'react';
import {connect} from 'react-redux';

import {Card, CardBody, CardTitle, Col, Row,} from 'reactstrap';
import {getBookById} from '../../reducers/db/books';
import BookPageLayout from './Layout';
import {
	// getReducedSalesTotalByBookId, 
	getSalesTotalByBookId
} from '../../reducers/db/bookSales';
// import {toggleBookFilterStatus} from '../../actions';
import TagList from '../../components/TagList';
import {getAllBookStatusesAsTags} from '../../reducers/db/bookStatuses';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import {
	ASSET_DROPBOX, ASSET_GDRIVE,
	ASSET_ONEDRIVE,
	getExternalAssetsByBookIdAsMapByTypeId
} from '../../reducers/db/bookExternalAssets';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {quickRound} from '../../utils/maths';
import UnpaidSalesCard from '../../components/shared/sales/UnpaidSalesCard';
import PandLCard from '../../components/shared/sales/PandLCard';

export const BookDashboardPage = class extends React.Component {

	render(){
		const {
			book,
			bookStatuses,
			externalAssets = {},
			// ...props
		} = this.props;

		// const status = book.Status.name;

		const canViewSales = !! book.User.canViewSales;
		const canEditStatus = !! book.User.canEditStatus;
		const canViewAssets = !! book.User.canViewAssets;

		// const pandl = props.bookSalesAllTimeTotal.royalties || 0;
		// const sales = props.bookSalesAllTimeTotal || {};

		return (
			<BookPageLayout pageName={"bookDashboard"}>
				<Row>

					{/*<Col xs="12" sm="6" md="3">*/}

						{/*<Card>*/}
							{/*<CardBody>*/}
								{/*{book.Cover &&*/}
								{/*<p><CardImg top width="100%" src={imagePath(encodeURI(book.Cover.Image.path))} alt={'Cover for ' + book.title}/></p>}*/}
								{/*<p>*/}
									{/*<small>Logline:</small><br />*/}
									{/*{book.logline}*/}
								{/*</p>*/}
							{/*</CardBody>*/}
						{/*</Card>*/}
					{/*</Col>*/}


					{canViewSales && <Col xs="12" sm="6" md="3">
						<PandLCard type="BOOK" itemId={book.id} />
					</Col>}

					{canViewSales && <Col xs="12" sm="6" md="3">
						<UnpaidSalesCard
							type="BOOK"
							itemId={book.id}
						/>
					</Col>}

					<Col xs="12" sm="6" md="3">
						<Card>
							<CardBody className="text-center">
								<CardTitle>Status</CardTitle>

								<hr />

								{canEditStatus && <TagList
									// title="Status"
									tags={bookStatuses}
									onToggle={(statusId) => {
										this.props.dispatch(feathersServices[SERVICE.BOOKS].patch(
											book.id,
											{
												statusId: statusId
											}
										), {})
									}}
									className="statusSetList"
									btnClass={"btn-status"}
									listState={{[book.Status.id]: true}}
								/>}
								{!canEditStatus && <TagList
									// title="Status"
									tags={bookStatuses}
									className="statusSetList"
									btnClass={"btn-status"}
									listState={{[book.Status.id]: true}}
									iconOn={['far', 'check']}
									iconOff={null}
									disabled
								/>}
							</CardBody>
						</Card>
					</Col>
					{canViewAssets && <Col xs="12" sm="6" md="3">
						<Card className={'externalAssetsCard'}>
							<CardBody>
								<CardTitle className="text-center">Assets</CardTitle>
								<hr />
								{externalAssets[ASSET_GDRIVE] && externalAssets[ASSET_GDRIVE].folder && <div className="asset">
									<a href={externalAssets[ASSET_GDRIVE].folder} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'google-drive']} /> Google Drive</a><br />
									<span>{externalAssets[ASSET_GDRIVE].notes}</span>
								</div>}
								{externalAssets[ASSET_DROPBOX] && externalAssets[ASSET_DROPBOX].folder && <div className="asset">
									<a href={externalAssets[ASSET_DROPBOX].folder} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'dropbox']} /> Dropbox</a><br />
									<span>{externalAssets[ASSET_DROPBOX].notes}</span>
								</div>}
								{externalAssets[ASSET_ONEDRIVE] && externalAssets[ASSET_ONEDRIVE].folder && <div className="asset">
									<a href={externalAssets[ASSET_ONEDRIVE].folder} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'microsoft']} /> OneDrive</a><br />
									<span>{externalAssets[ASSET_ONEDRIVE].notes}</span>
								</div>}
								{!externalAssets[ASSET_GDRIVE] && !externalAssets[ASSET_DROPBOX] && !externalAssets[ASSET_ONEDRIVE] && <div className={'text-center'}>
									<small><i>No external assets defined.</i></small>
								</div>}
							</CardBody>
						</Card>
					</Col>}
				</Row>
				{/*<Row>*/}
					{/**/}
				{/*</Row>*/}
				{/*<Row>*/}
					{/*<Col>*/}
						{/*<a href="https://www.pinterest.com/anapinskywalker/style/"*/}
						   {/*data-pin-do="embedBoard"*/}
						   {/*data-pin-board-width="400"*/}
						   {/*data-pin-scale-height="320"*/}
						   {/*data-pin-scale-width="100">*/}
						{/*</a>*/}
					{/*</Col>*/}
				{/*</Row>*/}
			</BookPageLayout>
		);
	}
};

export default connect((state, props) => {
	const id = parseInt(props.match.params.id, 10);
	return {
		bookStatuses: getAllBookStatusesAsTags(state),
		book: getBookById(state, id),
		bookSalesAllTimeTotal: getSalesTotalByBookId(state, id, 'ALL_TIME_BY_BOOK')[0] || {},
		externalAssets: getExternalAssetsByBookIdAsMapByTypeId(state, id),
	};
})(BookDashboardPage);