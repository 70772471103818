import React from 'react';
import PropTypes from 'prop-types';
import {
	Form,
	Button,
	Input,
	FormAlert,
} from '@wpa/components/lib/Form';
import { formStateMachine, FORM_STATE } from '@wpa/state-machine';
import { useDispatch } from 'react-redux';
import { useMachine } from '@xstate/react';
import { Card } from 'antd';
import logger from '@wpa/logger';

const sm = formStateMachine({
	formAction: (context, event) => 
		new Promise((resolve, reject) => {
			context
				.onSubmit(context.fields.content, context.dispatch)
				.then(() => {
					resolve();
				})
				.catch(
					(err) =>
						logger.error(err) ||
						reject('Unable to create a new site.')
				);
		}),
});

export const CardTextarea = ({
	content: originalContent,
	id,
	title,
	onSubmit,
	children,
}) => {
	const dispatch = useDispatch();

	const [state, send] = useMachine(
		sm.withFormContext(
			{
				onSubmit,
				dispatch,
			},
			{
				content: originalContent,
			}
		)
	);

	const { errorMessage } = state.context;
	const { content } = state.context.fields;

	const isError = state.matches(FORM_STATE.ERROR);
	const isSaving = state.matches(FORM_STATE.SUBMITTING);
	const isDisabled = isSaving || ! sm.hasChanged(state);

	return (
		<Form stateMachine={sm} send={send} state={state} buttonRow={false} showError={null}>
			<Card
				title={<label htmlFor={id}><h3>{title}</h3></label>}
				extra={
					<Button
						type="primary"
						htmlType="submit"
						loading={isSaving}
						size="small"
						disabled={isDisabled}
					>
						Save
					</Button>
				}
			>
				{isError && <FormAlert send={send} description={errorMessage} />}
				<Form.Item noStyle={true}>
					<Input.TextArea
						rows={10}
						name="content"
						value={content}
						defaultValue={originalContent}
						id={id}
					/>
				</Form.Item>
				{children}
			</Card>
		</Form>
	);
};

CardTextarea.propTypes = {
	//	Default content
	content: PropTypes.string.isRequired,
	//	Id of the form element
	id: PropTypes.string.isRequired,
	//	Card title
	title: PropTypes.string.isRequired,
	//	What to do with content - Promise
	onSubmit: PropTypes.func.isRequired,
};

export default CardTextarea;
