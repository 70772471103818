import React from 'react';
// import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import Menu from "../../components/Menu";
// import {ButtonModal} from '@wpa/components/lib/Form';

export class PublisherPageMenu extends React.PureComponent {
	static displayName = 'PublisherPageMenu';
	static propTypes = {};
	static defaultProps = {};

	render(){
		const props = this.props;

		const {
			params: { slug, id },
			url
		} = props.match;

		const baseLink = '/publishers/'+ slug +'/'+ id;

		return (
			<div>
				<Menu
					menu={[
						// {
						// 	label: 'Dashboard',
						// 	pathSuffix: '',
						// },
						{
							label: 'Details',
							pathSuffix: '/details'
						},

						{
							label: 'Sales',
							pathSuffix: '/sales'
						},
					]}
					baseLink={baseLink}
					currentPath={url}
				/>

				{/*<p className="text-center">*/}
					{/*<ButtonModal*/}
						{/*isOpen={props.match.path === '/invite/publisher/'+ slug +'/'+ id}*/}
						{/*title="Invite to Author"*/}
						{/*modal={<p >xxx</p>}*/}
					{/*>Share</ButtonModal>*/}
				{/*</p>*/}
			</div>
		);
	}
}

export default withRouter(PublisherPageMenu);
