'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.feathersServices = exports.SERVICE = exports.feathersAPI = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _feathers = require('@feathersjs/feathers');

var _feathers2 = _interopRequireDefault(_feathers);

var _feathersRedux = require('feathers-redux');

var _feathersRedux2 = _interopRequireDefault(_feathersRedux);

var _socketioClient = require('@feathersjs/socketio-client');

var _socketioClient2 = _interopRequireDefault(_socketioClient);

var _authenticationClient = require('@feathersjs/authentication-client');

var _authenticationClient2 = _interopRequireDefault(_authenticationClient);

var _socket = require('socket.io-client');

var _socket2 = _interopRequireDefault(_socket);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var apiRoot = process.env.REACT_APP_API;
if (!apiRoot) {
	throw new Error('API url not configured!');
}

var socket = (0, _socket2.default)(apiRoot);

/**
 * Socket event logging
 */
socket.on('connect', function () {
	//	eslint-disable-next-line no-console
	console.log('socket connected!');

	var oldOnevent = socket.onevent;
	socket.onevent = function (packet) {
		if (packet.data) {
			//	eslint-disable-next-line no-console
			console.log('>>>', {
				name: packet.data[0],
				payload: packet.data[1]
			});
		}
		oldOnevent.apply(socket, arguments);
	};
});

// @feathersjs/client is exposed as the `feathers` global.
var feathersAPI = exports.feathersAPI = (0, _feathers2.default)();

feathersAPI.configure((0, _socketioClient2.default)(socket, { timeout: 10000 }));

function getCookie(name) {
	var cookie = {};
	document.cookie.split(';').forEach(function (el) {
		var _el$split = el.split('='),
		    _el$split2 = _slicedToArray(_el$split, 2),
		    k = _el$split2[0],
		    v = _el$split2[1];

		cookie[k.trim()] = v;
	});
	return cookie[name];
}

var MyAuthenticationClient = _authenticationClient2.default.AuthenticationClient;
MyAuthenticationClient.prototype.parentGetFromLocation = MyAuthenticationClient.prototype.getFromLocation;
MyAuthenticationClient.prototype.getFromLocation = function (location) {
	//	Cookie name
	var name = 'wpa-api';
	location.hash = 'access_token=' + getCookie(name);
	return this.parentGetFromLocation(location);
};

feathersAPI.configure((0, _authenticationClient2.default)({
	Authentication: MyAuthenticationClient,
	storage: localStorage
}));

var SERVICE = exports.SERVICE = {
	ASSISTANT: 'assistant',
	AUTHMANAGEMENT: 'authManagement',
	AUTHOR_BOOKS: 'author-books',
	AUTHOR_IMAGES: 'author-images',
	AUTHORS: 'authors',
	BOOKS: 'books',
	BOOK_BLURBS: 'book-blurbs',
	BOOK_EXTERNAL_ASSETS: 'book-external-assets',
	BOOK_BLURB_CONTENTS: 'book-blurb-contents',
	BOOK_IMAGES: 'book-images',
	BOOK_SALES: 'book-sales',
	BOOK_SERIES: 'book-series',
	BOOK_STATUSES: 'book-statuses',
	BOOK_PUBLISHERS: 'book-publishers',
	BOOK_REVIEWS: 'book-reviews',
	BOOK_ROYALTIES: 'book-royalties',
	// PA_MESSAGES: 'pa-messages',
	EXPENSES: 'expenses',
	PERMISSIONS: 'permissions',
	PUBLISHERS: 'publishers',
	RESOURCE_INVITES: 'resource-invites',
	REVIEWS: 'reviews',
	SERIES: 'series',
	SITES: 'sites',
	SITE_HEADERS: 'site-headers',
	SITE_LAYOUTS: 'site-layouts',
	SITE_IMAGES: 'site-images',
	SITE_MENUS: 'site-menus',
	SITE_MENU_PAGES: 'site-menu-pages',
	SITE_PAGES: 'site-pages',
	SITE_PAGE_BLOCKS: 'site-page-blocks',
	SITE_PAGE_AUTHOR_BIO: 'site-page-author-bio',
	SITE_PAGE_BOOKS: 'site-page-books',
	SITE_PAGE_CONTENTS: 'site-page-contents',
	SITE_SOCIAL_MEDIAS: 'site-social-medias',
	SITE_SIDEBARS: 'site-sidebars',
	SOCIAL_MEDIAS: 'social-medias',
	SOCIAL_MEDIA_TYPES: 'social-media-types',
	UPLOADS: 'uploads',
	USERS: 'users',
	USER_AUTHORS: 'user-authors',
	USER_BOOKS: 'user-books',
	USER_PERMISSIONS: 'user-permissions'
};

exports.default = feathersAPI;

//	Redux actions for

var feathersServices = exports.feathersServices = (0, _feathersRedux2.default)(feathersAPI,
//	Reuse the SERVICE definition above
Object.values(SERVICE).reduce(function (services, key) {
	services[key] = key;
	return services;
}, {}));

feathersAPI.service('uploads').timeout = 30000;

/*
 *	Helper methods
 */
//	Service Helper
feathersServices.getServiceAction = function (service, action) {
	return 'SERVICES_' + service.toUpperCase() + '_' + action;
};

//	Action Helpers
feathersServices.getPendingAction = function (service, action) {
	return feathersServices.getServiceAction(service, action) + '_PENDING';
};
feathersServices.getFulfilledAction = function (service, action) {
	return feathersServices.getServiceAction(service, action) + '_FULFILLED';
};
feathersServices.getRejectedAction = function (service, action) {
	return feathersServices.getServiceAction(service, action) + '_REJECTED';
};

//	VERB helpers

feathersServices.getFindPendingAction = function (service) {
	return feathersServices.getPendingAction(service, 'FIND');
};
feathersServices.getFindFulfilledAction = function (service) {
	return feathersServices.getFulfilledAction(service, 'FIND');
};
feathersServices.getFindRejectedAction = function (service) {
	return feathersServices.getRejectedAction(service, 'FIND');
};

feathersServices.getGetPendingAction = function (service) {
	return feathersServices.getPendingAction(service, 'GET');
};
feathersServices.getGetFulfilledAction = function (service) {
	return feathersServices.getFulfilledAction(service, 'GET');
};
feathersServices.getGetRejectedAction = function (service) {
	return feathersServices.getRejectedAction(service, 'GET');
};

feathersServices.getCreatePendingAction = function (service) {
	return feathersServices.getPendingAction(service, 'CREATE');
};
feathersServices.getCreateFulfilledAction = function (service) {
	return feathersServices.getFulfilledAction(service, 'CREATE');
};
feathersServices.getCreateRejectedAction = function (service) {
	return feathersServices.getRejectedAction(service, 'CREATE');
};

feathersServices.getPatchPendingAction = function (service) {
	return feathersServices.getPendingAction(service, 'PATCH');
};
feathersServices.getPatchFulfilledAction = function (service) {
	return feathersServices.getFulfilledAction(service, 'PATCH');
};
feathersServices.getPatchRejectedAction = function (service) {
	return feathersServices.getRejectedAction(service, 'PATCH');
};

feathersServices.getUpdatePendingAction = function (service) {
	return feathersServices.getPendingAction(service, 'UPDATE');
};
feathersServices.getUpdateFulfilledAction = function (service) {
	return feathersServices.getFulfilledAction(service, 'UPDATE');
};
feathersServices.getUpdateRejectedAction = function (service) {
	return feathersServices.getRejectedAction(service, 'UPDATE');
};

feathersServices.getRemovePendingAction = function (service) {
	return feathersServices.getPendingAction(service, 'REMOVE');
};
feathersServices.getRemoveFulfilledAction = function (service) {
	return feathersServices.getFulfilledAction(service, 'REMOVE');
};
feathersServices.getRemoveRejectedAction = function (service) {
	return feathersServices.getRejectedAction(service, 'REMOVE');
};