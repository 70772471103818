import React, { useCallback } from 'react';
// import moment from 'moment';
// import {connect} from 'react-redux';
// import {
// 	CardBody,
// 	CardHeader,
// 	CardImg,
// 	CardTitle,
// 	FormGroup,
// 	Label
// } from 'reactstrap';
import { Button, 
	// labelSizes, colSizes, buttonSizes 
} from '@wpa/components/lib/Form';

// import { imagePath } from '../../utils/imagePath';
// import BookSummaryForm from '../../components/books/summaryForm';
// import {BookBlurbs} from '../../components/books/blurbs';
// import CoverEdit from '../../components/books/coverEdit';
// import {getBookById} from '../../reducers/db/books';
// import {getAuthorsByBookId} from '../../reducers/db/authorBooks';
// import {getAllBookStatusesAsTags} from '../../reducers/db/bookStatuses';
import BookPageLayout from './Layout';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { useMachine } from '@xstate/react';
import { useDispatch, useSelector } from 'react-redux';
import Logger from '@wpa/logger';

import { Card, Row, Col, Typography, Modal } from 'antd';
import { SpinnerSmallWithText } from '@wpa/components/lib/Spinner';
// import { getAllBookReviewsByBookId } from '../../reducers/db/bookReviews';
import { getAllReviewsByBookId } from '../../reducers/db/reviews';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Machine, assign } from 'xstate';
import { BookReviewForm} from '../../components/books/reviews/Form';

const STATE = {
	LOADING: 'loading',
	ERROR: 'error',
	DISPLAY: 'display',
	EDIT_REVIEW: 'editReview',
	NEW_REVIEW: 'newReview'
};
const ACTION = {
	LOAD: 'load',
	ERROR: 'error',
	DISPLAY: 'display',
	EDIT_REVIEW: 'editReview',
	NEW_REVIEW: 'newReview',
	MODAL_CLOSE: 'modalClose'
};

const bookReviewPageMachine = Machine({
	initial: STATE.LOADING,
	context: {
		error: false,
		errorMessage: '',
		bookId: null,
		showModal: false,
		modalTitle: '',
		selectedReviewId: null
	},
	states: {
		[STATE.LOADING]: {
			invoke: {
				id: 'loading',
				src: (context, event) =>
					new Promise((resolve, reject) => {
						context
							.dispatch(
								feathersServices[SERVICE.REVIEWS].find({
									query: {
										bookId: context.bookId
									}
								})
							)
							.then(res => {
								// context.defaultContent = context.content;
								resolve();
							})
							.catch(
								err => Logger.error(err) || reject('Unable to find reviews.')
							);
					}),
				onDone: {
					target: STATE.DISPLAY
				},
				onError: {
					target: STATE.ERROR,
					actions: assign({
						error: (context, event) => event.data,
						//	If we get back an error object, pluck the message, otherwise just use the error value
						errorMessage: (context, event) =>
							Logger.log(event) || event.data.message || event.data
					})
				}
			}
		},
		[STATE.DISPLAY]: {
			on: {
				[ACTION.LOAD]: {
					target: STATE.LOADING
				},
				[ACTION.NEW_REVIEW]: {
					target: STATE.NEW_REVIEW
				},
				[ACTION.EDIT_REVIEW]: {
					target: STATE.EDIT_REVIEW
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [
				(context, event) =>
					Logger.error(
						'Default error response:',
						context.errorMessage,
						context.error
					)
			],
			onExit: [
				//	Always clear internal state
				assign({ error: false, errorMessage: null })
			].filter(Boolean),
			on: {
				[ACTION.LOAD]: {
					target: STATE.LOADING
				}
			}
		},
		[STATE.NEW_REVIEW]: {
			onEntry: [
				assign({
					showModal: true,
					modalTitle: 'New Review',
					selectedReviewId: null
				})
			].filter(Boolean),
			onExit: [
				//	Always clear internal state
				assign({ showModal: false, modalTitle: '' })
			].filter(Boolean),
			on: {
				[ACTION.MODAL_CLOSE]: {
					target: STATE.DISPLAY
				}
			}
		},
		[STATE.EDIT_REVIEW]: {
			onEntry: [
				assign({
					showModal: true,
					modalTitle: 'Edit Review',
					selectedReviewId: (context, event) => event.selectedReviewId
				})
			],
			onExit: [
				//	Always clear internal state
				assign({ showModal: false, modalTitle: '' })
			].filter(Boolean),
			on: {
				[ACTION.MODAL_CLOSE]: {
					target: STATE.DISPLAY
				}
			}
		}
	}
});

export const BookReviewsPage = ({match}) => {
	const bookId = parseInt(match.params.id, 10);
	const dispatch = useDispatch();

	const [state, send] = useMachine(
		bookReviewPageMachine.withContext({
			dispatch: dispatch,
			bookId: bookId
		})
	);
	const reviews =
		useSelector(state => getAllReviewsByBookId(state, bookId)) || {};

	const {
		// errorMessage,
		showModal,
		modalTitle,
		selectedReviewId
	} = state.context;

	const addNewReview = useCallback(
		e => {
			e.preventDefault();

			send(ACTION.NEW_REVIEW, {
				bookId: bookId
			});
		},
		[send, bookId]
	);

	const editReview = useCallback(
		e => {
			e.preventDefault();

			send(ACTION.EDIT_REVIEW, {
				bookId: bookId,
				selectedReviewId: e.currentTarget.dataset.id
			});
		},
		[send, bookId]
	);

	const deleteReview = useCallback(
		e => {
			const id = e.currentTarget.dataset.id;
			Modal.confirm({
				title: 'Delete Review',
				content: 'Are you sure you want to delete this review?',
				okText: 'Yes',
				okType: 'danger',
				cancelText: 'No',
				onOk(){
					dispatch(
						feathersServices[SERVICE.REVIEWS].remove(
							id
						)
					)
						.then(res => {
							// context.defaultContent = context.content;
							// resolve();
						})
						.catch(
							err => Logger.error(err)
							//  || reject('Unable to find reviews.')
						);
				}
			});
		},
		[dispatch]
	);

	const closeModal = useCallback(
		e => {
			if (e) {
				e.preventDefault();
			}

			send(ACTION.MODAL_CLOSE, {
				bookId: bookId
			});
		},
		[send, bookId]
	);

	// const isError = state.matches(STATE.ERROR);
	const isLoading = state.matches(STATE.LOADING);

	return (
		<BookPageLayout pageName={'bookReviews'}>
			<Button type="primary" onClick={addNewReview} className="float-right">
				New Review
			</Button>

			<h2>Reviews</h2>

			{isLoading && (
				<Row>
					<Col className="text-center">
						<SpinnerSmallWithText
							label="Loading reviews..."
							block
							light
							textCenter
						/>
					</Col>
				</Row>
			)}

			{!isLoading && (
				<Row gutter={16}>
					{!reviews && <Col>No reviews yet.</Col>}
					{reviews &&
						reviews.map(review => (
							<Col span={8} key={review.id}>
								<Card
									actions={[
										<Button
											type="link"
											data-id={review.id}
											onClick={editReview}
										>
											<FontAwesomeIcon icon={['far', 'edit']} size={'lg'} />
										</Button>,
										<Button
											type="link"
											data-id={review.id}
											onClick={deleteReview}
										>
											<FontAwesomeIcon
												icon={['far', 'trash-alt']}
												size={'lg'}
											/>
										</Button>
									]}
								>
									<blockquote className="quote">
										<Typography.Paragraph
											ellipsis={{ rows: 3, expandable: true }}
										>
											{review.quote || review.review}
										</Typography.Paragraph>
										{review.author && (
											<footer>
												<cite>
													{review.sourceUrl && (
														<a href={review.sourceUrl} target="_blank" rel="noopener noreferrer">
															{review.author}
														</a>
													)}
													{!review.sourceUrl && <span>{review.author}</span>}
													{review.publishedAt && (
														<span>
															<br />
															{new Date(
																review.publishedAt
															).toLocaleDateString()}
														</span>
													)}
												</cite>
											</footer>
										)}
									</blockquote>
								</Card>
							</Col>
						))}
				</Row>
			)}

			<Modal
				title={<h3>{modalTitle}</h3>}
				visible={showModal}
				onCancel={closeModal}
				width="90%"
				destroyOnClose
				footer={false}
			>
				<BookReviewForm
					bookId={bookId}
					reviewId={selectedReviewId}
					onSubmit={closeModal}
				/>
			</Modal>
		</BookPageLayout>
	);
};

export default BookReviewsPage;

// 	constructor(props){
// 		super(props);

// 		this.state = {
// 			isCoverModalOpen: false,

// 		};

// 		this.toggleCoverEditModal = this.toggleCoverEditModal.bind(this);
// 	}

// 	toggleCoverEditModal(e){
// 		this.setState({
// 			isCoverModalOpen: ! this.state.isCoverModalOpen
// 		});
// 	}

// 	handleSubmit(){

// 	}

// 	render(){
// 		const props = this.props;
// 		const book = this.props.book;

// 		const bookStatuses = props.bookStatuses;

// 		return (
// 			<BookPageLayout page={"bookDetails"}>

// 					<Row>
// 						<Col sm={6} md={4} lg={3}>
// 							<Card>
// 								<CardBody>
// 									{book.Cover &&
// 									<CardImg top width="100%" src={imagePath(encodeURI(book.Cover.Image.path))} alt={'Cover for ' + book.title}/>}
// 									<Button color="primary" block onClick={this.toggleCoverEditModal}>Select Cover</Button>

// 									{/* Cover editing modal*/}
// 									<CoverEdit isOpen={this.state.isCoverModalOpen} toggle={this.toggleCoverEditModal} bookId={book.id} />
// 								</CardBody>
// 							</Card>

// 							{/*<Card>*/}
// 								{/*<CardHeader>*/}
// 									{/*<CardTitle>Available at</CardTitle>*/}
// 								{/*</CardHeader>*/}
// 								{/*<CardBody>*/}
// 									{/*STORE FORM*/}
// 								{/*</CardBody>*/}
// 							{/*</Card>*/}
// 						</Col>
// 						<Col sm={6} md={8} lg={9}>
// 							<Card>
// 								<CardHeader>
// 									<CardTitle>Summary</CardTitle>
// 								</CardHeader>
// 								<CardBody>
// 									<BookSummaryForm
// 										book={book}
// 									/>
// 								</CardBody>
// 							</Card>
// 						</Col>
// 					</Row>

// 					<BookBlurbs book={book} />

// 			</BookPageLayout>
// 		);
// 	}
// }

// export default connect((state, props) => {
// 	const id = parseInt(props.match.params.id, 10);
// 	return {
// 		book: getBookById(state, id),
// 		authors: getAuthorsByBookId(state, id),
// 		bookStatuses: getAllBookStatusesAsTags(state),
// 	};
// })(BookEditPage);
