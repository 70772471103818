import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import AuthorListItem from './ListItem';
import {getAllAuthors} from '../../reducers/db/authors';

export class AuthorList extends React.PureComponent {
	static displayName = 'AuthorList';
	static propTypes = {
		onToggle: PropTypes.func,
		authors: PropTypes.array,
		selected: PropTypes.object,
	};

	constructor(props){
		super(props);

		this.state = {};

		this.toggleItem = this.toggleItem.bind(this);
	}

	toggleItem(key, isSelected){
		if(this.props.onToggle){
			this.props.onToggle(key);
		}
	}

	render(){
		const props = this.props;
		const {
			authors,
			selected,
		} = props;

		//	Default is "true" when no value is specified
		// const selectedDefault = false;//selected !== false;

		if(!authors || authors.length < 1){
			return (
				<p>No authors found.</p>
			);
		}

		return (
			<ul className="authorList">{authors.map((author) =>
				<AuthorListItem key={author.id} author={author} onToggle={this.toggleItem} selected={selected[author.id] === true} />
			)}</ul>
		);
	}
}

export default connect(state => ({
	authors: getAllAuthors(state),
}))(AuthorList);