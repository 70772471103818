import { getItemIdsByParam } from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'SITE_MENUS';
export const ADD_SITE_MENUS = 'ADD_'+ serviceName;
export const UPDATE_SITE_MENUS = 'UPDATE_'+ serviceName;
export const REMOVE_SITE_MENUS = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */

// export function getSiteByDomain(domain, store){
// 	const siteId = getSiteIdByDomain(domain, store);
//
// 	return store.db.sites.byId[siteId];
// }
//
// export function getSiteIdByDomain(domain, store){
// 	const siteId = (store.db.sites.allIds || [])
// 		.filter(siteId => store.db.sites.byId[siteId].domain === domain)
// 		.pop();
//
// 	return siteId;
// }

export const addSiteMenus = items => ({
	type: ADD_SITE_MENUS,
	payload: items
});

export const removeSiteMenus = items => ({
	type: REMOVE_SITE_MENUS,
	payload: items
});

export const getMenuPageIdByPath = (state, path) => {
	const stateSlice = state.db.siteMenus;
	return stateSlice.allIds
		.filter(itemId => stateSlice.byId[itemId].path === '/' + path)
		.shift();
};
export const getMenuPageByPath = (state, path) => {
	const stateSlice = state.db.siteMenus;
	const pageId = stateSlice.allIds
		.filter(itemId => stateSlice.byId[itemId].path === '/' + path)
		.shift();

	if(!pageId){
		return null;
	}

	return stateSlice.byId[pageId];
};
export const getAllSiteMenuIds = (state, siteId) => {
	const stateSlice = state.db.siteMenus;
	return getItemIdsByParam(stateSlice, 'siteId', siteId);
};
export const getAllSiteMenusBySiteId = (state, siteId) => {
	const stateSlice = state.db.siteMenus;
	return getAllSiteMenuIds(state, siteId)
		.map(itemId => stateSlice.byId[itemId])
	;
};

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);