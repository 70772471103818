import {getItemIdsByParam} from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'REVIEWS';
export const INITIAL_REVIEWS_LOADED = 'INITIAL_'+ serviceName +'_LOADED';
export const ADD_REVIEWS = 'ADD_'+ serviceName;
export const UPDATE_REVIEWS = 'UPDATE_'+ serviceName;
export const REMOVE_REVIEWS = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */

export const addReviews = items => ({
	type: ADD_REVIEWS,
	payload: items
});

export const removeReviews = items => ({
	type: REMOVE_REVIEWS,
	payload: items
});


export function getAllReviews(state){
	const stateSlice = state.db.reviews;

	const reviews = stateSlice.allIds.map(reviewId => {
		const review = stateSlice.byId[reviewId];
		return review;
	});

	return reviews;
}

export function getAllReviewsByBookId(state, bookId){
	const stateSlice = state.db.reviews;

	const itemIds = getItemIdsByParam(stateSlice, 'bookId', bookId);

	const reviews = itemIds.map(reviewId => {
		const review = stateSlice.byId[reviewId];
		return review;
	});

	return reviews;
}

export function getReviewById(state, reviewId){
	const stateSlice = state.db.reviews;

	return stateSlice.byId[reviewId];
}

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);