import React from 'react';
import { withRouter } from 'react-router-dom';
import Menu from '../../components/Menu';

export class AuthorPageMenu extends React.PureComponent {
	static displayName = 'AuthorPageMenu';
	static propTypes = {};
	static defaultProps = {};

	render() {
		const props = this.props;

		const {
			params: { slug, id },
			url
		} = props.match;

		const baseLink = '/authors/' + slug + '/' + id;

		return (
			<div>
				<Menu
					menu={[
						// {
						// 	label: 'Dashboard',
						// 	pathSuffix: '',
						// },
						{
							label: 'Details',
							pathSuffix: '/details'
						},
						{
							label: 'Sales',
							pathSuffix: '/sales'
						}
					]}
					baseLink={baseLink}
					currentPath={url}
				/>

				{/*<p className="text-center">*/}
				{/*<ButtonModal*/}
				{/*isOpen={props.match.path === '/invite/author/'+ slug +'/'+ id}*/}
				{/*title="Invite to Author"*/}
				{/*modal={<p >xxx</p>}*/}
				{/*>Share</ButtonModal>*/}
				{/*</p>*/}
			</div>
		);
	}
}

export default withRouter(AuthorPageMenu);
