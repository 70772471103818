import React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import AssistantCharacter, { PA } from './assistant/Character';
import {Drawer} from 'antd';
import {
	Form,
	FormGroup,
	ButtonGroup
} from 'reactstrap';
import { Button, formHandleInputChange } from '@wpa/components/lib/Form';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import AssistantResponse from './assistant/Response';
import { ASSISTANT_HIDE, ASSISTANT_SHOW } from '../reducers/assistant';

// const GO_TO_ACCOUNT_SCREEN = 1;

export class Assistant extends React.Component {
	static displayName = 'Assistant';
	static propTypes = {};
	static defaultProps = {};

	constructor(props) {
		super(props);

		this.state = {
			response: ''
		};

		this.onChange = formHandleInputChange.bind(this);
		this.toggle = this.toggle.bind(this);
		this.response = this.response.bind(this);

		this.buttonResponse = this.buttonResponse.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(
			feathersServices[SERVICE.ASSISTANT].get('current', {
				query: {
					// outstanding: true,
					$limit: 1
				}
			})
		);
	}

	toggle() {
		if (!this.props.isVisible || !this.props.currentMessage.id) {
			this.props.dispatch({
				type: ASSISTANT_SHOW,
				payload: {}
			});
		} else {
			this.props.dispatch({
				type: ASSISTANT_HIDE,
				payload: {}
			});
		}
	}

	response(e) {
		if (e) {
			e.preventDefault();
		}

		const self = this;
		self.props.dispatch(
			feathersServices[SERVICE.ASSISTANT].patch(
				self.props.currentMessage.id,
				{},
				{
					query: {
						//
						responseId: self.state.responseId,
						//	Button
						response: self.state.response
					}
				}
			)
		);

		this.setState({ response: '' });
	}

	buttonResponse(responseId, value) {
		const self = this;

		self.setState(
			{
				responseId: responseId,
				response: value
			},
			() => {
				self.response();
			}
		);
	}

	render() {
		const self = this;
		const props = self.props;
		const character = PA.PAL;

		const m = props.currentMessage;

		const isVisible = !! (props.isVisible && m.id);

		m.response = {};

		const classes = ['assistantBox'];

		if (isVisible) {
			classes.push('assistantFullBox');
		} else {
			classes.push('assistantMiniBox');
		}

		return (
			<div className={classes.join(' ')}>
				{!isVisible && <AssistantCharacter
					name={character}
					button={true}
					toggle={this.toggle}
				/>}
				<Drawer
					visible={isVisible}
					placement="bottom"
					onClose={this.toggle}
					// mask={false}
					closable={false}
					className="assistantFullBox assistantBox"
					height="auto"
				>
					<AssistantCharacter
						name={character}
						button={!isVisible}
						toggle={this.toggle}
					/>
					{isVisible && (
						<div className="content clearfix">
							<div>
								{m.text.split(/\n\s*\n/).map((text, index) => (
									<div
										className="speech-bubble"
										key={
											'assistant_speech-bubble_' +
											props.currentMessageId +
											'_' +
											index
										}
									>
										{text}
									</div>
								))}
							</div>

							<Form onSubmit={this.response} onChange={this.onChange}>
								<FormGroup>
									{m.Responses.map(response => (
										<AssistantResponse
											key={'assistant_response_' + response.id}
											type={response.ResponseType.name}
											dispatch={props.dispatch}
											currentMessageId={m.id}
											value={response.value}
											label={response.label}
											id={response.id}
											onButtonClick={self.buttonResponse}
										/>
									))}
									{m.response.type === 'LIST' && (
										<ButtonGroup>
											{m.response.values.map((v, index) => (
												<Button
													key={
														'assistant_button_' +
														props.currentMessageId +
														'_' +
														index
													}
													type="primary"
													htmlType="submit"
													onClick={this.buttonResponse}
													// value={v}
													name={'response'}
												>
													{v.label}
												</Button>
											))}
										</ButtonGroup>
									)}
								</FormGroup>
							</Form>
						</div>
					)}
				</Drawer>
			</div>
		);
	}
}

export default connect(state => ({
	isVisible: state.assistant.isVisible,
	currentMessageId: state.assistant.currentMessage.id,
	currentMessage: state.assistant.currentMessage
}))(Assistant);
