//	eslint-disable-next-line
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAllSites } from '../../reducers/db/sites';

const List = () => {
	const items = useSelector(state => getAllSites(state)) || {};
	const sitesUrl = process.env.REACT_APP_SITES_URL;
	const columns = [
		{
			title: 'Domain',
			dataIndex: 'domain',
			render: text => (
				<a href={'//' + text} target="_blank" title={'Visit '+ text} rel="noopener noreferrer">
					{text}
				</a>
			)
		},
		{
			title: 'Type',
			dataIndex: 'SiteType.label'
		},
		{
			title: 'Title',
			dataIndex: 'title'
		},
		{
			title: 'Actions',
			render: (text, record, index) => {
				
				const url = sitesUrl + '/'+ record.domain +'/';
				return (
					<div>
						<Link to={'sites/' + record.domain + '/dashboard'}>
							<FontAwesomeIcon
								icon={['far', 'edit']}
								fixedWidth
								size={'lg'}
								title={'Edit Site'}
							/>
						</Link>
						<a href={url} target="_blank" rel="noopener noreferrer">
							<FontAwesomeIcon
								icon={['far', 'eye']}
								fixedWidth
								size={'lg'}
								title={'Preview Site'}
							/>
						</a>
					</div>
				);
			}
		}
	];

	return <Table columns={columns} dataSource={items} pagination={false} rowKey='id' />;
};

export default List;
