import React from 'react';
import {
	Pagination, PaginationItem, 
	// PaginationLink
} from 'reactstrap';
import {
	withRouter,
	Link
} from 'react-router-dom';
const queryString = require('query-string');

export class PaginationWPA extends React.Component {

	// constructor(props){
	// 	super(props);
	// }

	render(){
		const props = this.props;

		//	Get query details
		const search = queryString.parse(props.location.search);
		const path = props.path || props.location.pathname;

		const currentPage = props.currentPage || 1;
		const totalPages = props.totalPages;

		const maxPages = props.maxPages || 5;
		const distance = parseInt(maxPages/2, 10);

		const pages = [];

		const startPage = Math.max(
			currentPage + maxPages - 1 > totalPages
				//	Towards the end use the full distance
			? totalPages - maxPages
				//	Otherwise
			: currentPage - (
				currentPage - maxPages >= 0
				? distance
				: (maxPages - (currentPage === totalPages ? 0 : 1))
			),
			2
		);

		const endPage = Math.min(
			currentPage < maxPages
				//	Towards the beginning use the full distance
			? maxPages + 1
				//	Otherwise
			: currentPage + distance,
			totalPages-1
		);

		//	Always show the first page
		pages.push({
			no: 1,
			link: {
				pathname: path,
				search: queryString.stringify({...search, p: 1})
			}
		});

		//	Front ellipsis
		if(startPage > 2){
			pages.push({
				no: -1
			});
		}

		//	Pages
		for(let i = startPage; i <= endPage; i++){
			pages.push({
				no: i,
				link: {
					pathname: path,
					search: queryString.stringify({...search, p: i})
				}
			});
		}

		//	Second ellipsis
		if(endPage < totalPages-1){
			pages.push({
				no: -2
			});
		}

		//	Final page, only if we have more than one page
		if(totalPages > 1){
			pages.push({
				no: totalPages,
				link: {
					pathname: path,
					search: queryString.stringify({...search, p: totalPages})
				}
			});
		}

		//	Previous and next links
		const prevPage = Math.max(currentPage-1, 1);
		const prevLink = {
			pathname: path,
			search: queryString.stringify({...search, p: prevPage})
		};
		const nextPage = Math.min(currentPage+1, totalPages);
		const nextLink = {
			pathname: path,
			search: queryString.stringify({...search, p: nextPage})
		};

		return (
			<Pagination className="justify-content-center">
				<PaginationItem disabled={currentPage === 1}>
					{currentPage === 1 &&
					<span className="page-link">&laquo;</span>
					}
					{currentPage !== 1 &&
					<Link to={prevLink} className="page-link" onClick={props.search.bind(null, prevPage)}>
						&laquo;
					</Link>
					}
				</PaginationItem>
				{pages.map(page => (
					<PaginationItem key={page.no} disabled={page.no < 0} active={currentPage === page.no}>
						{page.no < 0 &&
						<span className="page-link">...</span>
						}
						{page.no > 0 &&
						<Link to={page.link} className="page-link" onClick={props.search.bind(null, page.no)}>
							{page.no}
						</Link>
						}
					</PaginationItem>
				))}
				<PaginationItem disabled={currentPage === totalPages}>
					{currentPage === totalPages &&
					<span className="page-link">&raquo;</span>
					}
					{currentPage !== totalPages &&
					<Link to={nextLink} className="page-link" onClick={props.search.bind(null, nextPage)}>
						&raquo;
					</Link>
					}
				</PaginationItem>
			</Pagination>
		);
	}
};

export default withRouter(PaginationWPA);