//	eslint-disable-next-line
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import {SpinnerSmallWithText} from '@wpa/components/lib/Spinner';
import {Button, Form} from 'antd';
import {formStateMachine, FORM_STATE, FORM_ACTION, xstateOnChange} from '@wpa/state-machine';
import Notice from '@wpa/components/lib/Notice';
import SitePageBlock from './Block';
import { getAllSitePageBlocksByPage, getAllSitePageBlocksBySidebar } from '../../../reducers/db/sitePageBlocks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMachine } from '@xstate/react';

const stateMachine = formStateMachine({
	formAction: (context, event) => new Promise((resolve, reject) => {
		let blockTypeId;
		switch(event.action){
			case 'content': blockTypeId = 1; break;
			case 'book': blockTypeId = 2; break;
			case 'authorBio': blockTypeId = 3; break;
			default: reject('Unknown content block type');
		}
		context.dispatch(
			feathersServices[SERVICE.SITE_PAGE_BLOCKS]
				.create({
					pageId: event.pageId || null,
					sidebarId: event.sidebarId || null,
					name: 'block_'+ (event.blockCount+1),
					blockTypeId: blockTypeId,
				}, {})
		)
			.then(payload => {
				resolve();
			})
			.catch(err => reject('Unable to create block.'))
		;
	})
});

export const AddSiteBlocks = ({pageId, sidebarId}) => {
	const dispatch = useDispatch();
	const blocks = useSelector((state) => {
		if(sidebarId){
			return getAllSitePageBlocksBySidebar(state, sidebarId);
		}
		return getAllSitePageBlocksByPage(state, pageId);
	}) || [];

	const [state, send] = useMachine(stateMachine.withContext({
		dispatch: dispatch
	}));

	const isError = state.matches(FORM_STATE.ERROR);
	const isLoading = state.matches(FORM_STATE.LOADING);
	const isSaving = state.matches(FORM_STATE.SUBMITTING);

	const blockCount = blocks.length;

	const handleAddContentBlock = useCallback(() => {
		send(FORM_ACTION.SUBMIT, {
			action: 'content', 
			pageId,
			sidebarId,
			blockCount,
		});
	}, [send, pageId, sidebarId, blockCount]);
	
	const handleAddBookBlock = useCallback((e) => {
		e.preventDefault(); 
		
		send(FORM_ACTION.SUBMIT, {
			action: 'book', 
			pageId,
			sidebarId,
			blockCount,
		});
	}, [send, pageId, sidebarId, blockCount]);
	
	const handleAddAuthorBioBlock = useCallback((e) => {
		e.preventDefault(); 
		
		send(FORM_ACTION.SUBMIT, {
			action: 'authorBio', 
			pageId,
			sidebarId,
			blockCount,
		});
	}, [send, pageId, sidebarId, blockCount]);

	if(isLoading){
		return (
			<SpinnerSmallWithText label="Loading..." />
		);
	}

	const {
		errorMessage
	} = state.context;

	return (
		<Form onFinish={handleAddContentBlock} onChange={xstateOnChange(send)}>
			<ul className="unstyled">
				<li>
					<Button 
						loading={isSaving} 
						disabled={isSaving} 
						type="primary" 
						htmlType="submit"
					><FontAwesomeIcon icon={['fas', 'align-left']} /> Add Content Block</Button>
				</li>
				<li>
					<Button 
						loading={isSaving} 
						disabled={isSaving} 
						type="primary"
						onClick={handleAddBookBlock}
					><FontAwesomeIcon icon={['fas', 'books']} /> Add Book Block</Button>
				</li>
				<li>
					<Button 
						loading={isSaving} 
						disabled={isSaving} 
						type="primary"
						onClick={handleAddAuthorBioBlock}
					><FontAwesomeIcon icon={['fas', 'address-book']} /> Add Author Bio Block</Button>
				</li>
			</ul>
			{isError && <Notice color="danger">{errorMessage}</Notice>}
		</Form>
	);
};

AddSiteBlocks.propTypes = {
	pageId: PropTypes.number,
	sidebarId: PropTypes.number,
};


export const SitePageBlocks = ({pageId, siteId}) => {
	const blocks = useSelector((state) => getAllSitePageBlocksByPage(state, pageId)) || [];

	return blocks
		.map(block => (
			<SitePageBlock 
				key={block.id} 
				sort={block.sort}
				siteId={siteId}
				blockId={block.id}
				blockTypeId={block.blockTypeId}
				blockCount={blocks.length}
			/>
		));
};

SitePageBlocks.propTypes = {
	pageId: PropTypes.number.isRequired,
};


export const SiteSidebarBlocks = ({sidebarId}) => {
	const blocks = useSelector((state) => getAllSitePageBlocksBySidebar(state, sidebarId)) || [];

	return (
		<div>
			{blocks
				.map(block => (
					<SitePageBlock 
						key={block.id} 
						blockId={block.id} 
						blockTypeId={block.blockTypeId}
						blockCount={blocks.length}
					/>
				))
			}
		</div>
	);
};

SiteSidebarBlocks.propTypes = {
	sidebarId: PropTypes.number.isRequired,
};

export default SitePageBlocks;
