//	eslint-disable-next-line
import React from 'react';
import { useState, useEffect } from 'react';
import { Select } from 'antd';

const FontSelect = ({ onChange, value }) => {
	const [list, setList] = useState([]);

	useEffect(() => {
		//	@todo: key in config
		fetch(
			'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAjqTVHkQgAnJwyf72wwVkYX9-jOqpIUk0'
		)
			.then(res => res && res.json())
			.then(res => {
				if (res.items) {
					setList(res.items);
				}
			});
	}, []);

	// function onChange(value) {
	// 	console.log(`selected ${value}`);
	// }

	const basicFontList = [
		{ family: 'Arial' },
		{ family: 'Arial Black' },
		{ family: 'Courier' },
		{ family: 'Georgia' },
		{ family: 'Times New Roman' },
		{ family: 'Verdana' }
	];

	return (
		<div>
			<Select name="fontSelect" showSearch onChange={onChange} value={value}>
				<Select.OptGroup label="Basic Fonts">
					{basicFontList.map(d => (
						<Select.Option key={d.family} style={{ fontFamily: d.family }}>
							{d.family}
						</Select.Option>
					))}
				</Select.OptGroup>
				<Select.OptGroup label="Google Fonts">
					{list.map(d => (
						<Select.Option key={d.family}>{d.family}</Select.Option>
					))}
				</Select.OptGroup>
			</Select>
			<div className="ant-form-extra">
				For Google font previews see the{' '}
				<a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer">
					official website for Google Fonts
				</a>
				.
			</div>
		</div>
	);
};

export default FontSelect;
