import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	Input,
	Button,
	formHandleInputChange,
	offsetSizes
} from '@wpa/components/lib/Form';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { errorStateReducer } from '../../reducers';
import { getPublisherById } from '../../reducers/db/publishers';

export const STATE = {
	LOADING: 'loading',
	DISPLAY: 'display',
	ERROR: 'error',
	ERROR_LOADING: 'errorLoading'
};

export const ACTION = {
	DISPLAY: 'display',
	SAVE: 'save',
	ERROR: 'error'
};

export const stateMachine = {
	initial: STATE.LOADING,
	states: {
		[STATE.LOADING]: {
			on: {
				[ACTION.ERROR]: {
					target: STATE.ERROR_LOADING,
				},
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
					actions: ['setFields']
				}
			}
		},
		[STATE.DISPLAY]: {
			on: {
				[ACTION.SAVE]: {
					target: STATE.SAVING,
				}
			}
		},
		[STATE.SAVING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR,
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {}
		},
		[STATE.ERROR_LOADING]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {}
		}
	}
};

// const stateReducers = [
// 	errorStateReducer.reducer
// (action, event) => {
// 	switch(action){
// 		case 'setFields':
// 			return {
// 				showCover: event.showCover,
// 				showTitle: event.showTitle,
// 				showSeriesName: event.showSeriesName,
// 				showAuthorName: event.showAuthorName,
// 				showBlurb: event.showBlurb,
// 				showExtendedBlurb: event.showExtendedBlurb,
// 				showPubDate: event.showPubDate,
// 				showPurchaseLinks: event.showPurchaseLinks,
// 				bookLimit: event.bookLimit,
// 				bookOrder: event.bookOrder,
// 				coverStyle: event.coverStyle,
// 			};

// 		//	No Change
// 		default:
// 			return {};
// 	}
// }
// ];

export class PublisherDetailsForm extends React.Component {
	static displayName = 'PublisherDetailsForm';
	static propTypes = {
		publisherId: PropTypes.number
	};
	static defaultProps = {};

	constructor(props) {
		super(props);

		this.handleInputChange = formHandleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			name: props.publisher.name || ''
		};
	}

	handleSubmit(e) {
		e.preventDefault();

		const self = this;

		//	@todo: Make all these optional if not changed value?
		self.props
			.dispatch(
				feathersServices[SERVICE.PUBLISHERS].patch(
					this.props.publisherId,
					{
						name: this.state.name
					},
					{}
				)
			)
			.catch(err => self.setState({ errorMessage: err.message }));
	}

	render() {
		const { name } = this.state;

		const {
			publisher,
			//	eslint-disable-next-line no-unused-vars
			...props
		} = this.props;

		const disabled = !publisher.User || !publisher.User.canEditDetails;

		return (
			<Form
				onChange={this.handleInputChange}
				id="publisherDetailsForm"
				onSubmit={this.handleSubmit}
				className="grid-form"
			>
				<FormGroup row>
					<Label md={4} lg={3} for="publisherDetailsForm-name">
						Name
					</Label>
					<Col md={8} lg={9}>
						<Input
							type="text"
							name="name"
							id="publisherDetailsForm-name"
							defaultValue={publisher.name || ''}
							value={name}
							disabled={disabled}
						/>
					</Col>
				</FormGroup>
				{!disabled && (
					<FormGroup row>
						<Col {...offsetSizes}>
							<Button type="primary" htmlType="submit">Update</Button>
						</Col>
					</FormGroup>
				)}
			</Form>
		);
	}
}

export default connect((state, props) => ({
	publisher: getPublisherById(state, props.publisherId),
	//	@todo: Figure out why this hack is needed for the refresh?!
	publisherName: state.db.publishers.byId[props.publisherId].name
}))(PublisherDetailsForm);
