import {
	TOGGLE_BOOK_FILTER_AUTHOR,
	TOGGLE_BOOK_FILTER_STATUS,
	UPDATE_BOOK_FILTER_AUTHORS,
	UPDATE_BOOK_FILTER_STATUSES
} from './reducers/page/books';
import {ADD_AUTHORS} from './reducers/db/authors';

/*
 * Action creators
 */

export const updateBookFilterStatuses = (payload) => ({
	type: UPDATE_BOOK_FILTER_STATUSES,
	payload: payload
});
export const toggleBookFilterStatus = (payload) => ({
	type: TOGGLE_BOOK_FILTER_STATUS,
	payload: payload
});


export const updateBookFilterAuthors = (payload) => ({
	type: UPDATE_BOOK_FILTER_AUTHORS,
	payload: payload
});
export const toggleBookFilterAuthor = (payload) => ({
	type: TOGGLE_BOOK_FILTER_AUTHOR,
	payload: payload
});


export const addAuthors = (books) => ({
	type: ADD_AUTHORS,
	payload: books
});
