import React from 'react';
import { connect } from 'react-redux';
import {
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
	Col
} from 'reactstrap';
import { Input, Button, formHandleInputChange } from '@wpa/components/lib/Form';
import withStateMachine from '../../functional/withStateMachine';
import feathersAPI from '@wpa/feathers-client';
import can from '../../abilities';
import Notice from '@wpa/components/lib/Notice';
import { getAllPublishers } from '../../reducers/db/publishers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InviteUserButton = withStateMachine(
	class extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				senderName: props.store.currentUser.name,
				inviteEmail: '',
				inviteName: '',
				publishers: {}
			};

			this.handleModalClick = this.handleModalClick.bind(this);
			this.handleInviteClick = this.handleInviteClick.bind(this);
			this.handleInviteSend = this.handleInviteSend.bind(this);
			this.handleInputChange = formHandleInputChange.bind(this);
		}

		handleModalClick() {
			//	@todo: check if we need an actual toggle functionality
			this.props.goToState('display');
		}

		handleInviteClick() {
			this.props.goToState('prepare');
		}

		handleInviteSend(event) {
			event.preventDefault();

			//	Reseet the error status
			this.props.goToState('sending', {}, () => {
				//	Authenticate
				feathersAPI
					.service('users')
					.create({
						email: this.state.inviteEmail,
						password: 'P' + new Date().getTime() + Math.random() * 100,
						name: this.state.inviteName,
						notifierOptions: {
							url:
								window.location.protocol +
								'//' +
								window.location.host +
								'/login/verify/:token',
							// senderName: this.state.senderName
						},
						invitedByName: this.state.senderName,
						publishers: Object.keys(this.state.publishers) || []
					})
					.then(json => {
						this.setState({
							inviteName: '',
							inviteEmail: ''
						});
						this.props.goToState('sent', {}, () => {});
					})
					.catch(err => {
						this.props.goToState('error', { message: err.message });
						//	@todo: pass error to bugsnug
					});
			});
		}

		render() {
			const props = this.props;
			const {
				error,
				errorMessage,
				showModal,
				inviteSending,
				inviteSent
			} = props.stateMachine;

			const { publishers } = this.props;

			if (!can.inviteUsers(props.store.currentUser)) {
				return null;
			}

			return (
				<div style={props.style}>
					<button className="btn btn-primary" onClick={this.handleInviteClick}>
						<i className="fas fa-heart" /> invite
					</button>
					<Modal
						isOpen={showModal}
						toggle={this.handleModalClick}
						autoFocus={false}
						fade={false}
					>
						<ModalHeader toggle={this.handleModalClick}>
							Share the &nbsp; <i className="fas fa-heart" />
						</ModalHeader>
						<ModalBody>
							<Form onSubmit={this.handleInviteSend} id="sendInviteForm">
								<FormGroup row>
									<Label sm={5} for="senderName">
										Who is the invite from?
									</Label>
									<Col sm={7}>
										<InputGroup>
											<InputGroupText>
												<FontAwesomeIcon icon={['far', 'user']} fixedWidth />
											</InputGroupText>
											<Input
												name="senderName"
												id="senderName"
												type="text"
												onChange={this.handleInputChange}
												value={this.state.senderName}
											/>
										</InputGroup>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label tag="div" xs={12}>
										Who is the invite for?
									</Label>
									<Col sm={5}>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<FontAwesomeIcon icon={['far', 'user']} fixedWidth />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												name="inviteName"
												type="text"
												placeholder="Jane Doe"
												onChange={this.handleInputChange}
												value={this.state.inviteName}
											/>
										</InputGroup>
									</Col>
									<Col sm={7}>
										<InputGroup>
											<InputGroupText>
												<FontAwesomeIcon icon={['far', 'envelope']} fixedWidth />
											</InputGroupText>
											<Input
												name="inviteEmail"
												type="text"
												autoComplete="off"
												onChange={this.handleInputChange}
												value={this.state.inviteEmail}
												placeholder="joe@example.com"
											/>
										</InputGroup>
									</Col>
								</FormGroup>

								<FormGroup>
									<legend>Add to publisher?</legend>
									{publishers.map(publisher => (
										<FormGroup check key={publisher.id}>
											<Label check>
												<Input
													type="checkbox"
													name={'publishers[]'}
													defaultValue={''}
													value={publisher.id}
													onChange={this.handleInputChange}
												/>{' '}
												{publisher.name}
											</Label>
										</FormGroup>
									))}
								</FormGroup>
								{error && (
									<Row>
										<Col>
											<Notice color="danger">{errorMessage}</Notice>
										</Col>
									</Row>
								)}
								{inviteSent && (
									<Notice color="success">Invitation sent!</Notice>
								)}
								<FormGroup>
									<Button type="primary" htmlType="submit" loading={inviteSending}>
										Invite
									</Button>
								</FormGroup>
							</Form>
						</ModalBody>
					</Modal>
				</div>
			);
		}
	},
	{
		defaultName: 'display',
		generate: (stateName, stateParams, previousState) => {
			switch (stateName) {
				case 'prepare':
					return {
						inviteSending: false,
						inviteSent: false,
						showModal: true,
						error: false,
						errorMessage: ''
					};
				case 'sending':
					return {
						inviteSending: true,
						inviteSent: false,
						showModal: true,
						error: false,
						errorMessage: ''
					};
				case 'sent':
					return {
						inviteSending: false,
						inviteSent: true,
						showModal: true,
						error: false,
						errorMessage: ''
					};
				case 'error':
					return {
						inviteSending: false,
						inviteSent: false,
						showModal: true,
						error: true,
						errorMessage: stateParams.message || 'Error'
					};
				case 'display':
				default:
					return {
						inviteSending: false,
						inviteSent: false,
						showModal: false,
						error: false,
						errorMessage: ''
					};
			}
		}
	}
);

export default connect(state => ({
	store: state,
	publishers: getAllPublishers(state) || []
}))(InviteUserButton);
