import React from 'react';
// import {Link, withRouter} from 'react-router-dom';
import {
	// Card, CardBlock, CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle, 
	Col, Row,
} from 'reactstrap';
import { connect, useSelector } from 'react-redux';

import PageHeader from '../components/PageHeader';

import BookCard from '../components/books/Card';
import BookFilter from '../components/books/Filter';
import {ButtonModal} from '@wpa/components/lib/Form';
import BookImport from '../components/books/Import';
import can from '../abilities';
import BooksAddForm from '../components/books/Add';
import {getAllBooks} from '../reducers/db/books';
import {withAuthorIds} from '../reducers/db/authorBooks';
import BookAddSales from '../components/books/AddSales';
import {getAllOfferedResourcesByType, RESOURCE_INVITES_STATUS} from '../reducers/db/resourceInvites';
// import AuthorLink from '../components/author/Link';
// import {imagePath} from '../utils/imagePath';
import BookOfferedCard from '../components/books/OfferedCard';
import {Layout} from 'antd';
import { getCurrentUser } from '../reducers/currentUser';
const {Content} = Layout;
//
// class BooksPageBase extends React.Component {
// 	render(){
// 		const props = this.props;
//
// 		const multiAuthor = props.store.db.authors.allIds.length > 1;
// 		const hasAuthors = props.store.db.authors.allIds.length > 0;
//
// 		const filter = props.store.pages.books.filter;
//
// 		return (
// 			<div>
// 				<Row>
// 				{props.store.db.books.allIds.map(bookId =>{
// 					const book = props.store.db.books.byId[bookId];
//
// 					//	Is this status visible?
// 					if(! filter.statuses[book.statusId]){
// 						return;
// 					}
//
// 					if(hasAuthors && props.store.db.authorBooks.byBookId[book.id]){
// 						//	Is the author selected
// 						const filteredAuthors = props.store.db.authorBooks.byBookId[book.id].filter(authorId => filter.authors[authorId]);
// 						if(! filteredAuthors.length){
// 							return;
// 						}
// 					}
//
// 					return (
// 						<Col xs="6" md="4" lg="3" xl="2" key={bookId}>
// 							<BookCard book={book} showAuthor={multiAuthor} />
// 						</Col>
// 					)
// 				})}
// 				</Row>
// 			</div>
// 		)
// 	}
// }
//
// const BooksPage = connect(state => ({store: state}))(BooksPageBase);


export const Page = ({books, filter, ...props}) =>{

	const currentUser = useSelector(state => getCurrentUser(state));
	
	//	Check if we have any books
	const hasBooks = props.hasBooks || props.offeredBooks.length > 0;

	const canImport = can.importAmazonBooks(currentUser);

	return (
		<Content id="bookPage">
			<PageHeader title="Books">
				<ButtonModal
					isOpen={props.match.path === '/books/add-sales'}
					title="Add Sales"
					modal={<BookAddSales />}
				>Add Sales</ButtonModal>
				<ButtonModal
					isOpen={props.match.path === '/books/add'}
					title="Add Book"
					modal={<BooksAddForm />}
				>Add Book</ButtonModal>
				{canImport &&
				<ButtonModal
					isOpen={props.match.path === '/books/import'}
					title="Import Books"
					modal={<BookImport />}
				>Import Books</ButtonModal>}
			</PageHeader>
			{!hasBooks &&
			<div>
				No books here yet.
			</div>
			}
			{hasBooks &&
			<Row>
				<Col>
					<BookFilter />
				</Col>
			</Row>}
			{hasBooks &&
			<Row>
				{props.offeredBooks
					.filter(offer => offer.statusId === RESOURCE_INVITES_STATUS.OFFERED)
					.map(offer => {

					const book = offer.resource;

					return (
						<Col xs="6" md="4" lg="3" xl="2" key={offer.id}>
							<BookOfferedCard
								book={offer.resource}
								showAuthor={false}
								note={offer.note}
								senderName={offer.offeringUserName}
								offerId={offer.id}
								key={"offered_card_"+offer.id}
							/>
							<BookCard
								book={book}
								showAuthors={false}
								showActions={false}
								key={"offered_book_"+offer.id}
							/>
						</Col>
					);
				})}
				{books.map(book =>{

					// //	Is this status visible?
					if(! filter.statuses[book.statusId]){
						return null;
					}

					if(book.authorIds.length > 0){
						//	Is the author selected
						const filteredAuthors = book.authorIds.filter(authorId => filter.authors[authorId]);
						if(! filteredAuthors.length){
							return null;
						}
					}

					return (
						<Col xs="6" md="4" lg="3" xl="2" key={book.id}>
							<BookCard
								book={book}
								showAuthors={props.multiAuthor}
								key={"book_"+book.id}
							/>
						</Col>
					);
				})}
			</Row>}
		</Content>
	);
};

export default connect(state => ({
	//	Do we have any books in the system
	hasBooks: state.db.books.allIds.length > 0,
	//	Books to be displayed - filter: 'bookList'
	//	@todo: reducer getter
	books: withAuthorIds(state, getAllBooks(state)),
	offeredBooks: getAllOfferedResourcesByType(state, 'book') || [],
	//	Do we have more than one author int he system
	multiAuthor: state.db.authors.allIds.length > 1,
	filter: state.pages.books.filter,
}))(Page);
