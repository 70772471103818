import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { xstateFormHandleInputChange } from '@wpa/state-machine';
import ImageList from './ImageList';
import Logger from '@wpa/logger';
import { SpinnerSmallWithText } from '@wpa/components/lib/Spinner';
import Modal from 'antd/lib/modal/Modal';
import { Row, Col } from 'antd';
import { useMachine } from '@xstate/react';
import { Machine } from 'xstate';
import { useDispatch } from 'react-redux';
import { Upload, Input, Button, FormAlert } from '@wpa/components/lib/Form';
import { assign } from 'xstate';
import { feathersServices } from '@wpa/feathers-client';
import { SERVICE } from '@wpa/feathers-client';
import { setErrorData } from '@wpa/state-machine/src/state-machine';

export const STATE = {
	LOADING: 'loading',
	DISPLAY: 'display',
	PREVIEW: 'preview',
	SELECTED: 'selected',
	ERROR: 'error',
	CLOSED: 'closed',
	SAVING: 'saving',
	SAVING_UPLOAD: 'savingUpload',
	UPDATING: 'updating',
};

export const EVENT = {
	ERROR: 'error',
	LOADED: 'loaded',
	IMAGE_SELECTED: 'imageSelected',
	OPEN: 'open',
	CLOSE: 'close',
	PREVIEW: 'preview',
	UPDATE: 'update',
	SAVE: 'save',
};

const ACTION = {
	CLOSE_MODAL: 'closeModal',
	SET_ERROR_DATA: 'setErrorData',
	IMAGE_SELECTED: 'imageSelected',
	FILE: 'file',
	UPDATE_FORM_ELEMENT: 'updateFormElement',
};

export const stateMachine = Machine(
	{
		initial: STATE.CLOSED,
		states: {
			[STATE.CLOSED]: {
				onEntry: [ACTION.CLOSE_MODAL],
				on: {
					[EVENT.OPEN]: {
						target: STATE.LOADING,
					},
				},
			},
			[STATE.LOADING]: {
				invoke: {
					src: (context, event) => {
						return context.getImages 
							? context.getImages()
							: context.getDispatchImages 
								? context.dispatch(context.getDispatchImages()) 
								: new Promise(r => r())
						;
					},
					onDone: {
						target: STATE.DISPLAY,
						actions: assign({
							imageList: (context, event) => {
								if(! context.getImages && !context.getDispatchImages){
									return [];
								}
								
								if(context.getImages){
									if(event.data && event.data.data){
										return event.data.data;
									}

									return event.data;
								}

								if (
									!(
										event.data &&
										event.data.value &&
										event.data.value.data
									)
								) {
									Logger.error(
										'Unexpected data format returned' +
											JSON.stringify(event.data)
									);
									throw new Error(
										'Unexpected data format returned'
									);
								}
								return event.data.value.data;
							},
						}),
					},
					onError: {
						target: STATE.ERROR,
						actions: [ACTION.SET_ERROR_DATA],
					},
				},
			},
			[STATE.DISPLAY]: {
				on: {
					[EVENT.IMAGE_SELECTED]: {
						target: STATE.SELECTED,
						actions: [ACTION.IMAGE_SELECTED],
					},
					[EVENT.PREVIEW]: {
						target: STATE.PREVIEW,
						actions: [ACTION.IMAGE_SELECTED, ACTION.FILE],
					},
					[EVENT.UPDATE]: {
						target: STATE.DISPLAY,
						actions: ACTION.UPDATE_FORM_ELEMENT,
					},
					[EVENT.CLOSE]: {
						target: STATE.CLOSED,
					},
				},
			},
			[STATE.PREVIEW]: {
				on: {
					[EVENT.PREVIEW]: {
						target: STATE.PREVIEW,
						actions: [ACTION.IMAGE_SELECTED, ACTION.FILE],
					},
					[EVENT.IMAGE_SELECTED]: {
						target: STATE.SELECTED,
						actions: [ACTION.IMAGE_SELECTED],
					},
					[EVENT.UPDATE]: {
						target: STATE.PREVIEW,
						actions: ACTION.UPDATE_FORM_ELEMENT,
					},
					[EVENT.SAVE]: {
						target: STATE.SAVING_UPLOAD,
					},
					[EVENT.CLOSE]: {
						target: STATE.CLOSED,
					},
				},
			},
			[STATE.SELECTED]: {
				on: {
					[EVENT.IMAGE_SELECTED]: {
						target: STATE.SELECTED,
						actions: [ACTION.IMAGE_SELECTED],
					},
					[EVENT.UPDATE]: {
						target: STATE.SELECTED,
						actions: ACTION.UPDATE_FORM_ELEMENT,
					},
					[EVENT.PREVIEW]: {
						target: STATE.PREVIEW,
						actions: [ACTION.IMAGE_SELECTED, ACTION.FILE],
					},
					[EVENT.SAVE]: {
						target: STATE.SAVING,
					},
					[EVENT.CLOSE]: {
						target: STATE.CLOSED,
					},
				},
			},
			[STATE.SAVING]: {
				invoke: {
					src: (context, event) => {
						return context.dispatch(
							feathersServices[context.selectedService].patch(
								null,
								context.selectedParams,
								{
									query: {
										...context.selectedQuery,
										imageId: context.selectedImageId,
									},
								}
							)
						);
					},
					onDone: {
						target: STATE.UPDATING,
					},
					onError: {
						target: STATE.ERROR,
						actions: [ACTION.SET_ERROR_DATA],
					},
				},
			},
			[STATE.SAVING_UPLOAD]: {
				invoke: {
					src: (context, event) => {
						return context.dispatch(
							feathersServices[SERVICE.UPLOADS].create({
								uri: context.file.imagePreviewUrl,
								name: context.file.name,
								title: context.fields.imageTitle,
								type: context.uploadType,
								resource: context.uploadResource,
							})
						);
					},
					onDone: {
						target: STATE.UPDATING,
						actions: [
							assign((context, event) => ({
								selectedImageId: event.data && event.data.value && event.data.value.item && event.data.value.item.id
							}))
						]
					},
					onError: {
						target: STATE.ERROR,
						actions: [
							ACTION.SET_ERROR_DATA,
							assign((context, event) => {
								const error = event.data;
								
								if (
									error.className &&
									error.className === 'timeout'
								) {
									return {
										errorMessage:
											'Upload exceeded timelimit. It might have completed - please, reload the page to check.',
									};
								}

								return {
									errorMessage: "Couldn't upload the selected image. Please try again."
								}
							}),
						],
					},
				},
			},
			[STATE.UPDATING]: {
				invoke: {
					src: (context, event) => context.onImageSave ? context.onImageSave(context.selectedImageId) : new Promise(resolve => resolve()),
					onDone: {
						target: STATE.CLOSED,
					},
					onError: {
						target: STATE.ERROR,
						actions: [ACTION.SET_ERROR_DATA],
					},
				}
			},
			[STATE.ERROR]: {
				onExit: assign({ error: false, errorMessage: null }),
				on: {
					[EVENT.CLOSE]: [STATE.CLOSED],
				},
			},
		},
	},
	{
		actions: {
			[ACTION.CLOSE_MODAL]: (context, event) => context.onClose && context.onClose(context.selectedImageId),
			[ACTION.UPDATE_FORM_ELEMENT]: assign((context, event) => {
				return {
					...context,
					...event,
				};
			}),
			[ACTION.IMAGE_SELECTED]: assign((context, event) => ({
				selectedImageId: event.imageId,
				fields: {
					imageTitle: event.imageTitle,
				},
			})),
			[ACTION.FILE]: assign((context, event) => ({
				file: event.file,
			})),
			[ACTION.SET_ERROR_DATA]: setErrorData,
		},
	}
);

export const ImageEdit = ({
	title = 'Select Image',
	isOpen,
	onClose,
	getImages,
	getDispatchImages,
	onImageSave,

	selectedService,
	selectedParams,
	selectedQuery,

	uploadType,
	uploadResource,

	...props
}) => {
	const dispatch = useDispatch();
	const [state, send] = useMachine(
		stateMachine.withContext({
			dispatch,
			onClose,
			onImageSave,
			selectedService,
			selectedParams,
			selectedQuery,
			uploadType,
			uploadResource,

			getImages,
			getDispatchImages,
			imageList: [],
			selectedImageId: null,
			file: null,
			fields: {
				imageTitle: '',
			},

			myStateName: '',
			errorMessage: '',
			currentState: '',
		})
	);

	const {
		errorMessage,
		selectedImageId,
		imageList,
		fields: { imageTitle },
	} = state.context;

	const isLoading = state.matches(STATE.LOADING);
	const isImageSelected = state.matches(STATE.SELECTED);
	const isPreview = state.matches(STATE.PREVIEW);
	const isSaving = state.matches(STATE.SAVING);
	const isError = state.matches(STATE.ERROR);

	//	When the modal is opened send the open event
	//	Need to generate an event from a prop change for the state machine
	useEffect(() => {
		if (isOpen) {
			send(EVENT.OPEN);
		}
	}, [send, isOpen]);

	const selectImage = useCallback(
		(imageId) => {
			const image = imageList.find((i) => i.imageId === imageId);
			send(EVENT.IMAGE_SELECTED, {
				imageId: imageId,
				imageTitle: image ? image.Image.title : '',
			});
		},
		[send, imageList]
	);

	const handleUpload = useCallback(
		(file) => send(EVENT.PREVIEW, { file: file }),
		[send]
	);

	const onCancel = useCallback(() => {
		send(EVENT.CLOSE);
	}, [send]);

	const onClick = useCallback(() => {
		send(EVENT.SAVE)
	}, [send])

	const footerComponents = [];

	if (isError) {
		footerComponents.push(
			<Row key="error">
				<Col span={24} className="text-left">
					<FormAlert send={send} description={errorMessage} />
				</Col>
			</Row>
		);
	}

	footerComponents.push(
		<Input.Group key="form">
			<Row gutter={8}>
				<Col flex="auto">
					<Input
						disabled={isImageSelected}
						placeholder="Image title"
						name="imageTitle"
						value={imageTitle}
						onChange={(e) =>
							send(EVENT.UPDATE, xstateFormHandleInputChange(e))
						}
					/>
				</Col>
				<Col>
					<Button
						htmlType="submit"
						type="primary"
						onClick={onClick}
						disabled={!(isImageSelected || isPreview || isSaving)}
						loading={isSaving}
					>
						{!isPreview && 'Save'}
						{isPreview && 'Upload & Save'}
					</Button>
				</Col>
			</Row>
		</Input.Group>
	);

	return (
		<Modal
			width="80%"
			title={title}
			visible={isOpen}
			className={props.className + ' imageEdit'}
			onCancel={onCancel}
			showUploadList={false}
			footer={footerComponents}
		>
			<Row gutter={16} style={{ flexWrap: 'nowrap' }}>
				<Col flex="200px">
					<Upload handleUpload={handleUpload}></Upload>
					{selectedImageId}
				</Col>
				<Col flex="auto">
					{isLoading && (
						<SpinnerSmallWithText label={'Loading image list'} />
					)}
					{!isLoading && (
						<ImageList
							images={imageList}
							onSelect={selectImage}
							selectedImageId={selectedImageId}
						/>
					)}
				</Col>
			</Row>
		</Modal>
	);
};

ImageEdit.propTypes = {
	/** Modal title */
	title: PropTypes.string,
	/** Modal state */
	isOpen: PropTypes.bool.isRequired,
	/** Function to call when the modal close event occurs - this needs to change the isOpen prop of the parent component */
	onClose: PropTypes.func.isRequired,
	/** Function that returns (Promise) list of existing images to choose from  */
	getImages: PropTypes.func,
	/** Function that returns (Promise) list of existing images to choose from eecuted through dispatch()  */
	getDispatchImages: PropTypes.func,
	/** onImageSave(selectedImageId) Function to execute (Promise) when a new image is selected / uploaded */
	onImageSave: PropTypes.func,
};


export default ImageEdit;
