//	eslint-disable-next-line
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, formItemLayout , FormAlert} from '@wpa/components/lib/Form';
import { SERVICE, feathersServices } from '@wpa/feathers-client';
import { useMachine } from '@xstate/react';
import {
	formStateMachine,
	FORM_STATE,
	FORM_ACTION,
	xstateFormHandleInputChange
} from '@wpa/state-machine';
import { Form, Input } from 'antd';
import Logger from '@wpa/logger';

const m = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context.action = event.action;

			//	Nothing to remove
			if (event.action === 'delete' && !event.socialMediaId) {
				return resolve();
			}

			//	Remove existing record
			if (event.action === 'delete') {
				return context
					.dispatch(
						feathersServices[SERVICE.SOCIAL_MEDIAS].remove(
							event.socialMediaId,
							{
								url: context.value
							},
							{}
						)
					)
					.then(res => {
						context.value = '';
						resolve();
					})
					.catch(
						err =>
							Logger.error(err) ||
							reject('Unable to ' + event.action + ' content.')
					);
			}

			if (event.socialMediaId) {
				context
					.dispatch(
						feathersServices[SERVICE.SOCIAL_MEDIAS].patch(
							event.socialMediaId,
							{
								url: context.value
							},
							{}
						)
					)
					.then(res => {
						resolve();
					})
					.catch(
						err =>
							Logger.error(err) ||
							reject('Unable to ' + event.action + ' content.')
					);
			} else {
				context
					.dispatch(
						feathersServices[SERVICE.SOCIAL_MEDIAS].create(
							{
								resourceId: event.resourceId,
								resourceTypeId: event.resourceTypeId,
								typeId: event.typeId,
								url: context.value
							},
							{}
						)
					)
					.then(res => {
						resolve();
					})
					.catch(
						err =>
							Logger.error(err) ||
							reject('Unable to ' + event.action + ' content.')
					);
			}
		})
});

export const SocialMedia = ({
	socialMediaId,
	resourceTypeId,
	resourceId,
	typeId,
	label,
	icon,
	placeholder,
	value: defaultValue,
	labelSizes,
	colSizes
}) => {
	const dispatch = useDispatch();

	const [state, send] = useMachine(
		m.withContext({
			value: defaultValue,
			dispatch: dispatch,
			action: null
		})
	);

	const { value, errorMessage, action } = state.context;

	const handleSubmit = useCallback(
		() => {

			send(FORM_ACTION.SUBMIT, {
				action: 'save',
				socialMediaId,
				typeId,
				resourceId,
				resourceTypeId,
			});
		},
		[send, socialMediaId, typeId, resourceTypeId, resourceId]
	);

	const handleRemove = useCallback(
		() => {

			send(FORM_ACTION.SUBMIT, {
				action: 'delete',
				socialMediaId,
				typeId,
				resourceId,
				resourceTypeId,
			});
		},
		[send, socialMediaId, typeId, resourceTypeId, resourceId]
	);

	const handleChange = useCallback(
		e => {
			//	Adjust name so we don't deal with the unique name
			e.target.name = 'value';
			send(FORM_ACTION.CHANGE, xstateFormHandleInputChange(e));
		},
		[send]
	);

	const isSaving = state.matches(FORM_STATE.SUBMITTING);
	const isError = state.matches(FORM_STATE.ERROR);

	return (
		<Form layout="horizontal" onChange={handleChange} onFinish={handleSubmit}>
			<Form.Item
				label={
					<span>
						{label}{' '}
						{icon && <FontAwesomeIcon icon={icon} fixedWidth size="lg" />}
					</span>
				}
				htmlFor={'value_' + typeId}
				{...formItemLayout}
			>
				<Input.Group compact>
					<Input
						placeholder={placeholder}
						value={value}
						id={'value_' + typeId}
						name={'value_' + typeId}
						style={{ width: '50%' }}
					/>
					<Button
						type="primary"
						htmlType="submit"
						loading={isSaving && action === 'save'}
						disabled={isSaving}
					>
						Save
					</Button>
					<Button
						type="secondary"
						onClick={handleRemove}
						loading={isSaving && action === 'delete'}
						disabled={isSaving}
					>
						Delete
					</Button>
				</Input.Group>
			</Form.Item>
			{isError && <FormAlert send={send} description={errorMessage} />}
		</Form>
	);
};

SocialMedia.propTypes = {
	resourceTypeId: PropTypes.number,
	resourceId: PropTypes.number,
	typeId: PropTypes.number,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	icon: PropTypes.array
};
SocialMedia.defaultProps = {};

export default SocialMedia;
