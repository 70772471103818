import {createReducer} from '../helpers';
// import {ADD_BOOK_ROYALTIES} from './bookRoyalties';

/*
 *	Constants
 */

const serviceName = 'BOOK_EXTERNAL_ASSETS';
export const ADD_BOOK_EXTERNAL_ASSETS = 'ADD_'+ serviceName;
export const UPDATE_BOOK_EXTERNAL_ASSETS = 'UPDATE_'+ serviceName;
export const REMOVE_BOOK_EXTERNAL_ASSETS = 'REMOVE_'+ serviceName;

export const ASSET_GDRIVE = 1;
export const ASSET_DROPBOX = 2;
export const ASSET_ONEDRIVE = 3;

/*
 *	Helper functions
 */

export function getExternalAssetsByBookIdAsMapByTypeId(state, bookId){
	const stateSlice = state.db.bookExternalAssets;

	const map = {};
	(stateSlice.allIds || [])
		.filter(itemId => stateSlice.byId[itemId].bookId === bookId)
		.forEach(itemId => {
			map[stateSlice.byId[itemId].typeId] = stateSlice.byId[itemId];
		});

	return map;
}

export function getExternalAssetByBookIdAndTypeId(state, bookId, typeId){
	const stateSlice = state.db.bookExternalAssets;

	const assetId = (stateSlice.allIds || [])
		.filter(itemId => stateSlice.byId[itemId].bookId === bookId && stateSlice.byId[itemId].typeId === typeId)
		.pop()
		;

	return assetId ? stateSlice.byId[assetId] : assetId;
}

// export function getBookPublishersByBookId(state, bookId){
// 	const stateSlice = state.db.bookPublishers;
// 	const itemIds = getItemIdsByParam(stateSlice, 'bookId', bookId);
//
// 	const bpMap = {};
// 	itemIds.forEach(itemId => {
// 		const bp = stateSlice.byId[itemId];
// 		//	We map to publisher ID not the bookPublisher ID
// 		bpMap[bp.publisherId] = {
// 			...bp,
// 			Publisher: state.db.publishers.byId[bp.publisherId] || { name: 'Publisher N/A' }
// 		};
// 	});
//
// 	return bpMap;
// }
//
// export function getBookPublishersOnlyByBookId(state, bookId){
// 	const stateSlice = state.db.bookPublishers;
// 	const itemIds = getItemIdsByParam(stateSlice, 'bookId', bookId);
//
// 	return itemIds.map(itemId => {
// 		const bp = stateSlice.byId[itemId];
// 		return bp;
// 	});
// }
//
// export function getBookStoreIdsByType(state, type){
// 	const stateSlice = state.db.bookPublishers;
//
// 	const ids = [];
// 	stateSlice.allIds.forEach(itemId => {
// 		const item = stateSlice.byId[itemId];
// 		if(item[type]){
// 			ids.push(item[type]);
// 		}
// 	});
//
// 	return ids;
// }

export const addBookExternalAssets = (payload) => ({
	type: ADD_BOOK_EXTERNAL_ASSETS,
	payload: payload
});


// /**
//  * Book External Assets Reducer
//  * @param state
//  * @param action
//  * @returns {*}
//  */
// export const bookExternalAssetsReducer = (state = {
// 	initialState: true,
// 	byId: {},
// 	allIds: [],
// }, action) => {
// 	let newState, payload;

// 	switch (action.type) {
// 		case feathersServices.getFindFulfilledAction(SERVICE.BOOK_EXTERNAL_ASSETS):
// 			payload = action.payload.data || [];

// 			//	Fallthrough as the action is the same

// 		case ADD_BOOK_EXTERNAL_ASSETS:
// 			newState = {...state};
// 			newState.initialState = false;

// 			payload = payload || action.payload;

// 			addItems(newState, payload);

// 			return newState;

// 		default:
// 			return state;
// 	}
// };

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);