'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _bugsnagReact = require('@wpa/bugsnag-react');

var DefaultLogger = require('js-logger');

var Logger = DefaultLogger;

Logger.useDefaults({
	defaultLevel: Logger.INFO,
	formatter: function formatter(messages, context) {
		messages.unshift(new Date().toUTCString());
	}
});

var consoleHandler = Logger.createDefaultHandler();
var bugsnagHandler = function bugsnagHandler(messages, context) {
	//	Only send things beyond INFO to bugsnag
	if (context.level.value > Logger.INFO.value) {
		//	@todo: make this optional
		_bugsnagReact.bugsnagClient.notify(messages[1] || messages[0] || 'No error message');
	}
};

Logger.setHandler(function (messages, context) {
	consoleHandler(messages, context);
	//	Only forward to bugsnag on live
	if (process.env.NODE_ENV !== 'development') {
		bugsnagHandler(messages, context);
	}
});

exports.default = Logger;