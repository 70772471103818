//  eslint-disable-next-line
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	xstateOnChange,
	FORM_ACTION,
	FORM_STATE,
	formStateMachine
} from '@wpa/state-machine';
import { useMachine } from '@xstate/react';
import { getLatestSitePageContentForBlockId } from '../../../../reducers/db/sitePageContents';
import Logger from '@wpa/logger';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { Form } from 'antd';
// import Button from '@wpa/components/lib/Form/button';
import { RichTextArea } from '@wpa/components/lib/RichTextArea';
import { FormAlert, Button } from '@wpa/components/lib/Form';

const m = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.SITE_PAGE_CONTENTS].create({
						content: context.content,
						blockId: event.blockId,
						pageId: event.pageId,
						publishFrom: event.action === 'publish' ? new Date() : null
					})
				)
				.then(res => {
					context.defaultContent = context.content;
					resolve();
				})
				.catch(
					err =>
						Logger.error(err) ||
						reject('Unable to ' + event.action + ' content.')
				);
		})
});

export const SitePageBlockContentForm = ({ siteId, blockId }) => {
	const dispatch = useDispatch();

	//	@todo: issue with entries without blockId screwing something here?
	const contentBlock =
		useSelector(state => getLatestSitePageContentForBlockId(state, blockId)) ||
		{};

	const [state, send] = useMachine(
		m.withContext({
			content: contentBlock.content,
			defaultContent: contentBlock.content,
			blockId: blockId,
			dispatch: dispatch
		})
	);

	const { content, defaultContent, errorMessage, action } = state.context;

	const isError = state.matches(FORM_STATE.ERROR);
	const isSaving = state.matches(FORM_STATE.SUBMITTING) && action === 'save';
	const isPublishing =
		state.matches(FORM_STATE.SUBMITTING) && action === 'publish';
	const isDisabled = isSaving || isPublishing;

	const isPublished = contentBlock.publishFrom && content === defaultContent;

	const handleSave = useCallback(
		() => {
			send(FORM_ACTION.SUBMIT, {
				action: 'save',
				blockId: blockId,
				pageId: contentBlock.pageId
			});
		},
		[send, blockId, contentBlock]
	);

	const handlePublish = useCallback(
		e => {
			e.preventDefault();

			send(FORM_ACTION.SUBMIT, {
				action: 'publish',
				blockId: blockId,
				pageId: contentBlock.pageId
			});
		},
		[send, blockId, contentBlock]
	);

	return (
		<Form onFinish={handleSave} onChange={xstateOnChange(send)}>
			<div className="float-right">
				<Button
					htmlType="submit"
					type="primary"
					isLoading={isSaving}
					disabled={isDisabled}
				>
					Save
				</Button>
				<Button
					onClick={handlePublish}
					isLoading={isPublishing}
					disabled={isDisabled}
					type="secondary"
				>
					Publish
				</Button>
			</div>
			<label htmlFor={'content_' + blockId}>Page Content</label>
			<p>
				<i>
					<small>
						{isPublished && 'Currently viewing published content'}
						{!isPublished && 'Unpublished content'}
					</small>
				</i>
			</p>

			{isError && <FormAlert send={send} description={errorMessage} />}
			<Form.Item>
				<RichTextArea
					id={'content_' + blockId}
					initialValue={content}
					textareaName="content"
					onChange={xstateOnChange(send)}
					imageType="site"
					siteId={siteId}
				/>
			</Form.Item>
		</Form>
	);
};

export default SitePageBlockContentForm;
