import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Input, 
	// TagInput, ConfirmDialogue, 
	Button, formHandleInputChange, offsetSizes} from '@wpa/components/lib/Form';
import {feathersServices, SERVICE} from "@wpa/feathers-client";
import {
	Col,
	Form, FormGroup, Label, 
	// FormText
} from 'reactstrap';
import {errorStateReducer} from "../../reducers";
import {getAuthorById} from '../../reducers/db/authors';



export const STATE = {
	LOADING: 'loading',
	DISPLAY: 'display',
	ERROR: 'error',
	ERROR_LOADING: 'errorLoading',
};

export const ACTION = {
	DISPLAY: 'display',
	SAVE: 'save',
	ERROR: 'error',
};

export const stateMachine = {
	initial: STATE.LOADING,
	states: {
		[STATE.LOADING]: {
			on: {
				[ACTION.ERROR]: {
					target: STATE.ERROR_LOADING
				},
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
					actions: ['setFields']
				}
			}
		},
		[STATE.DISPLAY]: {
			on: {
				[ACTION.SAVE]: {
					target: STATE.SAVING
				}
			}
		},
		[STATE.SAVING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {

			}
		},
		[STATE.ERROR_LOADING]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {

			}
		}
	}
};

// const stateReducers = [
// 	errorStateReducer.reducer,
// 	// (action, event) => {
// 	// 	switch(action){
// 	// 		case 'setFields':
// 	// 			return {
// 	// 				showCover: event.showCover,
// 	// 				showTitle: event.showTitle,
// 	// 				showSeriesName: event.showSeriesName,
// 	// 				showAuthorName: event.showAuthorName,
// 	// 				showBlurb: event.showBlurb,
// 	// 				showExtendedBlurb: event.showExtendedBlurb,
// 	// 				showPubDate: event.showPubDate,
// 	// 				showPurchaseLinks: event.showPurchaseLinks,
// 	// 				bookLimit: event.bookLimit,
// 	// 				bookOrder: event.bookOrder,
// 	// 				coverStyle: event.coverStyle,
// 	// 			};
// 	//
// 	// 		//	No Change
// 	// 		default:
// 	// 			return {};
// 	// 	}
// 	// }
// ];


export class AuthorDetailsForm extends React.Component {
	static displayName = 'AuthorDetailsForm';
	static propTypes = {
		authorId: PropTypes.number
	};
	static defaultProps = {

	};

	constructor(props){
		super(props);

		this.handleInputChange = formHandleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			name: props.author.name || '',
			paypalEmail: props.author.paypalEmail || '',
		};
	}

	handleSubmit(e){
		e.preventDefault();

		const self = this;

		//	@todo: Make all these optional if not changed value?
		self.props.dispatch(
			feathersServices[SERVICE.AUTHORS].patch(this.props.authorId, {
				name: this.state.name,
				paypalEmail: this.state.paypalEmail,
			}, {})
		)
			.catch(err => self.setState({ errorMessage: err.message}))
		;
	}

	render(){
		const {
			name,
			paypalEmail,
		} = this.state;

		const {
			author,
			// ...props
		} = this.props;
		const disabled = ! author.User;

		return (
			<Form onChange={this.handleInputChange} id="authorDetailsForm" onSubmit={this.handleSubmit} className="grid-form">
				<FormGroup row>
					<Label md={4} lg={3} for="authorDetailsForm-name">Name</Label>
					<Col md={8} lg={9}>
						<Input
							type="text" name="name" id="authorDetailsForm-name"
							defaultValue={author.name || ''}
							value={name}
							disabled={disabled}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label md={4} lg={3} for="authorDetailsForm-paypalEmail">Paypal Email</Label>
					<Col md={8} lg={9}>
						<Input
							type="text" name="paypalEmail" id="authorDetailsForm-paypalEmail"
							defaultValue={author.paypalEmail || ''}
							value={paypalEmail}
							disabled={disabled}
						/>
					</Col>
				</FormGroup>
				{! disabled && <FormGroup row>
					<Col {...offsetSizes}>
						<Button type="primary" htmlType="submit">Update</Button>
					</Col>
				</FormGroup>}
			</Form>
		);
	}
}

export default connect((state, props) => ({
	author: getAuthorById(state, props.authorId) || {}
	// authors: getAuthorsByBookId(state, props.book.id).map(author => ({
	// 	id: author.id,
	// 	name: author ? author.name : ''
	// })),
	// series: getSeriesByBookId(state, props.book.id).map(series => ({
	// 	id: series.id,
	// 	name: series ? series.name : ''
	// }))
}))(AuthorDetailsForm);
