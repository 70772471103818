//	eslint-disable-next-line
import React, { useCallback } from 'react';
import { Input } from 'antd';
import { formStateMachine } from '@wpa/state-machine';
import { Form } from '@wpa/components/lib/Form';
import { useMachine } from '@xstate/react';
import { withRouter } from 'react-router-dom';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { useDispatch } from 'react-redux';

const stateMachine = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => context
			.dispatch(
				feathersServices[SERVICE.SITE_PAGES].create({
					siteId: context.siteId,
					path: '/' + context.pageSlug,
					title: context.title,
				})
			)
			.then((res) => {
				context.onFinish();
				return resolve();
			})
			.catch((err) => reject('Unable to save page.'))
		),
});

export const SitePageCreateForm = ({
	match: {
		params: { slug, page },
		url,
		path,
	},
	siteId,
	onFinish
}) => {
	const dispatch = useDispatch();

	const [state, send] = useMachine(
		stateMachine.withFormContext(
			{
				dispatch,
				siteId,
				onFinish
			},
			{
				title: '',
				pageSlug: '',
			}
		)
	);

	const {
		fields: { title, pageSlug },
	} = state.context;

	return (
		<Form send={send} stateMachine={stateMachine} state={state}>
			<Form.Item label="Page Title" htmlFor="title">
				<Input
					placeholder="New Page"
					name="title"
					id="title"
					value={title}
				/>
			</Form.Item>
			<Form.Item
				label="Url"
				htmlFor="pageSlug"
				extra={slug + '/' + pageSlug}
			>
				<Input
					placeholder="new-page"
					name="pageSlug"
					id="pageSlug"
					value={pageSlug}
				/>
			</Form.Item>
		</Form>
	);
};

export default withRouter(SitePageCreateForm);
