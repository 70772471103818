//	eslint-disable-next-line
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toggleBookFilterAuthor, toggleBookFilterStatus } from '../../actions';

import TagList from '../TagList';
import AuthorList from '../author/List';

import { Card, Col, Row } from 'reactstrap';
import { getAllBookStatusesAsTags } from '../../reducers/db/bookStatuses';
import { isMultiAuthor } from '../../reducers/db/authors';

/**
 * Helper function for dispatching actions
 * so we don't create an anonymous function on every render
 * @param dispatch props.dispatch
 * @param call Redux action function
 * @param payload Passed on from the call oriignator
 * @returns {*}
 * @private
 */
function _dispatch(dispatch, call, payload) {
	return dispatch(call(payload));
}

export const BookFilter = ({...props}) => {
	const dispatch = useDispatch();
	const bookStatuses = useSelector(state => getAllBookStatusesAsTags(state));
	const filter = useSelector(state => state.pages.books.filter);
	const isMultiAuthor2 = useSelector(state => isMultiAuthor(state));

	return (
		<Card className="filter">
			<Row className="card-body">
				<Col xs="12">
					<h2>Book Filter</h2>
				</Col>

				<Col sm="7">
					<TagList
						title="Status"
						tags={bookStatuses}
						onToggle={_dispatch.bind(null, dispatch, toggleBookFilterStatus)}
						className="statusList"
						btnClass={'btn-status'}
						listState={filter.statuses}
					/>
				</Col>
				{isMultiAuthor2 && (
					<Col>
						<h3>Author</h3>
						<AuthorList
							onToggle={_dispatch.bind(null, dispatch, toggleBookFilterAuthor)}
							selected={filter.authors}
						/>
					</Col>
				)}
			</Row>
		</Card>
	);
};

export default BookFilter;