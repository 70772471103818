import React, {Component} from 'react';
// import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import SitePageLayout  from "./Layout";
// import {onSiteReady} from "../Layout";

import {
	Row, Col,
	Card, CardBody, 
	// CardHeading, CardTitle
} from 'reactstrap';
import {errorStateReducer} from "../../../reducers";
import {addFSM} from "../../../functional/withStateMachine";
import {SpinnerSmallWithText} from "@wpa/components/lib/Spinner";
import Notice from "@wpa/components/lib/Notice";
import {getPageIdByPath} from "../../../reducers/db/sitePages";
// import {getSiteIdByDomain} from "../../../reducers/db/sites";
import {feathersServices, SERVICE} from "@wpa/feathers-client";
import PageDetailsForm from "../../../components/sites/PageDetailsForm";
import ErrorBoundary from "@wpa/components/lib/ErrorBoundry";

export const STATE = {
	LOADING: 'loading',
	ERROR: 'error',
};

export const ACTION = {
	ERROR: 'error',
	DISPLAY: 'display'
};

const stateMachine = {
	initial: STATE.LOADING,
	states: {
		[STATE.LOADING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR
				},
			}
		},
		[STATE.DISPLAY]: {
			on: {

			}
		},
		[STATE.ERROR]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {

			}
		}
	}
};

export const stateReducers = [
	errorStateReducer.reducer,
	(action, event) => {
		switch(action.type){
			case 'setSiteId':
				return { siteId: event.siteId };


			//	No Change
			default:
				return {};
		}
	}
];

export class SitePageCustomisePage extends Component {
	static displayName = 'SitePageCustomisePage';
	static propTypes = {};
	static defaultProps = {};

	constructor(props){
		super(props);

		this.state = {
			siteId: null,
			pageId: null,
		};

		addFSM(this, stateMachine, stateReducers);
	}

	componentDidMount(){
		const props = this.props;

		if(props.pageId){
			return this.transition(ACTION.DISPLAY);
		}

		const siteId = props.siteId;
		this.props
			.dispatch(feathersServices[SERVICE.SITE_PAGES].find({
				query: {
					siteId: siteId
				}
			}))
			.then(res => {
				this.transition(ACTION.DISPLAY);
			})
			.catch(err => this.transition(ACTION.ERROR, {
				message: 'Unable to load site pages',
				error: err
			}))
		;
	}

	render(){
		const {
			currentState,
			errorMessage
		} = this.state;
		const {
			pageId
		} = this.props;


		return (
			<SitePageLayout pageName="sitePageCustomise">
				{currentState === STATE.LOADING &&
				<SpinnerSmallWithText label="Loading page content..." />}
				{currentState === STATE.ERROR &&
				<Notice color="danger">
					{errorMessage}
				</Notice>}
				{currentState !== STATE.LOADING && currentState !== STATE.ERROR &&
				<ErrorBoundary>
					<Row>
						<Col sm="8">
							<Card>
								<CardBody>
									<PageDetailsForm
										pageId={pageId}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ErrorBoundary>}
			</SitePageLayout>
		);
	}
}

export default withRouter(connect((state, props) => ({
	store: state,
	pageId: getPageIdByPath(state, props.match.params.page === 'home' ? '' : props.match.params.page),
}))(SitePageCustomisePage));