import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Radio } from '@wpa/components/lib/Form';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { getAllAuthors } from '../../reducers/db/authors';
import { getAllPublishers } from '../../reducers/db/publishers';
import { formStateMachine } from '@wpa/state-machine';
import logger from '@wpa/logger';
import { useMachine } from '@xstate/react';

const sm = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			let resourceId;
			switch (context.fields.siteTypeId) {
				case '1':
					resourceId = context.fields.authorId;
					break;
				case '2':
					resourceId = context.fields.publisherId;
					break;
				//	@todo: show errors
				default:
					return;
			}

			context
				.dispatch(
					feathersServices[SERVICE.SITES].create(
						{
							domain: context.fields.domain,
							title: '',
							typeId: parseInt(context.fields.siteTypeId, 10),
							resourceId: resourceId,
						},
						{}
					)
				)
				.then((payload) => {
					//	@todo: close the modal
					resolve();
				})
				.catch(
					(err) =>
						logger.error(err) ||
						reject('Unable to create a new site.')
				);
		}),
});

export const SiteCreateForm = () => {
	const dispatch = useDispatch();
	const authors = useSelector((state) => getAllAuthors(state)) || [];
	const publishers = useSelector((state) => getAllPublishers(state)) || [];

	const [state, send] = useMachine(
		sm.withFormContext(
			{
				dispatch: dispatch,
			},
			{
				siteTypeId: null,
				domain: '',
				authorId: null,
				publisherId: null,
			}
		)
	);

	const { domain, siteTypeId, authorId, publisherId } = state.context.fields;

	return (
		<Form stateMachine={sm} send={send} state={state}>
			<Form.Item label="Site Domain" htmlFor="domain">
				<Input
					name={'domain'}
					value={domain}
					defaultValue={''}
					placeholder="www.yoursite.com"
				/>
			</Form.Item>
			<Form.Item label={' '}>
				<Radio.Group value={siteTypeId} name="siteTypeId">
					<Radio value={'1'}>Author Site</Radio>
					<Radio value={'2'}>Publisher Site</Radio>
				</Radio.Group>
			</Form.Item>
			{siteTypeId === '1' && (
				<Form.Item label="Author" htmlFor="authorId">
					<Select
						name="authorId"
						value={authorId}
						send={send}
						options={authors.map((a) => ({
							label: a.name,
							value: a.id,
						}))}
						placeholder="Select an author"
					></Select>
				</Form.Item>
			)}
			{siteTypeId === '2' && (
				<Form.Item label="Publisher" htmlFor="publisherId">
					<Select
						name="publisherId"
						value={publisherId}
						send={send}
						options={publishers.map((p) => ({
							label: p.name,
							value: p.id,
						}))}
						placeholder="Select a publisher"
					></Select>
				</Form.Item>
			)}
		</Form>
	);
};

export default SiteCreateForm;
