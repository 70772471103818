import {combineReducers} from 'redux';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import Logger from '@wpa/logger';

import {assistantReducer} from './reducers/assistant';
import {currentUserReducer} from './reducers/currentUser';

import {reducer as authorsReducer} from './reducers/db/authors';
import {reducer as authorBooksReducer} from './reducers/db/authorBooks';
import {reducer as bookExternalAssetsReducer} from './reducers/db/bookExternalAssets';
import {reducer as bookPublishersReducer} from './reducers/db/bookPublishers';
import {reducer as bookRoyaltiesReducer} from './reducers/db/bookRoyalties';
import {reducer as booksReducer} from './reducers/db/books';
import {reducer as bookSalesReducer} from './reducers/db/bookSales';
import {reducer as bookReviewsReducer} from './reducers/db/bookReviews';
import {reducer as bookStatusesReducer} from './reducers/db/bookStatuses';
import {reducer as expensesReducer} from './reducers/db/expenses';
import {reducer as permissionsReducer} from './reducers/db/permissions';
import {reducer as publishersReducer} from './reducers/db/publishers';
import {reducer as resourceInvitesReducer} from './reducers/db/resourceInvites';
import {reducer as reviewsReducer} from './reducers/db/reviews';
import {reducer as seriesReducer} from './reducers/db/series';
import {reducer as siteLayoutsReducer} from './reducers/db/siteLayouts';
import {reducer as siteMenuPagesReducer} from './reducers/db/siteMenuPages';
import {reducer as siteMenusReducer} from './reducers/db/siteMenus';
import {reducer as sitePagesReducer} from './reducers/db/sitePages';
import {reducer as sitePageBlocksReducer} from './reducers/db/sitePageBlocks';
import {reducer as sitePageAuthorBioReducer} from './reducers/db/sitePageAuthorBio';
import {reducer as sitePageBooksReducer} from './reducers/db/sitePageBooks';
import {reducer as sitePageContentsReducer} from './reducers/db/sitePageContents';
import {reducer as sitesReducer} from './reducers/db/sites';
import {reducer as socialMediasReducer} from './reducers/db/socialMedias';
import {reducer as socialMediaTypesReducer} from './reducers/db/socialMediaTypes';

import {booksPageReducer} from './reducers/page/books';

// export const errorStateReducer = (action, event) => {
// 	switch(action){
// 		case 'setErrorMessage':
// 			Logger.error(event.message, event.error);
// 			return { errorMessage: event.message || '' };
// 		case 'clearMessage':
// 			return { errorMessage: ''};
//
// 		//	No Change
// 		default:
// 			return {};
// 	}
// };

/*
 *	State Reducers
 */

export const errorStateReducer = {
	setMessage: 'setErrorMessage',
	clearMessage: 'clearErrorMessage',
	reducer: function(action, event){
		switch(action.type) {
			case errorStateReducer.setMessage:
				Logger.error(event.message, event.error);
				return {errorMessage: event.message || ''};
			case errorStateReducer.clearMessage:
				return {errorMessage: ''};

			//	No Change
			default:
				return {};
		}
	}
};

export const formErrorStateReducer = {
	setMessages: 'setFormErrorMessages',
	clearMessages: 'clearFormErrorMessages',
	reducer: function(action, event){
		switch(action.type) {
			case formErrorStateReducer.setMessages:
				return {invalid: event.invalid || {}};
			case formErrorStateReducer.clearMessages:
				return {invalid: {}};

			//	No Change
			default:
				return {};
		}
	}
};

export const stateChangeReducer = {
	setState: 'setInternalState',
	reducer: function(action, event){
		switch(action.type) {
			case stateChangeReducer.setState:
				return {
					...event
				};

			//	No Change
			default:
				return {};
		}
	}
};


/*
 *	Reducers
 */



// const dashboard = (state = {}, action) => {
// 	switch (action.type) {
// 		default:
// 			return state;
// 	}
// };

export const bookFilters = {

};


// const sitesById = (state = {}, action) => {
// 	switch (action.type) {
// 		// case SET_NAME:
// 		// 	return Object.assign({}, state, {
// 		// 		name: action.payload
// 		// 	});
// 		// case SET_AUTHOR:
// 		// 	return Object.assign({}, state, {
// 		// 		penname: action.payload.penname,
// 		// 		isPublished: action.payload.isPublished
// 		// 	});
// 		// case SET_PUBLISHER:
// 		// 	return Object.assign({}, state, {
// 		// 		publisher: action.payload.publisher,
// 		// 		isPublisher: action.payload.isPublisher
// 		// 	});
// 		default:
// 			return state;
// 	}
// };

export const app = combineReducers({
	api: combineReducers({
		'authors': feathersServices[SERVICE.AUTHORS].reducer,
		'authorBooks': feathersServices[SERVICE.AUTHOR_BOOKS].reducer,
		'books': feathersServices[SERVICE.BOOKS].reducer,
		'publishers': feathersServices[SERVICE.PUBLISHERS].reducer,
		'sites': feathersServices[SERVICE.SITES].reducer,
		'sitePages': feathersServices[SERVICE.SITE_PAGES].reducer,
		'sitePageContents': feathersServices[SERVICE.SITE_PAGE_CONTENTS].reducer,
	}),
	db: combineReducers({
		authors: authorsReducer,
		authorBooks: authorBooksReducer,
		books: booksReducer,
		bookExternalAssets: bookExternalAssetsReducer,
		bookPublishers: bookPublishersReducer,
		bookRoyalties: bookRoyaltiesReducer,
		bookSales: bookSalesReducer,
		bookReviews: bookReviewsReducer,
		bookStatuses: bookStatusesReducer,
		expenses: expensesReducer,
		permissions: permissionsReducer,
		publishers: publishersReducer,
		reviews: reviewsReducer,
		resourceInvites: resourceInvitesReducer,
		series: seriesReducer,
		sites: sitesReducer,
		siteLayouts: siteLayoutsReducer,
		siteMenus: siteMenusReducer,
		siteMenuPages: siteMenuPagesReducer,
		sitePages: sitePagesReducer,
		sitePageBlocks: sitePageBlocksReducer,
		sitePageAuthorBio: sitePageAuthorBioReducer,
		sitePageBooks: sitePageBooksReducer,
		sitePageContents: sitePageContentsReducer,
		socialMedias: socialMediasReducer,
		socialMediaTypes: socialMediaTypesReducer,
		// userBooks: userBooksReducer,
	}),
	pages: combineReducers({
		'books': booksPageReducer,
	}),
	currentUser: currentUserReducer,
	assistant: assistantReducer,
	// sitesById
	errorHandler: (state = {}, action) => {
		const {error} = action;
		if(error){
			//	@todo: catch the jwt expired errors and redirect to auth
			if(action.payload && action.payload.message === 'jwt expired'){
				document.cookie =
				'wpa-api=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.' +
				process.env.REACT_APP_PUBLIC_URL.replace(/https?:\/\//, '') +
				';';
				window.location.assign(process.env.REACT_APP_AUTH_URL);
			}
			
			console.error('!!!!', action);
		}

		return state;
	}
});

export default app;