import React from 'react';
import './styles/bootstrap.css';
import '@wpa/styles/less/ant.less';
import '@wpa/styles/scss/main.scss';
import './App.scss';
import { Route, Switch } from 'react-router-dom';
import routes from './routes.js';
import { Layout } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import Nav from './components/Nav';
import ErrorBoundary from '@wpa/components/lib/ErrorBoundry';
import './fontawesome';
require('@wpa/feathers-client');



// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
	return (
		<Route
			path={route.path}
			render={(props) => (
				// pass the sub-routes down to keep nesting
				<route.component {...props} routes={route.routes} />
			)}
		/>
	);
}

function App() {
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider
				id="appNavContainer"
				breakpoint="lg"
				collapsedWidth="0"
				onBreakpoint={(broken) => {
					// console.log(broken);
				}}
				onCollapse={(collapsed, type) => {
					// console.log(collapsed, type);
				}}
			>
				<Nav />
			</Sider>
			{/* <Layout>
		<Header style={{ background: '#fff', padding: 0 }} />
		<Content style={{ margin: '24px 16px 0' }}>
			<div style={{ padding: 24, background: '#fff', minHeight: 360 }}>content</div>
		</Content>
		<Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
	</Layout> */}
			<Layout>
				{/* <Content> */}
				<ErrorBoundary>
					{/* <TransitionGroup>
				<CSSTransition key={currentKey} timeout={timeout} classNames="fade" appear> */}
					<Switch>
						{routes.map((route, i) => (
							<RouteWithSubRoutes key={i} {...route} />
						))}
					</Switch>
					{/* </CSSTransition>
			</TransitionGroup> */}
				</ErrorBoundary>
				{/* </Content> */}
			</Layout>
			{/* <div className="row h-100">
	<div className="col-sm-2" >
		
	</div>
	<div className={pageClasses.join(' ')} id="appMainContainer">
		<ErrorBoundary>
			<TransitionGroup>
				<CSSTransition key={currentKey} timeout={timeout} classNames="fade" appear>
					<Switch location={location}>
						{routes.map((route, i) => (
							<RouteWithSubRoutes location={location} key={i} {...route}/>
						))}
					</Switch>
				</CSSTransition>
			</TransitionGroup>
		</ErrorBoundary>
	</div>
</div> */}
		</Layout>
		// <div className="App">
		// 	<Layout style={{ minHeight: '100vh'}}>
		// 		<Content id="authPage" style={{margin:0}}>
		// 			<Row justify="center" type="flex"  style={{ minHeight: '100vh', margin: 0 }} align="middle">
		// 				<Col style={{marginLeft: '130px'}}>
		// 					<div className="boxFrame">
		// 						<div>
		// 							<p className="text-center">
		// 								<img src="/images/logo-b.png" alt="WriterPA Logo" width="150" />
		// 							</p>
		// 							<Switch>
		// 								{/* no different than other usage of
		// 								CSSTransitionGroup, just make
		// 								sure to pass `location` to `Route`
		// 								so it can match the old location
		// 								as it animates out
		// 							*/}
		// 								{routes.map((route, i) => (
		// 									<RouteWithSubRoutes key={i} {...route} />
		// 								))}
		// 							</Switch>
		// 							<div className="text-center">
		// 								<a href="/privacy">Privacy</a> |{' '}
		// 								<a href="https://twitter.com/theWriterPA" target="_blank" rel="noopener noreferrer">
		// 									<FontAwesomeIcon icon={['fab', 'twitter']} fixedWidth />
		// 								</a>{' '}
		// 								<a href="https://fb.me/theWriterPA" target="_blank" rel="noopener noreferrer">
		// 									<FontAwesomeIcon icon={['fab', 'facebook']} fixedWidth />
		// 								</a>
		// 							</div>
		// 						</div>
		// 					</div>
		// 				</Col>
		// 				<img
		// 					src="/images/wpa-over.png"
		// 					alt="WriterPA Assistant"
		// 					style={{ position: 'relative', left: '-19px' }}
		// 				/>
		// 			</Row>
		// 		</Content>
		// 	</Layout>
		// </div>
	);
}

export default App;
