import { feathersServices, SERVICE } from '@wpa/feathers-client';

/**
 * Constants
 */

const reducerName = 'ASSISTANT';

export const ASSISTANT_SHOW = reducerName + '_SHOW';
export const ASSISTANT_HIDE = reducerName + '_HIDE';
export const RESPOND = reducerName + '_RESPOND';

/**
 * Helper functions
 */

/**
 * Assistant reducer
 * @param state
 * @param action
 * @returns {*} new state
 */
export const assistantReducer = (
	state = {
		initialState: true,
		isVisible: true,
		currentMessageId: 1,
		lastResponse: null,

		//	Messages to display
		outstandingMessages: {},
		outstandingMessageIds: [],

		//	When nothing is outstanding, show the default message
		defaultMessage: {},

		currentMessage: {}
	},
	action
) => {
	let newState, payload;

	switch (action.type) {
		case feathersServices.getPatchFulfilledAction(SERVICE.ASSISTANT):
			newState = { ...state };

			//	@todo: actually get a proper message
			newState.currentMessage = action.payload;

			return newState;

		case feathersServices.getGetFulfilledAction(SERVICE.ASSISTANT):
			newState = { ...state };

			//	@todo: actually get a proper message
			newState.currentMessage = action.payload;

			return newState;

		case ASSISTANT_SHOW:
			newState = { ...state };
			newState.initialState = false;

			newState.isVisible = true;

			return newState;

		case ASSISTANT_HIDE:
			newState = { ...state };
			newState.initialState = false;

			newState.isVisible = false;

			return newState;

		case RESPOND:
			newState = { ...state };
			newState.initialState = false;

			payload = action.payload;

			newState.lastResponse = payload.response;
			newState.currentMessageId = payload.targetId;

			return newState;

		default:
			return state;
	}
};
