import {createReducer} from '../helpers';

/*
 *	Constants
 */

const serviceName = 'PUBLISHERS';
export const INITIAL_PUBLISHERS_LOADED = 'INITIAL_'+ serviceName +'_LOADED';
export const ADD_PUBLISHERS = 'ADD_'+ serviceName;
export const UPDATE_PUBLISHERS = 'UPDATE_'+ serviceName;
export const REMOVE_PUBLISHERS = 'REMOVE_'+ serviceName;

/*
 *	Helper functions
 */

export const addPublishers = items => ({
	type: ADD_PUBLISHERS,
	payload: items
});

export function getPublisherById(state, id) {
	const stateSlice = state.db.publishers;
	return stateSlice.byId[id];
}

export function getAllPublishersMap(state) {
	const stateSlice = state.db.publishers;
	return stateSlice.byId;
}

export function getAllPublishersAsTags(state) {
	const stateSlice = state.db.publishers;

	const items = stateSlice.allIds.map(itemId => {
		const item = stateSlice.byId[itemId];
		return {
			id: item.id,
			name: item.name,
			tagClass: ''
			// priority: item.priority
		};
	});

	return items;
}

export function getAllPublishers(state) {
	const stateSlice = state.db.publishers;
	return stateSlice.allIds.map(id => stateSlice.byId[id]);
}

export function getPrimaryPublisherId(state) {
	const stateSlice = state.db.publishers;
	return stateSlice.allIds.find(
		id => stateSlice.byId[id].User.isPrimary === true
	);
}

export function getPrimaryPublisher(state) {
	const stateSlice = state.db.publishers;
	return stateSlice.byId[
		stateSlice.allIds.find(id => stateSlice.byId[id].User.isPrimary === true)
	];
}

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		alterPayload: (item) => {
			//	@todo: publisher dashboard link - needs changing once more publisher data is available
			item.link = '/publishers/' + item.slug + '/' + item.id + '/details';
		},
		addActions: [],
		removeActions: [],
		actions: {}
	}
);
