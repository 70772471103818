import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input } from '@wpa/components/lib/Form';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { formStateMachine } from '@wpa/state-machine';
import { useMachine } from '@xstate/react';
import { Logger } from '@wpa/logger';

const stateMachine = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) =>
			context
				.dispatch(
					feathersServices[SERVICE.BOOKS].create({
						title: context.fields.title,
					})
				)
				.then((res) => {
					if (context.toggle) {
						context.toggle();
					}
					context.fields.title = '';
					resolve();
				})
				.catch(
					(err) =>
						Logger.error(err) || reject('Could nto save new book')
				)
		),
});

export const BooksAddForm = ({ toggle }) => {
	const dispatch = useDispatch();

	const [state, send] = useMachine(
		stateMachine.withFormContext(
			{
				dispatch,
				toggle,
			},
			{
				title: '',
			}
		)
	);

	const { title } = state.context.fields;
	const { defaults } = state.context;

	return (
		<Form
			send={send}
			state={state}
			stateMachine={stateMachine}
			buttonRow={{ label: 'Save' }}
		>
			<Form.Item htmlFor="title" label="Title">
				<Input
					name="title"
					value={title}
					defaultValue={defaults.title}
				/>
			</Form.Item>
		</Form>
	);
};
BooksAddForm.propTypes = {
	toggle: PropTypes.func,
};

export default BooksAddForm;
