//	eslint-disable-next-line
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	Form,
	// Button, formItemLayout, buttonSizes, FormAlert
} from '@wpa/components/lib/Form';
import { ColorSelect } from '@wpa/components/lib/Form';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { getSiteById } from '../../reducers/db/sites';
import { getAllSocialMediaTypes } from '../../reducers/db/socialMediaTypes';
import { getAuthorSocialMediaMap } from '../../reducers/db/socialMedias';
import SiteSocialMedia from '../shared/SiteSocialMedia';
import { Input } from 'antd';
import { useMachine } from '@xstate/react';
import Logger from '@wpa/logger';
import { formStateMachine, FORM_ACTION } from '@wpa/state-machine';
import FontSelect from '../FontSelect';

const m = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.SITES].patch(context.siteId, {
						domain: context.fields.domain,
						title: context.fields.title,
						contactEmail: context.fields.contactEmail,
						backgroundColor: context.fields.backgroundColor || '',
						textColor: context.fields.textColor || '',
						linkColor: context.fields.linkColor || '',
						headerFont: context.fields.headerFont || '',
						bodyFont: context.fields.bodyFont || '',
					})
				)
				//	@todo reset defaults
				.then((json) => resolve())
				.catch(
					(err) =>
						Logger.error(err) ||
						reject('Unable to save site details.')
				);
		}),
});

export const SiteDetailsForm = ({ siteId }) => {
	const dispatch = useDispatch();
	const site = useSelector((state) => getSiteById(state, siteId)) || {};
	const socialMediaTypes = useSelector((state) =>
		getAllSocialMediaTypes(state)
	);

	let socialMedia;

	// if(site){
	// 	switch(site.typeId){
	// 		case 1:
	//	@todo: this shouldn't be conditional
	socialMedia = useSelector((state) =>
		getAuthorSocialMediaMap(state, site.resourceId)
	);
	// 	}
	// }

	const [state, send] = useMachine(
		m.withFormContext(
			{
				dispatch,
				siteId,
			},
			{
				domain: site.domain || '',
				title: site.title || '',
				backgroundColor: site.backgroundColor || '',
				textColor: site.textColor || '',
				linkColor: site.linkColor || '',
				headerFont: site.headerFont || '',
				bodyFont: site.bodyFont || '',
			}
		)
	);

	const {
		domain,
		title,
		backgroundColor,
		textColor,
		linkColor,
		headerFont,
		bodyFont,
	} = state.context.fields;

	const defaults = state.context.defaults;

	const onBackgroundColorChange = useCallback(
		(color) =>
			send(FORM_ACTION.CHANGE, {
				fields: { backgroundColor: color.hex },
			}),
		[send]
	);

	const onTextColorChange = useCallback(
		(color) =>
			send(FORM_ACTION.CHANGE, {
				fields: { textColor: color.hex },
			}),
		[send]
	);

	const onLinkColorChange = useCallback(
		(color) =>
			send(FORM_ACTION.CHANGE, {
				fields: { linkColor: color.hex },
			}),
		[send]
	);

	return (
		<Form stateMachine={m} state={state} send={send}>
			<Form.Item htmlFor="domain" label="Domain">
				<Input
					id="domain"
					name="domain"
					value={domain}
					defaultValue={defaults.domain}
				/>
			</Form.Item>

			<Form.Item htmlFor="title" label="Title">
				<Input
					id="title"
					name="title"
					value={title}
					defaultValue={defaults.title}
				/>
			</Form.Item>
			{socialMediaTypes.map((smt) => {
				let icon = ['fab', smt.slug];
				if (smt.slug === 'contact-email') {
					icon = ['fas', 'envelope'];
				}

				if (!socialMedia[smt.id]) {
					return null;
				}

				return (
					<SiteSocialMedia
						key={smt.id}
						typeId={smt.id}
						label={smt.label}
						icon={icon}
						text={socialMedia[smt.id] && socialMedia[smt.id].url}
						socialMediaId={
							socialMedia[smt.id] && socialMedia[smt.id].id
						}
						siteId={site.id}
						//	Author
						resourceTypeId={1}
						noForm
					/>
				);
			})}

			<Form.Item htmlFor="bgcolour" label="BG Colour">
				<ColorSelect
					color={backgroundColor}
					onChange={onBackgroundColorChange}
				/>
			</Form.Item>

			<Form.Item htmlFor="textcolour" label="Text Colour">
				<ColorSelect color={textColor} onChange={onTextColorChange} />
			</Form.Item>

			<Form.Item htmlFor="linkcolour" label="Link Colour">
				<ColorSelect color={linkColor} onChange={onLinkColorChange} />
			</Form.Item>
			<Form.Item htmlFor="headerFont" label="Header Font">
				<FontSelect
					name="headerFont"
					id="headerFont"
					onChange={(value) => {
						send(FORM_ACTION.CHANGE, {
							fields: { headerFont: value },
						});
					}}
					value={headerFont}
				/>
			</Form.Item>
			<Form.Item htmlFor="bodyFont" label="Body Font">
				<FontSelect
					name="bodyFont"
					id="bodyFont"
					onChange={(value) => {
						send(FORM_ACTION.CHANGE, {
							fields: { bodyFont: value },
						});
					}}
					value={bodyFont}
				/>
			</Form.Item>
		</Form>
	);
};

export default SiteDetailsForm;
