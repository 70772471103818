//  eslint-disable-next-line
import React, { useCallback } from 'react';
import { Button, FormAlert } from '@wpa/components/lib/Form';
import { useDispatch } from 'react-redux';
import { feathersServices , SERVICE} from '@wpa/feathers-client';
import {FORM_ACTION, formStateMachine, FORM_STATE} from '@wpa/state-machine';
import { Modal } from 'antd';
import {useMachine} from '@xstate/react';

const m = formStateMachine({

});

const SitePageSettings = ({ pageId }) => {
	const dispatch = useDispatch();

	const [state, send] = useMachine(m.withContext({

	}));

	const {
		errorMessage
	} = state.context;

	const isError = state.matches(FORM_STATE.ERROR);

	const handleDelete = useCallback(
		e => {
			Modal.confirm({
				title: 'Delete Page',
				content: 'Are you sure you want to delete this page?',
				okText: 'Yes',
				okType: 'danger',
				cancelText: 'No',
				onOk() {
					dispatch(feathersServices[SERVICE.SITE_PAGES].remove(pageId))
						.then(res => {
							//	Just let it go through to a "404" for site page display
						})
						.catch(err =>
							send(FORM_ACTION.ERROR, {
								error: err,
								errorMessage: err.message
							})
						);
				}
			});
		},
		[send, dispatch, pageId]
	);

	return (
		<form>
			<p>Publish from, Publish to</p>
			<p>
				<Button type="danger" onClick={handleDelete}>
					Delete Page
				</Button>
			</p>
			{isError && <FormAlert send={send} description={errorMessage} />}
		</form>
	);
};

export default SitePageSettings;
