//	eslint-disable-next-line
import React, {useCallback} from 'react';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import { Select } from 'antd';
import {difference} from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { getSeriesAsTags } from '../../reducers/db/series';
import Logger from '@wpa/logger';

//	Need this as the Select does not give an event you can do a stop on...
const stopOnChangeBubbling = (e) => {
	e.stopPropagation();
};

export const BookSeriesSelect = ({onChange, selected, allowNew, ...props}) => {
	const dispatch = useDispatch();
	const seriesList = useSelector(state => getSeriesAsTags(state, null)) || [];
	
	const children = [];
	seriesList.forEach(option => {
		// @todo: can't filter at the moment as then we end up with values (id) displayed in the tag list
		// if(selected.indexOf(option.value+'') === -1){
		children.push(
			<Select.Option value={option.value+''} key={option.value}>{option.label}</Select.Option>
		);
		// }
	});

	const handleChange = useCallback((values) => {
		const diff = difference(values, selected);

		//	Item removed
		if(! diff || ! diff[0]){
			onChange(values);
			return null;
		}

		//	We've added an item, check if it's a new tag
		const record = seriesList.filter(s => s.value === parseInt(diff[0],10));

		//	Tag exists, just update components
		if(record.length > 0){
			onChange(values);
			return;
		}
		
		const position = values.indexOf(diff[0]);
			
		dispatch(
			feathersServices[SERVICE.SERIES]
				.create({
					name: diff[0],
				}, {})
		)
			.then(payload => {
				const id = payload.value.id || payload.value[0].id;
				values.splice(position, 1, id+'');
				onChange(values);
			})
			.catch(err => {
				//	Remove offending item before proceeding
				values.splice(position, 1);
				Logger.error(err);
			});
		
	}, [dispatch, selected, seriesList, onChange]);

	return (
		<div onChange={stopOnChangeBubbling}>
			<Select
				mode={allowNew ? 'tags' : 'multiple'}
				placeholder="Select series"
				filterOption={true}
				optionFilterProp="children"
				onChange={handleChange}
				value={selected || []}
				style={{width: '100%'}}
			>
				{children}
			</Select>
		</div>
	);
};

export default BookSeriesSelect;
