import React from 'react';
import {connect} from 'react-redux';
import {addFSM} from '../../functional/withStateMachine';
import PropTypes from 'prop-types';
import {
	Input,
	// ConfirmDialogue,
	Button,
	formHandleInputChange,
	// offsetSizes,
	labelSizes, colSizes,
	//  offsetSizesReverse
} from '@wpa/components/lib/Form';
import {feathersServices, SERVICE} from "@wpa/feathers-client";
import {
	Col,
	Form, FormGroup, Label, InputGroupAddon, InputGroup
} from 'reactstrap';
import {errorStateReducer} from "../../reducers";
import {SpinnerSmallWithText} from '@wpa/components/lib/Spinner';
// import {store} from '../../store';
// import {dispatchGetBookSalesTotals} from '../../reducers/db/bookSales';

function getStoreName(storeId){
	switch(storeId){
		case 1: return 'Amazon.com';
		case 2: return 'Amazon.co.uk';
		case 3: return 'Amazon.de';
		case 4: return 'Amazon.fr';
		case 5: return 'Amazon.ca';
		case 6: return 'Amazon.com.br';
		case 7: return 'Amazon.it';
		case 8: return 'Amazon.com.au';
		case 9: return 'Amazon.in';
		case 10: return 'Amazon.com.mx';
		case 11: return 'Amazon.co.jp';
		case 12: return 'Amazon.es';
		case 13: return 'Amazon.nl';
		default: return '-';
	}
}

const GBP_STORE_ID = 2;
// const USD_STORE_ID = 1;

export const STATE = {
	LOADING: 'loading',
	DISPLAY: 'display',
	ERROR: 'error',
	ERROR_LOADING: 'errorLoading',
};

export const ACTION = {
	DISPLAY: 'display',
	SAVE: 'save',
	ERROR: 'error',
};

export const stateMachine = {
	initial: STATE.LOADING,
	states: {
		[STATE.LOADING]: {
			on: {
				[ACTION.ERROR]: {
					target: STATE.ERROR_LOADING,
				},
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
					actions: ['setFields'],
				}
			}
		},
		[STATE.DISPLAY]: {
			on: {
				[ACTION.SAVE]: {
					target: STATE.SAVING,
				}
			}
		},
		[STATE.SAVING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR,
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {

			}
		},
		[STATE.ERROR_LOADING]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {

			}
		}
	}
};

const stateReducers = [
	errorStateReducer.reducer,
	(action, event) => {
		switch(action.type){
			case 'setFields':
				return {
					stores: event.stores,
				};

			//	No Change
			default:
				return {};
		}
	}
];


export class StoreExchangeRateForm extends React.Component {
	static displayName = 'StoreExchangeRateForm';
	static propTypes = {
		storeId: PropTypes.number.isRequired,
		salesPeriod: PropTypes.string.isRequired,
		onSave: PropTypes.func
	};
	static defaultProps = {

	};

	constructor(props){
		super(props);

		this.handleInputChange = formHandleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			exchangeRate: props.storeId === GBP_STORE_ID ? 1 : null,
		};
	}

	handleSubmit(e){
		e.preventDefault();

		this.props.onSave(this.state, this.props, e);
	}

	render(){
		const {
			exchangeRate
		} = this.state;

		const {
			storeId,
			salesPeriod,
			// ...props
		} = this.props;

		return (
			<Form
				onChange={this.handleInputChange}
				onSubmit={this.handleSubmit}
				className="grid-form"
				key={'exchangeFor'+  salesPeriod +'-'+ storeId}
			>
				<FormGroup row >
					<Label {...labelSizes}>{getStoreName(storeId)}</Label>
					<Col {...colSizes}>
						<InputGroup>
							<Input
								name={"exchangeRate"}
								type={"number"}
								value={exchangeRate}
								defaultValue={null}
								step={0.0001}
							/>
							<InputGroupAddon addonType="append">
								<Button type="primary" htmlType="submit">Save</Button>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</FormGroup>
			</Form>
		);
	}
}


export class ExchangeRateForm extends React.Component {
	static displayName = 'ExchangeRateForm';
	static propTypes = {

	};
	static defaultProps = {

	};

	constructor(props){
		super(props);

		this.handleInputChange = formHandleInputChange.bind(this);
		this.handleExchangeRateSave = this.handleExchangeRateSave.bind(this);

		this.state = {
			stores: [],
			exchangeRate: null,
		};

		addFSM(this, stateMachine, stateReducers);
	}

	componentDidMount(){
		//	@todo: replace with a generic call to initialize a store
		this.props
			.dispatch(feathersServices[SERVICE.BOOK_SALES].find({
				query: {
					exchangeRate: {
						$eq: null
					},
					salesPeriod: this.props.salesPeriod,
					$select: ['storeId', 'salesPeriod'],
					$groupBy: ['sales_period', 'store_id'],
					$limit: 999,
				}
			}))
			.then(json => {
				this.transition(ACTION.DISPLAY, {
					stores: json.value.data
				});
				// this.setState({
				// 	seriesList: json.value.data.map(item => {
				// 		return {
				// 			id: item.id,
				// 			name: item.name
				// 		};
				// 	})
				// });
			})
			.catch(err => {
				this.transition(ACTION.ERROR, {
					message: 'Unable to load stores'
				})
			})
		;
	}

	handleExchangeRateSave(state, props, e){
		// const self = this;
		this.props
			.dispatch(feathersServices[SERVICE.BOOK_SALES].patch(
				null,
				{
					exchangeRate: state.exchangeRate
				},
				{
					query: {
						storeId: props.storeId,
						exchangeRate: {
							$eq: null
						},
						salesPeriod: this.props.salesPeriod
					}
				}
			))
			.then(json => {
				//	@todo: switch this to a transition?
				this.setState({
					stores: this.state.stores.filter(store => store.storeId !== props.storeId)
				});

				// self.props.dispatch(dispatchGetBookSalesTotals());
				//	KENP update not needed since these are not based on the exchange rates
			})
			.catch(err => console.error(err))
		;
	}

	render(){
		const {
			stores,
			currentState,
		} = this.state;

		if(currentState === STATE.LOADING){
			return (<SpinnerSmallWithText label="Loading stores..." />);
		}

		const {
			salesPeriod,
			// ...props
		} = this.props;

			console.log('STATE', this.state.stores);

		return (
			stores.map(store => <StoreExchangeRateForm
				key={'storeExchangeRateForm-'+ store.storeId +'-'+ salesPeriod}
				storeId={store.storeId}
				salesPeriod={salesPeriod}
				onSave={this.handleExchangeRateSave}
			/>)
		);
	}
}

//	@todo: stores should come from the db
//	We need this for access to the dispatch function
export default connect((state, props) => ({
}))(ExchangeRateForm);
