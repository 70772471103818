import React, { useCallback } from 'react';
import moment from 'moment';

import {
	Button,
	// labelSizes,
	// colSizes,
	buttonSizes,
	formItemLayout
} from '@wpa/components/lib/Form';
import {
	formStateMachine,
	FORM_STATE,
	FORM_ACTION,
	xstateOnChange
} from '@wpa/state-machine';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { useMachine } from '@xstate/react';
import { useDispatch, useSelector } from 'react-redux';
import Logger from '@wpa/logger';

import { Row, Col, Form, Input, Checkbox, DatePicker } from 'antd';
import { getReviewById } from '../../../reducers/db/reviews';

const m = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					! event.reviewId ?
						feathersServices[SERVICE.REVIEWS].create(
							{
								review: context.review,
								quote: context.quote,
								author: context.author,
								sourceUrl: context.sourceUrl,
								isPublic: context.isPublic,
								publishedAt: context.publishedAt
							},
							{
								query: {
									bookId: event.bookId
								}
							}
						) :
						feathersServices[SERVICE.REVIEWS].patch(
							event.reviewId,
							{
								review: context.review,
								quote: context.quote,
								author: context.author,
								sourceUrl: context.sourceUrl,
								isPublic: context.isPublic,
								publishedAt: context.publishedAt
							},
							{
								// query: {
								// 	bookId: event.bookId
								// }
							}
						)
				)
				.then(res => {
					console.log('SUBMIT', context, event);
					if(event.onSubmit){
						event.onSubmit();
					}
					resolve();
				})
				.catch(err => Logger.error(err) || reject('Unable to save review.'));
		})
});

export const BookReviewForm = ({ bookId, reviewId, onSubmit }) => {
	const dispatch = useDispatch();

	const bookReview = useSelector(state => getReviewById(state, reviewId)) || {};

	const [state, send] = useMachine(
		m.withContext({
			dispatch: dispatch,
			bookId: bookId,
			review: bookReview.review,
			quote: bookReview.quote,
			author: bookReview.author,
			sourceUrl: bookReview.sourceUrl,
			isPublic: !!bookReview.isPublic,
			publishedAt: bookReview.publishedAt,
		})
	);

	const {
		// errorMessage,
		review,
		quote,
		author,
		sourceUrl,
		isPublic,
		publishedAt
	} = state.context;

	// const isError = state.matches(FORM_STATE.ERROR);
	const isSaving = state.matches(FORM_STATE.SUBMITTING);

	const handleSave = useCallback(
		() => {
			send(FORM_ACTION.SUBMIT, {
				bookId: bookId,
				reviewId: reviewId,
				onSubmit: onSubmit,
			});
		},
		[
			send,
			bookId,
			reviewId,
			onSubmit,
		]
	);

	return (
		<Form
			onFinish={handleSave}
			onChange={xstateOnChange(send)}
			layout="horizontal"
		>
			<Row gutter={16}>
				<Col md={{ span: 12 }}>
					<Form.Item
						label="Author"
						htmlFor="author"
						{...formItemLayout}
						required
					>
						<Input id="author" name="author" type="text" value={author} />
					</Form.Item>
					<Form.Item
						label="Source Url"
						htmlFor="sourceUrl"
						{...formItemLayout}
						extra={<small>Link to the original review location</small>}
					>
						<Input
							id="sourceUrl"
							name="sourceUrl"
							type="text"
							value={sourceUrl}
							placeholder="https://"
						/>
					</Form.Item>
					<Form.Item
						label="Published at"
						htmlFor="publishedAt"
						{...formItemLayout}
					>
						<DatePicker
							id="publishedAt"
							name="publishedAt"
							onChange={(date, dateString) =>
								send(FORM_ACTION.CHANGE, { publishedAt: dateString })
							}
							value={publishedAt ? moment(publishedAt, 'YYYY-MM-DD') : null}
						/>
					</Form.Item>
					<Form.Item label=" " {...formItemLayout} noStyle>
						<Checkbox name="isPublic" checked={isPublic}>Is public?</Checkbox>
					</Form.Item>
					<Form.Item wrapperCol={buttonSizes}>
						<Button type="primary" loading={isSaving} htmlType="submit">
							Save
						</Button>
					</Form.Item>
				</Col>
				<Col md={{ span: 12 }}>
					<Form.Item label="Quote" htmlFor="quote">
						<Input.TextArea
							id="quote"
							name="quote"
							value={quote}
							placeholder="This will be used on the book page on the website."
						/>
					</Form.Item>
					<Form.Item label="Full Review" htmlFor="review">
						<Input.TextArea
							id="review"
							name="review"
							value={review}
							rows="5"
							placeholder="Full review/quote for your reference."
						/>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};