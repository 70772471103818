import React from 'react';
import {
	Card,
	CardBody,
	Row,
	Col,
	Table,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
// import PageHeader from '../components/PageHeader';
import { SpinnerSmallWithText } from '@wpa/components/lib/Spinner';
import PaginationWPA from '../components/shared/PaginationWPA';
import InviteUserButton from '../components/users/Invite';
import { connect, useSelector } from 'react-redux';
import feathersAPI from '@wpa/feathers-client';
import can from '../abilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdminPageLayout } from './admin/Layout';
import { getCurrentUser } from '../reducers/currentUser';
const queryString = require('query-string');

export const AdminUsersPage = withRouter(
	class extends React.Component {
		constructor(props) {
			super(props);

			const search = queryString.parse(props.location.search);
			const limit = 10;

			this.state = {
				users: {
					limit: limit,
					skip: limit * (search.p - 1 || 0),
					data: null,
					total: 0
				}
			};

			this.search = this.search.bind(this);
			this.search(search.p || 1);
		}

		search(page) {
			return feathersAPI
				.service('users')
				.find({
					query: {
						$limit: this.state.users.limit,
						$skip: (page - 1) * this.state.users.limit
					}
				})
				.then(users => {
					this.setState({ users: users });
				});
		}

		componentDidMount() {}

		layout(children) {
			return (
				<Row>
					<Col>
						<Card>
							<CardBody>{children}</CardBody>
						</Card>
					</Col>
				</Row>
			);
		}

		render() {
			if (!this.state.users.data) {
				return this.layout(
					<SpinnerSmallWithText label="Loading users" center />
				);
			}

			const totalPages = parseInt(
				this.state.users.total / this.state.users.limit,
				10
			) + 1;
			const currentPage = (this.state.users.skip / this.state.users.limit) + 1;

			return this.layout(
				<div>
					<PaginationWPA
						search={this.search}
						currentPage={currentPage}
						totalPages={totalPages}
					/>

					<Table striped className="table-borderless">
						<thead>
							<tr>
								<th>&nbsp;</th>
								<th>Details</th>
								<th>Invite</th>
								<th className="text-center">Actions</th>
							</tr>
						</thead>
						<tbody>
							{this.state.users.data.map(user => {
								const inviteDate = user.createdAt ? new Date(user.createdAt) : null;
								return (
									<tr key={user.id}>
										<td>
											<span className="fa-layers fa-2x">
												<FontAwesomeIcon
													icon={['fas', 'user-circle']}
													fixedWidth
													style={{ color: user.isAdmin ? 'red' : null }}
												/>
												{user.isVerified && (
													<FontAwesomeIcon
														icon={['fas', 'badge-check']}
														fixedWidth
														style={{ color: 'green' }}
														transform="down-7 right-7 shrink-8"
													/>
												)}
											</span>
										</td>
										<td width="100%">
											{user.fullname}
											<br />
											<small>{user.email}</small>
										</td>
										<td style={{ whiteSpace: 'nowrap' }}>
											{user.invitedByName || 'No Name, id: '+ (user.invitedById || '')}
											<br />
											<small>{inviteDate ? inviteDate.toLocaleString() : 'N/A'}</small>
										</td>
										<td style={{ whiteSpace: 'nowrap' }}>
											<ul className="actions">
												{/*	@todo: Pin the user for paralell editing */}
												{/* <li>
													<a href="/todo">
														<FontAwesomeIcon
															icon={['far', 'thumbtack']}
															size={'lg'}
															fixedWidth
														/>
													</a>
												</li> */}
												{/* @todo: edit the user details */}
												<li>
													<Link to={'/admin/users/'+ user.id +'/edit'}>
														<FontAwesomeIcon
															icon={['far', 'edit']}
															size={'lg'}
															fixedWidth
														/>
													</Link>
												</li>
												{/* @todo: delete the user */}
												<li>
													<a href="/todo">
														<FontAwesomeIcon
															icon={['far', 'trash-alt']}
															size={'lg'}
															fixedWidth
														/>
													</a>
												</li>
											</ul>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
					<PaginationWPA
						search={this.search}
						currentPage={currentPage}
						totalPages={totalPages}
					/>
				</div>
			);
		}
	}
);

const AdminPermissionsPage = connect(state => ({ store: state }))(props => {
	// [
	//   [
	//     { perm... }
	//   ]
	// ]
	// const groups = [];
	// let groupName, group;
	//
	// props.store.db.permissions.allIds.forEach(permissionId => {
	// 	const permission = props.store.db.permissions.byId[permissionId];
	// 	if(! groupName){
	// 		group = [];
	// 		groupName = permission.category;
	// 	}
	// 	//	Group change
	// 	if(groupName != permission.category){
	// 		groups.push()
	// 	}
	//
	// 	group.push(permission);
	// });

	return (
		<Card>
			<CardBody>
				<Row>
					PERMISSION LIST
					{/*{props.store.db.permissions.allIds.map(permissionId => {*/}
					{/*const permission = props.store.db.permissions.byId[permissionId];*/}
					{/*return (*/}
					{/*<Col sm="6" md="4" key={permission.id}>*/}
					{/*{permission.label}*/}
					{/*</Col>*/}
					{/*)})}*/}
				</Row>
			</CardBody>
		</Card>
	);
});

export const AdminPage = ({...props}) => {
	const currentUser = useSelector(state => getCurrentUser(state));

	let subpage;
	let pageHeader;
	let title = 'Dashboard';

	const canAccessUsers = can.accessAdminUsers(currentUser);
	const canAccessPermissions = can.accessAdminPermissions(currentUser);

	switch (props.match.path) {
		case '/admin/users':
			if (canAccessUsers) {
				title = 'Users';
				pageHeader = <InviteUserButton key="inviteUserButton" />;
				subpage = <AdminUsersPage />;
			}
			break;
		case '/admin/permissions':
			if (canAccessPermissions) {
				title = 'Permissions';
				subpage = <AdminPermissionsPage />;
			}
			break;
		default: ;
	}

	return (
		<AdminPageLayout pageId="adminPage" title={title} pageHeader={[pageHeader]}>
			{subpage}
		</AdminPageLayout>
	);
};



// export default connect(state => ({ store: state }))(withRouter(page));
// export default withRouter(page);
export default AdminPage;
