'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.bugsnagClient = exports.ErrorBoundary = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _browser = require('@bugsnag/browser');

var _browser2 = _interopRequireDefault(_browser);

var _pluginReact = require('@bugsnag/plugin-react');

var _pluginReact2 = _interopRequireDefault(_pluginReact);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var env = process.env.NODE_ENV;

_browser2.default.start({
	apiKey: '4ac21b49cee58835c1fbfd83d02b6b29',
	plugins: [new _pluginReact2.default(_react2.default)],
	appVersion: '0.1.0',
	collectUserIp: false,
	releaseStage: typeof env !== 'undefined' ? env : 'development'
});

var ErrorBoundary = exports.ErrorBoundary = _browser2.default.getPlugin('react');
var bugsnagClient = exports.bugsnagClient = _browser2.default;