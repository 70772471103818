import {getItemIdsByParam} from '../helpers';
import {getBookStoreIdsByBookId} from './bookSales';
import {createReducer} from '../helpers';

/*
 *	Constants
 */

const serviceName = 'BOOK_ROYALTIES';
export const ADD_BOOK_ROYALTIES = 'ADD_'+ serviceName;
export const UPDATE_BOOK_ROYALTIES = 'UPDATE_'+ serviceName;
export const REMOVE_BOOK_ROYALTIES = 'REMOVE_'+ serviceName;

/*
 *	Helper functions
 */

export const getLatestBookRoyaltiesByStoreBookId = (state, storeBookId) => {
	const stateSlice = state.db.bookRoyalties;
	const itemIds = getItemIdsByParam(stateSlice, 'storeBookId', storeBookId);

	//	Exit early
	if(itemIds.length < 1){
		return [];
	}

	const items = itemIds.map(id => stateSlice.byId[id])
		.sort((a,b) => (a.activeFrom < b.activeFrom))
	;

	const firstDate = items[0].activeFrom;

	return items.filter(item => item.activeFrom === firstDate);
};


export const getLatestBookRoyaltiesByStoreBookIdAndPublisherId = (state, storeBookId, publisherId) => {
	const stateSlice = state.db.bookRoyalties;
	const itemIds = getItemIdsByParam(stateSlice, 'storeBookId', storeBookId);

	//	Exit early
	if(itemIds.length < 1){
		return [];
	}

	const items = itemIds.map(id => stateSlice.byId[id])
		.filter(item => item.publisherId === publisherId)
		.sort((a,b) => (a.activeFrom < b.activeFrom))
	;

	//	Exit early
	if(items.length < 1){
		return [];
	}

	const firstDate = items[0].activeFrom;

	return items.filter(item => item.activeFrom === firstDate);
};

export const getBookRoyaltiesByBookId = (state, bookId) => {
	const stateSlice = state.db.bookRoyalties;
	const storeBookIds = getBookStoreIdsByBookId(state, bookId);
	const itemIds = getItemIdsByParam(stateSlice, 'storeBookId', storeBookIds);

	//	Exit early
	if(itemIds.length < 1){
		return [];
	}

	const items = itemIds
		.map(id => stateSlice.byId[id])
	;

	return items;
};

export const addBookRoyalties = (payload) => ({
	type: ADD_BOOK_ROYALTIES,
	payload: payload
});


// /**
//  * Book Publishers Reducer
//  * @param state
//  * @param action
//  * @returns {*}
//  */
// export const bookRoyaltiesReducer = (state = {
// 	initialState: true,
// 	byId: {},
// 	allIds: [],
// }, action) => {
// 	let newState, payload;

// 	switch (action.type) {
// 		case feathersServices.getFindFulfilledAction(SERVICE.BOOK_ROYALTIES):
// 			payload = action.payload.data || [];

// 			//	Fallthrough as the action is the same

// 		case ADD_BOOK_ROYALTIES:
// 			newState = {...state};
// 			newState.initialState = false;

// 			payload = payload || action.payload;

// 			addItems(newState, payload);

// 			return newState;

// 		default:
// 			return state;
// 	}
// };

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);