import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label} from 'reactstrap';
import {
	Button,
	colSizes,
	formHandleInputChange,
	Input,
	labelSizes,
	// offsetSizes,
	offsetSizesReverse
} from '@wpa/components/lib/Form';
import {getAuthorsByBookId} from '../../reducers/db/authorBooks';
import {quickFloatSum, quickRound} from '../../utils/maths';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import {
	// getLatestBookRoyaltiesByStoreBookId,
	getLatestBookRoyaltiesByStoreBookIdAndPublisherId
} from '../../reducers/db/bookRoyalties';

export class BookRoyaltiesForm extends React.Component {
	static displayName = 'BookRoyaltiesForm';
	static propTypes = {
		//	BP ID - to decide between create and patch
		bookPublisherId: PropTypes.number,
		bookId: PropTypes.number,
		publisherId: PropTypes.number,
		//	asin, kobo_id, etc...
		storeBookId: PropTypes.string,
		//	which of the above it is
		storeBookIdField: PropTypes.string,
		publisherName: PropTypes.string,
		canEdit: PropTypes.bool,
	};

	constructor(props){
		super(props);

		this.handleChange = formHandleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getRoyaltiesMap = this.getRoyaltiesMap.bind(this);

		const royaltiesMap = this.getRoyaltiesMap();
		const activeFrom = royaltiesMap.activeFrom ? new Date(royaltiesMap.activeFrom).toISOString().substr(0, 10) : null;

		this.state = {
			storeBookId: props.storeBookId,
			activeFrom: activeFrom,
			publisherRoyalty: royaltiesMap.publisher.royaltyPercentage || 0,
		};

		//	@todo: do not mutate stae?
		//	eslint-disable-next-line
		props.authors.forEach(author => this.state["authorRoyalty_"+ author.id] = (royaltiesMap.authors[author.id] && royaltiesMap.authors[author.id].royaltyPercentage) || 0);
	}

	handleSubmit(e){
		e.preventDefault();

		const self = this;
		const props = self.props;

		//	Save or update the publisher assignment to the book
		if(!this.props.bookPublisherId){
			props.dispatch(feathersServices[SERVICE.BOOK_PUBLISHERS].create({
				bookId: props.bookId,
				publisherId: props.publisherId,
				[props.storeBookIdField]: self.state.storeBookId
			}, {}))
				.then(result => {
					//	@todo: Transition state
				})
				.catch(err => {
					//	@todo: handle error
				})
			;
		} else {
			props.dispatch(feathersServices[SERVICE.BOOK_PUBLISHERS].patch(
				props.bookPublisherId,
				{[props.storeBookIdField]: self.state.storeBookId},
				{}
			))
				.then(result => {
					//	@todo: Transition state
				})
				.catch(err => {
					//	@todo: handle error
				})
			;
		}

		//	Save royalties - always as new records to keep track of history
		const royalties = [];
		royalties.push({
			publisherId: props.publisherId,
			storeBookId: self.state.storeBookId,
			royaltyPercentage: self.state.publisherRoyalty || 0,
			authorId: null,
			activeFrom: self.state.activeFrom,
		});

		props.authors.forEach(author => {
			royalties.push({
				publisherId: props.publisherId,
				storeBookId: self.state.storeBookId,
				royaltyPercentage: self.state['authorRoyalty_'+author.id] || 0,
				authorId: author.id,
				activeFrom: self.state.activeFrom,
			});
		});

		props.dispatch(feathersServices[SERVICE.BOOK_ROYALTIES].create(royalties, {}))
			.then(result => {
				//	@todo: Transition state
			})
			.catch(err => {
				//	@todo: handle error
			})
		;

	}

	getRoyaltiesMap(){
		const royaltiesMap = {publisher: {}, authors: {}, activeFrom: ''};
		this.props.bookRoyalties.forEach(item => {
			if(royaltiesMap.activeFrom === ''){
				royaltiesMap.activeFrom = item.activeFrom;
			}
			if(!item.authorId){
				royaltiesMap.publisher = item;
			} else {
				royaltiesMap.authors[item.authorId] = item;
			}
		});

		return royaltiesMap;
	}

	render(){
		const {
			authors,
			storeBookId,
			publisherName,
			canEdit,
		} = this.props;

		const royaltiesMap = this.getRoyaltiesMap();

		// const royaltiesMap = this.state.royaltiesMap;

		let totalPercent = this.state.publisherRoyalty;
		authors.forEach(author => totalPercent = quickFloatSum(totalPercent, this.state["authorRoyalty_"+ author.id]));

		const activeFrom = royaltiesMap.activeFrom ? new Date(royaltiesMap.activeFrom).toISOString().substr(0, 10) : null;

		const maxWidth = '100px';
		const royaltyOptions = {
			type: "number",
			step: "0.01",
			max: "100",
			disabled: !canEdit,
		};

		//	@todo: clean up the styles
		return (
			<Form className="grid-form" onChange={this.handleChange} onSubmit={this.handleSubmit}>
				<FormGroup row>
					<Label {...labelSizes}>Book ID</Label>
					<Col {...colSizes}>
						<Input
							name={"storeBookId"}
							value={this.state.storeBookId}
							defaultValue={storeBookId}
							disabled={! canEdit}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Col {...offsetSizesReverse}>
						{/*<h3><small>Royalties</small></h3>*/}
						<span className={totalPercent > 100 ? 'text-danger' : ''}>Total: {quickRound(totalPercent, 2)}%</span>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label {...labelSizes}>{publisherName}</Label>
					<Col {...colSizes}>
						<InputGroup>
							<Input style={{maxWidth: maxWidth}}
								   name={"publisherRoyalty"}
								   {...royaltyOptions}
								   value={quickRound(this.state.publisherRoyalty || 0, 2)}
								   defaultValue={quickRound(royaltiesMap.publisher.royaltyPercentage || 0, 2)}
							/>
							<InputGroupAddon addonType="append">
								<InputGroupText>%</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</FormGroup>
				<hr />
				{authors.map(author => {
					return (
						<FormGroup row key={"authorRoyalty_"+ author.id}>
							<Label {...labelSizes}>{author.name}</Label>
							<Col {...colSizes}>
								<InputGroup>
									<Input style={{maxWidth: maxWidth}}
										   name={"authorRoyalty_"+ author.id}
										   {...royaltyOptions}
										   value={quickRound(this.state["authorRoyalty_"+ author.id], 2)}
										   defaultValue={quickRound((royaltiesMap.authors[author.id] && royaltiesMap.authors[author.id].royaltyPercentage) || 0, 2)}
									/>
									<InputGroupAddon addonType="append">
										<InputGroupText>%</InputGroupText>
									</InputGroupAddon>
								</InputGroup>
							</Col>
						</FormGroup>
					);
				})}
				<hr />
				<FormGroup row>
					<Label {...labelSizes}>Active from:</Label>
					<Col {...colSizes}>
						<Input
							name={"activeFrom"}
							type={"date"}
							value={this.state.activeFrom}
							defaultValue={activeFrom}
							disabled={! canEdit}
						/>
					</Col>
				</FormGroup>
				{canEdit &&
				<FormGroup row>
					<Col {...offsetSizesReverse}>
						<Button type="primary" htmlType="submit">Update</Button>
					</Col>
				</FormGroup>}
			</Form>
		);
	}
}

export default connect((state, props) => ({
	authors: getAuthorsByBookId(state, props.bookId),
	bookRoyalties: getLatestBookRoyaltiesByStoreBookIdAndPublisherId(state, props.storeBookId, props.publisherId),
	//	@todo: get latest royalties values
}))(BookRoyaltiesForm);