import {getItemIdsByParam} from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'BOOK_REVIEWS';
// export const INITIAL_BOOK_REVIEWS_LOADED = 'INITIAL_BOOK_REVIEWS_LOADED';
export const ADD_BOOK_REVIEWS = 'ADD_'+ serviceName;
export const UPDATE_BOOK_REVIEWS = 'UPDATE_'+ serviceName;
export const REMOVE_BOOK_REVIEWS = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */


export function getAllBookReviews(state){
	const stateSlice = state.db.bookReviews;

	const bookReviews = stateSlice.allIds.map(reviewId => {
		const review = stateSlice.byId[reviewId];
		return review;
	});

	return bookReviews;
}

export function getAllBookReviewsByBookId(state, bookId){
	const stateSlice = state.db.bookReviews;

	const itemIds = getItemIdsByParam(stateSlice, 'bookId', bookId);

	const bookReviews = itemIds.map(reviewId => {
		const review = stateSlice.byId[reviewId];
		return review;
	});

	return bookReviews;
}


// export const bookReviewsReducer = (state = {
// 	initialState: true,
// 	byId: {},
// 	allIds: []
// }, action) => {
// 	let newState, payload;

// 	switch (action.type) {
// 		case feathersServices.getFindFulfilledAction(SERVICE.BOOK_REVIEWS):
// 			payload = action.payload.data || [];

// 			//	Fallthrough as the action is the same

// 		case ADD_BOOK_REVIEWS:
// 			newState = {...state};
// 			newState.initialState = false;

// 			payload = payload || action.payload;

// 			addItems(newState, payload);

// 			return newState;
// 		default:
// 			return state;
// 	}
// };

// export default bookReviewsReducer;

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);