import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card } from 'antd';
import CustomSiteCssCard from '../../components/sites/CustomSiteCssCard';
import CustomSiteJSCard from '../../components/sites/CustomSiteJSCard';
import SiteDetailsForm from '../../components/sites/SiteDetailsForm';
import SiteHeaderForm from '../../components/sites/SiteHeaderForm';
import SiteLayout from './Layout';
import { getSiteById, getSiteIdByDomain } from '../../reducers/db/sites';
import { SpinnerSmallWithText } from '@wpa/components/lib/Spinner';
import { SERVICE, feathersServices } from '@wpa/feathers-client';
import Logger from '@wpa/logger';
import { FORM_STATE, loadingStateMachine } from '@wpa/state-machine';
import { useMachine } from '@xstate/react';
import { SiteThemeForm } from '../../components/sites/SiteThemeForm';
import { FormAlert } from '@wpa/components/lib/Form';
import {
	SiteBackgroundImageCard,
	SiteHeaderLogoImageCard,
	SiteHeaderBackgroundImageCard,
} from '../../components/ImageCard';

class SiteFooterForm extends React.Component {
	render() {
		return <div>[To Do...]</div>;
	}
}

const m = loadingStateMachine({
	loadingAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.SITE_HEADERS].find({
						query: {
							siteId: context.siteId,
							$limit: 1,
							$sort: {
								updated_at: -1,
							},
						},
					})
				)
				.then(({ value: data }) => {
					context.header = data.data && data.data[0] && data.data[0];
					resolve();
				})
				.catch(
					(err) =>
						Logger.error(err) ||
						reject('Unable to load site header details.')
				);
		}),
});

export const SiteSettingsPage = ({
	match: {
		params: { slug },
	},
}) => {
	const dispatch = useDispatch();

	const siteId = useSelector((state) => getSiteIdByDomain(state, slug));
	const site = useSelector((state) => getSiteById(state, siteId)) || {};

	const [state, send] = useMachine(
		m.withContext({
			siteId,
			header: {},
			dispatch,
		})
	);

	const { header, errorMessage } = state.context;
	// const backgroundImagePath =	site && site.BackgroundImage ? site.BackgroundImage.path : null;

	const isError = state.matches(FORM_STATE.ERROR);
	const isLoadingError = state.matches(FORM_STATE.LOADING_ERROR);
	const isLoading = state.matches(FORM_STATE.LOADING);

	return (
		<SiteLayout pageName={'siteSettings'} title="Settings">
			{isLoading && (
				<SpinnerSmallWithText label="Loading site details..." />
			)}
			{(isError || isLoadingError) && (
				<FormAlert
					send={send}
					description={errorMessage}
					style={{ marginBottom: '1rem' }}
				/>
			)}
			{siteId && (
				<div>
					<Row gutter={16}>
						<Col sm={16}>
							<Card title={<h3>Details</h3>}>
								<SiteDetailsForm siteId={siteId} />
							</Card>
						</Col>
						<Col sm={8}>
							<SiteThemeForm
								siteId={siteId}
								layoutId={site.layoutId}
							/>
							<SiteBackgroundImageCard
								title="Background Image"
								id={'siteBackgroundImage'}
								siteId={siteId}
							/>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col>
							<h2>Header</h2>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col sm={16}>
							<Card title={<h3>Settings</h3>}>
								{header.id && (
									<SiteHeaderForm headerId={header.id} />
								)}
								{!isLoadingError && !header.id && 'Loading...'}
								{isLoadingError &&
									'Unable to load header details.'}
							</Card>
						</Col>
						<Col sm={8}>
							{header.id && (
								<SiteHeaderLogoImageCard
									title="Logo"
									id={'headerLogoImage'}
									siteId={siteId}
									headerId={header.id}
								/>
							)}

							{header.id && (
								<SiteHeaderBackgroundImageCard
									button="Background Image"
									title="Header Background Image"
									id={'headerBackgroundImage'}
									siteId={siteId}
									headerId={header.id}
								/>
							)}
						</Col>
					</Row>
					<Row gutter={16}>
						<Col>
							<h2>Footer</h2>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col sm={16}>
							<Card title={<h3>Footer</h3>}>
								<SiteFooterForm />
							</Card>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col>
							<h2>Customize</h2>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col sm={12}>
							<CustomSiteCssCard
								siteCustomCSS={site.customCSS}
								siteId={siteId}
							/>
						</Col>
						<Col sm={12}>
							<CustomSiteJSCard
								siteCustomJS={site.customJS}
								siteId={siteId}
							/>
						</Col>
					</Row>
				</div>
			)}
		</SiteLayout>
	);
};

export default withRouter(SiteSettingsPage);
