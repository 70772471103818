import React from 'react';
import {connect} from 'react-redux';

import {Card, CardBody, CardHeader, CardTitle, Col, Row,} from 'reactstrap';
import {getBookById} from '../../reducers/db/books';
import BookPageLayout from './Layout';
// import ImageList from '../../components/ImageEdit';
import feathersAPI from '@wpa/feathers-client';
import BookExternalAssetsForm from '../../components/books/ExternalAssetForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	ASSET_DROPBOX,
	ASSET_GDRIVE,
	ASSET_ONEDRIVE,
	getExternalAssetByBookIdAndTypeId
} from '../../reducers/db/bookExternalAssets';

export const BookAssetsPage = class extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			imageList: []
		};

		this.getBookCovers = this.getBookCovers.bind(this);
	}

	componentDidMount(){
		// this.getBookCovers();
	}

	getBookCovers(){
		return feathersAPI.service('book-images')
			.find({
				query: {
					bookId: this.props.bookId
				}
			})
			.then(json => json.data)
			.then(data => {
				this.setState({
					imageList: data
				})
			})
			;
	}

	render(){
		const {
			gdriveAsset,
			dropboxAsset,
			onedriveAsset,
			// book,
			bookId,
			// ...props
		} = this.props;

		let gfolderId = gdriveAsset.folder ? gdriveAsset.folder.match(/folders\/(.*)$/) : null;
		if(gfolderId) gfolderId = gfolderId[1];

		return (
			<BookPageLayout pageName={"bookAssets"}>
				<Row>
					<Col xs={12} sm={6} lg={4}>
						<Card className={gdriveAsset && gdriveAsset.folder ? '' : 'text-muted'}>
							<CardHeader>
								<CardTitle><FontAwesomeIcon icon={['fab', 'google-drive']} /> Google Drive</CardTitle>
							</CardHeader>
							<CardBody>
								<BookExternalAssetsForm bookId={bookId} typeId={1} />
							</CardBody>
						</Card>
					</Col>
					<Col xs={12} sm={6} lg={4}>
						<Card className={dropboxAsset && dropboxAsset.folder ? '' : 'text-muted'}>
							<CardHeader>
								<CardTitle><FontAwesomeIcon icon={['fab', 'dropbox']} /> Dropbox</CardTitle>
							</CardHeader>
							<CardBody>
								<BookExternalAssetsForm bookId={bookId} typeId={2} />
							</CardBody>
						</Card>
					</Col>
					<Col>
						<Card className={onedriveAsset && onedriveAsset.folder ? '' : 'text-muted'}>
							<CardHeader>
								<CardTitle><FontAwesomeIcon icon={['fab', 'microsoft']} /> OneDrive</CardTitle>
							</CardHeader>
							<CardBody>
								<BookExternalAssetsForm bookId={bookId} typeId={3} />
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card>

							<CardHeader>
								<CardTitle><FontAwesomeIcon icon={['fab', 'google-drive']} /> Google Drive Preview</CardTitle>
								<p><i>Save a full link to the gdrive folder above to see the preview below. Items will open in a new window when clicked. You will need to be logged into Google Drive on this browser.</i></p>
							</CardHeader>
							<CardBody>
								{gdriveAsset.folder && <p><a href={gdriveAsset.folder} target="_blank" rel="noopener noreferrer">Open gdrive folder</a></p>}
								{gfolderId && <iframe
									title="gdrive"
									src={'https://drive.google.com/embeddedfolderview?id='+ gfolderId +'#grid'}
									width="100%" height="400px"
									style={{
										border: 'none'
									}}
								>Iframe loading...</iframe>}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</BookPageLayout>
		);
	}
};

export default connect((state, props) => {
	const id = parseInt(props.match.params.id, 10);

	const gdriveAsset = getExternalAssetByBookIdAndTypeId(state, id, ASSET_GDRIVE) || {};
	const dropboxAsset = getExternalAssetByBookIdAndTypeId(state, id, ASSET_DROPBOX) || {};
	const onedriveAsset = getExternalAssetByBookIdAndTypeId(state, id, ASSET_ONEDRIVE) || {};

	return {
		gdriveAsset: gdriveAsset,
		dropboxAsset: dropboxAsset,
		onedriveAsset: onedriveAsset,
		gdriveAssetLatest: gdriveAsset.lastUpdated,
		dropboxAssetLatest: dropboxAsset.lastUpdated,
		onedriveAssetLatest: onedriveAsset.lastUpdated,
		bookId: id,
		book: getBookById(state, id),
	};
})(BookAssetsPage);