import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PA = {
	PAL: 'PAL',
	ALEX: 'Alex',
};

export class AssistantCharacter extends Component {
	static displayName = 'AssistantCharacter';
	static propTypes = {
		/**
		 * Character name
		 * See constants above (PA)
		 */
		name: PropTypes.string.isRequired,
		/**
		 * Character toggle
		 */
		toggle: PropTypes.func.isRequired,
		button: PropTypes.bool,
	};
	static defaultProps = {
		button: false,
	};

	render(){
		const props = this.props;
		let name, mascot;

		switch(props.name){
			case PA.PAL:
				name = 'P.A.L.';
				mascot = '/images/mascot-test-5b.png';
				break;
			case PA.ALEX:
				name = 'Alex';
				mascot = '/images/mascot-test.png';
				break;
			default:;
		}

		return (
			<div className={'assistant assistant-'+ props.name.toLowerCase()}>
				{props.button &&
				<a className="bubble" href="#fixme" onClick={props.toggle}>
					<span>{name}</span>
				</a>
				}
				{!props.button &&
				<div>
					<button
						onClick={props.toggle}
						className={'btn btn-primary float-right'}
						title={'Dismiss'}
					>
						<FontAwesomeIcon icon={['fas', 'times']} /></button>

					<img src={mascot} alt="Your PA" width={"150px"} />
					<span className="assistantName">
						{/*		@todo: link to config page		*/}
						<a
							onClick={props.toggle}
							href={'#fixme'}
							style={{
								color: '#fff',
								textDecoration: 'none',
							}}
						>
							<FontAwesomeIcon icon={['fas', 'cog']} size={'sm'}/>
							&nbsp;
							{name}
						</a>
						{/*Publishing*/}
						{/*Assistant*/}
						{/*Link*/}
					</span>
				</div>}
			</div>
		);
	}
}

export default AssistantCharacter;
