//	eslint-disable-next-line
import React from 'react';
import { withRouter } from 'react-router-dom';
import Menu from '../../../components/Menu';

export const SitePageMenu = ({
	match: {
		url,
		params: { slug, page }
	}
}) => {
	return (
		<Menu
			mode="horizontal"
			currentPath={url}
			// @todo: handle the Home page (/) that currently creates urls with "undefined" in them
			baseLink={'/sites/' + slug + '/page/' + (page || 'home')}
			menu={[
				{
					label: 'Content',
					pathSuffix: ''
				},
				{
					label: 'Sidebar A',
					pathSuffix: '/sidebar-a'
				},
				{
					label: 'Sidebar B',
					pathSuffix: '/sidebar-b'
				},
				// {
				// 	label: 'Customise',
				// 	pathSuffix: '/config'
				// }
			]}
		/>
	);
};

export default withRouter(SitePageMenu);
