import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFSM } from '../../functional/withStateMachine';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import {
	SpinnerSmallWithText,
	SpinnerSmall
} from '@wpa/components/lib/Spinner';
import { Button, formHandleInputChange, FormAlert } from '@wpa/components/lib/Form';
import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Form,
	FormGroup,
	Label
} from 'reactstrap';
import Notice from '@wpa/components/lib/Notice';
import { errorStateReducer } from '../../reducers';
import {
	xstateOnChange,
	loadingStateMachine,
	LOADING_STATE
} from '@wpa/state-machine';
import { RichTextArea } from '@wpa/components/lib/RichTextArea';
import { BookBlurb } from './BookBlurb';
import { useMachine } from '@xstate/react';
import { Row, Col } from 'antd';
import { getBookById } from '../../reducers/db/books';

const STATE = {
	LOADING: 'loading',
	ERROR: 'error',
	DISPLAY: 'display',
	SAVING: 'saving',
	PUBLISHING: 'publishing'
};

const ACTION = {
	ERROR: 'error',
	DISPLAY: 'display',
	SAVE: 'save',
	PUBLISH: 'publish'
};

const stateMachine = {
	initial: STATE.LOADING,
	states: {
		[STATE.LOADING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
					actions: ['setContent']
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR
				}
			}
		},
		[STATE.DISPLAY]: {
			on: {
				[ACTION.SAVE]: {
					target: STATE.SAVING
				},
				[ACTION.PUBLISH]: {
					target: STATE.PUBLISHING
				}
			}
		},
		[STATE.SAVING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
					actions: ['setContent']
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR
				}
			}
		},
		[STATE.PUBLISHING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
					actions: ['setContent']
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {}
		}
	}
};

const stateReducers = [
	errorStateReducer.reducer,
	(action, event) => {
		switch (action.type) {
			case 'setContent':
				return {
					content: event.content || {},
					blurb: event.blurb || ''
				};

			default: throw new Error('Unrecognised action type.');
		}
	}
];

export class BookBlurbBase extends React.Component {
	static displayName = 'BookBlurb';

	constructor(props) {
		super(props);

		this.state = {
			//	Blurb text
			blurb: '',
			//	Content object
			content: {},
			errorMessage: ''
		};

		this.onChange = formHandleInputChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onBookBlurbSave = this.onBookBlurbSave.bind(this);
		this.onPublish = this.onPublish.bind(this);
		this.onBookBlurbPublish = this.onBookBlurbPublish.bind(this);
		this.saveNewContentEntry = this.saveNewContentEntry.bind(this);

		addFSM(this, stateMachine, stateReducers);
	}

	componentDidMount() {
		const self = this;

		self.props
			.dispatch(
				feathersServices[SERVICE.BOOK_BLURB_CONTENTS].find({
					query: {
						bookBlurbId: this.props.blurb.id,
						$limit: 1,
						$sort: {
							updated_at: 'DESC'
						}
					}
				})
			)
			.then(payload => {
				self.transition(ACTION.DISPLAY, {
					content: payload.value.data && payload.value.data[0],
					blurb:
						payload.value.data &&
						payload.value.data[0] &&
						payload.value.data[0].content
				});

				// self.props.goToState('display', {
				// 	content: json.data[0],
				// 	blurb: json.data[0] && json.data[0].content ? json.data[0].content : ''
				// });
			})
			.catch(err =>
				self.transition(ACTION.ERROR, { message: err.message, error: err })
			);
	}

	onBookBlurbSave(payload) {
		this.saveNewContentEntry(false);
	}
	onBookBlurbPublish(payload) {
		this.saveNewContentEntry(true);
	}

	saveNewContentEntry(isPublished) {
		const self = this;

		self.props
			.dispatch(
				feathersServices[SERVICE.BOOK_BLURB_CONTENTS].create(
					{
						bookBlurbId: self.props.blurb.id,
						content: self.state.blurb,
						isPublished: isPublished
					},
					{}
				)
			)
			.then(payload => {
				self.transition(ACTION.DISPLAY, {
					content: payload.value,
					blurb: self.state.blurb
				});
			})
			.catch(err =>
				self.transition(ACTION.ERROR, { message: err.message, error: err })
			);
	}

	onSubmit(e) {
		e.preventDefault();
		if (!this.props.disabled) {
			this.transition(ACTION.SAVE, {});
		}
	}

	onPublish(e) {
		e.preventDefault();
		if (!this.props.disabled) {
			this.transition(ACTION.PUBLISH, {});
		}
	}

	render() {
		const props = this.props;
		const { currentState, errorMessage, content, blurb } = this.state;

		const bookBlurb = props.blurb;
		const disabled = !!props.disabled;

		//	State checks
		const isSaving = currentState === STATE.SAVING;
		const isPublishing = currentState === STATE.PUBLISHING;
		const isLoading = currentState === STATE.LOADING;
		const error = currentState === STATE.ERROR;

		// const charCount = blurb.replace(/<\/?[^>]+>/g, '').length;
		const trimmed = blurb.trim();
		const charCount = trimmed ? trimmed.length : 0;
		const wordCount = trimmed
			? (trimmed.replace(/['";:,.?¿\-!¡]+/g, '').match(/\S+/g) || []).length
			: 0;

		let send;
		return (
			<Card>
				<Form onChange={this.onChange} onSubmit={this.onSubmit}>
					<CardHeader>
						<CardTitle>
							<Label for={'bookBlurb-' + bookBlurb.id}>{bookBlurb.label}</Label>
						</CardTitle>
						<Row>
							<Col xs={4}>
								<small>
									{wordCount} words / {charCount} chars
								</small>
							</Col>
							{!disabled && (
								<Col xs={8} className="text-right">
									<Button type="primary" loading={isSaving} size="small">
										Save
									</Button>{' '}
									&nbsp;
									{(!content.isPublished || content.content !== blurb) && (
										<Button
											type="secondary"
											loading={isPublishing}
											size="small"
											onClick={this.onPublish}
										>
											Publish
										</Button>
									)}
								</Col>
							)}
						</Row>
					</CardHeader>
					<CardBody>
						{isLoading && (
							<SpinnerSmallWithText label="Loading blurb content..." />
						)}
						{error && <Notice color="danger">{errorMessage}</Notice>}
						{!isLoading && (
							<FormGroup>
								<RichTextArea
									id={'bookBlurb_' + bookBlurb.id}
									initialValue={content.content}
									textareaName="content"
									onChange={xstateOnChange(send)}
									height="300px"
								/>
								{/* <Input type="textarea" rows="10" name="blurb"
									   value={blurb}
									   defaultValue={content.content || ''}
									   id={"bookBlurb-"+bookBlurb.id}
								/> */}
							</FormGroup>
						)}
					</CardBody>
				</Form>
			</Card>
		);
	}
}

//	@todo: extract this to separate file
// const BookBlurb = connect((state) => ({

// }))(BookBlurbBase);

const stateMachine2 = loadingStateMachine({
	loadingAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.BOOK_BLURBS].find({
						query: {
							bookId: context.bookId,
							isArchived: false
						}
					})
				)
				.then(res => {
					if (res && res.value && res.value.data) {
						context.blurbs = res.value.data || [];
					}
					return resolve();
				})
				.catch(err => reject('Unable to load blurbs.'));
		})
});

export const BookBlurbs = ({ bookId }) => {
	const dispatch = useDispatch();
	const book = useSelector((state) => getBookById(state, bookId));

	const [state, send] = useMachine(
		stateMachine2.withContext({
			//	Static
			dispatch,
			bookId: bookId,
			blurbs: []
		})
	);

	const { errorMessage, blurbs } = state.context;

	const isError = state.matches(LOADING_STATE.ERROR);
	const isLoadingError = state.matches(LOADING_STATE.LOADING_ERROR);
	const isLoading = state.matches(LOADING_STATE.LOADING);

	const disabled = !book.User.canEditBlurbs;

	return (
		<div>
			<h2>
				{isLoading && <SpinnerSmall light inline style={{ height: '4em' }} />}
				Blurbs &nbsp;
				{/*<Button color="primary" onClick={this.addBlurb}><i className="fas fa-plus fa-fw"></i>Add</Button> &nbsp;*/}
			</h2>

			<Row gutter={16}>
				{/*	Error */}
				{(isError || isLoadingError) && (
					<Col>
						<FormAlert send={send} description={errorMessage} />
					</Col>
				)}
				{blurbs.map(item => (
					<Col sm={item.label === 'Excerpt' ? 24 : 12} key={item.id}>
						<BookBlurb blurb={item} disabled={disabled} />
					</Col>
				))}
			</Row>
		</div>
	);
};

export default BookBlurbs;
