//  eslint-disable-next-line
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	xstateOnChange,
	FORM_ACTION,
	FORM_STATE,
	formStateMachine
} from '@wpa/state-machine';
import { useMachine } from '@xstate/react';
import Logger from '@wpa/logger';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { Form, Input } from 'antd';
import { FormAlert, formItemLayout, Button } from '@wpa/components/lib/Form';
import {AuthorSelect} from '../../../author/Select';
import {getLatestSitePageAuthorBioForBlockId} from '../../../../reducers/db/sitePageAuthorBio';

const m = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			const params = {
				title: context.title,
				authorId: context.authorId || null,
			};

			if (!event.authorBioBlockId) {
				context
					.dispatch(
						feathersServices[SERVICE.SITE_PAGE_AUTHOR_BIO].create({
							blockId: event.blockId,
							...params
						})
					)
					.then(res => {
						context.defaultTitle = context.title;
						context.defaultAuthorId = context.authorId;
						resolve();
					})
					.catch(
						err =>
							Logger.error(err) || reject('Unable to save book block details.')
					);
			} else {
				context
					.dispatch(
						feathersServices[SERVICE.SITE_PAGE_AUTHOR_BIO].patch(event.authorBioBlockId, {
							...params
						})
					)
					.then(res => {
						context.defaultTitle = context.title;
						context.defaultAuthorId = context.authorId;
						resolve();
					})
					.catch(
						err =>
							Logger.error(err) || reject('Unable to save book block details.')
					);
			}
		})
});

export const SitePageBlockAuthorBioForm = ({ blockId }) => {
	const dispatch = useDispatch();
	const authorBioBlock = useSelector(state => getLatestSitePageAuthorBioForBlockId(state, blockId)) || {};

	const [state, send] = useMachine(
		m.withContext({
			blockId: blockId,
			dispatch: dispatch,
			title: authorBioBlock.title,
			defaultTitle: authorBioBlock.title,
			authorId: authorBioBlock.authorId,
			defaultAuthorId: authorBioBlock.authorId,
		})
	);

	const {
		errorMessage,
		title,
		defaultTitle,
		authorId,
		defaultAuthorId,
	} = state.context;

	const isChanged = defaultTitle !== title || defaultAuthorId !== authorId; 

	const isLoading = state.matches(FORM_STATE.LOADING);
	const isError = state.matches(FORM_STATE.ERROR);
	const isSaving = state.matches(FORM_STATE.SUBMITTING);
	const isDisabled = isSaving || ! isChanged;

	const authorBioBlockId = authorBioBlock.id;

	const handleSave = useCallback(
		e => {
			e.preventDefault();

			send(FORM_ACTION.SUBMIT, {
				blockId: blockId,
				authorBioBlockId: authorBioBlockId,
			});
		},
		[send, blockId, authorBioBlockId]
	);

	const onAuthorsChange = useCallback(
		value => 
			send(FORM_ACTION.CHANGE, {
				authorId: value
			}),
		[send]
	);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<Form
			onSubmit={handleSave}
			onChange={xstateOnChange(send)}
			layout="horizontal"
		>
			<Form.Item
				label="Title"
				extra="Optional heading for the block."
				{...formItemLayout}
			>
				<Input name={'title'} value={title} />
			</Form.Item>
			<Form.Item
				label="Author"
				{...formItemLayout}
				required
			>
				<AuthorSelect
					name="authorsInput"
					onChange={onAuthorsChange}
					//	If we don't make this a string we don't get a match initially
					selected={authorId ? authorId+'' : null}
					single
				/>
			</Form.Item>
			<Form.Item {...formItemLayout} label="&nbsp;">
				<Button
					onClick={handleSave}
					isLoading={isSaving}
					disabled={isDisabled}
					type="primary"
				>
					Save
				</Button>
			</Form.Item>
			{isError && <FormAlert send={send} description={errorMessage} />}
		</Form>
	);
};

export default SitePageBlockAuthorBioForm;
