//	eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllPermissions } from '../../reducers/db/permissions';
import { Checkbox, Row, Col } from 'antd';
import { feathersServices, SERVICE } from '@wpa/feathers-client';

export const UserPermissions = ({ permissions, userId }) => {
	const dispatch = useDispatch();
	const permissionList = useSelector(state => getAllPermissions(state)) || [];
	const permissionGroups = [...new Set(permissionList.map(p => p.category))];

	const [userPermissions, setUserPermissions] = useState(permissions);
	// const defaultValues = Object.keys(permissions).map(k => permissions[k]);

	//	Reset default permissions when we get updated permissions or change user
	useEffect(() => {
		setUserPermissions(permissions);
	}, [userId, permissions]);

	//	Save checkbox change
	const handleSave = e => {
		const isChecked = e.target.checked;
		const permissionId = e.target.value;

		dispatch(
			isChecked 
				? feathersServices[SERVICE.USER_PERMISSIONS]
					.create({
						userId: userId,
						permissionId: permissionId
					})
				: feathersServices[SERVICE.USER_PERMISSIONS]
					.remove(null, {
						query: {
							userId: userId,
							permissionId: permissionId
						}
					})
				
		)
			.then(res => {
				const newPermissions = {...userPermissions};
				
				//	Update local state
				if(isChecked){
					newPermissions[e.target['data-slug']] = permissionId;
				} else {
					delete newPermissions[e.target['data-slug']];
				}
				
				setUserPermissions(newPermissions);
			})
			//	@todo: add proper error handling / bugsnag?
			//	eslint-disable-next-line
			.catch(err => console.error('UserPermission handleSave', err))
		;
	};
	
	return (
		<div>
			<div>
				{permissionGroups.map(g => {
					return (
						<Row gutter={16} key={g}>
							<Col span={24}>
								<strong>{g}</strong>
							</Col>
							{permissionList
								.filter(p => p.category === g)
								.map(p => {
									const isChecked = !!userPermissions[p.slug];
									return (
										<Col span={24} key={p.id}>
											<Checkbox
												checked={isChecked}
												value={p.id}
												onChange={handleSave}
												data-slug={p.slug}
											>
												{p.label}
											</Checkbox>
										</Col>
									);
								})}
						</Row>
					);
				})}
			</div>
		</div>
	);
};

export default UserPermissions;
