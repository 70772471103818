//	eslint-disable-next-line
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import SitePageLayout  from './Layout';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import {addFSM} from '../../../functional/withStateMachine';
import {errorStateReducer, stateChangeReducer} from '../../../reducers';
import {getPageIdByPath} from '../../../reducers/db/sitePages';
import Notice from '@wpa/components/lib/Notice';
import {SpinnerSmallWithText} from '@wpa/components/lib/Spinner';
import {getSiteIdByDomain} from '../../../reducers/db/sites';
import SitePageBlocks, { AddSiteBlocks } from './Blocks';
import {Row, Col} from 'antd';
import SitePageSettings from '../../../components/sites/page/Settings';

// class MyEditor extends React.Component {
// 	constructor(props){
// 		super(props);
// 		this.state = {
// 			editorState: EditorState.createEmpty()
// 		};
// 		this.onChange = (editorState) => this.setState({editorState});
// 	}
//
// 	render(){
// 		return (
// 			<Editor
// 				editorState={this.state.editorState}
// 				onChange={this.onChange}
// 				toolbar={{
// 					inline: true
// 				}}
// 			/>
// 		)
// 	}
// }


export const STATE = {
	LOADING: 'loading',
	ERROR: 'error',
	DISPLAY: 'display'
};

export const ACTION = {
	ERROR: 'error',
	DISPLAY: 'display'
};

const stateMachine = {
	initial: STATE.LOADING,
	states: {
		[STATE.LOADING]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
					actions: [stateChangeReducer.setState]
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR
				},
			}
		},
		[STATE.DISPLAY]: {
			on: {
				[ACTION.DISPLAY]: {
					target: STATE.DISPLAY,
					actions: [stateChangeReducer.setState]
				},
			}
		},
		[STATE.ERROR]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {

			}
		}
	}
};

export const stateReducers = [
	errorStateReducer.reducer,
	stateChangeReducer.reducer
];

export class SitePageContentPage extends Component {
	static displayName = 'SitePageContentPage';
	static propTypes = {};
	static defaultProps = {};

	constructor(props){
		super(props);

		this.state = {
		};

		this.loadBlocks = this.loadBlocks.bind(this);

		addFSM(this, stateMachine, stateReducers);
	}

	componentDidMount(){
		// this.loadPages();
		this.loadBlocks();
	}

	componentWillUpdate(prevProps){
		if(this.props.pageId !== prevProps.pageId){
			// this.loadPages();
			this.loadBlocks();
		}
	}

	// loadPages(){
	// 	const props = this.props;

	// 	const siteId = props.siteId;
	// 	this.props
	// 		.dispatch(feathersServices[SERVICE.SITE_PAGES].find({
	// 			query: {
	// 				siteId: siteId
	// 			}
	// 		}))
	// 		.then(res => {
	// 			this.transition(ACTION.DISPLAY, {
	// 				pages: res.value.data || []
	// 			});
	// 		})
	// 		.catch(err => this.transition(ACTION.ERROR, {
	// 			message: 'Unable to load site pages',
	// 			error: err
	// 		}))
	// 	;
	// }

	loadBlocks(){
		const self = this;

		self.props.dispatch(
			feathersServices[SERVICE.SITE_PAGE_BLOCKS]
				.find({
					query: {
						pageId: self.props.pageId,
						$limit: 100,
						$sort: {
							sort: 'ASC',
							created_at: 'ASC'
						}
					}
				})
		)
			.then(payload=>{
				self.transition(ACTION.DISPLAY, {
					blocks: payload.value.data
				});
			})
			.catch(err => self.transition(ACTION.ERROR, {message: err.message, error: err}))
		;
	}

	render(){
		const {
			pageId,
			siteId,
		} = this.props;
		const {
			currentState,
			errorMessage,
		} = this.state;

		//	State checks
		// const isSaving = currentState === STATE.SAVING;
		const isLoading = currentState === STATE.LOADING;
		// const error = currentState === STATE.ERROR;

		if(isLoading){
			return (
				<SitePageLayout pageName="sitePageContent">
					<SpinnerSmallWithText label="Loading page content..." />
				</SitePageLayout>
			);
		}

		//	If we have no page don't do anything else
		if(!pageId){
			return (
				<SitePageLayout pageName="sitePageContent">
					No page found
				</SitePageLayout>
			);
		}

		return (
			<SitePageLayout pageName="sitePageContent">
				{currentState === STATE.ERROR &&
				<Notice color="danger">
					{errorMessage}
				</Notice>}
				{/*<ErrorBoundary>*/}
				<Row gutter={16}>
					<Col sm={16}>
						<SitePageBlocks pageId={pageId} siteId={siteId} />
					</Col>
					<Col sm={8}>
						<h3>Page Settings</h3>

						<SitePageSettings pageId={pageId} />

						<h3>Content Blocks</h3>
						<p>Theme Blocks</p>

						<p>Add blocks</p>
						<AddSiteBlocks pageId={pageId} />
					</Col>
				</Row>
				{/*</ErrorBoundary>*/}
			</SitePageLayout>
		);
	}
}


export default withRouter(connect((state, props) => {
	const siteId = getSiteIdByDomain(state, props.match.params.slug);
	const pageId = getPageIdByPath(state, props.match.params.page === 'home' ? '' : props.match.params.page);

	return {
		pageId: pageId,
		siteId: siteId,
	};
})(SitePageContentPage));