import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
	Row, Col
} from 'reactstrap';
import SiteLayout from "../Layout";
import SitePageMenu from "./Menu";

export class SitePageLayout extends Component {
	static displayName = 'SitePageLayout';
	static propTypes = {
		pageName: PropTypes.string.isRequired,
	};
	static defaultProps = {};

	// constructor(props){
	// 	super(props);
	// }

	render(){
		const props = this.props;

		return (
			<SiteLayout pageName={props.pageName}>
				<Row>
					<Col>
						<SitePageMenu/>
					</Col>
				</Row>
				{props.children}
			</SiteLayout>
		);
	}
}

export default SitePageLayout;