import { getItemIdsByParam } from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'SITE_PAGE_BLOCKS';
export const ADD_SITE_PAGE_BLOCKS = 'ADD_'+ serviceName;
export const UPDATE_SITE_PAGE_BLOCKS = 'UPDATE_'+ serviceName;
export const REMOVE_SITE_PAGE_BLOCKS = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */

// export function getSiteByDomain(domain, store){
// 	const siteId = getSiteIdByDomain(domain, store);
//
// 	return store.db.sites.byId[siteId];
// }
//
// export function getSiteIdByDomain(domain, store){
// 	const siteId = (store.db.sites.allIds || [])
// 		.filter(siteId => store.db.sites.byId[siteId].domain === domain)
// 		.pop();
//
// 	return siteId;
// }

export const addSitePageBlocks = items => ({
	type: ADD_SITE_PAGE_BLOCKS,
	payload: items
});

export const removeSitePageBlocks = items => ({
	type: REMOVE_SITE_PAGE_BLOCKS,
	payload: items
});

export const getSitePageBlockById = (state, blockId) => {
	const stateSlice = state.db.sitePageBlocks;
	return stateSlice.byId[blockId];
};
export const getAllSitePageBlockIds = (state, pageId) => {
	const stateSlice = state.db.sitePageBlocks;
	return getItemIdsByParam(stateSlice, 'pageId', pageId);
};
export const getAllSiteSidebarBlockIds = (state, sidebarId) => {
	const stateSlice = state.db.sitePageBlocks;
	return getItemIdsByParam(stateSlice, 'sidebarId', sidebarId);
};
export const getAllSitePageBlocksByPage = (state, pageId) => {
	const stateSlice = state.db.sitePageBlocks;
	return getAllSitePageBlockIds(state, pageId)
		.map(itemId => stateSlice.byId[itemId])
		.sort((a, b) => a.sort >= b.sort);
};
export const getAllSitePageBlocksBySidebar = (state, sidebarId) => {
	const stateSlice = state.db.sitePageBlocks;
	return getAllSiteSidebarBlockIds(state, sidebarId)
		.map(itemId => stateSlice.byId[itemId])
		.sort((a, b) => a.sort >= b.sort);
};

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);