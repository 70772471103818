import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Card, CardBody, CardTitle, Table} from 'reactstrap';
import { getSalesTotalByPublisherId } from '../../reducers/db/bookSales';
import { getAllAuthorsMap } from '../../reducers/db/authors';
import { quickRound } from '../../utils/maths';
import { getExpensesTotalByType } from '../../reducers/db/expenses';

export class AuthorPayoutsCard extends React.Component {
    static displayName = 'AuthorPayouts';
    static propTypes = {
        publisherId: PropTypes.number,
    };
    static defaultProps = {

    };

    // constructor(props){
    //     super(props);
    // }

    render() {
        const {
            authorsMap,
            authorSales,
            authorExpenses,
        } = this.props;

        console.log('SALES', authorExpenses);
        
        return (
            <Card>
                <CardBody>
                    <CardTitle>Author Payouts</CardTitle>
                    <p><small>Negative payments indicate advances.</small></p>
                    <Table
                        striped
                        responsive
                    >
                        <thead>
                            <tr>
                                <th>To Pay</th>
                                <th>Total</th>
                                <th>Author</th>
                            </tr>
                        </thead>
                        <tbody>
                        {authorSales.map(sales => {
                            const unpaidExpense =  sales.partialRoyalties - (authorExpenses.find(expense => expense.authorId === sales.authorId) || {}).expense; 
                            const unpaidClass = unpaidExpense > 0 ? 'text-danger' : unpaidExpense < 0 ? 'text-success' : '';

                            return (
                            <tr key={sales.authorId}>
                                <td className={unpaidClass}><span>&pound;{quickRound(unpaidExpense, 2)}</span></td>
                                <td><span>&pound;{quickRound(sales.partialRoyalties, 2)}</span></td>
                                <td>{authorsMap[sales.authorId].name}</td>
                            </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        );
    }
}

export default connect((state, props) => {
console.log(props);
    return {
		authorsMap: getAllAuthorsMap(state),
        authorSales: getSalesTotalByPublisherId(state, props.publisherId, 'ALL_TIME_BY_AUTHOR_BY_PUBLISHER'),
        authorExpenses: getExpensesTotalByType(state, 'ALL_TIME_BY_AUTHOR')
    };
})(AuthorPayoutsCard);
