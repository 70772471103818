import {getSalesTotalByBookId} from '../../../reducers/db/bookSales';
import PropTypes from 'prop-types';
import {quickRound} from '../../../utils/maths';

export function salesConnect(state, props){
	let sales;

	switch(props.type){
		case 'BOOK':
			sales = getSalesTotalByBookId(state, props.itemId, 'ALL_TIME_BY_BOOK')[0] || {};

			return {
				unpaidSales: parseInt(sales.unpaidSales || 0, 10),
				unpaidKenp: parseInt(sales.unpaidKenp || 0, 10),
				unpaidReturns: parseInt(sales.unpaidReturns || 0, 10),
				sales: parseInt(sales.sales || 0, 10),
				kenp: parseInt(sales.kenp || 0, 10),
				returns: parseInt(sales.returns || 0, 10),
				royalties: parseFloat(quickRound(sales.royalties || 0, 2)),
			};

		default: return {

		};
	}
}

export const salesPropTypes = {
	sales: PropTypes.number,
	kenp: PropTypes.number,
	returns: PropTypes.number,
	GBPRoyalty: PropTypes.number,
	type: PropTypes.oneOf(['USER', 'BOOK', 'PUBLISHER', 'AUTHOR']),
	itemId: PropTypes.number,
};
export const salesDefaultProps = {
	sales: 0,
	kenp: 0,
	returns: 0,
	GBPRoyalty: 0,
	type: '',
	itemId: null,
};

export function getStoreName(storeId){
	switch(storeId){
		case 1: return 'Amazon.com';
		case 2: return 'Amazon.co.uk';
		case 3: return 'Amazon.de';
		case 4: return 'Amazon.fr';
		case 5: return 'Amazon.ca';
		case 6: return 'Amazon.com.br';
		case 7: return 'Amazon.it';
		case 8: return 'Amazon.com.au';
		case 9: return 'Amazon.in';
		case 10: return 'Amazon.com.mx';
		case 11: return 'Amazon.co.jp';
		case 12: return 'Amazon.es';
		case 13: return 'Amazon.nl';
		default: return '-';
	}
}