//	eslint-disable-next-line
import React, { useEffect, useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { List, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Button, FormAlert } from '@wpa/components/lib/Form';
import { getAllSitePagesBySite } from '../../reducers/db/sitePages';
import { getSiteIdByDomain } from '../../reducers/db/sites';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	formStateMachine,
	FORM_STATE,
	FORM_ACTION,
	xstateOnChange,
} from '@wpa/state-machine';
import { useMachine } from '@xstate/react';
import { SERVICE, feathersServices } from '@wpa/feathers-client';

const stateMachine = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.SITE_MENU_PAGES].create(
						{
							menuId: context.menuId,
							pageId: context.pageId,
						},
						{
							query: {
								includes: {
									page: true,
								},
							},
						}
					)
				)
				.then((res) => {
					return resolve();
				})
				.catch((err) => reject('Unable to save page.'));
		}),
});

const ListItemButton = ({ pageTitle, menuId, pageId, onError }) => {
	const dispatch = useDispatch();

	const [state, send] = useMachine(
		stateMachine.withFormContext(
			{
				dispatch,
				onError,
				menuId,
				pageId,
			},
			{}
		)
	);

	const isSaving = state.matches(FORM_STATE.SUBMITTING);
	const isDisabled = isSaving || stateMachine.hasChanged(state);

	const handleSave = useCallback(
		() => {
			send(FORM_ACTION.SUBMIT, {});
		},
		//	We explicitely want these to force a change
		//	@todo: test if this is needed
		//	eslint-disable-next-line
		[send, menuId, pageId]
	);

	return (
		<Form
			onFinish={handleSave}
			onChange={xstateOnChange(send)}
			layout="horizontal"
		>
			<Button
				type="primary"
				size="small"
				htmlType="submit"
				disabled={isDisabled}
				loading={isSaving}
			>
				<FontAwesomeIcon
					icon={['fas', 'long-arrow-alt-right']}
					size="lg"
					title={'Add ' + pageTitle + ' page to the menu'}
				/>
			</Button>
		</Form>
	);
};

const SiteMenuAvailablePageList = ({
	match: {
		params: { slug, menuId },
	},
}) => {
	const siteId = useSelector((state) => getSiteIdByDomain(state, slug));
	const pages = useSelector((state) => getAllSitePagesBySite(state, siteId));

	const [error, setError] = useState([]);

	return (
		<List>
			{pages.map((page) => (
				<List.Item
					key={menuId + '-' + page.id}
					style={{ flexWrap: 'wrap' }}
					actions={[
						<ListItemButton
							pageTitle={page.title}
							pageId={page.id}
							menuId={parseInt(menuId, 10)}
							onError={(em, e) => {
								if (e) {
									error[page.id] = (
										<FormAlert
											description={em}
											style={{
												marginTop: '1em',
												marginBottom: 0,
											}}
										/>
									);
								} else {
									delete error[page.id];
								}
								setError([...error]);
							}}
						/>,
					]}
					extra={error[page.id]}
				>
					{page.title}
				</List.Item>
			))}
		</List>
	);
};

export default withRouter(SiteMenuAvailablePageList);
