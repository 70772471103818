import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input } from '@wpa/components/lib/Form';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { Row, Col } from 'antd';
import AuthorSelect from '../author/Select';
import BookSeriesSelect from './BookSeriesSelect';
import { getAuthorsByBookId } from '../../reducers/db/authorBooks';
import { getSeriesByBookId } from '../../reducers/db/series';
import { useMachine } from '@xstate/react';
import { formStateMachine, FORM_ACTION } from '@wpa/state-machine';
import { getBookById } from '../../reducers/db/books';
import { Logger } from '@wpa/logger';

const stateMachine = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.BOOKS].patch(
						context.bookId,
						{
							title: context.fields.title,
							logline: context.fields.logline,
							authors: context.fields.authorList,
							series: context.fields.seriesList,
							bookLength: context.fields.bookLength,
							heatText: context.fields.heatText,
							heatRating: context.fields.heatRating,
							pinterest: context.fields.pinterest,
							goodreads: context.fields.goodreads,
							themes: context.fields.themes,
							warning: context.fields.warning,
							genre: context.fields.genre,
						},
						{}
					)
				)
				//	@todo reset defaults
				.then((data) => resolve())
				.catch(
					(err) =>
						Logger.error(err) ||
						reject('Unable to save book details.')
				);
		}),
});

export const BookSummaryForm = ({ bookId }) => {
	const dispatch = useDispatch();

	const book = useSelector((state) => getBookById(state, bookId));
	const authors = useSelector((state) =>
		getAuthorsByBookId(state, bookId).map((author) => ({
			id: author.id,
			name: author ? author.name : '',
		}))
	);
	const series = useSelector((state) =>
		getSeriesByBookId(state, bookId).map((series) => ({
			id: series.id,
			name: series ? series.name : '',
		}))
	);

	const [state, send] = useMachine(
		stateMachine.withFormContext(
			{
				dispatch,
				bookId,
			},
			{
				authorList: authors.map((a) => a.id + '') || [],
				seriesList: series.map((s) => s.id + '') || [],
				title: book.title || '',
				logline: book.logline || '',
				bookLength: book.bookLength,
				heatRating: book.heatRating,
				heatText: book.heatText,
				pinterest: book.pinterest,
				goodreads: book.goodreads,
				themes: book.themes,
				warning: book.warning,
				genre: book.genre,
			}
		)
	);

	const {
		title,
		logline,
		bookLength,
		heatRating,
		heatText,
		pinterest,
		goodreads,
		themes,
		warning,
		genre,
		authorList,
		seriesList,
	} = state.context.fields;

	const { defaults } = state.context;

	const disabled = false;

	const onAuthorsChange = useCallback(
		(payload) => {
			send(FORM_ACTION.CHANGE, {
				fields: { authorList: payload },
			});
		},
		[send]
	);
	const onBookSeriesChange = useCallback(
		(payload) => {
			send(FORM_ACTION.CHANGE, {
				fields: { seriesList: payload },
			});
		},
		[send]
	);

	return (
		<Form
			send={send}
			stateMachine={stateMachine}
			state={state}
			buttonRow={{ label: 'Update' }}
		>
			<Form.Item htmlFor="bookSummaryForm-title" label="Title">
				<Input
					name="title"
					id="bookSummaryForm-title"
					defaultValue={defaults.title}
					value={title}
					disabled={disabled}
				/>
			</Form.Item>
			<Form.Item htmlFor="bookSummaryForm-logline" label="Logline">
				<Input
					name="logline"
					id="bookSummaryForm-logline"
					defaultValue={defaults.logline}
					value={logline}
					disabled={disabled}
				/>
			</Form.Item>
			<Form.Item htmlFor="bookSummaryForm-authors" label="Author(s)">
				<AuthorSelect
					name="authorsInput"
					onChange={onAuthorsChange}
					selected={authorList}
					allowNew
				/>
			</Form.Item>
			<Form.Item htmlFor="bookSummaryForm-series" label="Series">
				<BookSeriesSelect
					name="series"
					onChange={onBookSeriesChange}
					selected={seriesList}
					allowNew
				/>
			</Form.Item>
			<Form.Item htmlFor="bookSummaryForm-bookLength" label="Book Length">
				<Input
					name="bookLength"
					id="bookSummaryForm-bookLength"
					defaultValue={defaults.bookLength}
					value={bookLength}
					disabled={disabled}
					placeholder="50,000 words"
				/>
			</Form.Item>

			<Form.Item
				htmlFor="bookSummaryForm-heatText"
				label="Heat Level"
				extra="Heat rating description and / or a 0-5 rating"
			>
				<Input.Group>
					<Row gutter={0}>
						<Col flex="auto">
							<Input
								type="text"
								name="heatText"
								id="bookSummaryForm-heatText"
								defaultValue={defaults.heatText || ''}
								value={heatText}
								disabled={disabled}
								placeholder="Describe the heat level"
							/>
						</Col>
						<Col flex="80px">
							<Input
								type="number"
								name="heatRating"
								id="bookSummaryForm-heatRating"
								defaultValue={
									defaults.heatRating === 0
										? '0'
										: defaults.heatRating
								}
								value={heatRating === 0 ? '0' : heatRating}
								disabled={disabled}
								max="5"
								min="0"
								placeholder="0"
							/>
						</Col>
					</Row>
				</Input.Group>
			</Form.Item>

			<Form.Item
				htmlFor="bookSummaryForm-pinterest"
				label="Book Pinterest"
			>
				<Input
					name="pinterest"
					id="bookSummaryForm-pinterest"
					defaultValue={defaults.pinterest}
					value={pinterest}
					disabled={disabled}
					placeholder="link..."
				/>
			</Form.Item>

			<Form.Item
				htmlFor="bookSummaryForm-goodreads"
				label="Book Goodreads"
			>
				<Input
					name="goodreads"
					id="bookSummaryForm-goodreads"
					defaultValue={defaults.goodreads}
					value={goodreads}
					disabled={disabled}
					placeholder="link..."
				/>
			</Form.Item>

			<Form.Item htmlFor="bookSummaryForm-themes" label="Book Themes">
				<Input
					name="themes"
					id="bookSummaryForm-themes"
					defaultValue={defaults.themes}
					value={themes}
					disabled={disabled}
					placeholder="werewolf, urban, alpha, historical, ..."
				/>
			</Form.Item>
			<Form.Item htmlFor="bookSummaryForm-warning" label="Book Warning">
				<Input
					name="warning"
					id="bookSummaryForm-warning"
					defaultValue={defaults.warning}
					value={warning}
					disabled={disabled}
					placeholder="Content warning goes here..."
				/>
			</Form.Item>
			<Form.Item htmlFor="bookSummaryForm-genre" label="Book Genre">
				<Input
					name="genre"
					id="bookSummaryForm-genre"
					defaultValue={defaults.genre}
					value={genre}
					disabled={disabled}
					placeholder="Romance, Sci-fi, ..."
				/>
			</Form.Item>

			{/*<FormGroup row>*/}
			{/*<Label md={4} lg={3} for="bookSummaryForm-asin">ASIN</Label>*/}
			{/*<Col md={8} lg={9}>*/}
			{/*<Input*/}
			{/*type="text" name="asin" id="bookSummaryForm-asin"*/}
			{/*defaultValue={book.asin || ''}*/}
			{/*value={asin}*/}
			{/*/>*/}
			{/*</Col>*/}
			{/*</FormGroup>*/}

			{/*<FormGroup row>*/}
			{/*<Label md={4} lg={3}>Publisher</Label>*/}
			{/*<Col md={8} lg={9} className="form-control-plaintext">*/}
			{/*<Input type="text" name="name" defaultValue={"Wing & Fang Press"}/>*/}
			{/*</Col>*/}
			{/*</FormGroup>*/}

			{/*<FormGroup row>*/}
			{/*<Label md={4} lg={3}>World</Label>*/}
			{/*<Col md={8} lg={9} className="form-control-plaintext">*/}
			{/*<Input type="text" name="name" defaultValue={"Eonika"}/>*/}
			{/*</Col>*/}
			{/*</FormGroup>*/}
		</Form>
	);
};

BookSummaryForm.propTypes = {
	bookId: PropTypes.number.isRequired,
};

export default BookSummaryForm;
