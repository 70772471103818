//	eslint-disable-next-line
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import SitePageLayout from './Layout';
import { Row, Col, Alert } from 'antd';

// import { getPageIdByPath } from '../../../reducers/db/sitePages';
import { getSiteIdByDomain } from '../../../reducers/db/sites';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { AddSiteBlocks, SiteSidebarBlocks } from './Blocks';
import { loadingStateMachine } from '@wpa/state-machine';
import { useMachine } from '@xstate/react';
import { FormAlert } from '@wpa/components/lib/Form';

const LOADING_STATE = {
	LOADING: 'loading',
	ERROR: 'error',
	DISPLAY: 'display'
};

const LOADING_ACTION = {
	LOAD: 'load',
	ERROR: 'error',
	DISPLAY: 'display'
};

const stateMachine = loadingStateMachine({
	loadingAction: (context, event) => new Promise((resolve, reject) => {
		context.dispatch(
			feathersServices[SERVICE.SITE_SIDEBARS]
				.find({
					query: {
						name: event.sidebarName || context.sidebarName,
						siteId: context.siteId,
						$limit: 1
					}
				})
		)
			.then(payload => {
				if(! payload || ! payload['value'] || ! payload['value']['data'] || ! (payload['value']['total'] > 0)){
					context.sidebarId = null;
					return reject('No sidebar found.');
				}

				//	Save the sidebarId
				context.sidebarId = payload['value']['data'][0]['id'];

				context.dispatch(
					feathersServices[SERVICE.SITE_PAGE_BLOCKS]
						.find({
							query: {
								pageId: null,
								sidebarId: context.sidebarId,
								$limit: 100,
								$sort: {
									sort: 'ASC',
									created_at: 'ASC'
								}
							}
						})
				)
					.then(payload => {
						resolve();
					})
					.catch(err => reject('Unable to fetch blocks.'))
				;
			})
			.catch(err => reject('Unable to fetch sidebar details.'))
		;
	})
});


export const SitePageSidebarPage = ({
	match: {
		params: { 
			slug, 
			page, 
			sidebar 
		}
	}
}) => {
	const dispatch = useDispatch();
	const siteId = useSelector(state => getSiteIdByDomain(state, slug));
	// const pageId = useSelector(state => getPageIdByPath(state, page ? page : ''));

	
	const [state, send] = useMachine(stateMachine.withContext({
		siteId,
		sidebarName: 'sidebar-'+ sidebar,
		dispatch
	}));
	
	const isError = state.matches(LOADING_STATE.ERROR);
	const isLoading = state.matches(LOADING_STATE.LOADING);

	//	Hacky solution to make sure we force a reloading of the blocks when we switch between sidebars
	useEffect(() => {
		send(LOADING_ACTION.LOAD, {
			sidebarName: 'sidebar-'+ sidebar
		});
	}, [sidebar, send]);

	const {
		sidebarId,
		errorMessage
	} = state.context;

	if(isLoading){
		return <SitePageLayout pageName="sitePageSidebar">Loading...</SitePageLayout>;
	}

	return (
		<SitePageLayout pageName="sitePageSidebar">
			<Row gutter={16}>
				<Col sm={16}>
					{isError && <FormAlert send={send} description={errorMessage} />}
					{sidebarId && <SiteSidebarBlocks sidebarId={sidebarId} />}
				</Col>
				<Col sm={8}>
					<Alert
						message="Global sidebars"
						description="Sidebars are not page specific. Changing blocks here will change them for all pages!"
						type="warning"
						showIcon
					/>
					<br />
					{sidebarId && <AddSiteBlocks sidebarId={sidebarId} className="" />}
				</Col>
			</Row>
		</SitePageLayout>
	);
};

export default withRouter(SitePageSidebarPage);
