import React from 'react';
import { connect } from 'react-redux';

import {
	Card,
	CardBody,
	// CardHeader,
	// CardTitle,
	Col,
	// Form,
	// FormGroup,
	// InputGroup,
	// InputGroupAddon,
	// InputGroupText,
	// Label,
	Row,
	Table,
	// CardImg
} from 'reactstrap';
import BookPageLayout from './Layout';
import {
	// getSalesTotalByPublisherId,
	getSalesTotalByAuthorId
} from '../../reducers/db/bookSales';
import { quickFloatSum, quickRound } from '../../utils/maths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { getPublisherById } from '../../reducers/db/publishers';
import {
	// Button,
	ButtonModal,
	// colSizes,
	formHandleInputChange,
	// Input,
	// labelSizes,
	// offsetSizesReverse
} from '@wpa/components/lib/Form';
import ExchangeRateForm from '../../components/books/ExchangeRateForm';
import { getAllBooksMap } from '../../reducers/db/books';
import { getAllAuthorsMap } from '../../reducers/db/authors';
import { Link } from 'react-router-dom';
import { imagePath } from '../../utils/imagePath';
import ExpenseForm from '../../components/shared/ExpenseForm';
import {
	// getExpensesTotalByAuthorId,
	getExpensesTotalByType
} from '../../reducers/db/expenses';
// import AuthorPayoutsCard from '../../components/publishers/AuthorPayouts';

const BookSalesRow = props => {
	const { sales, book } = props;

	const isUnpaid =
		sales.unpaidSales > 0 || sales.unpaidKenp > 0 || sales.unpaidRefunds > 0;

	const expenses = 0;

	return (
		<tr
			className={isUnpaid ? 'text-danger' : ''}
			key={sales.salesPeriod + '-' + sales.storeBookId + '-sales'}
		>
			<td className="text-right">
				{book.Cover && book.link && (
					<Link to={book.link}>
						<img
							className="float-left"
							width="50"
							src={imagePath(encodeURI(book.Cover.Image.path))}
							alt={'Cover for ' + book.title}
						/>
					</Link>
				)}
				{book.link && (
					<Link to={book.link}>
						<small>{book.title}</small>
					</Link>
				)}
				{!book.link && <small>{book.title}</small>}
			</td>
			{/* <td className="text-right">
				<b>{isUnpaid ? 'N/A' : '£' + quickRound(sales.royalties, 2)}</b>
			</td> */}
			{/* <td className="text-right">
				{isUnpaid
					? 'N/A'
					: '£' +
					  quickRound(
							quickFloatSum(sales.royalties, -sales.partialRoyalties),
							2
					  )}
			</td> */}
			<td className="text-right">
				<b>{isUnpaid ? 'N/A' : '£' + quickRound(sales.partialRoyalties, 2)}</b>{' '}
				<small>
					({isUnpaid ? 'N/A' : '£' + quickRound(sales.royalties, 2)})
				</small>
			</td>
			<td className="text-right">&pound;{expenses}</td>
			<td className="text-right">
				{isUnpaid
					? 'N/A'
					: '£' +
					  quickRound(quickFloatSum(sales.partialRoyalties, -expenses), 2)}
			</td>
			<td className="text-right">{sales.sales}</td>
			<td className="text-right">{sales.kenp}</td>
			<td className="text-right">{sales.refunds}</td>
			<td className="text-right">&nbsp;</td>
		</tr>
	);
};
const AuthorSalesRow = props => {
	const sales = props.sales || {};
	const author = props.author || {};
	const expenses = (props.expenses && props.expenses.expense) || 0;
	const outstandingPayments = quickRound(sales.partialRoyalties - expenses, 2);

	const isUnpaid =
		sales.unpaidSales > 0 || sales.unpaidKenp > 0 || sales.unpaidRefunds > 0;

	return [
		<tr
			className={isUnpaid ? 'text-danger' : ''}
			key={sales.salesPeriod + '-' + sales.storeBookId + '-sales'}
		>
			<td colSpan="3" className="text-right">
				<small>
					{author.link && <Link to={author.link}>{author.name}</Link>}
					{!author.link && (author.name || 'Other')}
				</small>
			</td>
			<td className="text-right">
				{isUnpaid || !props.author
					? 'N/A'
					: '£' + quickRound(sales.partialRoyalties, 2)}
			</td>
			<td className="text-right">{'£' + quickRound(expenses, 2)}</td>
			<td
				className={
					'text-right ' +
					(outstandingPayments > 0
						? 'text-danger'
						: outstandingPayments < 0
						? 'text-success'
						: '')
				}
			>
				{'£' + outstandingPayments}
			</td>
			<td className="text-right">{sales.sales}</td>
			<td className="text-right">{sales.kenp}</td>
			<td className="text-right">{sales.refunds}</td>
			<td className="text-right">
				{!isUnpaid && props.author && (
					<ButtonModal
						title={'Expenses for ' + props.author.name}
						modal={
							<ExpenseForm
								authorId={props.author.id}
								invoicedAt={new Date(sales.salesPeriod + '-01')}
							/>
						}
					>
						<FontAwesomeIcon
							icon={['fas', 'wallet']}
							title="Add Expense"
							fixedWidth
						/>
					</ButtonModal>
				)}
			</td>
		</tr>
	];
};

//	eslint-disable-next-line
const ExpensesRow = props => {
	const sales = props.sales;

	const isUnpaid =
		sales.unpaidSales > 0 || sales.unpaidKenp > 0 || sales.unpaidRefunds > 0;

	const expenses = 0;

	return (
		<tr className={isUnpaid ? 'text-danger' : ''} key={'salesRowMain'}>
			<td>&nbsp;</td>
			<td>&nbsp;</td>
			<td>{isUnpaid ? 'N/A' : '£' + quickRound(sales.partialRoyalties, 2)}</td>
			<td>
				{isUnpaid
					? 'N/A'
					: '£' +
					  quickRound(
							quickFloatSum(sales.royalties, -sales.partialRoyalties),
							2
					  )}
			</td>
			<td>&pound;</td>
			<td>
				{isUnpaid
					? 'N/A'
					: '£' +
					  quickRound(quickFloatSum(sales.partialRoyalties, -expenses), 2)}
			</td>
			<td>{sales.sales}</td>
			<td>{sales.kenp}</td>
			<td>{sales.refunds}</td>
			<td>&nbsp;</td>
		</tr>
	);
};

const SalesRow = props => {
	const sales = props.sales;

	const isUnpaid =
		sales.unpaidSales > 0 || sales.unpaidKenp > 0 || sales.unpaidRefunds > 0;

	const expenses = 0;

	const style = {
		fontSize: '1.2em',
		fontWeight: 'bold'
	};

	return (
		<tr
			className={isUnpaid ? 'text-danger' : ''}
			key={'salesRowMain'}
			style={style}
		>
			<td className="text-left">{sales.salesPeriod}</td>
			{/* <td className="text-right">
				<b>{isUnpaid ? 'N/A' : '£' + quickRound(sales.royalties, 2)}</b>
			</td> */}
			{/* <td className="text-right">
				{isUnpaid
					? 'N/A'
					: '£' +
						quickRound(
							quickFloatSum(sales.royalties, -sales.partialRoyalties),
							2
						)}
			</td> */}
			<td className="text-right">
				{isUnpaid ? 'N/A' : '£' + quickRound(sales.partialRoyalties, 2)}{' '}
				<small>
					({isUnpaid ? 'N/A' : '£' + quickRound(sales.royalties, 2)})
				</small>
			</td>
			<td className="text-right">&pound;{expenses}</td>
			<td className="text-right">
				{isUnpaid
					? 'N/A'
					: '£' +
					  quickRound(quickFloatSum(sales.partialRoyalties, -expenses), 2)}
			</td>
			<td className="text-right">{sales.sales}</td>
			<td className="text-right">{sales.kenp}</td>
			<td className="text-right">{sales.refunds}</td>
			<td className="text-right">
				{isUnpaid && props.canUpdateSales && (
					<ButtonModal
						title={sales.salesPeriod + ' Exchange Rates'}
						modal={<ExchangeRateForm salesPeriod={sales.salesPeriod} />}
					>
						<FontAwesomeIcon
							icon={['fas', 'pound-sign']}
							title="Update Exchange Rates"
							fixedWidth
						/>
					</ButtonModal>
				)}
			</td>
		</tr>
	);
};

export class AuthorSalesPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			details: '2'
		};

		this.handleFormChange = formHandleInputChange.bind(this);
	}

	render() {
		const props = this.props;
		const {
			authorsMap,
			booksMap,
			// publisher,
			bookSales,
			bookSalesByBook,
			// authorSales,
			authorExpenses
		} = props;

		// const { details } = this.state;

		const salesPeriodMap = {};
		const authorSalesPeriodMap = {};
		const authorExpensesPeriodMap = {};

		bookSalesByBook.forEach(sales => {
			if (!salesPeriodMap[sales.salesPeriod]) {
				salesPeriodMap[sales.salesPeriod] = [];
			}

			salesPeriodMap[sales.salesPeriod].push(sales);
		});

		// authorSales.forEach(sales => {
		// 	if (!authorSalesPeriodMap[sales.salesPeriod]) {
		// 		authorSalesPeriodMap[sales.salesPeriod] = [];
		// 	}

		// 	authorSalesPeriodMap[sales.salesPeriod].push(sales);
		// });

		authorExpenses.forEach(expense => {
			if (!authorExpensesPeriodMap[expense.salesPeriod]) {
				authorExpensesPeriodMap[expense.salesPeriod] = [];
			}

			authorExpensesPeriodMap[expense.salesPeriod].push(expense);
		});

		const details = '1';

		return (
			<BookPageLayout pageName={'bookSales'}>
				<h2 className="">Sales</h2>

				<Row>
					<Col>
						<Card>
							<CardBody>
								<Table striped responsive>
									<thead>
										<tr>
											<th>Sales period</th>
											{/* <th className="text-right">
												<FontAwesomeIcon
													icon={['fas', 'pound-sign']}
													title="Royalties"
												/>
											</th> */}
											{/* <th className="text-right">
												<FontAwesomeIcon icon={['fas', 'pound-sign']} />
												&nbsp;
												<FontAwesomeIcon
													icon={['fas', 'briefcase']}
													title="Publisher GBP Royalties"
												/>
											</th> */}
											<th className="text-right">
												<FontAwesomeIcon icon={['fas', 'pound-sign']} />
												&nbsp;
												<FontAwesomeIcon
													icon={['fas', 'user']}
													title="Author GBP Royalties"
												/>
											</th>
											<th className="text-right">
												<FontAwesomeIcon
													icon={['fas', 'wallet']}
													title="Expenses"
												/>
											</th>
											<th className="text-right">P&amp;L</th>
											<th className="text-right">Sales</th>
											<th className="text-right">KENP</th>
											<th className="text-right">Refunds</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{bookSales.map(sales => {
											const rows = [];

											rows.push(
												<SalesRow
													sales={sales}
													key={sales.salesPeriod + '_' + (sales.storeId || '')}
													canUpdateSales={
														true
														// publisher &&
														// publisher.User &&
														// publisher.User.canEditRoyalties
													}
												/>
											);

											if (details === '1') {
												(salesPeriodMap[sales.salesPeriod] || [])
													.sort(
														(a, b) =>
															booksMap[a.bookId].title >
															booksMap[b.bookId].title
													)
													.forEach(sales2 => {
														rows.push(
															<BookSalesRow
																sales={sales2}
																book={booksMap[sales2.bookId]}
																key={
																	sales2.salesPeriod +
																	'_' +
																	sales2.bookId +
																	'_' +
																	(sales.storeId || '')
																}
															/>
														);
													});
											}

											if (details === '2') {
												const authorSales =
													authorSalesPeriodMap[sales.salesPeriod] || [];
												if (authorSales && authorSales.length > 0) {
													rows.push(
														<tr key={sales.salesPeriod + '-author'}>
															<th
																className="text-right"
																style={{
																	fontWeight: 'bold',
																	fontSize: '0.8em'
																}}
																colSpan="3"
															>
																Author
															</th>
															<th
																className="text-right"
																style={{
																	fontWeight: 'bold',
																	fontSize: '0.8em'
																}}
															>
																Royalties
															</th>
															<th
																className="text-right"
																style={{
																	fontWeight: 'bold',
																	fontSize: '0.8em'
																}}
															>
																Paid Out
															</th>
															<th
																className="text-right"
																style={{
																	fontWeight: 'bold',
																	fontSize: '0.8em'
																}}
															>
																To Pay
															</th>
															<th
																className="text-right"
																style={{
																	fontWeight: 'bold',
																	fontSize: '0.8em'
																}}
																colSpan="4"
															>
																&nbsp;
															</th>
														</tr>
													);
												}
												authorSales
													.sort(
														(a, b) =>
															(authorsMap[a.authorId]
																? authorsMap[a.authorId].name
																: '') >
															(authorsMap[b.authorId]
																? authorsMap[b.authorId].name
																: '')
													)
													.forEach(sales2 => {
														const expenses = (
															authorExpensesPeriodMap[sales2.salesPeriod] || []
														).find(
															expense => expense.authorId === sales2.authorId
														);

														rows.push(
															<AuthorSalesRow
																sales={sales2}
																expenses={expenses}
																author={authorsMap[sales2.authorId]}
																key={
																	sales2.salesPeriod +
																	'_' +
																	sales2.authorId +
																	'_' +
																	(sales.storeId || '')
																}
															/>
														);
													});
											}

											return rows;
										})}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</BookPageLayout>
		);
	}
}

export default connect((state, props) => {
	const id = parseInt(props.match.params.id, 10);

	return {
		bookSales: getSalesTotalByAuthorId(state, id, 'MONTHLY_BY_AUTHOR'),
		//	Author name
		authorsMap: getAllAuthorsMap(state),
		//	Book title
		booksMap: getAllBooksMap(state),
		//	Publisher details
		// author: getPublisherById(state, id),
		//	Sales
		// authorSales: getSalesTotalByPublisherId(
		// 	state,
		// 	id,
		// 	'MONTHLY_BY_AUTHOR_BY_PUBLISHER'
		// ),
		bookSalesByBook: getSalesTotalByAuthorId(
			state,
			id,
			'MONTHLY_BY_BOOK_ID_AUTHORS'
		),
		authorExpenses: getExpensesTotalByType(state, 'MONTHLY_BY_AUTHOR')
	};
})(AuthorSalesPage);
