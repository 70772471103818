import {createReducer} from '../helpers';

/*
 *	Constants
 */

const serviceName = 'AUTHOR_BOOKS'; 
// export const INITIAL_AUTHOR_BOOKS_LOADED = 'INITIAL_'+ serviceName +'_LOADED';
export const ADD_AUTHOR_BOOKS = 'ADD_'+ serviceName ;
export const UPDATE_AUTHOR_BOOKS = 'UPDATE_'+ serviceName;
export const REMOVE_AUTHOR_BOOKS = 'REMOVE_'+ serviceName;

export const ADD_AUTHOR_TO_BOOK = 'ADD_AUTHOR_TO_BOOK';
export const REMOVE_AUTHOR_FROM_BOOK = 'REMOVE_AUTHOR_FROM_BOOK';

/*
 *	Helper functions
 */

export const getAuthorIdsByBookId = (state, bookId) => {
	const stateSlice = state.db.authorBooks;
	return stateSlice.byBookId[bookId] || [];
};

export const getAuthorsByBookId = (state, bookId) => {
	const stateSlice = state.db.authors;
	const itemIds = getAuthorIdsByBookId(state, bookId);
	return itemIds
		//	Only map authors we have a record for
		//	it's okay if we don't have author details yet, as
		//	due to async they might come in out of sync
		.filter(id => !!stateSlice.byId[id])
		.map(id => stateSlice.byId[id]);
};
export const getAuthorsMap = (state) => {
	const stateSlice = state.db.authors;
	return stateSlice.byId;
};

export function withAuthorIds(state, items, id = 'id'){
	const stateSlice = state.db.authorBooks;
	items.forEach(item => {
		const bookId = item[id];
		item.authorIds = stateSlice.byBookId[bookId] || [];
	});

	return items || [];
}
export function withAuthors(state, items, id = 'id'){
	const stateSlice = state.db.authorBooks;
	items.forEach(item => {
		const bookId = item[id];
		const authorIds = stateSlice.byBookId[bookId];
		item.authors = authorIds.map(authorId => state.db.authors.byId[authorId]) || [];
	});

	return items || [];
}

export function withBooks(state, authors){

}

export const addAuthorToBook = (payload) => ({
	type: ADD_AUTHOR_TO_BOOK,
	payload: payload
});
export const removeAuthorFromBook = (payload) => ({
	type: REMOVE_AUTHOR_FROM_BOOK,
	payload: payload
});

/*
 *	Reducer
 */

export const reducer = createReducer(
	{
		byAuthorId: {},
		byBookId: {},
	},
	serviceName,
	{
		alterPayload: (item) => {
			item.id =  item.authorId +'_'+ item.bookId;
		},
		resetSecondaryKeys: (state) => {
			state.byAuthorId = {};
			state.byBookId = {};
	
			state.allIds.forEach(abId => {
				const ab = state.byId[abId];
	
				if(! state.byBookId[ab.bookId]){
					state.byBookId[ab.bookId] = [];
				}
				state.byBookId[ab.bookId].push(ab.authorId);
	
				if(! state.byAuthorId[ab.authorId]){
					state.byAuthorId[ab.authorId] = [];
				}
				state.byAuthorId[ab.authorId].push(ab.bookId);
			});
	
			return state;
		},
		addActions: [
			ADD_AUTHOR_TO_BOOK
		],
		removeActions: [
			REMOVE_AUTHOR_FROM_BOOK
		],
		actions: {
		}
	}
);