import { getItemIdByParam } from '../helpers';
import { REMOVE_AUTHOR_FROM_BOOK } from './authorBooks';
import {createReducer} from '../helpers';

/*
 *	Constants
 */
const serviceName = 'AUTHORS';
export const INITIAL_AUTHORS_LOADED = 'INITIAL_'+ serviceName +'_LOADED';
export const ADD_AUTHORS = 'ADD_'+ serviceName;
export const UPDATE_AUTHORS = 'UPDATE_'+ serviceName;
export const REMOVE_AUTHORS = 'REMOVE_'+ serviceName;

/*
 *	Helper functions
 */

export const removeAuthors = items => ({
	type: REMOVE_AUTHORS,
	payload: items
});

export function isMultiAuthor(state) {
	return state.db.authors.allIds.length > 1;
}

export function getAuthorBySlug(state, slug) {
	const stateSlice = state.db.authors;
	const itemId = getItemIdByParam(stateSlice, 'slug', slug);

	return stateSlice.byId[itemId];
}

export function getAuthorById(state, id) {
	const stateSlice = state.db.authors;
	return stateSlice.byId[id];
}

export function getAllAuthors(state) {
	const stateSlice = state.db.authors;
	return stateSlice.allIds.map(id => stateSlice.byId[id]);
}

export function getAllAuthorsMap(state) {
	const stateSlice = state.db.authors;
	return stateSlice.byId;
}

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		alterPayload: (item) => {
			//	@todo: once dashboard is created update the link here
			item.link = '/authors/' + item.slug + '/' + item.id + '/details';
		},
		actions: {
			//	Sharing this action type with authorBooks reducer
			[REMOVE_AUTHOR_FROM_BOOK]: (newState, action) => {
				//	Remove the stub authors when the book is removed
				action.payload
					.filter(
						item =>
							newState.byId[item.authorId] && !newState.byId[item.authorId].User
					)
					.forEach(item => {
						delete newState.byId[item.authorId];
						newState.allIds.splice(newState.allIds.indexOf(item.authorId), 1);
					});

				return newState;
			}
		}
	}
);