//	eslint-disable-next-line
import React from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { useSelector } from 'react-redux';
import Menu from '../../components/Menu';
import { getSiteIdByDomain } from '../../reducers/db/sites';
import { getAllSitePagesBySite } from '../../reducers/db/sitePages';
import SitePageAddButton from '../../components/sites/page/AddButton';
import {getAllSiteMenusBySiteId} from '../../reducers/db/siteMenus';
import {kebabCase} from 'lodash';

const SiteMenu = ({
	match: {
		params: { slug, page },
		url,
		path
	}
}) => {
	const siteId = useSelector(state => getSiteIdByDomain(state, slug));
	// @todo: handle siteId not found
	const menus = useSelector(state => getAllSiteMenusBySiteId(state, siteId)) || [];
	const pages = useSelector(state => getAllSitePagesBySite(state, siteId)) || {};

	const sitePages = pages.map(item => {
		let icon;

		switch (item.path) {
			case '/':
				icon = 'home';
				break;
			case '/about':
				icon = 'user';
				break;
			case '/contact':
				icon = 'envelope';
				break;
			case '/books/*':
				icon = 'book';
				break;
			case '/books':
				icon = 'books';
				break;
			case '/privacy':
				icon = 'eye';
				break;
			default:
				icon = 'file';
				break;
		}

		return {
			label: item.title,
			pathSuffix: '/page' + (item.path === '/' ? '/home' : item.path) ,
			isCurrent:
				path.includes('/sites/:slug/page') &&
				'/' + (page ? page : 'home') === item.path,
			ficon: icon
		};
	});

	let menuPath = '/menus';
	if(menus.length > 0){
		menuPath = '/menus/'+ kebabCase(menus[0].name) +'/'+ menus[0].id;
	}
	
	return (
		<Menu
			currentPath={url}
			baseLink={'/sites/' + slug}
			menu={[
				{
					label: 'Dashboard',
					ficon: 'chart-pie',
					pathSuffix: ''
				},
				{
					label: 'Settings',
					ficon: 'cog',
					pathSuffix: '/settings'
				},
				{
					label: 'Menus',
					ficon: 'line-columns',
					pathSuffix: menuPath
				},
				{
					label: 'Pages',
					custom: <NavLink tag={'h5'}>
						Pages:
						<SitePageAddButton className="float-right" size="small" siteId={siteId}></SitePageAddButton>
					</NavLink>,
					//	Required as this is what the Menu.key is generated based off
					pathSuffix: '#Pages'
				},
				...sitePages
			]}
		/>
	);
};

SiteMenu.displayName = 'SiteMenu';

SiteMenu.propTypes = {};

SiteMenu.defaultProps = {};

export default withRouter(SiteMenu);