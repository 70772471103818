import { applyMiddleware, compose, createStore } from 'redux';
import {app} from './reducers';
// import { crashReporter } from '../_core/store';
import promise from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import Logger from '@wpa/logger';

export const crashReporter = () => next => action => {
	try {
		console.debug('ACTION', action);
		return next(action);
	} catch (err) {
		Logger.error('Caught an exception!', err);
		throw err;
	}
};

// Create the saga middleware
export const sagaMiddleware = createSagaMiddleware();

//	Redux Devtools
// const composeEnhancers =
// 	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
console.log('REDUX', window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__, window);
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

export const store = createStore(
	app,
	composeEnhancers(
		applyMiddleware(
			sagaMiddleware,
			// Thunk middleware for Redux
			// reduxThunk,
			// Resolve, reject promises with conditional optimistic updates
			promise,
			crashReporter
		)
	)
);