import AdminPage from './pages/AdminPage.jsx';
import AdminUserPage from './pages/admin/UserPage.jsx';
import AccountPage from './pages/AccountPage.jsx';
import AuthorsPage from './pages/AuthorsPage.jsx';
import AuthorDashboardPage from './pages/author/dashboardPage';
import AuthorDetailsPage from './pages/author/DetailsPage';
import AuthorSalesPage from './pages/author/SalesPage';
import PublishersPage from './pages/PublishersPage.jsx';
import BooksPage from './pages/BooksPage.jsx';
import DashPage from './pages/DashPage.jsx';
import SitesPage from './pages/SitesPage.jsx';
import BookAssetsPage from './pages/book/AssetsPage';
import BookDetailsPage from './pages/book/DetailsPage';
import BookReviewsPage from './pages/book/ReviewsPage';
import BookDashboardPage from './pages/book/DashboardPage';
import BookSalesPage from './pages/book/SalesPage';
import SitePageSidebarPage from './pages/site/page/SidebarPage';
import SiteDashboardPage from './pages/site/DashboardPage';
import SitePageContentPage from './pages/site/page/ContentPage';
import SitePageCustomisePage from './pages/site/page/CustomisePage';
import SiteSettingsPage from './pages/site/SettingsPage.jsx';
import SiteMenusPage from './pages/site/MenusPage.jsx';
import BookRoyaltiesPage from './pages/book/RoyaltiesPage';
import PublisherDetailsPage from './pages/publisher/DetailsPage';
import PublisherSalesPage from './pages/publisher/SalesPage';

//	From most specific to least - order matters!
const routes = [
	{
		path: '/account',
		component: AccountPage
	},
	{
		path: '/admin/users/:id/edit',
		component: AdminUserPage
	},
	{
		path: '/admin/users',
		component: AdminPage
	},
	{
		path: '/admin/permissions',
		component: AdminPage
	},
	{
		path: '/admin',
		component: AdminPage
	},
	{
		path: '/authors/add',
		component: AuthorsPage
	},
	{
		path: '/authors/:slug/:id/details',
		component: AuthorDetailsPage
	},
	{
		path: '/authors/:slug/:id/sales',
		component: AuthorSalesPage
	},
	{
		path: '/authors/:slug/:id',
		component: AuthorDashboardPage
	},
	{
		path: '/authors',
		component: AuthorsPage
	},
	{
		path: '/books/import',
		component: BooksPage
	},
	{
		path: '/books/add',
		component: BooksPage
	},
	{
		path: '/books/add-sales',
		component: BooksPage
	},
	{
		path: '/invite/book/:slug/:id',
		component: BookDashboardPage
	},
	{
		path: '/books/:slug/:id/assets',
		component: BookAssetsPage
	},
	{
		path: '/books/:slug/:id/details',
		component: BookDetailsPage
	},
	{
		path: '/books/:slug/:id/reviews',
		component: BookReviewsPage
	},
	{
		path: '/books/:slug/:id/sales',
		component: BookSalesPage
	},
	{
		path: '/books/:slug/:id/royalties',
		component: BookRoyaltiesPage
	},
	{
		path: '/books/:slug/:id',
		component: BookDashboardPage
	},
	{
		path: '/books',
		component: BooksPage
	},
	{
		path: '/publishers/add',
		component: PublishersPage
	},
	{
		path: '/publishers/:slug/:id/sales',
		component: PublisherSalesPage
	},
	{
		path: '/publishers/:slug/:id/details',
		component: PublisherDetailsPage
	},
	// {
	// 	path: '/publishers/:slug/:id',
	// 	component: PublisherDashboardPage
	// },
	{
		path: '/publishers',
		component: PublishersPage
	},
	{
		path: '/sites/:slug/page/:page+/config',
		component: SitePageCustomisePage
	},
	{
		path: '/sites/:slug/page/:page+/sidebar-:sidebar(a|b)',
		component: SitePageSidebarPage
	},
	// {
	// 	path: '/sites/:slug/page/:page+/sidebar-b',
	// 	component: SidebarPage
	// },
	{
		path: '/sites/:slug/page/:page+',
		component: SitePageContentPage
	},
	//	@todo: redirect to /sites/:slug/page/home
	{
		path: '/sites/:slug/page/',
		component: SitePageContentPage
	},
	{
		path: '/sites/:slug/settings',
		component: SiteSettingsPage
	},
	{
		path: '/sites/:slug/menus/:menuSlug/:menuId',
		component: SiteMenusPage
	},
	{
		path: '/sites/:slug',
		component: SiteDashboardPage
	},
	{
		path: '/sites',
		component: SitesPage
	},
	// {
	// 	path: '/logout',
	// 	component: DashPage
	// },
	{
		path: '/',
		component: DashPage
	}
];

export default routes;
