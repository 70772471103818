import React from 'react';
import {
	Card,
	CardTitle,
	// CardBlock, CardText, CardFooter,
	CardHeader,
	CardBody,
} from 'reactstrap';
// import BooksCard from "../components/dashboard/Books";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Row, Col } from 'antd';
const { Content } = Layout;

export const page = (props) => {
	// let booksCard;

	// //	Hide this card when user already has books
	// //	@todo: figure out where this variable should come from
	// if(1){
	// 	booksCard = <div className="col-sm-4">
	// 		<BooksCard />
	// 	</div>;
	// }

	const kenpTotals = props.kenpTotals;

	return (
		<Content id="dashPage">
			<Row gutter={16}>
				<Col sm={12} md={12} lg={8} xl={8}>
					<Card color="info">
						{/*<CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180" alt="Card image cap" />*/}
						{/*	@todo: update reactstrap at some point so we use CardBody */}
						<CardBody>
							<CardTitle>WriterPA Alpha</CardTitle>

							<p>
								Hi there! Glad to have you on board. Just a{' '}
								<b>quick note</b> before you jump in:
							</p>

							<p>
								As this is an early preview release things might
								be a bit rough around the edges.
							</p>

							<p>Feedback and feature suggestions welcome :)</p>

							<p>
								Joanna
								<br />
								contact@writerpa.com
							</p>

							{/*<CardSubtitle>Card subtitle</CardSubtitle>*/}
							{/*<CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>*/}
							{/*<Button>Button</Button>*/}
						</CardBody>
					</Card>
				</Col>
				{kenpTotals.length > 0 && (
					<Col sm={12} lg={6}>
						<Card>
							<CardBody className="text-center">
								<CardTitle style={{ fontSize: '0.95em' }}>
									Latest KENP
								</CardTitle>
								<div style={{ fontSize: '1.5em' }}>
									{kenpTotals[0].change === 1 && (
										<FontAwesomeIcon
											icon={['fas', 'sort-up']}
											className={'text-success'}
										/>
									)}
									{kenpTotals[0].change === -1 && (
										<FontAwesomeIcon
											icon={['fas', 'sort-down']}
											className={'text-danger'}
										/>
									)}
									{kenpTotals[0].change === 0 && (
										<FontAwesomeIcon
											icon={['fas', 'sort']}
											className={'text-muted'}
										/>
									)}
									&nbsp;<b>${kenpTotals[0].kenpRate}</b>{' '}
									<small>
										{kenpTotals[0].month}/
										{kenpTotals[0].year}
									</small>
								</div>

								<hr />

								{kenpTotals
									.filter(
										(item, index) => index > 0 && index < 4
									)
									.map((kenp) => (
										<div
											key={
												'kenp' +
												kenp.year +
												'-' +
												kenp.month
											}
										>
											{kenp.change === 1 && (
												<FontAwesomeIcon
													icon={['fas', 'sort-up']}
													className={'text-success'}
												/>
											)}
											{kenp.change === -1 && (
												<FontAwesomeIcon
													icon={['fas', 'sort-down']}
													className={'text-danger'}
												/>
											)}
											{kenp.change === 0 && (
												<FontAwesomeIcon
													icon={['fas', 'sort']}
													className={'text-muted'}
												/>
											)}
											&nbsp;<b>${kenp.kenpRate}</b>{' '}
											<small>
												{kenp.month}/{kenp.year}
											</small>
										</div>
									))}
							</CardBody>
						</Card>
						{/*<Card>*/}
						{/*<CardHeader>*/}
						{/*<CardTitle>KENP</CardTitle>*/}
						{/*</CardHeader>*/}
						{/*<CardBody>*/}
						{/**/}

						{/*</CardBody>*/}
						{/*</Card>*/}
					</Col>
				)}
				<Col sm={12} lg={10}>
					<Card>
						<CardHeader>
							<CardTitle>Resources</CardTitle>
						</CardHeader>
						<CardBody>
							<p>
								<b>Cover templates</b>
							</p>

							<ul>
								<li>
									<a
										href="https://kdp.amazon.com/en_US/cover-templates"
										target="_blank"
										rel="noopener noreferrer"
									>
										Amazon paperback cover template
									</a>
								</li>
								<li>
									<a
										href="https://bookcovermasterclass.com/diy"
										target="_blank"
										rel="noopener noreferrer"
									>
										Book Cover Master Class Starter Cover
										Template
									</a>
								</li>
							</ul>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

export default connect((state) => ({
	kenpTotals: state.db.bookSales.kenpTotals || [],
}))(page);
