import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { Button, Input, formHandleInputChange } from '@wpa/components/lib/Form';
import { InputGroup, InputGroupAddon } from 'reactstrap';

export class AssistantResponse extends React.Component {
	static displayName = 'Assistant';
	static propTypes = {
		type: PropTypes.string,
		label: PropTypes.string,
		value: PropTypes.string,
		currentMessageId: PropTypes.number,
		dispatch: PropTypes.func,
		id: PropTypes.number
	};
	static defaultProps = {};

	constructor(props) {
		super(props);

		this.state = {
			response: props.value || '',
			processing: false
		};

		this.onChange = formHandleInputChange.bind(this);

		this.forcedResponse = this.forcedResponse.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.onChange = formHandleInputChange.bind(this);
	}

	forcedResponse(e) {
		const self = this;
		self.props
			.dispatch(
				feathersServices[SERVICE.ASSISTANT].patch(
					self.props.currentMessageId,
					{},
					{
						query: {
							responseId: self.props.id
						}
					}
				)
			)
			.then(() => {
				self.setState({
					processing: false
				});
			});
	}

	handleClick(e) {
		e.preventDefault();

		const self = this;

		self.setState(
			{
				response: this.props.value || '',
				processing: true
			},
			() => {
				self.props.onButtonClick(self.props.id, self.state.response, e);
			}
		);
	}

	render() {
		switch (this.props.type) {
			case 'link':
				return (
					<Link
						name={'response'}
						className="btn btn-primary btn-sm"
						onClick={this.forcedResponse}
						to={this.props.value}
					>
						{this.props.label}
					</Link>
				);
			case 'button':
				return (
					<Button
						name={'response'}
						type="primary"
						htmlType="submit"
						size="small"
						// onClick={this.forcedResponse}
						value={this.props.value}
						loading={!!this.state.processing}
						disabled={!!this.state.processing}
						onClick={this.handleClick}
					>
						{this.props.label}
					</Button>
				);
			case 'text':
				return (
					<InputGroup>
						<Input
							name={'response'}
							placeholder={this.props.label}
							value={this.state.response}
							defaultValue={''}
							onChange={this.onChange}
						/>
						<InputGroupAddon addonType="append">
							<Button onClick={this.handleClick} type="submit">
								Send
							</Button>
						</InputGroupAddon>
					</InputGroup>
				);
			//	Response of no known type
			default:
				return null;
		}
	}
}

export default AssistantResponse;
