import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'BOOK_STATUSES';
export const INITIAL_BOOK_STATUSES_LOADED = 'INITIAL_'+ serviceName +'_LOADED';
export const ADD_BOOK_STATUSES = 'ADD_'+ serviceName;
export const UPDATE_BOOK_STATUSES = 'UPDATE_'+ serviceName;
export const REMOVE_BOOK_STATUSES = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */


export function getAllBookStatusesAsTags(state){
	const stateSlice = state.db.bookStatuses;

	const bookStatuses = stateSlice.allIds.map(statusId => {
		const status = stateSlice.byId[statusId];
		return {
			id: status.id,
			name: status.name,
			tagClass: status.slug,
			priority: status.priority
		};
	});

	return bookStatuses;
}

/*
 *	Reducer
 */
export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);