//	eslint-disable-next-line
import React from 'react';
import { withRouter } from 'react-router-dom';
import Menu from '../../components/Menu';
import can from '../../abilities';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../reducers/currentUser';

export const AdminPageMenu = ({
	match: {
		params: {
			slug, id
		},
		url,
		path
	}
}) => {
	const currentUser = useSelector(state => getCurrentUser(state));

	const canAccessUsers = can.accessAdminUsers(currentUser);
	const canAccessPermissions = can.accessAdminPermissions(currentUser);

	const menu = [
		{
			label: 'Dashboard',
			pathSuffix: ''
		}
	];

	if (canAccessUsers) {
		menu.push({
			label: 'Users',
			pathSuffix: '/users'
		});
	}

	if (canAccessPermissions) {
		menu.push({
			label: 'Permissions',
			pathSuffix: '/permissions'
		});
	}

	return (
		<div>
			<Menu
				menu={menu}
				baseLink={'/admin'}
				currentPath={url}
			/>
		</div>
	);
};

export default withRouter(AdminPageMenu);