import React from 'react';
import {connect} from 'react-redux';
import {Col, Form, FormGroup, Label} from 'reactstrap';
import {Button, colSizes, formHandleInputChange, Input, labelSizes, offsetSizes} from '@wpa/components/lib/Form';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import {getAllPublishers, getPrimaryPublisherId} from '../../reducers/db/publishers';
import Notice from '@wpa/components/lib/Notice';
import Logger from '@wpa/logger';
import {errorStateReducer} from '../../reducers';
import {addFSM} from '../../functional/withStateMachine';
// import {dispatchGetBookSalesTotals} from '../../reducers/db/bookSales';

const STATE = {
	LOADING: 'loading',
	ERROR: 'error',
	DISPLAY: 'display',
	SAVING: 'saving',
	PUBLISHING: 'publishing',
};

const ACTION = {
	ERROR: 'error',
	DISPLAY: 'display',
	RESET: 'reset',
	CLEAR_KDP: 'clear-kdp',
	SAVE: 'save',
	SELECT: 'select',
};

const stateMachine = {
	initial: STATE.DISPLAY,
	states: {
		// [STATE.LOADING]: {
		// 	on: {
		// 		[ACTION.DISPLAY]: {
		// 			target: STATE.DISPLAY,
		// 		},
		// 		[ACTION.ERROR]: {
		// 			target: STATE.ERROR,
		// 		},
		// 	}
		// },
		[STATE.DISPLAY]: {
			on: {
				[ACTION.SELECT]: {
					target: STATE.DISPLAY,
					actions: [
						'selectKdpFile'
					]
				},
				[ACTION.SAVE]: {
					target: STATE.SAVING,
					actions: [
						errorStateReducer.clearMessage,
						'hideNote',
					]
				},
				[ACTION.CLEAR_KDP]: {
					target: STATE.DISPLAY,
					actions: [
						'unselectKdpFile'
					]
				}
			}
		},
		[STATE.SAVING]: {
			on: {
				[ACTION.RESET]: {
					target: STATE.DISPLAY,
					actions: [
						'unselectKdpFile'
					]
				},
				[ACTION.ERROR]: {
					target: STATE.ERROR,
					actions: [
						'unselectKdpFile'
					]
				}
			}
		},
		[STATE.ERROR]: {
			onEntry: [errorStateReducer.setMessage],
			onExit: [errorStateReducer.clearMessage],
			on: {
				[ACTION.SELECT]: {
					target: STATE.DISPLAY,
					actions: [
						'selectKdpFile'
					]
				},
				[ACTION.SAVE]: {
					target: STATE.SAVING,
				},
			}
		},
	}
};

const stateReducers = [
	errorStateReducer.reducer,
	(action, event) => {
		switch(action.type){
			case 'selectKdpFile':
				return {
					kdpFile: event.kdpFile || {},
					kdpDataUrl: event.kdpDataUrl || '',
				};

			case 'unselectKdpFile':
				return {
					kdpFile: null,
					kdpDataUrl: null,
				};

			case 'hideNote':
				return {
					showNote: false,
				};
				
			default:;
		}
	}
];

export class BookAddSales extends React.Component {
	static displayName = 'BookAddSales';

	constructor(props){
		super(props);

		this.state = {
			kdpFile: null,
			kdpDataUrl: null,
			// uploading: false,
			showNote: true,
			error: false,
			errorMessage: '',
			publisherId: props.primaryPublisherId,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.onFileInputChange = this.onFileInputChange.bind(this);
		this.onKdpFileInputClear = this.onKdpFileInputClear.bind(this);
		this.onInputChange = formHandleInputChange.bind(this);

		addFSM(this, stateMachine, stateReducers);
	}

	/**
	 * Turn upload into a dataURL
	 * @param e
	 */
	onFileInputChange(e){
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			this.transition(ACTION.SELECT, {
				kdpFile: file,
				kdpDataUrl: reader.result
			});
		};

		reader.readAsDataURL(file);
	}

	onKdpFileInputClear(e){
		e.preventDefault();

		this.transition(ACTION.CLEAR_KDP, {});
	}

	onBookAddSalesSave(payload){
		const {
			kdpFile,
			kdpDataUrl,
			publisherId,
		} = this.state;

		if(!kdpFile){
			this.transition(ACTION.ERROR, {
				message: 'No file selected'
			});
			return;
		}

		const self = this;

		self.props.dispatch(feathersServices[SERVICE.UPLOADS]
			.create({
				uri: kdpDataUrl,
				name: kdpFile.name,
				type: 'SALES_AMAZON',
				resource: {
					//	Publisher Id
					id: publisherId,
				}
			}, {})
		)
			.then(json =>{
				self.transition(ACTION.RESET, {});
			})
			.catch(err => {
				Logger.error(err);
				self.transition(ACTION.ERROR, {message: 'Could not upload all the sales. :( Please reload the page and try again. If it still doesn\'t work contact Joanna.'});
			})
		;
	}

	handleSubmit(e){
		e.preventDefault();

		this.transition(ACTION.SAVE, {});
	}

	render(){

		const {
			primaryPublisherId,
			publishers,
		} = this.props;

		const {
			showNote,
			currentState,
			publisherId,
		} = this.state;

		const fileName = this.state.kdpFile && this.state.kdpFile.name;

		return (
			<Form onSubmit={this.handleSubmit}>
				{showNote && <Notice color={"light"} icon={"info"}>
					<b>Tip:</b> Import books before uploading sales for more detailed book records.
				</Notice>}
				{publishers.length > 1 &&
				<FormGroup row>
					<Label {...labelSizes} htmlFor="publisherId">Publisher</Label>
					<Col {...colSizes}>
						<Input type="select" name="publisherId" id="publisherId"
							   value={publisherId} defaultValue={primaryPublisherId}
							   onChange={this.onInputChange}
						>
							{publishers.map(publisher => (
							<option value={publisher.id}>{publisher.User && publisher.User.isPrimary ? '*' : ''}{publisher.name}</option>
							))}
						</Input>
					</Col>
				</FormGroup>}
				<FormGroup row>
					<Label {...labelSizes}>KDP Sales<br /><small>(new format)</small></Label>
					<Col {...colSizes}>
						{fileName &&
							<p style={{overflowWrap: 'break-word'}}>
								{fileName}<br />
								<Button
									type={'secondary'}
									size={'small'}
									disabled={this.state.uploading}
									onClick={this.onKdpFileInputClear}
								>Clear?</Button>
							</p>
						}
						{!fileName &&
						<Input
							type="file"
							name="kdpFile"
							id="salesKdpFile"
							onChange={this.onFileInputChange}
						/>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label {...labelSizes}>KDP Sales<br /><small>(old format)</small></Label>
					<Col {...colSizes}>
						<i>Coming soon...</i>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Col {...offsetSizes}>
						<Button
							loading={currentState === STATE.SAVING}
							disabled={currentState === STATE.SAVING}
							type={'primary'}
							htmlType="submit"
						>Upload</Button>
					</Col>
				</FormGroup>
				{currentState === STATE.ERROR && <Notice color={"danger"}>
					{this.state.errorMessage}
				</Notice>}
			</Form>
		);
	}
}

export default connect(state => ({
	primaryPublisherId: getPrimaryPublisherId(state),
	publishers: getAllPublishers(state),
}))(BookAddSales);