//	eslint-disable-next-line
import React, { useCallback } from 'react';
import { Card, Form } from 'antd';
import { xstateOnChange, FORM_STATE , FORM_ACTION, formStateMachine} from '@wpa/state-machine';
import { RichTextArea } from '@wpa/components/lib/RichTextArea';
import {useDispatch, useSelector} from 'react-redux';
import {useMachine} from '@xstate/react';
import {feathersServices, SERVICE} from '@wpa/feathers-client';
import Logger from '@wpa/logger';
import {FormAlert, Button} from '@wpa/components/lib/Form';
import {getAuthorById} from '../../reducers/db/authors';


const m = formStateMachine({
	formAction: (context, event) =>
		console.log('Save BIO') || new Promise((resolve, reject) => {
			context
				.dispatch(
					feathersServices[SERVICE.AUTHORS].patch(
						event.authorId,
						{
							bio: context.bio,
						},
						{}
					)
				)
				.then(payload => {
					resolve();
				})
				.catch(
					err =>
						Logger.error(err) || reject('Unable to save author bio.')
				);
		})
});

export const AuthorBio = ({ authorId }) => {
	const dispatch = useDispatch();
	const author = useSelector(state => getAuthorById(state, authorId)) || {};

	const [state, send] = useMachine(
		m.withContext({
			bio: author.bio,
			dispatch: dispatch
		})
	);

	const { bio, errorMessage } = state.context;

	const isError = state.matches(FORM_STATE.ERROR);
	const isSaving = state.matches(FORM_STATE.SUBMITTING);

	const handleSubmit = useCallback(
		e => {
			e.preventDefault();

			send(FORM_ACTION.SUBMIT, {
				action: 'save',
				authorId
			});
		},
		[send, authorId]
	);

	return (
		<Form onSubmit={handleSubmit}>
			<Card title={<h3>Author Bio</h3>} 
				extra={<Button
					type="primary"
					htmlType="submit"
					loading={isSaving}
					disabled={author.bio === bio}
				>
					Save
				</Button>}
			>
				{isError && <FormAlert send={send} description={errorMessage} />}
				<RichTextArea
					id={'authorBio_' + authorId}
					initialValue={author.bio}
					textareaName="bio"
					onChange={xstateOnChange(send)}
					height="400px"
				/>
			</Card>
		</Form>
	);
};

export default AuthorBio;
