import {createReducer} from '../helpers';

/*
 *	Constants
 */

const serviceName = 'SOCIAL_MEDIA_TYPES';
export const ADD_SOCIAL_MEDIA_TYPES = 'ADD_'+ serviceName;
export const UPDATE_SOCIAL_MEDIA_TYPES = 'UPDATE_'+ serviceName;
export const REMOVE_SOCIAL_MEDIA_TYPES = 'REMOVE_'+ serviceName;

/*
 *	Helper functions
 */

export const addSocialMediaTypes = items => ({
	type: ADD_SOCIAL_MEDIA_TYPES,
	payload: items
});

export function getAllSocialMediaTypes(state) {
	const stateSlice = state.db.socialMediaTypes;
	return (stateSlice && stateSlice.allIds && stateSlice.allIds.map(id => stateSlice.byId[id])) || [];
}

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);