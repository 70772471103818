
/**
 * Constants
 */

const reducerName = 'CURRENT_USER';

export const ADD_PERMISSION = reducerName + '_ADD_PERMISSION';
export const UPDATE = reducerName + '_UPDATE';

/**
 * Helper functions
 */

export const updateCurrentUser = (payload) => ({
	type: UPDATE,
	payload: payload
});
export const addPermission = (payload) => ({
	type: ADD_PERMISSION,
	payload: payload
});

export const getCurrentUser = (state) => {
	return state.currentUser;
};


export const currentUserReducer = (state = {
	permissions: {
		// byId: [],
		// bySlug: []
		//	... SLUG: ID
		//	individual permissions set as object properties
	},
	name: '',
	fullname: '',
	email: '',
	userStatus: {},
}, action) => {
	let newState, newCurrentUser, permissions;
	switch (action.type) {
		case UPDATE:
			
			permissions = action.payload.Permissions;
			if(Array.isArray(action.payload.Permissions)){
				permissions = {};
				action.payload.Permissions.forEach(p => permissions[p.slug] = p.id);
			}

			newState = Object.assign({}, state, {
				permissions: permissions,
				name: action.payload.name,
				fullname: action.payload.fullname,
				email: action.payload.email,
				userStatus: action.payload.UserStatus,
			});

			delete newState.userStatus.id;

			console.log('UPDATE USER', newState);

			return newState;
		case ADD_PERMISSION:
			newCurrentUser = Object.assign({}, state);
			newCurrentUser.permissions[action.payload.Permission.slug] = action.payload.Permission.id;
			return newCurrentUser;
		default:
			return state;
	}
};
