//	eslint-disable-next-line
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../components/PageHeader';
import ErrorBoundary from '@wpa/components/lib/ErrorBoundry';
import AdminPageMenu from './Menu';
import {withRouter} from 'react-router-dom';

import { Layout } from 'antd';

const { Content, Sider } = Layout;

export const AdminPageLayout = ({pageId, subtitle, title, pageHeader, ...props}) => {

	const subtitleText = ['Writer PA Admin', subtitle].filter(Boolean).join(' / ');

	return (
		<Layout>
			<Sider className="secondaryMenu" theme="light">
				<AdminPageMenu />
			</Sider>
			<Layout>
				<Content id={pageId}>
					<PageHeader
						title={title}
						subtitle={subtitleText}
					>
						{pageHeader}
					</PageHeader>

					<ErrorBoundary>
						{props.children}
					</ErrorBoundary>
				</Content>
			</Layout>
		</Layout>
	);
};

export default withRouter(AdminPageLayout);

export class BookPageLayout extends Component {
	static displayName = 'BookPageLayout';
	static propTypes = {
		page: PropTypes.string.isRequired,
		// onSiteReady: PropTypes.func.isRequired
	};
	static defaultProps = {};

	render(){
		const props = this.props;
		const pageId = props.pageName + 'Page';

		//
		// const {
		// 	params: { slug, page },
		// } = props.match;

		const title = (props.book && props.book.title) || 'Draft Idea';
		const subtitle = props.subtitle || props.authors.map(author => author.name).join(', ');

		//	Site ready
		return (
			<Layout>
				<Sider className="secondaryMenu" theme="light">
					???
					{/* <BookMenu book={props.book} /> */}
				</Sider>
				<Layout>
					<Content id={pageId}>
						<PageHeader
							title={title}
							subtitle={subtitle}
						/>

						<ErrorBoundary>
							{props.children}
						</ErrorBoundary>
					</Content>
				</Layout>
				{/* <SecondaryMenu menu={<BookMenu book={props.book} />}>
				<div id={pageId} className="pageContent">
					<PageHeader
						title={title}
						subtitle={subtitle}
					/>

					<ErrorBoundary>
						{props.children}
					</ErrorBoundary>
				</div>
			</SecondaryMenu> */}
			</Layout>
		);
	}
}

// export default withRouter(connect((state, props) => {
// 	const id = parseInt(props.match.params.id, 10);
// 	return {
// 		authors: getAuthorsByBookId(state, id) || [],
// 		book: (id && getBookById(state, id)) || {},
// 	};
// })(BookPageLayout));