import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { addItems } from '../helpers';
import {
	quickFloatSum,
	quickIntSum,
	quickRound
} from '../../utils/maths';
import { getBookPublishersOnlyByBookId } from './bookPublishers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const reducerName = 'BOOK_SALES';
const serviceName = 'BOOK_SALES';

export const ADD_BOOK_SALES = 'ADD_'+ serviceName;
export const UPDATE_BOOK_SALES = 'UPDATE_'+ serviceName;
export const REMOVE_BOOK_SALES = 'REMOVE_'+ serviceName;

export const GET_TOTALS = reducerName + '_GET_TOTALS';
export const GET_KENP_TOTALS = reducerName + '_GET_KENP_TOTALS';
export const GET_PUBLISHER_TOTALS = reducerName + '_GET_PUBLISHER_TOTALS';
export const GET_AUTHOR_TOTALS = reducerName + '_GET_AUTHOR_TOTALS';

/*
 * Helper functions
 */

export const addBookSales = items => ({
	type: ADD_BOOK_SALES,
	payload: items
});

//	Trigger actions
export const dispatchGetBookSalesTotals = () =>
	feathersServices[SERVICE.BOOK_SALES].get('totals');
export const dispatchGetKenpTotals = () =>
	feathersServices[SERVICE.BOOK_SALES].get('kenp-totals');

/**
 * Get all the store IDs (asin, kobo, etc.)
 * @param state
 * @param id book ID
 * @returns {Array}
 */
export function getBookStoreIdsByBookId(state, id) {
	const bps = getBookPublishersOnlyByBookId(state, id, false) || [];

	const ids = [];
	bps.forEach(bp => {
		if (bp.asin) {
			ids.push(bp.asin);
		}
	});

	return ids;
}

export function getSalesTotalByBookId(state, id, totalType) {
	const stateSlice = state.db.bookSales;

	const sales = (stateSlice.totals || []).filter(
		sale => sale.type === totalType && sale.bookId === id
	);
	return sales;
}

export function getSalesTotalByPublisherId(state, id, totalType) {
	const stateSlice = state.db.bookSales;

	const sales = (stateSlice.totals || []).filter(
		sale => sale.type === totalType && sale.publisherId === id
	);
	return sales;
}

export function getSalesTotalByAuthorId(state, id, totalType) {
	const stateSlice = state.db.bookSales;

	const sales = (stateSlice.totals || []).filter(
		sale => sale.type === totalType && sale.authorId === id
	);
	return sales;
}

export function getPublisherSalesTotalByPublisherId(state, id, totalType) {
	const stateSlice = state.db.bookSales;

	const ids = id ? (Array.isArray(id) ? id : [id]) : [];

	let sales = [];
	ids.forEach(id => {
		if (stateSlice.publisherTotals[id]) {
			sales = stateSlice.publisherTotals[id].filter(
				sale => sale.type === totalType
			);
		}
	});

	return sales;
}

export function getAuthorSalesTotalByAuthorId(state, id, totalType) {
	const stateSlice = state.db.bookSales;

	const ids = id ? (Array.isArray(id) ? id : [id]) : [];

	let sales = [];
	ids.forEach(id => {
		if (stateSlice.authorTotals[id]) {
			sales = stateSlice.authorTotals[id].filter(
				sale => sale.type === totalType
			);
		}
	});

	return sales;
}

export function getAuthorSalesTotalByPublisherId(state, id, totalType) {
	const stateSlice = state.db.bookSales;

	let sales = [];
	Object.keys(stateSlice.authorTotals).forEach(authorId => {
		stateSlice.authorTotals[authorId]
			.filter(sale => sale.publisherId === id)
			.filter(sale => sale.type === totalType)
			.forEach(sale => {
				sales.push(sale);
			});
	});

	return sales;
}

export function getReducedSalesTotalByBookId(state, id, totalType) {
	const ids = getBookStoreIdsByBookId(state, id);
	const sales = getSalesTotalByBookId(state, id, totalType);

	if (sales.length === 0) {
		const dummySale = {
			GBPRoyalty: 0,
			USDRoyalty: 0,
			publisherId: null,
			kenp: 0,
			refunds: 0,
			sales: 0,
			salesPeriod: null,
			storeBookId: ids,
			storeId: null,
			type: totalType,
			unpaidKenp: 0,
			unpaidRefunds: 0,
			unpaidSales: 0
		};

		return dummySale;
	}

	switch (totalType) {
		case 'ALL_TIME':
			//	@todo: REDUCE is being stupid about single element arrays...
			if (sales.length === 1) {
				sales[0] = {
					...sales[0],
					GBPRoyalty: quickRound(sales[0].GBPRoyalty),
					USDRoyalty: quickRound(sales[0].USDRoyalty),
					kenp: parseInt(sales[0].kenp, 10) || 0,
					refunds: parseInt(sales[0].refunds, 10) || 0,
					sales: parseInt(sales[0].sales, 10) || 0,
					storeBookId: ids,
					unpaidKenp: parseInt(sales[0].unpaidKenp, 10) || 0,
					unpaidRefunds: parseInt(sales[0].unpaidRefunds, 10) || 0,
					unpaidSales: parseInt(sales[0].unpaidSales, 10) || 0
				};

				return sales[0];
			}

			return sales.reduce((result, item) => {
				return {
					GBPRoyalty: quickRound(
						quickFloatSum(result.GBPRoyalty, item.GBPRoyalty)
					),
					USDRoyalty: quickRound(
						quickFloatSum(result.USDRoyalty, item.USDRoyalty)
					),
					publisherId: null,
					kenp: quickIntSum(result.kenp, item.kenp),
					refunds: quickIntSum(result.refunds, item.refunds),
					sales: quickIntSum(result.sales, item.sales),
					salesPeriod: null,
					storeBookId: ids,
					storeId: null,
					type: 'ALL_TIME',
					unpaidKenp: quickIntSum(result.unpaidKenp, item.unpaidKenp),
					unpaidRefunds: quickIntSum(result.unpaidRefunds, item.unpaidRefunds),
					unpaidSales: quickIntSum(result.unpaidSales, item.unpaidSales)
				};
			});

		default:
			return sales;
	}
}

/**
 * Books reducer
 * @param state
 * @param action
 * @returns {*} new state
 */
export const bookSalesReducer = (
	state = {
		initialState: true,
		initialTotalsState: true,
		initialKenpTotalsState: true,
		initialPublisherTotalsState: true,
		initialAuthorTotalsState: true,
		byId: {},
		allIds: [],
		totals: [],
		totalsDate: null,
		kenpTotals: [],
		kenpTotalsDate: null,
		authorTotals: {},
		authorTotalsDate: null,
		publisherTotals: {},
		publisherTotalsDate: null
	},
	action
) => {
	let newState, payload;

	switch (action.type) {
		case feathersServices.getGetFulfilledAction(SERVICE.BOOK_SALES):
			newState = { ...state };
			newState.initialTotalsState = false;

			payload = action.payload || {};

			if (payload.id === 'totals') {
				newState.totals = [...(payload.data || [])];
				// newState.publisherTotals = publisherTotals;

				//	Need this for comparisons to refresh correctly :/
				newState.totalsDate = new Date();
			}

			if (payload.id === 'kenp-totals') {
				if (Array.isArray(payload.data)) {
					newState.kenpTotals = [...payload.data];

					newState.kenpTotals.forEach((kenp, index) => {
						const a = parseFloat(kenp.kenpRate);
						const b = !newState.kenpTotals[index + 1]
							? a
							: parseFloat(newState.kenpTotals[index + 1].kenpRate);

						//	Change 1 = increase, -1 = decrease, 0 = same
						kenp.change = a > b ? 1 : a < b ? -1 : 0;
					});
				}

				//	Need this for comparisons to refresh correctly :/
				newState.kenpTotalsDate = new Date();
			}

			if (payload.id === 'author-totals') {
				const totals = {};

				payload.data.forEach(item => {
					if (!totals[item.authorId]) {
						totals[item.authorId] = [];
					}

					totals[item.authorId].push(item);
				});

				newState.authorTotals = totals;

				//	Need this for comparisons to refresh correctly :/
				newState.authorTotalsDate = new Date();
			}

			return newState;

		case feathersServices.getFindFulfilledAction(SERVICE.BOOK_SALES):
			payload = action.payload.data || [];

			//	Don't process partial records
			if (payload && payload[0] && !payload[0].id) {
				return state;
			}

		//	Fallthrough as the action is the same
		case ADD_BOOK_SALES:
			newState = { ...state };
			newState.initialState = false;

			payload = payload || action.payload;

			addItems(newState, payload);

			return newState;

		// GET_TOTALS
		// GET_KENP_TOTALS
		default:
			return state;
	}
};

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {
			[feathersServices.getGetFulfilledAction(SERVICE.BOOK_SALES)+'#totals']: (newState, action) => {
				let payload = action.payload || {};

				newState.totals = [...(payload.data || [])];

				//	Need this for comparisons to refresh correctly :/
				newState.totalsDate = new Date();

				return newState;
			},
			[feathersServices.getGetFulfilledAction(SERVICE.BOOK_SALES)+'#kenp-totals']: (newState, action) => {
				let payload = action.payload || {};

				if (Array.isArray(payload.data)) {
					newState.kenpTotals = [...payload.data];

					newState.kenpTotals.forEach((kenp, index) => {
						const a = parseFloat(kenp.kenpRate);
						const b = !newState.kenpTotals[index + 1]
							? a
							: parseFloat(newState.kenpTotals[index + 1].kenpRate);

						//	Change 1 = increase, -1 = decrease, 0 = same
						kenp.change = a > b ? 1 : a < b ? -1 : 0;
					});
				}

				//	Need this for comparisons to refresh correctly :/
				newState.kenpTotalsDate = new Date();

				return newState;
			},
			[feathersServices.getGetFulfilledAction(SERVICE.BOOK_SALES)+'#author-totals']: (newState, action) => {
				const totals = {}, payload = action.payload || {};

				payload.data.forEach(item => {
					if (!totals[item.authorId]) {
						totals[item.authorId] = [];
					}

					totals[item.authorId].push(item);
				});

				newState.authorTotals = totals;

				//	Need this for comparisons to refresh correctly :/
				newState.authorTotalsDate = new Date();

				return newState;
			},
		}
	}
);
