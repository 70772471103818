import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import feathersAPI from '@wpa/feathers-client';
import can from '../abilities';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Assistant from './Assistant';

import { Menu } from 'antd';

export class Nav extends React.Component {
	constructor(props) {
		super(props);

		this.logout = this.logout.bind(this);
	}

	async logout(e) {
		e.preventDefault();
		await feathersAPI.logout();

		//	Delete the parent domain cookie if there is one
		document.cookie =
			'wpa-api=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.' +
			process.env.REACT_APP_PUBLIC_URL.replace(/https?:\/\//, '') +
			';';
		window.location.assign(process.env.REACT_APP_AUTH_URL);
	}

	render() {
		const isAdmin = can.accessAdmin(this.props.store.currentUser); //(USER_ACCESS_ADMIN, this.props.store.currentUser);

		const menu = [
			{
				type: 'account',
				link: '/account',
				label: 'Account',
				icon: ['far', 'user']
			}
		];

		if (can.accessBooks(this.props.store.currentUser)) {
			menu.push({
				type: 'books',
				link: '/books',
				label: 'Books',
				icon: ['far', 'book']
			});
		}
		if (can.accessAuthors(this.props.store.currentUser)) {
			menu.push({
				type: 'authors',
				link: '/authors',
				label: 'Authors',
				icon: ['far', 'address-book']
			});
		}
		if (can.accessPublishers(this.props.store.currentUser)) {
			menu.push({
				type: 'publishers',
				link: '/publishers',
				label: 'Publishers',
				icon: ['far', 'briefcase']
			});
		}
		if (can.accessWorlds(this.props.store.currentUser)) {
			menu.push({
				type: 'worlds',
				link: '/worlds',
				label: 'Worlds',
				icon: ['far', 'globe']
			});
		}
		if (can.accessSites(this.props.store.currentUser)) {
			menu.push({
				type: 'sites',
				link: '/sites',
				label: 'Sites',
				icon: ['far', 'desktop']
			});
		}

		if (isAdmin) {
			menu.push({
				type: 'admin',
				link: '/admin',
				label: 'Admin',
				icon: ['far', 'cogs']
			});
		}

		return (
			<div id="appNav">
				<p className="text-center menu-dashboard">
					<Link to="/">
						<img
							src="/images/logo-icon-w.png"
							alt="WriterPA Logo"
							width="32"
							className="icon"
							title="Dashboard"
						/>
					</Link>
				</p>
				<Menu theme="dark" mode="inline" style={{ backgroundColor: 'none' }}>
					{menu.map(menuItem => (
						<Menu.Item key={menuItem.type} className={'menu-'+menuItem.type}>
							<Link to={menuItem.link}>
								<FontAwesomeIcon icon={menuItem.icon} fixedWidth />
								<span>{menuItem.label}</span>
							</Link>
						</Menu.Item>
					))}
				</Menu>
				<ul className="list-unstyled" style={{ marginLeft: '2em' }}>
					<li>
						<a href="/logout" onClick={this.logout}>
							Logout
						</a>
					</li>
					<li>
						<a href="/privacy">Privacy policy</a>
					</li>
				</ul>

				{/*	Always present */}
				<Assistant />
			</div>
		);
	}
}

export default connect(state => ({
	store: { currentUser: state.currentUser }
}))(withRouter(Nav));
