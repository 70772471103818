import {createReducer} from '../helpers';

/*
 *	Constants
 */

const serviceName = 'SITE_LAYOUTS';
export const ADD_SITE_LAYOUTS = 'ADD_'+ serviceName;
export const UPDATE_SITE_LAYOUTS = 'UPDATE_'+ serviceName;
export const REMOVE_SITE_LAYOUTS = 'REMOVE_'+ serviceName;

/*
 *	Helper functions
 */

export const addSiteLayouts = items => ({
	type: ADD_SITE_LAYOUTS,
	payload: items
});
export const removeSiteLayouts = items => ({
	type: REMOVE_SITE_LAYOUTS,
	payload: items
});

export function getAllSiteLayouts(state) {
	const stateSlice = state.db.siteLayouts;
	return (stateSlice && stateSlice.allIds && stateSlice.allIds.map(id => stateSlice.byId[id])) || [];
}

// export function getAuthorSocialMediaMap(state, authorId) {
// 	const stateSlice = state.db.socialMedias;
// 	const o = {};
// 	stateSlice && stateSlice.allIds && stateSlice.allIds
// 		.filter(id => stateSlice.byId[id].resourceTypeId === 1 && stateSlice.byId[id].resourceId === authorId)
// 		.forEach(id => {
// 			o[stateSlice.byId[id].typeId] = stateSlice.byId[id];
// 		});

// 	return o;
// }

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);