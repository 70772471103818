import { getItemIdsByParam } from '../helpers';
import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'SITE_PAGE_CONTENTS';
export const ADD_SITE_PAGE_CONTENTS = 'ADD_'+ serviceName;
export const UPDATE_SITE_PAGE_CONTENTS = 'UPDATE_'+ serviceName;
export const REMOVE_SITE_PAGE_CONTENTS = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */

export const addSitePageContents = items => ({
	type: ADD_SITE_PAGE_CONTENTS,
	payload: items
});

export const getLatestSitePageContentForBlockId = (state, blockId) => {
	const stateSlice = state.db.sitePageContents;
	const contents = getItemIdsByParam(stateSlice, 'blockId', blockId);
	
	const mostRecentDate = new Date(Math.max.apply(null, contents.map( e => {
		return new Date(stateSlice.byId[e].updatedAt);
	})));
	
	const mostRecentObject = contents.filter( e => { 
		const d = new Date(stateSlice.byId[e].updatedAt); 
		//	@todo: double check this should be === and not == for some reason
		return d.getTime() === mostRecentDate.getTime();
	})[0];

	return stateSlice.byId[mostRecentObject];
};

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);