import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@wpa/components/lib/Form';
import ImageEdit from './ImageEdit';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import { imagePath } from '../utils/imagePath';
import Logger from '@wpa/logger';
import { useDispatch, useSelector } from 'react-redux';
import { useMachine } from '@xstate/react';
import { Card } from 'antd';
import { Machine } from 'xstate';
import { assign } from 'xstate';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { getAuthorById } from '../reducers/db/authors';
import { getSiteById } from '../reducers/db/sites';
import { SpinnerSmallWithText } from '@wpa/components/lib/Spinner';
import { getBookById } from '../reducers/db/books';

const getImagePreviewUrl = (image) => ({
	imagePreviewUrl:
		image && image.path ? imagePath(encodeURI(image.path)) : null,
});

function getAuthorImages(authorId) {
	return () =>
		feathersServices[SERVICE.AUTHOR_IMAGES].find({
			query: {
				$limit: 50,
				authorId,
			},
		});
}

function getBookCoverImages(bookId) {
	return () =>
		feathersServices[SERVICE.BOOK_IMAGES].find({
			query: {
				$limit: 50,
				bookId,
			},
		});
}

function getSiteImages(siteId) {
	return () =>
		feathersServices[SERVICE.SITE_IMAGES].find({
			query: {
				$limit: 50,
				siteId: siteId,
			},
		});
}

const authorImageConfig = (authorId) => ({
	uploadType: 'IMAGE_AUTHOR',
	uploadResource: {
		id: authorId,
		isPrimary: true,
		// ???
		// location: props.id
	},
	selectedService: SERVICE.AUTHOR_IMAGES,
	selectedParams: {
		isPrimary: true,
	},
	selectedQuery: { authorId },
	selectedRemoveParams: {
		isPrimary: false,
	},
	selectedRemoveQuery: { authorId },
});

const bookCoverImageConfig = (bookId) => ({
	uploadType: 'IMAGE_COVER',
	uploadResource: {
		id: bookId,
		isPrimary: true,
	},
	selectedService: SERVICE.BOOK_IMAGES,
	selectedParams: {
		isPrimary: true,
	},
	selectedQuery: { bookId },
	selectedRemoveParams: {
		isPrimary: false,
	},
	selectedRemoveQuery: { bookId },
});

const siteImageConfig = (siteId, location) => ({
	uploadType: 'IMAGE_SITE',
	uploadResource: {
		id: siteId,
		isPrimary: true,
	},
	selectedService: SERVICE.SITE_IMAGES,
	selectedParams: {
		isPrimary: true,
	},
	selectedQuery: { siteId },
	selectedRemoveParams: {
		isPrimary: false,
	},
	selectedRemoveQuery: { siteId },
});

export const STATE = {
	DISPLAY: 'display',
	LOADING: 'loading',
	ERROR: 'error',
	SELECT_IMAGE: 'selectImage',
	REMOVING_IMAGE: 'removeImage',
};

export const EVENT = {
	REMOVE_IMAGE: 'removeImage',
	UPDATE_IMAGE: 'updateImage',
	OPEN_MODAL: 'openModal',
	CLOSE_MODAL: 'closeModal',
	ERROR: 'error',
	LOAD: 'load',
};

const ACTION = {
	HIDE_MODAL: 'hideModal',
	SHOW_MODAL: 'showModal',
	REMOVE_IMAGE: 'removeImage',
	ERROR_MESSAGE: 'errorMessage',
};

const sm = Machine(
	{
		initial: STATE.LOADING,
		context: {
			error: false,
			errorMessage: '',
			//	Used when multiple actions are handled
			action: '',
		},
		states: {
			[STATE.LOADING]: {
				onEntry: [
					assign((context, event) => ({
						...event,
					})),
				],
				invoke: {
					id: 'loading',
					src: (context, event) => {
						//	Call a custom loading function if one was passed in
						if (context.loadPreview) {
							return context.loadPreview(context, event);
						}

						//	Otherwise fill in the path
						return new Promise((resolve) =>
							resolve(getImagePreviewUrl({ path: context.path }))
						);
					},
					onDone: [
						{
							target: STATE.SELECT_IMAGE,
							actions: assign({
								imagePreviewUrl: (context, event) =>
									(event.data &&
										event.data.imagePreviewUrl) ||
									null,
							}),
							cond: (context, event) => !!context.showImagePicker,
						},
						{
							target: STATE.DISPLAY,
							actions: assign({
								imagePreviewUrl: (context, event) =>
									(event.data &&
										event.data.imagePreviewUrl) ||
									null,
							}),
						},
					],
					onError: {
						target: STATE.ERROR,
						actions: [ACTION.ERROR_MESSAGE],
					},
				},
			},
			[STATE.DISPLAY]: {
				on: {
					[EVENT.OPEN_MODAL]: {
						target: STATE.SELECT_IMAGE,
						// actions: [
						// 	(context, event) => {
						// 		context.showImagePicker = true;
						// 	},
						// ],
					},
					[EVENT.REMOVE_IMAGE]: {
						target: STATE.REMOVING_IMAGE,
						// actions: [
						// 	(context, event) => {
						// 		context.imagePreviewUrl = null;
						// 	},
						// ],
					},
					[EVENT.UPDATE_IMAGE]: {
						target: STATE.DISPLAY,
						actions: [
							(context, event) => {
								context.imagePreviewUrl = event.imagePreviewUrl;
							},
						],
					},
					[EVENT.LOAD]: {
						target: STATE.LOADING,
						// actions: [
						// 	(context, event) => {
						// 		context.imagePreviewUrl = null;
						// 	}
						// ]
					},
				},
			},
			[STATE.REMOVING_IMAGE]: {
				invoke: {
					id: 'removing',
					src: (context, event) => {
						//	Call a custom loading function if one was passed in
						if (context.onRemoveImage) {
							return context.onRemoveImage(context, event);
						}
						//	Otherwise mark all items for this resource
						return context.dispatch(
							feathersServices[context.selectedService].patch(
								null,
								context.selectedRemoveParams,
								{
									query: {
										...context.selectedRemoveQuery,
									},
								}
							)
						);
					},
					onDone: {
						target: STATE.DISPLAY,
						actions: [ACTION.REMOVE_IMAGE]
					},
					onError: {
						target: STATE.ERROR,
						actions: [ACTION.ERROR_MESSAGE],
					},
				},
			},
			[STATE.SELECT_IMAGE]: {
				exit: [ACTION.HIDE_MODAL],
				entry: [ACTION.SHOW_MODAL, 'showModal'],
				on: {
					[EVENT.CLOSE_MODAL]: {
						target: STATE.LOADING,
						// actions: [
						// 	(context, event)  => {
						// 		context.showImagePicker = false;
						// 	},
						// ],
					},
					[EVENT.LOAD]: {
						target: STATE.LOADING,
					},
				},
			},
			[STATE.ERROR]: {
				onEntry: [
					assign({
						error: (context, event) => event.error || context.error,
						errorMessage: (context, event) =>
							event.errorMessage || context.errorMessage,
					}),
					(context, event) =>
						Logger.error(
							'Default error response:',
							context.errorMessage,
							context.error
						),
				],
				onExit: [
					//	Always clear internal state
					assign({ error: false, errorMessage: null }),
				].filter(Boolean),
				on: {
					[EVENT.LOAD]: {
						target: STATE.LOADING,
					},
					[EVENT.DISPLAY]: {
						target: STATE.DISPLAY,
					},
				},
			},
		},
	},
	{
		actions: {
			[ACTION.HIDE_MODAL]: assign(() => ({
				showImagePicker: false,
			})),
			[ACTION.SHOW_MODAL]: assign(() => ({
				showImagePicker: true,
			})),
			[ACTION.REMOVE_IMAGE]: assign(() => ({
				imagePreviewUrl: null,
				path: null,
			})),
			[ACTION.ERROR_MESSAGE]: assign((context, event) => ({
				error: event.data,
				//	If we get back an error object, pluck the message, otherwise just use the error value
				errorMessage:
					Logger.log(event) || event.data.message || event.data,
			})),
		},
	}
);

const onUpdateImage = (send) => (e) => {
	e.preventDefault();
	send(EVENT.OPEN_MODAL, {});
};

export const ImageCard = ({
	// id,
	showTitle = false,
	// siteId,
	// headerId,
	path,
	// imagePath,
	title = 'Select Image',
	loadPreview,
	uploadType,
	uploadResource,
	selectedService,
	selectedParams,
	selectedQuery,
	selectedRemoveParams,
	selectedRemoveQuery,
	getImages,
	getDispatchImages,
	onImageSave,
	onRemoveImage,
	...props
}) => {
	const dispatch = useDispatch();
	const button = title || 'Select Image';

	const [state, send] = useMachine(
		sm.withContext({
			dispatch,
			path,
			loadPreview,
			showImagePicker: false,
			imagePreviewUrl: '',
			selectedService,
			selectedRemoveParams,
			selectedRemoveQuery,
			onRemoveImage,
		})
	);

	useEffect(() => {
		send(EVENT.LOAD, {
			path: path,
		});
	}, [send, path]);

	const { showImagePicker, imagePreviewUrl } = state.context;

	const onDeleteImage = useCallback(
		(e) => {
			e.preventDefault();

			send(EVENT.REMOVE_IMAGE);
		},
		[send]
	);

	const onClose = useCallback(() => {
		return send(EVENT.CLOSE_MODAL);
	}, [send]);

	// useEffect(() => {
	// 	const subscription = service.subscribe((state) => {
	// 		// simple state logging
	// 		console.log(
	// 			'--> STATE ImageCard: ',
	// 			state.value,
	// 			state.context,
	// 			state
	// 		);
	// 	});

	// 	return subscription.unsubscribe;
	// }, [service]);

	const isLoading = state.matches(STATE.LOADING);
	const isRemoving = state.matches(STATE.REMOVING_IMAGE);
	const isDisabled = isLoading || isRemoving;

	return (
		<Card title={showTitle && title}>
			{isLoading && <SpinnerSmallWithText label="Loading..." />}

			{!isLoading && imagePreviewUrl && (
				<Button
					type="secondary"
					onClick={onDeleteImage}
					disabled={isDisabled}
					loading={isRemoving}
					block
				>
					Remove image
				</Button>
			)}

			{!isLoading && imagePreviewUrl && (
				<img
					alt=""
					src={imagePreviewUrl}
					width={'100%'}
					onClick={onUpdateImage(send)}
				/>
			)}
			{!isLoading && (
				<Button
					type="primary"
					onClick={onUpdateImage(send)}
					disabled={isDisabled}
					block
				>
					{button}
				</Button>
			)}
			<ImageEdit
				isOpen={showImagePicker}
				onClose={onClose}
				onImageSave={onImageSave ? onImageSave(dispatch) : null}
				title={'Update ' + title}
				uploadType={uploadType}
				uploadResource={uploadResource}
				selectedParams={selectedParams}
				selectedQuery={selectedQuery}
				selectedService={selectedService}
				getImages={getImages}
				getDispatchImages={getDispatchImages}
				//	Pass on other parent config
				{...props}
			/>
		</Card>
	);
};

ImageCard.propTypes = {
	/**
	 * Image type
	 */
	// id: PropTypes.string.isRequired,
	/**
	 * Site ID
	 */
	// siteId: PropTypes.number.isRequired,
	/**
	 * Site header ID
	 */
	// headerId: PropTypes.number,
	/**
	 * Show / hide title
	 */
	showTitle: PropTypes.bool,
	/**
	 * Image picker title
	 */
	title: PropTypes.string,
	/**
	 * The path to the default image
	 */
	path: PropTypes.string,
	/**
	 * The path to the default image
	 */
	loadPreview: PropTypes.func,
	/**
	 * The function to get images to select from
	 */
	getImages: PropTypes.func,
	/**
	 * The function to get images to select from if using dispatch()
	 */
	getDspatchImages: PropTypes.func,
	/**
	 * The function to remove the image (deselect)
	 */
	onRemoveImage: PropTypes.func,
	/**
	 * The function to call after the image was saved onImageSave(disatch)
	 */
	onImageSave: PropTypes.func,

	uploadType: PropTypes.string,
	uploadResource: PropTypes.object,
	selectedService: PropTypes.string,
	selectedParams: PropTypes.object,
	selectedQuery: PropTypes.object,
	selectedRemoveParams: PropTypes.object,
	selectedRemoveQuery: PropTypes.object,
};

export const AuthorImageCard = ({ authorId, ...props }) => {
	const author = useSelector((state) => getAuthorById(state, authorId));

	const path =
		author &&
		author.ProfilePic &&
		author.ProfilePic[0] &&
		author.ProfilePic[0].path
			? author.ProfilePic[0].path
			: null;

	return (
		<ImageCard
			path={path}
			getDispatchImages={getAuthorImages(authorId)}
			{...authorImageConfig(authorId)}
			{...props}
		/>
	);
};

export const BookCoverImageCard = ({
	bookId,
	bookTitle = 'Book',
	...props
}) => {
	const book = useSelector((state) => getBookById(state, bookId));
	
	const path =
		book && book.Cover && book.Cover.Image && book.Cover.Image.path
			? book.Cover.Image.path
			: null;

	return (
		<ImageCard
			path={path}
			book={book}
			title={'Update ' + bookTitle + ' Cover'}
			getDispatchImages={getBookCoverImages(bookId)}
			{...bookCoverImageConfig(bookId)}
			{...props}
		/>
	);
};

export const SiteBackgroundImageCard = ({ siteId, ...props }) => {
	const site = useSelector((state) => getSiteById(state, siteId)) || {};

	const path =
		site && site.BackgroundImage ? site.BackgroundImage.path : null;

	const onImageSave = useCallback((dispatch) => (selectedImageId) => {
		return dispatch(
			feathersServices[SERVICE.SITES]
				.patch(
					siteId, 
					{
						backgroundImageId: selectedImageId,
					}
				)
		);
	}, [siteId]);

	const onRemoveImage = useCallback((context, event) => {
		return context.dispatch(
			feathersServices[SERVICE.SITES]
			.patch(
				siteId,
				{
					backgroundImageId: null,
				}
			)
		);
	}, [siteId]);

	return (
		<ImageCard
			path={path}
			onImageSave={onImageSave}
			onRemoveImage={onRemoveImage}
			getDispatchImages={getSiteImages(siteId)}
			{...siteImageConfig(siteId)}
			{...props}
		/>
	);
};

export const SiteHeaderLogoImageCard = ({ siteId, headerId, ...props }) => {
	const loadPreview = useCallback(
		(context, event) => {
			return context
				.dispatch(feathersServices[SERVICE.SITE_HEADERS].get(headerId))
				.then(({ value: data }) => {
					const image = data['LogoImage'];
					return getImagePreviewUrl(image);
				});
		},
		[headerId]
	);

	const onImageSave = useCallback((dispatch) => (selectedImageId) => {
		return dispatch(
			feathersServices[SERVICE.SITE_HEADERS]
				.patch(
					headerId, 
					{
						logoImageId: selectedImageId,
					}
				)
		);
	}, [headerId]);

	const onRemoveImage = useCallback((context, event) => {
		return context.dispatch(
			feathersServices[SERVICE.SITE_HEADERS]
			.patch(
				headerId,
				{
					logoImageId: null,
				}
			)
		);
	}, [headerId]);

	return (
		<ImageCard
			onImageSave={onImageSave}
			onRemoveImage={onRemoveImage}
			loadPreview={loadPreview}
			getDispatchImages={getSiteImages(siteId)}
			{...siteImageConfig(siteId)}
			{...props}
		/>
	);
};

export const SiteHeaderBackgroundImageCard = ({
	siteId,
	headerId,
	...props
}) => {
	const loadPreview = useCallback(
		(context, event) => {
			return context
				.dispatch(feathersServices[SERVICE.SITE_HEADERS].get(headerId))
				.then(({ value: data }) => {
					
					const image = data['BackgroundImage'];
					return getImagePreviewUrl(image);
				});
		},
		[headerId]
	);

	const onImageSave = useCallback((dispatch) => (selectedImageId) => {
		return dispatch(
			feathersServices[SERVICE.SITE_HEADERS]
				.patch(
					headerId, 
					{
						backgroundImageId: selectedImageId,
					}
				)
		);
	}, [headerId]);

	const onRemoveImage = useCallback((context, event) => {
		return context.dispatch(
			feathersServices[SERVICE.SITE_HEADERS]
			.patch(
				headerId,
				{
					backgroundImageId: null,
				}
			)
		);
	}, [headerId]);

	return (
		<ImageCard
			onImageSave={onImageSave}
			onRemoveImage={onRemoveImage}
			loadPreview={loadPreview}
			getDispatchImages={getSiteImages(siteId)}
			{...siteImageConfig(siteId)}
			{...props}
		/>
	);
};

export default ImageCard;
