import React from 'react';
import PropTypes from 'prop-types';
import {
	Modal, ModalHeader, ModalBody
} from 'reactstrap';
import AuthorCreateForm from "./CreateForm";
import {Button} from "@wpa/components/lib/Form";

export class AuthorAddButton extends React.Component {
	static displayName = 'AuthorAddButton';
	static propTypes = {
		showModal: PropTypes.bool,
	};
	static defaultProps = {
		showModal: false,
	};

	constructor(props){
		super(props);

		this.state = {
			showModal: props.showModal,
		};

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(){
		console.log(this.state);
		this.setState({
			showModal: !this.state.showModal
		});
	}

	render(){
		return (
			<div style={{display: "inline"}}>
				<Button type="primary" onClick={this.handleClick}>
					<i className="far fa-plus"></i>{this.props.children}
				</Button>
				<Modal
					isOpen={this.state.showModal}
					toggle={this.handleClick}
					autoFocus={false}
					fade={false}
				>
					<ModalHeader toggle={this.handleClick}>
						Add Author
					</ModalHeader>
					<ModalBody>
						<AuthorCreateForm onAuthorCreateFormClose={this.handleClick} />
					</ModalBody>
				</Modal>
			</div>
		)
	}
}

export default AuthorAddButton;