import {createReducer} from '../helpers';

/*
 * Constants
 */

const serviceName = 'RESOURCE_INVITES';
// export const INITIAL_BOOK_STATUSES_LOADED = 'INITIAL_BOOK_STATUSES_LOADED';
export const ADD_RESOURCE_INVITES = 'ADD_'+ serviceName;
export const UPDATE_RESOURCE_INVITES = 'UPDATE_'+ serviceName;
export const REMOVE_RESOURCE_INVITES = 'REMOVE_'+ serviceName;

export const RESOURCE_INVITES_STATUS = {
	OFFERED: 1,
	ACCEPTED: 2,
	REJECTED: 3,
	RESCINDED: 4,
};

/*
 * Helper functions
 */

export const addResourceInvites = (items) => ({
	type: ADD_RESOURCE_INVITES,
	payload: items
});

export function getAllOfferedResourcesByType(state, type){
	const stateSlice = state.db.resourceInvites;
	const userId = 'me';

	return stateSlice.allIds
		.filter(id => stateSlice.byId[id].receivingUserId === userId && stateSlice.byId[id].resourceType === type)
		.map(id => stateSlice.byId[id])
	;
}

/*
 *	Reducer
 */
export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);