import { getItemIdByParam } from '../helpers';
import Logger from '@wpa/logger';
import {createReducer} from '../helpers';

/*
 * Constants
 */
const serviceName = 'SITE_PAGE_BOOKS';
export const ADD_SITE_PAGE_BOOKS = 'ADD_'+ serviceName;
export const UPDATE_SITE_PAGE_BOOKS = 'UPDATE_'+ serviceName;
export const REMOVE_SITE_PAGE_BOOKS = 'REMOVE_'+ serviceName;

/*
 * Helper functions
 */

export const addSitePageBooks = items => ({
	type: ADD_SITE_PAGE_BOOKS,
	payload: items
});

export const getByPageId = (state, pageId) => {
	Logger.warn('DEPRECATED: pageId not stored on block content any more');
	const stateSlice = state.db.sitePageBooks;
	const itemId = getItemIdByParam(stateSlice, 'pageId', pageId);
	return stateSlice.byId[itemId];
};

export const getLatestSitePageBooksForBlockId = (state, blockId) => {
	const stateSlice = state.db.sitePageBooks;
	const id = getItemIdByParam(stateSlice, 'blockId', blockId);
	
	return stateSlice.byId[id];
};

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);