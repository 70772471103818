import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { feathersServices, SERVICE } from '@wpa/feathers-client';
import {
	Col,
	Form,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label
} from 'reactstrap';
import {
	Input,
	Button,
	formHandleInputChange,
	labelSizes
} from '@wpa/components/lib/Form';
import { getAllPublishers } from '../reducers/db/publishers';

export const RESOURCE_TYPE = {
	BOOK: 'book'
};

const resourceTypeMap = Object.keys(RESOURCE_TYPE).map(r => RESOURCE_TYPE[r]);

export class InviteForm extends React.Component {
	static displayName = 'InviteForm';
	static propTypes = {
		resourceId: PropTypes.number.isRequired,
		type: PropTypes.oneOf(resourceTypeMap).isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			offeringUserName: props.userName,
			inviteName: '',
			inviteEmail: '',
			note: '',
			canShare: false,
			canEditDetails: false,
			canEditStatus: false,
			canViewSales: false,
			canEditBlurbs: false,
			canViewAssets: false,
			canEditAssets: false,
			publishers: {}
		};

		this.handleInputChange = formHandleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(e) {
		e.preventDefault();

		this.props.dispatch(
			feathersServices[SERVICE.RESOURCE_INVITES].create(
				{
					offeringUserName: this.state.offeringUserName,
					receivingUserName: this.state.inviteName,
					receivingUserEmail: this.state.inviteEmail,
					resourceType: this.props.type,
					resourceId: this.props.resourceId,
					note: this.state.note,
					//	@todo: different options here for different resource types
					options: JSON.stringify({
						canShare: this.state.canShare,
						canEditDetails: this.state.canEditDetails,
						canEditStatus: this.state.canEditStatus,
						canViewSales: this.state.canViewSales,
						canEditBlurbs: this.state.canEditBlurbs,
						canViewAssets: this.state.canViewAssets,
						canEditAssets: this.state.canEditAssets,
						publishers: Object.keys(this.state.publishers) || []
					})
				},
				{}
			)
		);

		//	@todo: convert this into a state component
		this.props.toggle();
	}

	handleLabelClick(e) {
		//	If we don't stop here the modal closes :/
		e.stopPropagation();
	}

	render() {
		const { userName, publishers } = this.props;

		return (
			<Form
				onChange={this.handleInputChange}
				onSubmit={this.handleSubmit}
				onClick={this.handleLabelClick}
			>
				<FormGroup row>
					<Label sm={5} for="senderName">
						Who is the invite from?
					</Label>
					<Col sm={7}>
						<InputGroup>
							<InputGroupText>
								<i className="fas fa-fw fa-user" />
							</InputGroupText>
							<Input
								name="offeringUserName"
								id="offeringUserName"
								type="text"
								value={this.state.offeringUserName}
								defaultValue={userName}
							/>
						</InputGroup>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label tag="div" xs={12}>
						Who is the invite for?
					</Label>
					<Col sm={5}>
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="far fa-fw fa-user" />
								</InputGroupText>
							</InputGroupAddon>
							<Input
								name="inviteName"
								type="text"
								placeholder="Jane Doe"
								value={this.state.inviteName}
								defaultValue=""
							/>
						</InputGroup>
					</Col>
					<Col sm={7}>
						<InputGroup>
							<InputGroupText>
								<i className="far fa-fw fa-envelope" />
							</InputGroupText>
							<Input
								name="inviteEmail"
								type="text"
								autoComplete="off"
								value={this.state.inviteEmail}
								defaultValue=""
								placeholder="joe@example.com"
							/>
						</InputGroup>
					</Col>
				</FormGroup>

				<FormGroup>
					<Label {...labelSizes}>Note:</Label>
					<Col>
						<Input
							type="textarea"
							name="note"
							value={this.state.note}
							defaultValue=""
							placeholder="Write your note here..."
						/>
					</Col>
				</FormGroup>

				<FormGroup tag="fieldset" row>
					<legend className="col-form-label col">
						<i className="far fa-fw fa-key" /> Permissions:
					</legend>
					<Col xs={12}>
						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									name="canShare"
									defaultValue={''}
									value={this.state.canShare}
								/>{' '}
								Can share?
							</Label>
						</FormGroup>
					</Col>
					<Col sm={6}>
						<legend className="col-form-label col" style={{ paddingLeft: 0 }}>
							<i className="far fa-fw fa-pencil" /> Can edit:
						</legend>
						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									name="canEditDetails"
									defaultValue={''}
									value={this.state.canEditDetails}
								/>{' '}
								details
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									name="canEditStatus"
									defaultValue={''}
									value={this.state.canEditStatus}
								/>{' '}
								status
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									name="canEditBlurbs"
									defaultValue={''}
									value={this.state.canEditBlurbs}
								/>{' '}
								blurbs
							</Label>
						</FormGroup>

						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									name="canEditAssets"
									defaultValue={''}
									value={this.state.canEditAssets}
								/>{' '}
								assets
							</Label>
						</FormGroup>
					</Col>
					<Col sm={6}>
						<legend className="col-form-label col" style={{ paddingLeft: 0 }}>
							<i className="far fa-fw fa-eye" /> Can view:
						</legend>
						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									name="canViewSales"
									defaultValue={''}
									value={this.state.canViewSales}
								/>{' '}
								sales
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									name="canViewAssets"
									defaultValue={''}
									value={this.state.canViewAssets}
								/>{' '}
								assets
							</Label>
						</FormGroup>
					</Col>
				</FormGroup>
				<FormGroup>
					<legend>Add to publisher?</legend>
					{publishers.map(publisher => (
						<FormGroup check key={publisher.id}>
							<Label check>
								<Input
									type="checkbox"
									name={'publishers[]'}
									defaultValue={''}
									value={publisher.id}
								/>{' '}
								{publisher.name}
							</Label>
						</FormGroup>
					))}
				</FormGroup>
				{/*{error && <Row>*/}
				{/*<Col>*/}
				{/*<Notice color="danger">{errorMessage}</Notice>*/}
				{/*</Col>*/}
				{/*</Row>}*/}
				{/*{inviteSent &&*/}
				{/*<Notice color="success">Invitation sent!</Notice>*/}
				{/*}*/}
				<FormGroup>
					<Button type="primary" htmlType="submit">Share</Button>
				</FormGroup>
			</Form>
		);
	}
}

export default connect(state => ({
	userName: state.currentUser.name,
	publishers: getAllPublishers(state) || []
}))(InviteForm);
