//	eslint-disable-next-line
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { Button } from '@wpa/components/lib/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SitePageCreateForm from './CreateForm';

export const SitePageAddButton = ({siteId, size, className, ...props}) => {
	const [showModal, setShowModal]  = useState(props.showModal || false);

	const handleClick = () => {
		setShowModal(! showModal);
	};

	return (
		<div style={{ display: 'inline' }} className={className}>
			<Button type="primary" onClick={handleClick} size={size} title="Add New Page">
				<FontAwesomeIcon icon={['far', 'plus']} />
				{props.children}
			</Button>
			<Modal
				isOpen={showModal}
				toggle={handleClick}
				autoFocus={false}
				fade={false}
			>
				<ModalHeader toggle={handleClick}>Add Page</ModalHeader>
				<ModalBody>
					<SitePageCreateForm onFinish={handleClick} siteId={siteId} />
				</ModalBody>
			</Modal>
		</div>
	);
};

export default SitePageAddButton;
