import {createReducer} from '../helpers';

/*
 *	Constants
 */

const serviceName = 'SOCIAL_MEDIAS';
export const ADD_SOCIAL_MEDIAS = 'ADD_'+ serviceName;
export const UPDATE_SOCIAL_MEDIAS = 'UPDATE_'+ serviceName;
export const REMOVE_SOCIAL_MEDIAS = 'REMOVE_'+ serviceName;

/*
 *	Helper functions
 */

export const addSocialMedias = items => ({
	type: ADD_SOCIAL_MEDIAS,
	payload: items
});
export const removeSocialMedias = items => ({
	type: REMOVE_SOCIAL_MEDIAS,
	payload: items
});

export function getAllSocialMedias(state) {
	const stateSlice = state.db.socialMedias;
	return (stateSlice && stateSlice.allIds && stateSlice.allIds.map(id => stateSlice.byId[id])) || [];
}

export function getAuthorSocialMediaMap(state, authorId) {
	const stateSlice = state.db.socialMedias;
	const o = {};
	stateSlice && stateSlice.allIds && stateSlice.allIds
		.filter(id => stateSlice.byId[id].resourceTypeId === 1 && stateSlice.byId[id].resourceId === authorId)
		.forEach(id => {
			o[stateSlice.byId[id].typeId] = stateSlice.byId[id];
		});

	return o;
}

/*
 *	Reducer
 */

export const reducer = createReducer(
	{},
	serviceName,
	{
		addActions: [],
		removeActions: [],
		actions: {}
	}
);